import React from 'react'
import Chart from 'chart.js/auto'
import { useNavigate } from 'react-router-dom'

const ReportChart = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='flex flex-col items-center'>
        <div className='mt-8'>
          <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
            Reports
          </h1>
        </div>
        <div className='my-4'>
          <button
            onClick={() => navigate('/testrunsummary')}
            className='block text-blue-600 pb-2 text-xl font-semibold border border-blue-600 rounded-lg p-2 hover:bg-blue-100'
            href='/PieChart'
          >
            TestCase Status Report
          </button>
        </div>
        <div className='my-4'>
          <button
            onClick={() => navigate('/userproductivityreport')}
            className='block text-blue-600 pb-2 text-xl font-semibold border border-blue-600 rounded-lg p-2 hover:bg-blue-100'
            href='/BarChart'
          >
            User Productivity Report
          </button>
        </div>
      </div>
    </>
  )
}

export default ReportChart
