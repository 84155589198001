import React, { useState, useContext } from 'react'
import Sidebar from './Sidebar'
import Header from './header'
import { ModelContext } from '../contexts/modelContext'
import { Route, Routes } from 'react-router-dom'
import { signedInRoutes } from '../constants/appConstants'
import ModuleModal from '../components/Modal/ModuleModal'
import UpdateAppModal from '../components/Modal/UpdateApplication'
import Modal from '../components/Modal/ApplicationModal'
import ScenarioModal from '../components/Modal/ScenarioModal'
import UpdateModuleModal from '../components/Modal/updateModuleModal'
import UpdateScenarioModal from '../components/Modal/updateScenario'
// import TcModal from "../components/Modal/TestCaseModal";
import UpdateTcModal from '../components/Modal/UpdateTestCase'

function CustomLayout () {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [refreshState, setRefreshState] = useState(false)
  const [modelstate, setModelState] = useContext(ModelContext)

  const setIsModal = () => {
    setModelState({
      modelType: '',
      isModal: false,
      itemID: '',
      scenarioID: '',
      appID: '',
      apitype: '',
      loading: false,
      sidebarExpanded: false
    })
  }

  const isCompanyRoute = !!signedInRoutes.some((route) =>
    window.location.pathname.includes('/company')
  )
  console.log('refreshState', refreshState)
  return (
    <div className='flex h-screen overflow-hidden '>
      {/* Sidebar */}
      {isCompanyRoute
        ? null
        : (
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setRefreshState={setRefreshState}
            refreshState={refreshState}
          />
          )}

      {/* Content area */}
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-gray-100 custom-main-content'>
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className='px-4 py-3 max-w-full mx-auto'>
            <Routes>
              {signedInRoutes.map((route, routeIdx) => (
                <Route
                  key={routeIdx}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </div>
        </main>

        {/* <Banner /> */}
        <>
          {modelstate.modelType === 'Application' && (
            <Modal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              setApp={{}}
              setRefreshState={setRefreshState}
            />
          )}
          {modelstate.modelType === 'addModule' && (
            <ModuleModal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              appID={modelstate.itemID}
              setModule={{}}
              setRefreshState={setRefreshState}
            />
          )}
          {modelstate.modelType === 'modifyApplication' && (
            <UpdateAppModal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              appID={modelstate.itemID}
              setRefreshState={setRefreshState}
            />
          )}
          {modelstate.modelType === 'addScenario' && (
            <ScenarioModal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              moduleID={modelstate.itemID}
              applicationID={modelstate.appID}
              schedule={{}}
              setSchedule={{}}
              setRefreshState={setRefreshState}
            />
          )}
          {modelstate.modelType === 'modifyModule' && (
            <UpdateModuleModal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              moduleID={modelstate.itemID}
              setRefreshState={setRefreshState}
            />
          )}

          {modelstate.modelType === 'modifyScenario' && (
            <UpdateScenarioModal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              scenarioID={modelstate.itemID}
              setRefreshState={setRefreshState}
            />
          )}
          {/* {modelType === "Scenario-TestCase" && (
    <TcModal
      showModal={isModal}
      setShowModal={setIsModal}
      id={itemID}
      moduleID={moduleID}
      appID={appID}
    />
  )} */}
          {modelstate.modelType === 'modifyTC' && (
            <UpdateTcModal
              showModal={modelstate.isModal}
              setShowModal={setIsModal}
              id={modelstate.itemID}
              scenarioID={modelstate.scenarioID}
              setRefreshState={setRefreshState}
            />
          )}
        </>
      </div>
    </div>
  )
}

export default CustomLayout
