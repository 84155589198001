import { useState, useEffect } from 'react'
import { GET } from '../../config/api'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import name from '../../assets/Icons/T.png'
import { openNotification, notificationTypes } from '../../utils/notifications'
import pencil from '../../assets/Icons/pencil.png'
import appIcon from '../../assets/Icons/app.png'
import module from '../../assets/Icons/module.png'
import scenario from '../../assets/Icons/scenario.png'
import tc from '../../assets/Icons/testcaseicon.png'
import Arrow from '../../assets/Icons/arrowright.png'

function CXOAssignPage () {
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)
  const sessionId = useSelector((state) => state.user.sessionId)
  const { assigncxoID } = useParams()

  const [manualrun, setManualRun] = useState({})
  const [cxo, setCxo] = useState({})
  const [data, setData] = useState({})
  const [application, setApplication] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showAutoModal, setShowAutoModal] = useState(false)
  const [appID, setAppID] = useState(0)
  const [testcases, setTestCases] = useState({})

  const getTestPacks = () => {
    GET(`/getTestPack?testpackid=${assigncxoID}`, { sessionID: sessionId })
      .then((res) => {
        let detail = res.data.message.testPackData
        detail = detail.map((item) => item.testcase_id)
        setTestCases(detail)
        setData(res.data.message)
        setAppID(res.data.message.application_id)
        const applicationID = res.data.message.application_id
        GET(`/testCasesForTestPack?appid=${applicationID}`, { sessionID: sessionId })
          .then((res) => {
            setCxo(res.data.message)
          }
          )
          .catch((err) => {
            console.log(err)
            openNotification(
              notificationTypes.ERROR,
              'Something went wrong, please try again later'
            )
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCloud = () => {
    openNotification(notificationTypes.SUCCESS, 'Success', 'Please wait for the test to complete.')
  }

  useEffect(() => {
    getTestPacks()
  }, [assigncxoID, appID])

  return (
    <>
      <div className='pb-5'>
        <div className='grid grid-cols-1 gap-1'>
          {/* <div className='col-span-2' /> */}
          <div className='mb-40 col-span-8'>
            <div className='mt-6 pb-10 min:h-2/5'>
              {
                cxo === null
                  ? (
                    <div className='pt-48 pb-24 min:h-2/5 bg-customWhite shadow-xl'>
                      App not found
                    </div>
                    )
                  : (
                    <>
                      <div>
                        <div className='grid grid-cols-2 gap-8'>
                          <h1 className='font-bold pt-3 text-black font-DMSANS text-base xl:text-3xl'>
                            Test Pack Details
                          </h1>
                        </div>

                        <div>
                          <div className='grid grid-cols-6 gap-2'>
                            <div className='h-20 col-span-2 mb-5 bg-white rounded-xl'>
                              <div className='flex'>
                                <img className='m-3' src={name} alt='id' />
                                <div className='mt-1 ml-3 flex flex-col'>
                                  <div>
                                    <h1 className='mb-0 font-DMSANS text-customGreyTwo text-base'>
                                      Test Pack Name &nbsp;
                                    </h1>
                                  </div>
                                  <div>
                                    <h1 className='mb-0 pt-3 font-DMSANS font-bold text-base xl:text-xl'>
                                      {data.testpack_name}
                                    </h1>
                                  </div>
                                </div>
                                {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                              </div>
                            </div>
                            <div className='col-span-4 mb-5 bg-white rounded-xl'>
                              <div className='flex'>
                                <h1 className='mb-0 font-DMSANS text-customGreyTwo text-base ml-5 mt-1'> Test Pack Description  &nbsp;</h1>
                                {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                              </div>
                              <h1 className='mb-0 font-DMSANS font-bold text-base xl:text-xl ml-5'>{data.testpack_description}</h1>
                            </div>
                          </div>
                        </div>
                        <div className='bg-customWhite p-5 h-96 overflow-y-auto rounded-2xl'>
                          {
                            cxo === null
                              ? (
                                <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>Loading...</h1>
                                )
                              : (
                                  Object.keys(cxo).length > 0
                                    ? (
                                      <div>
                                        <div class='flex my-2 form-check text-xl'>
                                          <img className='ml-1 mt-1 h-4 mr-2' src={Arrow} />
                                          <img src={appIcon} className='mt-1 h-7 mr-2' />
                                          {cxo.application.name}
                                        </div>
                                        {
                                        cxo.application.modules.map((item) =>
                                          <div className='ml-5'>
                                            <div class='flex my-2 form-check text-xl'>
                                              <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                                              <img src={module} className='mt-1 h-7 mr-2' />
                                              {item.name}
                                            </div>
                                            {
                                              item.scenarios.map((item) =>
                                                <div className='ml-5'>
                                                  <div class='flex my-2 form-check text-xl'>
                                                    <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                                                    <img src={scenario} className='mt-1 h-7 mr-2' />
                                                    {item.name}
                                                  </div>
                                                  {
                                                    item.testCases.map((item) =>
                                                      testcases.includes(item.id) === true
                                                        ? (
                                                          <div className='ml-5'>
                                                            <div class='flex my-2 form-check text-xl'>
                                                              <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                                                              <img src={tc} className='h-9 mr-1' />
                                                              <span className='mt-1'>{item.name}</span>
                                                            </div>
                                                          </div>
                                                          )
                                                        : (
                                                          <div />
                                                          )
                                                    )
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>
                                        )
                                      }
                                      </div>
                                      )
                                    : (
                                      <div>
                                        <div class='flex my-2 form-check text-xl'>
                                          <h1>No Data</h1>
                                        </div>
                                      </div>
                                      )
                                )
                          }
                        </div>
                        {
                          user === null
                            ? (
                                null
                              )
                            : (
                                user.accountType === 2
                                  ? (
                                    <div />
                                    )
                                  : (
                                    <div className='mt-5 flex float-right'>
                                      <div className='ml-auto grid grid-cols-3 gap-4'>
                                        {
                                        cxo === null
                                          ? (
                                            <div />
                                            )
                                          : (
                                            <div
                                              style={{ visibility: data.onCloudExecution === 1 ? 'visible' : 'hidden' }}
                                              className='col-span-2 ml-auto'
                                            >
                                              <button
                                                className='px-4 py-2 text-lg rounded-xl bg-customBlue text-white focus:bg-customBlue focus:text-white text-center'
                                                onClick={() => handleCloud()}
                                              >
                                                Schedule a Run
                                              </button>
                                            </div>
                                            )
                                      }
                                        <div className='col-span-1'>
                                          <button
                                            onClick={() => navigate(`/createtestrun/${assigncxoID}`)}
                                            className='px-4 py-2 text-lg rounded-xl bg-customBlue text-white focus:bg-customBlue'
                                          >
                                            Run Now
                                          </button>

                                        </div>
                                      </div>
                                    </div>
                                    )
                              )
                        }
                      </div>
                    </>
                    )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CXOAssignPage
