import SecondSectionImage from '../../../assets/Images/img4.webp'
import rightArrow from '../../../assets/Icons/rightarrow.png'
import EnquiryModal from '../../../components/Modal/EnquiryModal'
import { useState } from 'react'

function ThirdSection () {
  const [isModal, setIsModal] = useState(false)

  return (
    <div>
      <div className='grid grid-rows-1 md:grid-rows-0 grid-cols-0 md:grid-cols-2 gap-0'>
        <div className='hidden md:block my-auto xl:mx-28 col-span-1'>
          <img src={SecondSectionImage} alt='SecondSectionImage' />
        </div>
        <div className='py-8 md:py-6 xl:py-12 px-6 md:px-12 xl:px-24 bg-customGreyFour'>
          <h1 className='text-5xl lg:text-7xl font-heading font-black my-8 md:my-12 mr-2 md:mr-12'>Feature release</h1>
          <p className='text-xl font-para font-medium mb-8 md:mb-6 xl:mb-10 md:mr-3 xl:mr-16'>Have you ever faced a scenario where you release a new feature and the old one fails</p>
          <p className='text-xl font-para font-medium mb-12 md:mb-12 xl:mb-16 md:mr-3 xl:mr-16'>
            Don't worry about that anymore. We plan and test for all contingencies so that you can make new features flow smoothly into the product
          </p>
          <button onClick={() => setIsModal(true)} className='flex font-para font-bold px-4 md:px-6 xl:px-8 py-2 md:py-4 xl:py-6 border-2 border-black'>
            Learn More
            <img src={rightArrow} className='ml-5 h-6' alt='arrow' />
          </button>
        </div>
      </div>
      <EnquiryModal
        showModal={isModal}
        setShowModal={setIsModal}
      />
    </div>
  )
}

export default ThirdSection
