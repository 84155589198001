import TextField from '../../components/InputComponents/InputField'
import { useEffect, useState } from 'react'
import { GET, POST } from '../../config/api'
import { useSelector } from 'react-redux'
import { openNotification, notificationTypes } from '../../utils/notifications'
import { useNavigate, useParams } from 'react-router-dom'

const AddTestRunPage = () => {
  const { testpackID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [notes, setNotes] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [testType, setTestType] = useState('')
  const [buildVersionNo, setBuildVersionNo] = useState('')
  const [exeEnv, setExeEnv] = useState('')
  const [release, setRelease] = useState([])
  const [selectedRelease, setSelectedRelease] = useState('')

  useEffect(() => {
    getRelease()
  }, [])

  const getRelease = () => {
    GET('release', { sessionID: sessionId })
      .then((res) => {
        setRelease(res.data.message)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }

  const handleAddTestRun = async () => {
    // Check if any required data is null
    if (
      !testpackID ||
      !name ||
      !startDate ||
      !endDate ||
      !testType ||
      !buildVersionNo ||
      !exeEnv
    ) {
      handleError('Please fill in all required fields.')
      return
    }

    const data = {
      testpack_id: testpackID,
      name,
      description: notes,
      startDate,
      endDate,
      testType,
      buildVersionNo,
      exeEnv
    }

    try {
      const addTestRunResponse = await POST('/addtestrun', data, {
        sessionID: sessionId
      })

      if (addTestRunResponse.data.status === 200 || addTestRunResponse.data.status === 304) {
        if (selectedRelease) {
          const releaseTestRunResponse = await POST('/release/testrun', {
            testRunId: String(addTestRunResponse.data.testrunId),
            releaseId: selectedRelease
          }, {
            sessionID: sessionId
          })

          if (releaseTestRunResponse.data.status === 200) {
            openNotification(notificationTypes.SUCCESS, 'Test Run Added Successfully')
            navigate('/report')
          } else {
            handleError('Error Adding Test Run to release')
          }
        } else {
          openNotification(notificationTypes.SUCCESS, 'Test Run Added Successfully')
          navigate('/report')
        }
      } else {
        handleError('Error Adding Test Run')
      }
    } catch (error) {
      handleError('An unexpected error occurred while adding the test run.')
    }
  }

  const handleError = (errorMessage) => {
    openNotification(notificationTypes.ERROR, errorMessage)
  }

  return (
    <>
      <div className='pb-52 bg-gray-100 pt-16'>
        <div className='mr-12 grid grid-cols-10 gap-24'>
          <div className='ml-6 pb-32 col-span-10'>
            <div className='mt-10 flex mb-4'>
              <button
                onClick={handleAddTestRun}
                className='ml-auto mr-14 bg-customGreenFour hover:bg-green-300 border-2 hover:border-green-300 text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
              >
                Save Changes
              </button>
            </div>
            <h1 className='pl-2 text-4xl font-DMSANS font-bold'>
              Create new test run
            </h1>
            <div className='mr-24'>
              <div className='pt-6 pl-2 pr-28 grid grid-cols-3 gap-8'>
                <div>
                  <TextField
                    label='Run Name'
                    placeholder='IOB'
                    value={name}
                    onChangeHandler={(e) => setName(e.target.value)}
                    maxLength={500}
                  />
                </div>
                <div>
                  <TextField
                    label='Test Type'
                    placeholder='Enter Test Type'
                    maxLength={500}
                    value={testType}
                    onChangeHandler={(e) => setTestType(e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    label='Build Version No'
                    placeholder='Enter Build Version No'
                    maxLength={500}
                    value={buildVersionNo}
                    onChangeHandler={(e) => setBuildVersionNo(e.target.value)}
                  />
                </div>
              </div>
              <div className='pt-1 pl-2 pr-28 grid grid-cols-3 gap-8'>
                <div>
                  <div>
                    <label
                      className='form-label inline-block mb-2 font-bold text-black'
                      for='birthday'
                    >
                      Start Date
                    </label>
                  </div>
                  <input
                    type='date'
                    className='bg-white appearance-none border-2 border-gray-300 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div>
                  <div>
                    <label
                      className='form-label inline-block mb-2 font-bold text-black'
                      for='birthday'
                    >
                      End Date
                    </label>
                  </div>
                  <input
                    className='bg-white appearance-none border-2 border-gray-300 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
                    type='date'
                    id='birthday'
                    name='birthday'
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className='mt-1'>
                  <TextField
                    label='Exe Env'
                    placeholder='Exe Env'
                    maxLength={500}
                    value={exeEnv}
                    onChangeHandler={(e) => setExeEnv(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label
                  className='ml-2 block text-gray-700 text-sm font-bold mb-2'
                  for='release'
                >
                  Release
                </label>
                <select
                  className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                  name='ModuleName'
                  id='release'
                  required
                  onChange={(e) => {
                    setSelectedRelease(e.target.value)
                  }}
                >
                  <option
                    className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                    value='select'
                  >
                    select
                  </option>
                  {release?.map((obj) => {
                    // console.log(obj)
                    return (
                      <option
                        className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                        key={obj.id}
                        value={obj.id}
                      >
                        {obj.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='pt-1 pl-2 pr-28'>
                <label
                  for='exampleFormControlTextarea1'
                  class='form-label inline-block font-medium text-base mb-2 text-black'
                >
                  Notes
                </label>
                <textarea
                  class='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-black bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                  id='Note'
                  rows='3'
                  placeholder='Enter Notes'
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTestRunPage
