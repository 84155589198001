import React, { useState, useEffect } from 'react'
import { GET, POST } from '../../config/api'
import { useSelector } from 'react-redux'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { BiRefresh } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const SecurityTestRelease = (
  { releaseId }
) => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [domain, setDomain] = useState('')
  const [releaseReport, setReleaseReport] = useState('')
  const [urlList, setUrlList] = useState([])
  const [isTableVisible, setTableVisible] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const navigate = useNavigate()

  const handleDomainChange = (e) => {
    setDomain(e.target.value)
  }

  const handleTestUrl = () => {
    if (domain) {
      const dataList = document.getElementById('data')
      const options = Array.from(dataList.options)
      const domainExists = options.some((option) => option.value === domain)

      if (domainExists) {
        startScan(domain)
      } else {
        postDomain(domain, () => {
          startScan(domain)
        })
      }
    }
  }

  const isValidUrl = (domain) => {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
      'i'
    )
    return pattern.test(domain)
  }

  function extractDomain (url) {
    url = url.replace(/(^\w+:|^)\/\//, '')
    url = url.split(/[/?#]/)[0]
    const parts = url.split('.')
    const tld = parts.pop()
    return parts.join('.') + '.' + tld
  }

  const postDomain = (domain, callback) => {
    const ddomain = extractDomain(domain)

    const data = {
      domain: ddomain
    }

    const dataList = document.getElementById('data')
    const options = Array.from(dataList.options)
    const domainExists = options.some((option) => option.value === ddomain)

    if (domainExists) {
      openNotification(notificationTypes.INFO, 'Domain already exists.')
      return
    }

    if (!isValidUrl(ddomain)) {
      openNotification(notificationTypes.ERROR, 'Invalid domain URL.')
      return
    }

    POST('/security', data, { sessionID: sessionId })
      .then((res) => {
        console.log(res)
        if (callback && typeof callback === 'function') {
          callback()
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Error while scanning. Please try again later.'
        )
        console.error(err)
      })
  }

  const startScan = (url, callback) => {
    const domain = extractDomain(url)

    const isInProgress = releaseReport.some(
      (report) => report.url === domain && report.status === 0
    )

    if (isInProgress) {
      openNotification(notificationTypes.INFO, 'Scan is still running for this URL.')
      return
    }

    GET(`security/scan?domain=${domain}&releaseId=${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        const scan = res.data.message
        console.log(scan)
        openNotification(
          notificationTypes.SUCCESS,
          'Scan has started.'
        )
        if (callback && typeof callback === 'function') {
          callback()
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.INFO,
          'Scan is running in another Release.'
        )
        console.error(err)
      })
  }

  const getreleaseAllScanReport = () => {
    GET(`release/releaseid/security?releaseId=${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        setReleaseReport(res.data.map((item) => item.data))
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'getreleaseAllScanReport error'
        )
        console.error(err)
      })
  }

  const getApplications = () => {
    GET('security', { sessionID: sessionId })
      .then((res) => {
        setUrlList(res.data)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong. Please try again later.'
        )
      })
  }

  const handleRefresh = () => {
    setTableVisible(false)
    getreleaseAllScanReport()
    setDomain('')
    setTimeout(() => {
      setTableVisible(true)
    }, 500)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh()
    }, 6000000)
    return () => clearInterval(interval)
  }, [])

  const handleComponentRefresh = () => {
    setRefreshing(true)
    getreleaseAllScanReport()
    if (urlList.length > 0) {
      getApplications()
    }
    setDomain('')
    setTimeout(() => {
      setRefreshing(false)
    }, 500)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleComponentRefresh()
    }, 6000000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getreleaseAllScanReport()
    getApplications()
  }, [])

  if (refreshing) {
    return <p>Loading...</p>
  }

  const navigateToEachDomainReport = (domain) => {
    navigate(`/securityscans?domain=${domain}`)
  }

  return (
    <div>
      <div className='flex gap-2'>
        <input
          type='text'
          list='data'
          className='pl-4 w-full border rounded-md'
          value={domain}
          onChange={handleDomainChange}
          placeholder='Enter a domain'
        />
        <datalist id='data' className='pl-4 w-full border rounded-md'>
          {urlList.map((url, index) => (
            <option key={index}>{url}</option>
          ))}
        </datalist>
        <button
          className='w-32 px-4 py-2 bg-green-500 text-white rounded'
          onClick={handleTestUrl}
          disabled={!domain}
        >
          Scan URL
        </button>
        <button
          className='w-32 px-4 py-2 bg-blue-500 text-white rounded'
          onClick={handleComponentRefresh}
          disabled={refreshing}
        >
          Refresh
        </button>
      </div>
      <div>
        {releaseReport.length > 0
          ? (
            <div className='mt-4'>
              <div className='flex gap-2'>
                <h3 className='text-lg font-bold mb-2'>Scans Audit:</h3>
                <button onClick={() => handleRefresh()} className='text-blue-500 text-3xl pb-3'><BiRefresh /></button>
              </div>
              {isTableVisible
                ? (
                  <table className='border-collapse'>
                    <thead>
                      <tr>
                        <th className='border border-gray-400 px-4 py-2'>Scanned URL</th>
                        <th className='border border-gray-400 px-4 py-2'>Status</th>
                        <th className='border border-gray-400 px-4 py-2'>Percentage</th>
                        <th className='border border-gray-400 px-4 py-2'>score</th>
                        <th className='border border-gray-400 px-4 py-2'>Start Time</th>
                        <th className='border border-gray-400 px-4 py-2'>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {releaseReport.sort((a, b) => {
                        if (a.status === 1 && b.status !== 1) return 1
                        if (a.status !== 1 && b.status === 1) return -1
                        return 0
                      }).map((report, index) => (
                        <tr key={index}>
                          <td
                            className='border border-gray-400 px-4 py-2 text-blue-500 cursor-pointer underline'
                            onClick={() => navigateToEachDomainReport(report.url)}
                          >
                            {report.url}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {report.status === 1
                              ? (
                                <span className='text-green-500'>Complete</span>
                                )
                              : (
                                <span className='text-yellow-500'>In Progress</span>
                                )}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {report.completedPercentage}%
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {report.status === 1
                              ? (
                                <span> {report.score}</span>
                                )
                              : (
                                <span>0.00</span>
                                )}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {new Date(report.startTime).toLocaleString('en-US', {
                              month: 'short',
                              day: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                            })}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {new Date(report.endTime).toLocaleString('en-US', {
                              month: 'short',
                              day: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  )
                : (
                  <p>Loading...</p>
                  )}
            </div>
            )
          : (
            <p>No scan report available.</p>
            )}
      </div>
    </div>
  )
}

export default SecurityTestRelease
