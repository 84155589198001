import LOGIN from '../../assets/Images/loginImage.png'
import SigninForm from '../../components/Forms/SigninForm'
import { useEffect } from 'react'
import { documentTitles } from '../../constants/appConstants'

function SignInPage () {
  useEffect(() => {
    document.title = documentTitles.SIGN_IN
  }, [])

  return (
    <div className='bg-customGrey pt-12 px-24'>
      <div className='flex items-center justify-center h-screen grid grid-cols-1 lg:grid-cols-2 gap-8'>
        <div className='hidden lg:block border-r border-customGreenSix '>
          <img className='pr-16' src={LOGIN} alt='login' />
        </div>
        <div>
          <SigninForm />
        </div>
      </div>
    </div>
  )
}

export default SignInPage
