import { useEffect } from 'react'
import { documentTitles } from '../../constants/appConstants'
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
import FourthSection from './FourthSection'

function HomePage () {
  useEffect(() => {
    document.title = documentTitles.HOME
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <div>
        <FirstSection />
      </div>
      <div>
        <SecondSection />
      </div>
      <div>
        <ThirdSection />
      </div>
      <div>
        <FourthSection />
      </div>
    </div>
  )
}

export default HomePage
