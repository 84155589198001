import React from 'react'

const RunRequestButton = () => {
  return (
    <div className='bg-customGrey pb-5 pt-20'>
      <div className='mr-2 grid grid-cols-10 gap-24'>
        <div className='col-span-2' />
        <div className='mt-6 mb-40 ml-10 mr-10 col-span-8'>
          <h1 className='text-3xl ml-2 -mt-10 mb-10 font-bold font-DMSANS'>
            Run Request
          </h1>
          <div className='mt-28'>
            <ul className='flex justify-center gap-20'>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Run_a_Test_Pack_as_CXO__LGcmS9NLSbySDfw15iaUIg'
                >
                  <button className='w-80 h-20 bg-blue-400 hover:bg-blue-600 rounded-lg text-lg text-white border-solid border-2 border-black'>
                    {' '}
                    TEST RUN REQUEST BY CXO{' '}
                  </button>
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Create_Test_Execution_as_Test_Lead__rZLiAv5yR6a_pXSuvIMktg'
                >
                  <button className='w-80 h-20 bg-blue-400 hover:bg-blue-600 rounded-lg text-lg text-white border-solid border-2 border-black'>
                    {' '}
                    TEST RUN REQUEST BY TEST LEAD{' '}
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RunRequestButton
