import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import DataTable from 'react-data-table-component'

const FeatureManage = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [feature, setFeature] = useState([])
  const [records, setRecords] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    getFeatures()
  }, [])

  const getFeatures = () => {
    GET('features', { sessionID: sessionId })
      .then((res) => {
        setFeature(res.data.message)
        const initialRecords = res.data.message.result || []
        setRecords(initialRecords)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Feature Id',
      selector: (row) => row.featureId,
      sortable: true,
      wrap: true
    },
    {
      name: 'Feature',
      selector: (row) => row.name,
      sortable: true,
      wrap: true
    },
    {
      name: 'Release',
      selector: (row) => {
        if (
          row.releases &&
          Array.isArray(row.releases) &&
          row.releases.length > 0
        ) {
          const filteredReleases = row.releases.filter(
            (release) => release && release.name
          )

          if (filteredReleases.length > 0) {
            const releaseNames = filteredReleases
              .map((release) => release.name)
              .join(', ')
            return releaseNames
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      },
      sortable: true,
      wrap: true
    }
  ]
  const customStyles = {
    headRow: {
      style: {
        fontWeight: 'bold'
      }
    },
    headCells: {
      style: {
        fontSize: '16px'
      }
    }
  }

  const handleFilter = (e) => {
    setSearchKeyword(e.target.value)
    setErrorMessage('') // Clear the error message

    // Check if feature.result is available
    if (feature?.result) {
      const newData = feature.result.filter((row) => {
        const rowStr =
          '' +
          row.featureId +
          row.name +
          (row.releases
            ? row.releases
              .filter((release) => release && release.name)
              .map((release) => release.name)
              .join(', ') || '-'
            : '')

        return rowStr?.toLowerCase().includes(e.target.value.toLowerCase())
      })

      // Update the records state with the filtered data
      setRecords(newData)

      // If there are no results, set the error message
      if (newData.length === 0) {
        setErrorMessage('No matching records found.')
      }
    } else {
      setErrorMessage('Please enter some text to search')
    }
  }
  const handleFocus = () => {
    // Show error message when the input field is focused without entering any text
    if (!searchKeyword) {
      setErrorMessage('Please enter some text to search.')
    }
  }
  const handleBlur = () => {
    setErrorMessage('')
  }

  return (
    <div className='grid grid-cols-10 gap-1 m-2 p-2'>
      <div className='col-span-10'>
        <div className='flex justify-between items-center'>
          <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
            Feature Release Map
          </h1>
        </div>
        <div className='w-1/3 mb-2'>
          <input
            type='text'
            placeholder='Enter text to search...'
            className='border rounded p-2 w-full'
            value={searchKeyword}
            onChange={handleFilter}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {errorMessage && (
            <div className='text-red-600 mt-2'>
              {errorMessage}
            </div>
          )}
        </div>
        <div className='grid grid-cols-10 items-center justify-between' />
        <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl bg-customWhite'>
          <DataTable
            columns={columns}
            data={records}
            pagination
            striped
            highlightOnHover
            responsive
            customStyles={customStyles}
            defaultSortFieldId={1} // Set the default sort field ID
            defaultSortAsc={false} // Set the default sort order to descending
            fixedHeader
            fixedHeaderScrollHeight='500px'
          />
        </div>
      </div>
    </div>
  )
}

export default FeatureManage
