import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import * as _ from 'lodash'

import TextField from '../../components/InputComponents/InputField'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { GET, POST } from '../../config/api'
import { useParams, useNavigate } from 'react-router-dom'

import Arrow from '../../assets/Icons/arrowright.png'
import Arrow1 from '../../assets/Icons/arr.png'
import Arrow2 from '../../assets/Icons/arr2.png'
import save from '../../assets/Icons/save.png'

import appIcon from '../../assets/Icons/app.png'
import module from '../../assets/Icons/module.png'
import scenario from '../../assets/Icons/scenario.png'
import tc from '../../assets/Icons/testcaseicon.png'

const CXOPage = () => {
  const { applicationID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)
  const navigate = useNavigate()
  const [testPackName, setTestPackName] = useState('')
  const [testPackDesc, setTestPackDesc] = useState('')
  const [testPackData, setTestPackData] = useState([])
  const [selectedTestPack, setSelectedTestPack] = useState([])

  const [isManual, setIsManual] = useState(0)
  const [isAuto, setIsAuto] = useState(0)
  const [isCloud, setIsCloud] = useState(0)

  const selectItem = (event, item) => {
    item.checked = event.target.checked
    const tempData = _.cloneDeep(testPackData)
    setTestPackData(updateSelections(tempData, item))
  }

  const selectItemCurrentPack = (event, item) => {
    item.checked = event.target.checked
    const tempData = _.cloneDeep(selectedTestPack)
    setSelectedTestPack(updateSelections(tempData, item))
  }

  const updateSelections = (tempData, item) => {
    let appLevelChange = false
    let modLevelChange = false
    let scenLevelChange = false
    if (tempData.application.uid === item.uid) {
      tempData.application.checked = item.checked
      appLevelChange = true
    }
    tempData.application.modules.map((modules) => {
      if (appLevelChange || modules.uid === item.uid) {
        modules.checked = item.checked
        modLevelChange = true
      }
      modules.scenarios.map((eachScenario) => {
        if (modLevelChange || eachScenario.uid === item.uid) {
          eachScenario.checked = item.checked
          scenLevelChange = true
        }
        eachScenario.testCases.map((eachTestCase) => {
          if (scenLevelChange || eachTestCase.uid === item.uid) {
            eachTestCase.checked = item.checked
          }
        })
        scenLevelChange = false
      })
      modLevelChange = false
    })
    return tempData
  }

  const handleAdd = () => {
    const res = _.cloneDeep(testPackData)
    let filteredScenario, filteredModules
    res.application.modules.map((modules) => {
      modules.scenarios.map((eachScenario) => {
        filteredScenario = eachScenario.testCases.filter((eachTestCase) => {
          return eachTestCase.checked === true
        })
        eachScenario.checked = filteredScenario.length > 0
        eachScenario.testCases = filteredScenario
      })
      filteredModules = modules.scenarios.filter((eachModule) => {
        return eachModule.checked === true
      })
      modules.checked = filteredModules.length > 0
      modules.scenarios = filteredModules
    })
    res.application.modules = res.application.modules.filter((eachModule) => {
      return eachModule.checked === true
    })

    res.application.checked = false
    res.application.modules.map((modules) => {
      modules.scenarios.map((eachScenario) => {
        eachScenario.testCases.map((eachTestCase) => {
          eachTestCase.checked = false
        })
        eachScenario.checked = false
      })
      modules.checked = false
    })
    setSelectedTestPack(res.application.modules.length > 0 ? res : null)
  }

  const handleRemove = () => {
    const tempTestPack = _.cloneDeep(selectedTestPack)
    const idsToRemove = []

    tempTestPack.application.modules.map((modules) => {
      if (modules.checked) {
        idsToRemove.push(modules.uid)
      }
      modules.scenarios.map((eachScenario) => {
        if (eachScenario.checked) {
          idsToRemove.push(eachScenario.uid)
        }
        eachScenario.testCases.map((eachTestCase) => {
          if (eachTestCase.checked) {
            idsToRemove.push(eachTestCase.uid)
          }
        })
      })
    })
    if (tempTestPack.application.checked) {
      idsToRemove.push(tempTestPack.application.uid)
    }

    let filteredScenario, filteredModules
    tempTestPack.application.modules.map((modules) => {
      modules.scenarios.map((eachScenario) => {
        filteredScenario = eachScenario.testCases.filter((eachTestCase) => {
          return eachTestCase.checked === false
        })
        eachScenario.checked =
          filteredScenario.length > 0 ? eachScenario.checked : true
        eachScenario.testCases = filteredScenario
      })
      filteredModules = modules.scenarios.filter((eachModule) => {
        return eachModule.checked === false
      })
      modules.checked = filteredModules.length > 0 ? modules.checked : true
      modules.scenarios = filteredModules
    })
    tempTestPack.application.modules = tempTestPack.application.modules.filter(
      (eachModule) => {
        return eachModule.checked == false
      }
    )
    tempTestPack.application.checked = false

    setSelectedTestPack(tempTestPack)

    const fullTestPack = _.cloneDeep(testPackData)
    fullTestPack.application.modules.map((modules) => {
      if (idsToRemove.includes(modules.uid)) {
        modules.checked = false
      }
      modules.scenarios.map((eachScenario) => {
        if (idsToRemove.includes(eachScenario.uid)) {
          eachScenario.checked = false
        }
        eachScenario.testCases.map((eachTestCase) => {
          if (idsToRemove.includes(eachTestCase.uid)) {
            eachTestCase.checked = false
          }
        })
      })
    })
    if (idsToRemove.includes(fullTestPack.application.uid)) {
      fullTestPack.application.checked = false
    }

    setTestPackData(fullTestPack)
  }

  const handleManual = (event) => {
    if (event.target.checked) {
      setIsManual(1)
    } else {
      setIsManual(0)
    }
  }

  const handleAuto = (event) => {
    if (event.target.checked) {
      setIsAuto(1)
    } else {
      setIsAuto(0)
    }
  }

  const handleCloud = (event) => {
    if (event.target.checked) {
      setIsCloud(1)
    } else {
      setIsCloud(0)
    }
  }

  const getApp = () => {
    GET(`/testCasesForTestPack?appid=${applicationID}`, {
      sessionID: sessionId
    })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          res.data.message.application.checked = false
          res.data.message.application.modules.forEach((testModule) => {
            testModule.checked = false
            testModule.scenarios.forEach((testScenario) => {
              testScenario.checked = false
              testScenario.testCases.forEach((testCase) => {
                testCase.checked = false
              })
            })
          })
          setTestPackData(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const addTestPack = () => {
    const testCaseArr = []

    console.log(selectedTestPack.length)
    if (typeof selectedTestPack === 'undefined' || selectedTestPack === null || selectedTestPack.length === 0) {
      openNotification(
        notificationTypes.ERROR,
        'ERROR: 40210',
        'Please select atleast one test case'
      )
      return
    }

    selectedTestPack.application.modules.map((modules) =>
      modules.scenarios.map((eachScenario) =>
        eachScenario.testCases.map((eachTestCase) =>
          testCaseArr.push(eachTestCase)
        )
      )
    )

    const apiData = {
      packData: {
        testpackName: testPackName,
        testpackDescription: testPackDesc,
        isManual,
        isAuto,
        isCloud,
        applicationID
      },
      testcaseData: testCaseArr
    }

    if (testPackName === '') {
      openNotification(
        notificationTypes.ERROR,
        'ERROR: 40210',
        'Please fill all the fields'
      )
      return
    }

    POST('/postTestPack', apiData, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          openNotification(notificationTypes.SUCCESS, 'Test Pack Created')
          navigate('/testpackmanagement')
        } else if (res.data.status === 40220) {
          openNotification(
            notificationTypes.ERROR,
            'ERROR: 40220',
            'A duplicate test pack exists. Please provide a unique test pack name.'
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getApp()
  }, [])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10 w-full flex justify-between'>
          <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
            Create Test Pack
          </h1>
          <button
            onClick={addTestPack}
            className='ml-auto px-5 h-fit py-2 text-xl rounded-lg bg-customBlueTwo hover:bg-green-300 font-bold text-green-600'
          >
            <div className='flex'>
              <img className='mr-2' src={save} alt='' />
              Create
            </div>
          </button>
        </div>
        <div className='col-span-10'>
          <div className='grid grid-cols-2 gap-10'>
            <div className='col-span-1'>
              <h1 className='text-3xl py-1 font-DMSANS font-bold'>
                Test Pack Details
              </h1>
              <TextField
                label='Enter Test Pack Name'
                placeholder='Enter Test Pack Name'
                value={testPackName}
                onChangeHandler={(e) => setTestPackName(e.target.value)}
                maxLength={500}
              />
              <TextField
                label='Enter Test Pack Description'
                placeholder='Enter Test Pack Description'
                value={testPackDesc}
                onChangeHandler={(e) => setTestPackDesc(e.target.value)}
                maxLength={500}
              />
            </div>
            <div className='col-span-1'>
              <h1 className='text-3xl py-1 font-DMSANS font-bold'>
                Execution Type
              </h1>
              <div class='pl-5 flex flex-col'>
                <div class='my-2 form-check text-lg'>
                  <input
                    class='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                    type='checkbox'
                    value=''
                    onClick={(event) => handleManual(event)}
                    id='flexCheckManual'
                  />
                  <label
                    class='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                    for='flexCheckManual'
                  >
                    Manual Execution
                  </label>
                </div>
                <div class='my-2 form-check text-lg'>
                  <input
                    class='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                    onClick={(event) => handleAuto(event)}
                    type='checkbox'
                    value=''
                    id='flexCheckAuto'
                  />
                  <label
                    class='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                    for='flexCheckAuto'
                  >
                    Automated Execution
                  </label>
                </div>
                <div class='my-2 form-check text-lg'>
                  <input
                    class='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                    onClick={(event) => handleCloud(event)}
                    type='checkbox'
                    value=''
                    id='flexCheckCloud'
                  />
                  <label
                    class='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                    for='flexCheckCloud'
                  >
                    Execution on Cloud
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-10 my-4'>
          <h1 className='text-3xl py-1 font-DMSANS font-bold'>Pack/CXO View</h1>
          <div className='grid grid-cols-10 gap-8'>
            <div className='col-span-4'>
              <div className='bg-customWhite p-5 h-96 overflow-y-auto rounded-2xl'>
                <div>
                  <h1>
                    {selectedTestPack === null ? null : Object.keys(
                      selectedTestPack
                    ).length > 0 ? (
                      <div>
                        <div class='flex my-2 form-check text-xl'>
                          <input
                            onClick={(event) =>
                              selectItemCurrentPack(
                                event,
                                selectedTestPack.application
                              )}
                            class='h-6 w-6 mr-2'
                            type='checkbox'
                            value={selectedTestPack.application.name}
                            checked={selectedTestPack.application.checked}
                            id={selectedTestPack.application.uid}
                          />
                          {/* <img className='ml-1 mt-1 h-4 mr-2' src={Arrow} /> */}
                          <img
                            className='ml-3 mt-2 h-4 mr-2'
                            src={Arrow}
                            alt=''
                          />
                          <img src={appIcon} className='mt-1 h-7 mr-2' alt='' />
                          {selectedTestPack.application.name}
                        </div>
                        {selectedTestPack.application.modules.map((modules) => (
                          <div className='ml-5'>
                            <div class='flex my-2 form-check text-xl'>
                              <input
                                onClick={(event) =>
                                  selectItemCurrentPack(event, modules)}
                                class='h-6 w-6 mt-1'
                                type='checkbox'
                                value={modules.name}
                                checked={modules.checked}
                                id={modules.uid}
                              />
                              {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                              <img
                                className='ml-3 mt-2 h-4 mr-2'
                                src={Arrow}
                                alt=''
                              />
                              <img
                                src={module}
                                className='mt-1 h-7 mr-2'
                                alt=''
                              />
                              {modules.name}
                            </div>
                            {modules.scenarios.map((eachScenario) => (
                              <div className='ml-5'>
                                <div class='flex my-2 form-check text-xl'>
                                  <input
                                    onClick={(event) =>
                                      selectItemCurrentPack(event, eachScenario)}
                                    class='h-6 w-6 mt-1'
                                    type='checkbox'
                                    value={eachScenario.name}
                                    checked={eachScenario.checked}
                                    id={eachScenario.uid}
                                  />
                                  {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                  <img
                                    className='ml-3 mt-2 h-4 mr-2'
                                    src={Arrow}
                                    alt=''
                                  />
                                  <img
                                    src={scenario}
                                    className='mt-1 h-7 mr-2'
                                    alt=''
                                  />
                                  {eachScenario.name}
                                </div>
                                {eachScenario.testCases.map((eachTestCase) => (
                                  <div className='ml-5'>
                                    <div class='flex my-2 form-check text-xl'>
                                      <input
                                        onClick={(event) =>
                                          selectItemCurrentPack(
                                            event,
                                            eachTestCase
                                          )}
                                        class='h-6 w-6 mt-1'
                                        type='checkbox'
                                        value={eachTestCase.name}
                                        checked={eachTestCase.checked}
                                        id={eachTestCase.uid}
                                      />
                                      {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                      <img
                                        className='ml-3 mt-2 h-4 mr-2'
                                        src={Arrow}
                                        alt='arrow'
                                      />
                                      <img
                                        src={tc}
                                        className='h-9 mr-1'
                                        alt=''
                                      />
                                      <span className='mt-1'>
                                        {eachTestCase.name}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                        ) : (
                          <div>
                            <div class='flex my-2 form-check text-xl'>
                              <h1>No Data</h1>
                            </div>
                          </div>
                        )}
                  </h1>
                </div>
              </div>
            </div>
            <div className='my-auto col-span-2'>
              <div className='flex flex-col justify-center'>
                <button
                  className='mx-auto flex mb-2 px-5 py-2 rounded-xl bg-red-300 font-medium text-black'
                  onClick={handleAdd}
                >
                  <img src={Arrow2} className='h-8' alt='arrow' />
                  <span className='ml-2 text-xl'>Add</span>
                </button>
                <button
                  className='mx-auto flex mb-2 px-5 py-2 rounded-xl bg-green-300 font-medium text-black'
                  onClick={handleRemove}
                >
                  <span className='ml-2 text-xl'>Remove</span>
                  <img src={Arrow1} className='h-8' alt='arrow' />
                </button>
              </div>
            </div>
            <div className='col-span-4'>
              <div className='bg-customWhite p-5 h-96 overflow-y-auto rounded-2xl'>
                <div>
                  <div class='pl-5 flex flex-col'>
                    <div class='flex my-2 form-check text-xl'>
                      {testPackData === null ? (
                        <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>
                          Loading...
                        </h1>
                      ) : Object.keys(testPackData).length > 0 ? (
                        <div>
                          <div class='flex my-2 form-check text-xl'>
                            <input
                              onClick={(event) =>
                                selectItem(event, testPackData.application)}
                              class='h-6 w-6 mr-2'
                              type='checkbox'
                              value={testPackData.application.name}
                              checked={testPackData.application.checked}
                              id={testPackData.application.uid}
                            />
                            {/* <img className='ml-1 mt-1 h-4 mr-2' src={Arrow} /> */}
                            <img
                              className='ml-3 mt-2 h-4 mr-2'
                              src={Arrow}
                              alt=''
                            />
                            <img
                              src={appIcon}
                              className='mt-1 h-7 mr-2'
                              alt=''
                            />
                            {testPackData.application.name}
                          </div>
                          {testPackData.application.modules.map((modules) => (
                            <div className='ml-5'>
                              <div class='flex my-2 form-check text-xl'>
                                <input
                                  onClick={(event) =>
                                    selectItem(event, modules)}
                                  class='h-6 w-6 mt-1'
                                  type='checkbox'
                                  value={modules.name}
                                  checked={modules.checked}
                                  id={modules.uid}
                                />
                                {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                <img
                                  className='ml-3 mt-2 h-4 mr-2'
                                  src={Arrow}
                                  alt=''
                                />
                                <img
                                  src={module}
                                  className='mt-1 h-7 mr-2'
                                  alt=''
                                />
                                {modules.name}
                              </div>
                              {modules.scenarios.map((eachScenario) => (
                                <div className='ml-5'>
                                  <div class='flex my-2 form-check text-xl'>
                                    <input
                                      onClick={(event) =>
                                        selectItem(event, eachScenario)}
                                      class='h-6 w-6 mt-1'
                                      type='checkbox'
                                      value={eachScenario.name}
                                      checked={eachScenario.checked}
                                      id={eachScenario.uid}
                                    />
                                    {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                    <img
                                      className='ml-3 mt-2 h-4 mr-2'
                                      src={Arrow}
                                      alt=''
                                    />
                                    <img
                                      src={scenario}
                                      className='mt-1 h-7 mr-2'
                                      alt=''
                                    />
                                    {eachScenario.name}
                                  </div>
                                  {eachScenario.testCases.map(
                                    (eachTestCase) => (
                                      <div className='ml-5'>
                                        <div class='flex my-2 form-check text-xl'>
                                          <input
                                            onClick={(event) =>
                                              selectItem(event, eachTestCase)}
                                            class='h-6 w-6 mt-1'
                                            type='checkbox'
                                            value={eachTestCase.name}
                                            checked={eachTestCase.checked}
                                            id={eachTestCase.uid}
                                          />
                                          {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                          <img
                                            className='ml-3 mt-2 h-4 mr-2'
                                            src={Arrow}
                                            alt=''
                                          />
                                          <img
                                            src={tc}
                                            className='h-9 mr-1'
                                            alt=''
                                          />
                                          <span className='mt-1'>
                                            {eachTestCase.name}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <div class='flex my-2 form-check text-xl'>
                            <h1>No Data</h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-10'>
          <button
            onClick={addTestPack}
            className='ml-auto px-5 py-2 text-xl rounded-lg bg-customBlueTwo hover:bg-green-300 font-bold text-green-600'
          >
            <div className='flex w-fit'>
              <img className='mr-2' src={save} alt='' />
              Create
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export default CXOPage
