import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET, DELETE, PUT } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import TestCase from './TestCase'
import { useParams, useNavigate } from 'react-router-dom'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import alertcircle from '../../assets/Icons/alert-circle.png'
import { documentTitles } from '../../constants/appConstants'
import arrow from '../../assets/Icons/arrowup.png'
import arrow1 from '../../assets/Icons/arrowdown.png'
import Label from '../../components/InputComponents/Label'
import MultiSelect from 'react-select'
import { BiEdit, BiSave } from 'react-icons/bi'
import { MdDeleteOutline } from 'react-icons/md'
import moment from 'moment'
import { TCtype } from '../../constants/TestCase'

const ViewManualRunResultsPage = () => {
  const { viewmanualrunresultsID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)
  const navigate = useNavigate()
  const [testRun, setTestRun] = useState({})
  const [allTC, setAllTC] = useState({})
  const [filteredTC, setFilteredTC] = useState({})
  const [pass, setPass] = useState(0)
  const [fail, setFail] = useState(0)
  const [onHold, setOnHold] = useState(0)
  const [progress, setProgress] = useState(0)
  const [noresult, setNoresult] = useState(0)
  const [viewTestDetails, setViewTestDetails] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [TCname, setTCName] = useState('')
  const [TCdesc, setTCDesc] = useState('')
  const [TCtestType, setTCTestType] = useState('')
  const [TCstartDate, setTCStartDate] = useState('')
  const [TCendDate, setTCEndDate] = useState('')
  const [TCbuildVersion, setTCBuildVersion] = useState('')
  const [TCexecutionENV, setTCExecutionENV] = useState('')
  const [release, setRelease] = useState([])
  const [releasemappingName, setReleasemappingName] = useState([])
  const [selectedRelease, setSelectedRelease] = useState(' ')

  const selectOptions = [
    { value: 'No Run', label: 'No Run' },
    { value: 'Fail', label: 'Fail' },
    { value: 'Pass', label: 'Pass' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'In-Progress', label: 'In-Progress' }
  ]

  const Testdata = {
    labels: ['No Run', 'Fail', 'Pass', 'On Hold', 'In-Progress'],
    datasets: [
      {
        data: [noresult, fail, pass, onHold, progress],
        backgroundColor: [
          'rgb(192,192,192)',
          'rgb(199, 40, 22)',
          'rgb(64, 168, 123)',
          'rgb(252, 132, 3)',
          'rgb(255,204,0)'
        ],
        borderColor: [
          'rgb(192,192,192)',
          'rgb(199, 40, 22)',
          'rgb(64, 168, 123)',
          'rgb(252, 132, 3)',
          'rgb(255,204,0)', ,
        ],
        borderWidth: 2
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        rtl: false,
        labels: {
          // usePointStyle: true,
          pointStyle: 'rect',
          padding: 5,
          font: {
            size: 12,
            color: 'black',
            family: 'DM Sans',
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0 // or >= 1 or ...
        },
        labels: {
          value: {
            color: 'white'
          }
        }
      }
    }
  }

  const getTC = () => {
    GET(`/gettestcasedata?id=${viewmanualrunresultsID}`, {
      sessionID: sessionId
    })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setAllTC(res.data.message)
          setFilteredTC(res.data.message)
          setPass(res.data.message.filter((tc) => tc.status === 'Pass').length)
          setFail(res.data.message.filter((tc) => tc.status === 'Fail').length)
          setProgress(
            res.data.message.filter((tc) => tc.status === 'In-Progress').length
          )
          setOnHold(
            res.data.message.filter(
              (tc) =>
                tc.status === 'On Hold' ||
                tc.status === 'On hold' ||
                tc.status === 'On Hold '
            ).length
          )
          setNoresult(
            res.data.message.length -
            (res.data.message.filter((tc) => tc.status === 'Pass').length +
              res.data.message.filter(
                (tc) =>
                  tc.status === 'On Hold' ||
                  tc.status === 'On hold' ||
                  tc.status === 'On Hold '
              ).length +
              res.data.message.filter((tc) => tc.status === 'Fail').length +
              res.data.message.filter((tc) => tc.status === 'In-Progress')
                .length)
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleViewTestDetails = () => {
    setViewTestDetails(!viewTestDetails)
  }

  const updateMultiSelect = (item) => {
    let allFilteredItems = []
    item.forEach((selectedElement) => {
      const filterItems = allTC.filter((ele) => {
        return selectedElement.value === ele.status
      })
      allFilteredItems = [...allFilteredItems, ...filterItems]
    })
    setFilteredTC(allFilteredItems)
  }

  const deleteTestRun = () => {
    const confirmed = window.confirm('Are you sure you want to delete this TestRun?')
    if (confirmed) {
      DELETE(`/deleteTestRun?testrunID=${viewmanualrunresultsID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Successfully deleted TestRun'
            )
            navigate('/report')
            window.location.reload()
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting TestPack',
              'TestPack has dependency with Release or TestRun'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.INFO,
            'Error deleting TestPack',
            'TestPack has dependency with Release or TestRun'
          )
        })
    }
  }

  const getRelease = () => {
    GET('release', { sessionID: sessionId })
      .then((res) => {
        setRelease(res.data.message)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }

  const getReleaseTRRMappingName = () => {
    GET(`/getReleaseTestRunReport?testrunreportId=${viewmanualrunresultsID}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setReleasemappingName(res.data.message)
        } else {
          console.log(res.data.message)
        }
      })
      .catch((err) => {
        console.error('Error:', err)
      })
  }

  const getTestRunData = () => {
    GET(`/gettestrunbyid?id=${viewmanualrunresultsID}`, {
      sessionID: sessionId
    })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setTestRun(res.data.message)
          setTCName(res.data.message.name)
          setTCDesc(res.data.message.description)
          setTCTestType(res.data.message.testType)
          let startDate = new Date(res.data.message.startDate)
          startDate = startDate.toLocaleString().split(',')[0]
          setTCStartDate(startDate)
          let endDate = new Date(res.data.message.endDate)
          endDate = endDate.toLocaleString().split(',')[0]
          setTCEndDate(endDate)
          setTCBuildVersion(res.data.message.buildVersion)
          setTCExecutionENV(res.data.message.executionEnvironment)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const updateTestrunreport = () => {
    return new Promise((resolve, reject) => {
      const data = {
        name: TCname,
        description: TCdesc,
        testType: TCtestType,
        buildVersion: TCbuildVersion,
        executionEnvironment: TCexecutionENV,
        startDate: TCstartDate,
        endDate: TCendDate
      }

      if (
        TCname === '' ||
        TCtestType === '' ||
        TCbuildVersion === '' ||
        TCexecutionENV === '' ||
        TCstartDate === '' ||
        TCendDate === ''
      ) {
        openNotification(
          notificationTypes.INFO,
          'Check all fields are filled. Please try again'
        )
        reject('Fields are not filled')
        return
      }

      PUT(`/updatetestrunreport?testrunreportId=${viewmanualrunresultsID}`, data, {
        sessionID: sessionId
      })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Success',
              'TestRunReport data updated successfully'
            )
            window.location.reload()
            resolve()
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              'Error in updating TestRunReport. Please try again'
            )
            reject('Error updating TestRunReport')
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Error in updating TestRunReport. Please try again'
          )
          reject('Error updating TestRunReport')
        })
    })
  }

  const selectRelease = () => {
    if (selectedRelease === 'removeMapping') {
      DELETE(`/deleteReleaseTestrunreport?testrunreportId=${viewmanualrunresultsID}`, {
        sessionID: sessionId
      })
        .then((res) => {
          // Handle the response
        })
        .catch((error) => {
          console.error('Error occurred during DELETE request:', error)
        })
    } else {
      console.log(selectedRelease)
      const releaseData = {
        releaseId: selectedRelease
      }
      if (releaseData.releaseId !== ' ') {
        PUT(`/updateReleaseTestrunreport?testrunreportId=${viewmanualrunresultsID}`, releaseData, {
          sessionID: sessionId
        })
          .then((res) => {
            window.location.reload()
            // Handle the response
          })
          .catch((error) => {
            console.error('Error occurred during PUT request:', error)
          })
      }
    }
  }

  const handleClick = async () => {
    try {
      if (isEditing) {
        await updateTestrunreport()
        selectRelease()
        // window.location.reload()
      }
      setIsEditing(!isEditing)
    } catch (error) {
      // add any error msg
    }
  }

  console.log(selectedRelease)

  useEffect(() => {
    getTC()
    getTestRunData()
    getRelease()
    getReleaseTRRMappingName()
    document.title = documentTitles.REPORT
  }, [viewmanualrunresultsID])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1
              onClick={handleViewTestDetails}
              className='font-bold text-black font-DMSANS text-4xl py-1'
            >
              <span className='flex'>
                Test Run Results&nbsp;{' '}
                <img className='h-8' src={viewTestDetails ? arrow : arrow1} />
              </span>
            </h1>
            <div className='flex'>
              <button
                onClick={handleClick}
                className={`mx-2 flex gap-1 items-center text-center text-base font-DMSANS font-semibold ${isEditing ? 'bg-customGreen' : 'bg-customBlue'} text-white px-4 py-2 rounded-lg`}
              >
                {isEditing
                  ? (
                    <>
                      Save
                      <BiSave />
                    </>
                    )
                  : (
                    <>
                      Edit
                      <BiEdit />
                    </>
                    )}
              </button>
              <button
                onClick={deleteTestRun}
                className='mx-2 flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customRed text-white px-4 py-2 rounded-lg'
              >
                Delete <MdDeleteOutline />
              </button>
            </div>
          </div>
        </div>
        <div className='flex justify-between items-center col-span-full grid grid-cols-10 gap-4'>
          {viewTestDetails && (
            <>
              <div className='col-span-7'>
                {testRun === null
                  ? null
                  : (
                    <div className=' bg-customWhite rounded-xl mb-6 shadow-xl pb-10 pt-4 mr-4 px-4'>
                      <div className='grid grid-cols-2 gap-4'>
                        <div className='mb-5 mr-5'>
                          <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                            <div className='flex'>
                              <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                                Test Run Name
                              </h1>
                            </div>
                            {isEditing
                              ? (
                                <div className='m-2'>
                                  <input
                                    type='text'
                                    className='p-2 w-full border-2 rounded-lg'
                                    value={TCname}
                                    onChange={(e) => setTCName(e.target.value)}
                                  />
                                </div>
                                )
                              : (
                                <h1 className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'>
                                  {TCname}
                                </h1>
                                )}
                          </div>
                        </div>
                        <div className='mb-5 mr-5'>
                          <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                            <div className='flex'>
                              <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                                Test Run Type &nbsp;
                              </h1>
                            </div>
                            {isEditing
                              ? (
                                <div className='m-2'>
                                  <input
                                    type='text'
                                    className='p-2 w-full border-2 rounded-lg'
                                    value={TCtestType}
                                    onChange={(e) => setTCTestType(e.target.value)}
                                  />
                                </div>
                                )
                              : (
                                <h1 className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'>
                                  {TCtestType}
                                </h1>
                                )}
                          </div>
                        </div>
                      </div>
                      <div className='grid grid-cols-3 gap-4'>
                        <div className='mb-5 mr-5'>
                          <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                            <div className='flex'>
                              <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                                Build Version No
                              </h1>
                            </div>
                            {isEditing
                              ? (
                                <div className='m-2'>
                                  <input
                                    type='text'
                                    className='p-2 w-full border-2 rounded-lg'
                                    value={TCbuildVersion}
                                    onChange={(e) => setTCBuildVersion(e.target.value)}
                                  />
                                </div>
                                )
                              : (
                                <h1 className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'>
                                  {TCbuildVersion}
                                </h1>
                                )}
                          </div>
                        </div>
                        <div className='mb-5 mr-5'>
                          <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                            <div className='flex'>
                              <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                                Start Date
                              </h1>
                            </div>
                            {isEditing
                              ? (
                                <div className='m-2'>
                                  <input
                                    className='p-2 w-full border-2 rounded-lg'
                                    type='date'
                                    value={moment(TCstartDate).format('YYYY-MM-DD')}
                                    onChange={(e) => setTCStartDate(e.target.value)}
                                    max={moment(TCendDate).format('YYYY-MM-DD')}
                                  />
                                </div>
                                )
                              : (
                                <h1
                                  className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'
                                >
                                  {TCstartDate}
                                </h1>
                                )}
                          </div>
                        </div>
                        <div className='mb-5 mr-5'>
                          <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                            <div className='flex'>
                              <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                                End Date &nbsp;
                              </h1>
                            </div>
                            {isEditing
                              ? (
                                <div className='m-2'>
                                  <input
                                    className='p-2 w-full border-2 rounded-lg'
                                    type='date'
                                    value={moment(TCendDate).format('YYYY-MM-DD')}
                                    onChange={(e) => setTCEndDate(e.target.value)}
                                    min={moment(TCstartDate).format('YYYY-MM-DD')}
                                  />
                                </div>
                                )
                              : (
                                <h1 className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'>
                                  {TCendDate}
                                </h1>
                                )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-5 mr-5'>
                        <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                          <div className='flex'>
                            <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                              Test Run Description &nbsp;
                            </h1>
                          </div>
                          {isEditing
                            ? (
                              <div className='m-2'>
                                <input
                                  type='text'
                                  className='p-2 w-full border-2 rounded-lg'
                                  value={TCdesc}
                                  onChange={(e) => setTCDesc(e.target.value)}
                                />
                              </div>
                              )
                            : (
                              <h1 className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'>
                                {TCdesc}
                              </h1>
                              )}
                        </div>
                      </div>
                      <div className='mb-5 mr-5'>
                        <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                          <div className='flex'>
                            <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                              Execution Environment &nbsp;
                            </h1>
                          </div>
                          {isEditing
                            ? (
                              <div className='m-2'>
                                <input
                                  type='text'
                                  className='p-2 w-full border-2 rounded-lg'
                                  value={TCexecutionENV}
                                  onChange={(e) => setTCExecutionENV(e.target.value)}
                                />
                              </div>
                              )
                            : (
                              <h1 className='pb-3 font-bold font-DMSANS text-base xl:text-xl ml-2'>
                                {TCexecutionENV}
                              </h1>
                              )}
                        </div>
                      </div>
                      {isEditing
                        ? (
                          <div className='mb-5 mr-5'>
                            <div className='shadow-xl border-2 border-gray-100 h-full w-full bg-white rounded-xl'>
                              <div className='flex'>
                                <h1 className='pt-3 font-DMSANS text-customGreyTwo text-base ml-2'>
                                  Release &nbsp;
                                </h1>
                              </div>
                              <div className='m-2'>
                                <select
                                  className='p-2 w-full border-2 rounded-lg'
                                  name='ModuleName'
                                  id='release'
                                  required
                                  value={selectedRelease}
                                  onChange={(e) => {
                                    setSelectedRelease(e.target.value)
                                  }}
                                >
                                  {releasemappingName && releasemappingName.name
                                    ? (
                                      <>
                                        <option>{releasemappingName.name} </option>
                                        <option value='removeMapping'>Remove Mapping</option>
                                      </>
                                      )
                                    : (
                                      <option>Select</option>
                                      )}
                                  {release?.map((obj) => (
                                    obj.name !== releasemappingName.name && (
                                      <option key={obj.id} value={obj.id}>
                                        {obj.name}
                                      </option>
                                    )
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          )
                        : (
                          <>{' '}</>
                          )}
                    </div>
                    )}
              </div>
              <div className='col-span-3 bg-customWhite rounded-xl mb-6 shadow-xl'>
                <div className='flex-rows w-full'>
                  <div className='mt-10 flex'>
                    <h1 className='w-full text-3xl font-bold font-DMSANS text-center'>
                      Test Case
                    </h1>
                    {/* <img src={alertcircle} alt='alert circle' className='ml-3 mt-1 h-7' /> */}
                  </div>
                  <div>
                    {pass === null &&
                      fail === null &&
                      noresult === null
                      ? null
                      : (
                        <>
                          <h1 className='text-xl font-DMSANS text-center mb-2'>
                            Total:&nbsp;
                            {noresult + fail + pass + onHold + progress}
                          </h1>
                          <Doughnut
                            data={Testdata}
                            plugins={[ChartDataLabels]}
                            options={options}
                          />
                        </>
                        )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='overflow-scroll col-span-10 overscroll-contain h-[500px] bg-customWhite rounded-xl p-4 shadow-xl'>
          <div className='w-full mb-4'>
            <Label label='Filter by Test Case Result' />
            <MultiSelect
              defaultValue={selectOptions}
              options={selectOptions}
              onChange={(e) => updateMultiSelect(e)}
              isMulti
              name='TCResult'
              placeholder='Select result values you want to see'
              isSearchable={false}
              closeMenuOnSelect={false}
            />
          </div>
          <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
            <thead class='text-center bg-customGrey rounded-2xl'>
              <tr>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[200px]'
                >
                  Application {'>'} Module {'>'} Scenario
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[100px]'
                >
                  Test Case ID
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[200px]'
                >
                  Test Case Description
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[100px]'
                >
                  Test Case Result
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {filteredTC &&
                  (filteredTC.length > 0
                    ? (
                        filteredTC.map((tc) => <TestCase tc={tc} />)
                      )
                    : (
                      <tr class='border-b border-1 border-customGreyThree'>
                        <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                          No Test Cases Found
                        </td>
                      </tr>
                      ))}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ViewManualRunResultsPage
