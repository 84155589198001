import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './navbar.module.css'

// Constants
import { signedOutNavLinks } from '../../constants/navbar'
import { routes } from '../../constants/routes'
import logo from '../../assets/Images/logo.png'

// components
import { AiOutlineMenu, AiFillCloseCircle } from 'react-icons/ai'

const NavBar = () => {
  // objects
  const navigate = useNavigate()
  const navContainerREF = useRef(null)

  // states
  const [showMenu, setShowMenu] = useState(false)

  const handleToggleMenu = () => {
    if (showMenu) {
      navContainerREF.current.style.right = '-100vw'
    } else {
      navContainerREF.current.style.right = '0'
    }

    setShowMenu(!showMenu)
  }

  const handleSignUp = () => {
    navigate(routes.SIGN_UP)
  }

  const handleSignIn = () => {
    navigate(routes.SIGN_IN)
  }

  const handleHowitworks = () => {
    navigate(routes.HOWITWORKS)
  }

  return (
    <div className={styles.navbar}>
      <header className={styles.container}>
        <div onClick={() => navigate(routes.HOME)} className={styles.name}>
          <img className={styles.logo} src={logo} alt='logo' />
        </div>
        <div className={styles.menuIcon}>
          <AiOutlineMenu onClick={handleToggleMenu} />
        </div>
        <nav ref={navContainerREF} className={styles.nav}>
          <div className={styles.closeIconCtnr}>
            <AiFillCloseCircle onClick={handleToggleMenu} />
          </div>
          <a onClick={handleHowitworks}>
            <div className={styles.howitworks}>
              How Greentick works
            </div>
          </a>
          <a onClick={handleSignIn}>
            <div className={styles.SignInBtn}>
              Sign In
            </div>
          </a>
          <a onClick={handleSignUp}>
            <div className={styles.SignUpBtn}>
              Sign Up
            </div>
          </a>
        </nav>
      </header>
    </div>
  )
}

export default NavBar
