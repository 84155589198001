import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import { openNotification, notificationTypes } from '../../utils/notifications'
import Application from './Application'
import AssignRunModal from '../../components/Modal/AssignRunModal'
import AutoTestModal from '../../components/Modal/AutoTestModal'

const ViewTestPack = () => {
  const user = useSelector((state) => state.user)
  const sessionID = useSelector((state) => state.user.sessionId)

  const [application, setApplication] = useState({})
  const [testPack, setTestPack] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showAutoModal, setShowAutoModal] = useState(false)

  const { testpackID } = useParams()

  const handleCloud = () => {
    openNotification(notificationTypes.SUCCESS, 'Success', 'Please wait for the test to complete.')
  }

  const getTestPackData = () => {
    GET(`/getTestPackById?id=${testpackID}`, { sessionID })
      .then((res) => {
        setTestPack(res.data.message)
        const appID = res.data.message.application_id
        GET(`/getSpecificApp?appID=${appID}`, { sessionID })
          .then((res) => {
            setApplication(res.data.message)
          }
          )
          .catch((err) => {
            console.log(err)
          }
          )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getTestPackData()
  }, [testpackID])

  return (
    <>
      <div className='pb-5'>
        <div className='grid grid-cols-1 gap-1'>
          {/* <div className='col-span-2' /> */}
          <div className='mb-40 col-span-4'>
            <div className='mt-5 py-10 min:h-2/5 bg-customWhite shadow-xl'>
              <h1
                className='py-3 mb-4 text-customGreyTwo font-DMSANS text-base xl:text-3xl ml-5 leading-none lg:leading-11'
              >
                Test Pack Details
              </h1>
              {
                testPack === null
                  ? null
                  (
                    <div>
                      <h1
                        className='py-3 mb-4 text-customGreyTwo font-DMSANS text-base xl:text-2xl ml-5 leading-none lg:leading-11'
                      >
                        No Test Pack Found
                      </h1>
                    </div>
                  )
                  : (
                    <div>
                      <h1
                        className='py-3 mb-4 text-black font-DMSANS text-base xl:text-2xl ml-5 leading-none lg:leading-11'
                      >
                        <span className='text-customGreyTwo'>Test Pack Name: </span> {testPack.testpack_name}
                      </h1>
                      <h1
                        className='py-3 mb-4 text-black font-DMSANS text-base xl:text-2xl ml-5 leading-none lg:leading-11'
                      >
                        <span className='text-customGreyTwo'>Test Pack Description: </span> {testPack.testpack_description}
                      </h1>
                    </div>
                    )
              }
              <div className='mx-12'>
                {
                  application === null
                    ? null
                    : (
                      <Application app={application} testPackID={testPack.id} />
                      )
                }
              </div>

              {
                user === null
                  ? (
                      null
                    )
                  : (
                      user.accountType === 2
                        ? (
                          <div />
                          )
                        : (
                          <div className='mt-5 flex float-right'>
                            <div className='ml-auto grid grid-cols-2'>
                              {
                              testPack === null
                                ? (
                                  <div />
                                  )
                                : (
                                    testPack.is_automatic === true
                                      ? (
                                        <div className='ml-auto'>
                                          <button
                                            className='px-4 py-2 text-lg rounded-xl bg-customBlue text-white focus:bg-customBlue focus:text-white text-center'
                                            onClick={() => handleCloud()}
                                          >
                                            Schedule a Run
                                          </button>
                                        </div>
                                        )
                                      : (
                                        <div />
                                        )
                                  )
                            }
                              <div className='ml-auto'>
                                <button
                                  onClick={() => setShowModal(true)}
                                  className='px-4 py-2 text-lg rounded-xl bg-customBlue text-white focus:bg-customBlue'
                                >
                                  Run Now
                                </button>

                              </div>
                            </div>
                          </div>
                          )
                    )
              }
            </div>
            <AssignRunModal
              showModal={showModal}
              setShowModal={setShowModal}
              testpackId={testPack.id}
            />
            <AutoTestModal
              showAutoModal={showAutoModal}
              setAutoShowModal={setShowAutoModal}
              testpackId={testPack.id}
              isCloud={testPack.is_cloud}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewTestPack
