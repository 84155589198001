import { useNavigate } from 'react-router-dom'

import { routes } from '../../../constants/routes'
import Dashboard from '../../../assets/Icons/Dashboard.png'

function DefectItem () {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(routes.DEFECTS)}
      class='hover:bg-customBlueFour flex items-center p-2 text-base font-bold text-black rounded-lg'
    >
      <img className='ml-6' src={Dashboard} alt='Defects' />
      <span
        className='ml-3'
      >
        Defects
      </span>
    </div>
  )
}

export default DefectItem
