import React, { useEffect, useState } from 'react'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { GET } from '../../config/api'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiRefresh } from 'react-icons/bi'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

const EachDomainReport = () => {
  const location = useLocation()
  const sessionId = useSelector((state) => state.user.sessionId)
  const searchParams = new URLSearchParams(location.search)
  const domain = searchParams.get('domain')
  const appId = searchParams.get('appId')
  const scanId = searchParams.get('scanId')
  const [scanreport, setScanReport] = useState('')
  const [isTableVisible, setTableVisible] = useState(true)
  const [status, setStatus] = useState('')
  const [scanreportsdata, setScanReportsData] = useState('')
  const [vulnfixesdata, setVulnFixesData] = useState('')
  const [vulnscanresultdata, setVulnScanResultData] = useState('')
  const [visibleReports, setVisibleReports] = useState([])
  const navigate = useNavigate()

  const getScanReport = async () => {
    try {
      const res = await GET(`release/security/scanreport?appId=${appId}&scanId=${scanId}`, { sessionID: sessionId })
      setStatus(res.data)
      setScanReport([res.data.data])
      if (res.data.message !== 'In Progress') {
        setScanReportsData(res.data.data.data)
        setVulnFixesData(res.data.data.data.map((item) => item.vuln.fixes.map((item) => item.testFix)))
        setVulnScanResultData(res.data.data.data.map((item) => item.vuln.fixes.map((item) => item.report.scanresult)))
      }
    } catch (err) {
      openNotification(
        notificationTypes.ERROR,
        'Something went wrong starting the scan. Please try again later.'
      )
      console.error(err)
    }
  }

  const handleRefresh = () => {
    setTableVisible(false)
    getScanReport().then(() => {
      setTimeout(() => {
        setTableVisible(true)
      }, 500)
    })
  }

  const handleToggleVisibility = (index) => {
    if (visibleReports.includes(index)) {
      setVisibleReports(visibleReports.filter((item) => item !== index))
    } else {
      setVisibleReports([...visibleReports, index])
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh()
    }, 6000000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getScanReport()
  }, [])

  const navigateToEachDomainReport = (domain) => {
    navigate(`/securityscans?domain=${domain}`)
  }

  return (
    <div className='grid grid-cols-10 gap-1 m-2 p-2'>
      <div className='col-span-10'>
        <div className='grid grid-cols-10'>
          <div className='col-span-6'>
            <h1 className='mt-5 ml-5 pb-3 mb-4  text-black font-bold font-DMSANS text-base xl:text-4xl leading-none lg:leading-11'>
              Security Report
            </h1>
          </div>
          <div className='col-span-3' />
          <div className='col-span-1'>
            <div className='mt-5 ml-5 pb-3 mb-4'>
              <button
                onClick={() => navigateToEachDomainReport(domain)}
                class='mr-2 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded'
              >
                Back
              </button>
            </div>
          </div>
        </div>
        {scanreport && scanreport.length > 0
          ? (
            <div className='mt-4'>
              <div className='flex gap-2'>
                <h3 className='text-lg font-bold mb-2'>Scans Audit:</h3>
                <button onClick={() => handleRefresh()} className='text-blue-500 text-3xl pb-3'><BiRefresh /></button>
              </div>
              {isTableVisible
                ? (
                  <table className='border-collapse mb-10'>
                    <thead>
                      <tr>
                        <th className='border border-gray-400 text-sm px-4 py-2'>Domain</th>
                        <th className='border border-gray-400 text-sm px-4 py-2'>Start Time</th>
                        <th className='border border-gray-400 text-sm px-4 py-2'>End Time</th>
                        <th className='border border-gray-400 text-sm px-4 py-2'>Status</th>
                        <th className='border border-gray-400 text-sm px-4 py-2'>Progress</th>
                        <th className='border border-gray-400 text-sm px-4 py-2'>score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scanreport.map((report, index) => (
                        <tr key={index}>
                          <td className='border border-gray-400 px-4 py-2'>{report.url}</td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {new Date(report.startTime).toLocaleString('en-US', {
                              month: 'short',
                              day: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                            })}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {new Date(report.endTime).toLocaleString('en-US', {
                              month: 'short',
                              day: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                            })}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {status.message === 'In Progress'
                              ? (
                                <span className='text-yellow-500'>In Progress</span>
                                )
                              : (
                                <span className='text-green-500'>Complete</span>
                                )}
                          </td>
                          <td className='border border-gray-400 px-4 py-2'>{report.completedPercentage}</td>
                          <td className='border border-gray-400 px-4 py-2'>
                            {status.message === 'In Progress'
                              ? (
                                <span>0.00</span>
                                )
                              : (
                                <span>{report.score}</span>
                                )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  )
                : (
                  <p>Loading....</p>
                  )}
            </div>
            )
          : (
            <p>No scan report available.</p>
            )}
        {scanreportsdata && scanreportsdata.length > 0 ? (
          <div className='mt-4'>
            {isTableVisible ? (
              <div>
                {scanreportsdata.map((report, index) => (
                  <div key={index} className='bg-white drop-shadow-sm p-4 mb-4 rounded-md'>
                    <p onClick={() => handleToggleVisibility(`${index}`)} className='cursor-pointer text-base'> {index + 1} {report.categoryName} <div className='flex gap-3 pr-4 float-right'> {visibleReports.includes(`${index}`) ? <FaAngleUp /> : <FaAngleDown />}</div></p> {/* <span className=' text-green-500 font-bold'>{report.categoryScore.substring(0, 5)}%</span> */}
                    {visibleReports.includes(`${index}`) && (
                      <div className='bg-white drop-shadow-lg p-4 rounded-md'>
                        {vulnscanresultdata && vulnscanresultdata.length > 0
                          ? (
                              vulnscanresultdata.map((report, index) => (
                                <div key={index}>
                                  {report && report.length > 0
                                    ? (
                                        report.map((scanReport, subIndex) => {
                                          const fixes = vulnfixesdata[index] || []
                                          const fixesForScan = fixes[subIndex] || []
                                          return (
                                            <div key={subIndex}>
                                              <li className='ml-6 list-disc'> {scanReport.name} </li>
                                              <hr className='ml-6 border-t border-gray-200' />
                                              <li className='ml-10 list-none'>Steps to fix :</li>
                                              {fixesForScan.map((fix, fixIndex) => (
                                                <React.Fragment key={fixIndex}>
                                                  {Array.isArray(fix)
                                                    ? (
                                                        fix.map((report, nestedIndex) => (
                                                          <li key={`${fixIndex}-${nestedIndex}`} className='list-disc ml-16'>
                                                            {report}
                                                          </li>
                                                        ))
                                                      )
                                                    : (
                                                      <li key={fixIndex} className='list-disc ml-16'>
                                                        {fix}
                                                      </li>
                                                      )}
                                                </React.Fragment>
                                              ))}
                                            </div>
                                          )
                                        })
                                      )
                                    : (
                                      <p />
                                      )}
                                </div>
                              ))
                            )
                          : (
                            <p />
                            )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p />
            )}
          </div>
        ) : (
          <p />
        )}
      </div>
    </div>
  )
}

export default EachDomainReport
