import TextField from '../../InputComponents/InputField'
import { useState, useEffect, useRef } from 'react'
import styles from '../modal.module.css'
import close from '../../../assets/Icons/close.png'

const EnquiryModal = ({
  showModal,
  setShowModal
}) => {
  const modalRef = useRef(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const closeModal = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer4}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2
              className='pb-3 ml-4 text-black font-bold font-DMSANS text-base text-customGreenThree xl:text-4xl leading-none lg:leading-11'
            >
              Hello!
            </h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <img src={close} alt='close' className='h-12' />
            </a>
          </div>
        </div>
        <div className='pt-5 pl-5 border-2 border-customGreyTwo rounded-3xl'>
          <h1 className={styles.heading}>
            Let us know more about you!
          </h1>
          <div className='flex flex-col pr-10 ml-5 mb-5'>
            <form
              action='https://formspree.io/f/meqwoadr'
              method='POST'
            >
              <div className='grid grid-cols-2 gap-4'>
                <TextField
                  label='Name'
                  name='name'
                  placeholder={name}
                  value={name}
                  maxLength={5000}
                  type='text'
                  onChangeHandler={(e) => setName(e.target.value)}
                  required
                />
                <TextField
                  label='Email'
                  name='email'
                  placeholder={email}
                  value={email}
                  maxLength={5000}
                  type='text'
                  onChangeHandler={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label for='exampleFormControlTextarea1' class='ml-1 font-bold form-label inline-block mb-2 text-gray-700'>
                  Message
                </label>
                <textarea
                  class='rounded-2xl form-control block w-full px-3 py-3 mb-5 text-base font-bold text-gray-700 bg-clip-padding border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                  value={message}
                  name='message'
                  onChange={(e) => setMessage(e.target.value)}
                  id='message'
                  rows='3'
                />
              </div>
              <button
                className='float-right w-24 bg-customGreenFour text-customGreenFive font-bold py-2 px-4 rounded-xl'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnquiryModal
