import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import TestPackItem from './TestPackItem'
import Arrow from '../../../../../assets/Icons/arrow.png'
import Arrow1 from '../../../../../assets/Icons/arrowright.png'
import User from '../../../../../assets/Icons/app.png'
import rightclick from '../../../../../assets/Icons/threedots.png'
import ContextMenu from '../../../../ContextMenu'

const Application = (props) => {
  const navigate = useNavigate()
  const containerRef = useRef(null)

  const [isHover, setIsHover] = useState(false)
  const [isModule, setIsModule] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const addTestPack = () => {
    navigate(`/createcxoview/${props.item.id}`)
  }

  const menuItems = [
    {
      id: 1,
      text: 'Add TestPack',
      handleFunction: addTestPack
    }
  ]

  const handleAppModules = (id) => {
    setIsModule(!isModule)
  }

  return (
    <div
      style={{ backgroundColor: isModule ? '#E5F0F8' : 'white' }}
      className='hover:bg-customBlueFour'
    >
      <div
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        className='py-1 font-DMSANS text-sm xl:text-base font-medium leading-none lg:leading-11'
      >
        <span class='flex hover:bg-customBlueFour items-center p-2 font-normal text-black rounded-lg' ref={containerRef} onClick={() => handleAppModules(props.item.id)}>
          {
                isModule
                  ? (
                    <img src={Arrow} className='ml-1 mr-2' alt='Arrow' />
                    )
                  : (
                    <img src={Arrow1} className='ml-3 mr-2' alt='Arrow' />
                    )
              }
          <img src={User} className='h-5 mr-2' alt='User' />
          {props.item.applicationName}&nbsp;
          <img
            style={{ visibility: isHover ? 'visible' : 'hidden' }}
            src={rightclick}
            className='ml-auto'
            alt='Dashboard'
          />
        </span>
        <TestPackItem applicationID={props.item.id} />
        <ContextMenu
          parentRef={containerRef}
          items={menuItems}
        />
      </div>
    </div>
  )
}

export default Application
