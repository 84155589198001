import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import { POST } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { BarLoader } from 'react-spinners'

let testCasesBackup = []

const TestCaseStatusReport = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [report, setReport] = useState(null)
  const [selectedAppId, setSelectedAppId] = useState('')
  const [selectedModId, setSelectedModId] = useState('')
  const [selectedScenarioId, setSelectedScenarioId] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getReport()
  }, [])

  const getReport = (ab = {}) => {
    setIsLoading(true)
    POST('/getTCReport', ab, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setReport(res.data.message)
          testCasesBackup = res.data.message.testCaseByApplication
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    const ab = {
      application_id: selectedAppId
    }
    getReport(ab)
  }, [selectedAppId])

  useEffect(() => {
    const ab = {
      application_id: selectedAppId,
      module_id: selectedModId
    }
    getReport(ab)
  }, [selectedAppId, selectedModId])

  useEffect(() => {
    const ab = {
      application_id: selectedAppId,
      module_id: selectedModId,
      scenario_id: selectedScenarioId
    }
    getReport(ab)
  }, [selectedAppId, selectedModId, selectedScenarioId])

  const statusColors = {
    'In Progress': 'rgba(255, 99, 132, 0.6)',
    Pending: 'rgba(54, 162, 235, 0.6)',
    Review: 'rgba(255, 206, 86, 0.6)',
    Complete: 'rgb(192, 192, 200)',
    Deprecated: 'rgb(245, 66, 66)',
    Approved: 'rgb(36, 219, 26)'
  }

  const prepareDataForPieChart = () => {
    const testCaseStatusCounts = {
      'In Progress': 0,
      Pending: 0,
      Review: 0,
      Complete: 0,
      Deprecated: 0,
      Approved: 0
    }

    report?.testCaseByApplication?.forEach((testCase) => {
      const status = testCase.testcase_status
      if (testCaseStatusCounts.hasOwnProperty(status)) {
        testCaseStatusCounts[status]++
      } else {
        testCaseStatusCounts[status] = 1
      }
    })

    const labels = Object.keys(testCaseStatusCounts)
    const data = labels.map((status) => testCaseStatusCounts[status] || 0)

    const totalCount = data.reduce((acc, curr) => acc + curr, 0)
    const percentages = data.map((count) => ((count / totalCount) * 100).toFixed(2))
    const allStatusesStruckOut = data.every(count => count === 0)

    return {
      labels: labels.map((status) => `${status} (${testCaseStatusCounts[status] || 0})`),
      datasets: [
        {
          data,
          percentages,
          backgroundColor: labels.map((label) => statusColors[label]),
          allStatusesStruckOut
        }
      ]
    }
  }
  const dataset = prepareDataForPieChart().datasets[0]
  const totalTestCases = dataset.data.reduce((acc, value) => acc + value, 0)

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        rtl: false,
        labels: {
          pointStyle: 'rect',
          padding: 15,
          font: {
            size: 12,
            color: 'black',
            family: 'DM Sans',
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0
        },
        formatter: (value, context) => {
          const percentage = ((value / totalTestCases) * 100).toFixed(0) + '%'
          return `${value} (${percentage})`
        },
        anchor: 'end',
        align: 'start',
        offset: 10,
        labels: {
          value: {
            color: 'white',
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        }
      }
    }
  }

  const handleSearchKeywordChange = (e) => {
    const value = e.target.value
    setSearchKeyword(value)
    setErrorMessage('')
    if (!value) {
      setErrorMessage('Please enter text to search')
      return setReport((prevReportData) => ({
        ...prevReportData,
        testCaseByApplication: testCasesBackup
      }))
    }

    const filteredRecord = testCasesBackup.filter((item) => {
      const testCaseName = item.testcaseName
      const testCaseStatus = item.testcase_status
      const scenarioName = item.scenarioName
      const appName = (() => {
        const selectedApp = report?.applicationList.find(
          (app) => app.id === item.application_id
        )
        return selectedApp ? selectedApp.applicationName : ''
      })()
      const moduleName = (() => {
        const selectedModule = report?.module?.find(
          (mod) => mod.id === item.module_id
        )
        return selectedModule ? selectedModule.modelName : ''
      })()

      const rowStr = '' + testCaseName + testCaseStatus + scenarioName + appName + moduleName

      return rowStr?.toLowerCase().includes(value.toLowerCase())
    })
    setReport((prevReportData) => ({
      ...prevReportData,
      testCaseByApplication: filteredRecord
    }))
  }
  const handleFocus = () => {
    if (!searchKeyword) {
      setErrorMessage('Please enter text to search')
    } else {
      setErrorMessage('')
    }
  }

  const handleBlur = () => {
    setErrorMessage('')
  }

  const columns = [
    {
      name: 'AppName',
      selector: (row) => {
        const selectedApp = report?.applicationList.find(
          (app) => app.id === row.application_id
        )
        return selectedApp ? selectedApp.applicationName : ''
      },
      sortable: true
    },
    {
      name: 'ModuleName',
      selector: (row) => {
        const selectedModule = report?.module?.find(
          (mod) => mod.id === row.module_id
        )
        return selectedModule ? selectedModule.modelName : ''
      },
      sortable: true

    },
    {
      name: 'ScenarioName',
      selector: (row) => row.scenarioName,
      sortable: true,
      cell: (row) => (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <span title={row.scenarioName}>{row.scenarioName}</span>
        </div>
      )
    },
    {
      name: 'TCName',
      selector: (row) => row.testcaseName,
      sortable: true,
      id: 'testcaseName'
    },
    {
      name: 'TCStatus',
      selector: (row) => row.testcase_status,
      sortable: true
    }
  ]
  const customStyles = {
    headRow: {
      style: {
        fontWeight: 'bold'
      }
    },
    headCells: {
      style: {
        fontSize: '16px'
      }
    }
  }

  return (
    <>
      <div>
        <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
          TestCase Status Report
        </h1>
      </div>
      <div className='bg-white rounded-xl grid grid-cols-1 md:grid-cols-2 mb-6'>
        <div className='md:flex md:justify-between '>
          {/*  <div className="md:justify-between"> */}
          <div className='m-3'>
            <label htmlFor='appDropdown' className='text-black font-DMSANS text-xl'>
              Application:
            </label>
            <h1 className='mt-2 mb-1 font-DMSANS text-base'>
              <select
                className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg  max-w-[200px]'
                onChange={(e) => {
                  const selectedAppId = e.target.value
                  const selectedApp = report?.applicationList.find(
                    (app) => app.id === selectedAppId
                  )
                  setSelectedAppId(selectedAppId, selectedApp)
                  setSelectedModId('')
                }}
                value={selectedAppId || ''}
              >
                <option value=''>Select Application</option>
                {report?.applicationList.map((value) => (
                  <option
                    className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                    value={value.id}
                    key={value.id}
                  >
                    {value.applicationName}
                  </option>
                ))}
              </select>
            </h1>
          </div>
          {selectedAppId && ( // Check if Application is selected
            <div className='m-3'>
              <label htmlFor='moduleDropdown' className='text-black font-DMSANS text-xl'>
                Module:
              </label>
              <h1 className='mt-2 mb-1 font-DMSANS text-base'>
                {selectedAppId && (
                  <select
                    className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg  max-w-[300px]'
                    onChange={(e) => {
                      const selectedModId = e.target.value
                      const selectedModule = report?.module?.find(
                        (mod) => mod.id === selectedModId
                      )
                      setSelectedModId(selectedModId, selectedModule)
                      setSelectedScenarioId('')
                    }}
                    value={selectedModId || ''}
                  >
                    <option value=''>Select Module</option>
                    {report?.module?.map((value) => (
                      <option
                        className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                        value={value.id}
                        key={value.id}
                      >
                        {value.modelName}
                      </option>
                    ))}
                  </select>
                )}
              </h1>
            </div>)}
          {selectedModId && ( // Check if Module is selected
            <div className='m-3 md:col-span-2'>
              <label htmlFor='scenarioDropdown' className='text-black font-DMSANS text-xl'>
                Scenario:
              </label>
              <h1 className='mt-2 mb-1 font-DMSANS text-base'>
                {selectedModId && (
                  <select
                    className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg max-w-[450px]'
                    onChange={(e) => setSelectedScenarioId(e.target.value)}
                    value={selectedScenarioId || ''}
                  >
                    <option value=''>Select Scenario</option>
                    {report?.scenario?.map((value) => (
                      <option
                        className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                        value={value.id}
                        key={value.id}
                      >
                        {value.scenarioName}
                      </option>
                    ))}
                  </select>
                )}
              </h1>
            </div>)}
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px'
          }}
        >
          <BarLoader loading={isLoading} />
          <p>Loading...</p>
        </div>
      )
        : report?.testCaseByApplication ? (

          <div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
              {selectedAppId && report?.testCaseByApplication.length === 0 && (
                <p className='text-base font-DMSANS font-bold px-6 py-3 text-center'>No records to display for the selected Application</p>
              )}
              {!selectedAppId || (selectedAppId && report?.testCaseByApplication.length > 0) && (
                <>
                  <div
                    className='bg-white rounded-xl p-8 mb-4 pb-12 '
                    style={{ width: '450px', height: '450px' }}
                  >
                    <h1 className='text-black text-xl font-DMSANS text-center'>
                      TotalTestCases: {totalTestCases}
                    </h1>
                    <Pie
                      data={prepareDataForPieChart()}
                      options={options}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                  <div className='col-span-2 md:col-span-1 bg-white rounded-xl p-3 h-4/5 mb-4'>
                    <div className='text-black font-DMSANS text-xl'>
                      <h1>Testcase Report</h1>
                    </div>
                    <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
                      <thead className='text-center bg-customGrey rounded-2xl'>
                        <tr>
                        <th
                          scope='col'
                          className='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[100px]'
                        >
                          Status
                        </th>
                        <th
                          scope='col'
                          className='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[100px]'
                        >
                          Count
                        </th>
                        <th
                          scope='col'
                          className='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[50px]'
                        >
                          Percentage
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        {prepareDataForPieChart().labels.map((status, index) => (
                        <tr key={index}>
                          <td className='text-base text-black font-medium p-2'>
                            <div
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                marginRight: '8px',
                                backgroundColor: statusColors[status.split(' (')[0]]
                              }}
                            />
                            {/*   <span style={{ color: statusColors[status.split(' (')[0]] }}/> */}
                            {status.split(' (')[0]}
                          </td>
                          <td className='text-base text-black font-medium p-2'>
                            {prepareDataForPieChart().datasets[0].data[index]}
                          </td>
                          <td className='text-base text-black font-medium p-2'>
                            {/* Calculate and display percentage */}
                            {`${((prepareDataForPieChart().datasets[0].data[index] /
                              prepareDataForPieChart().datasets[0].data.reduce(
                                (a, b) => a + b,
                                0
                              )) *
                              100).toFixed(0)}%`}
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </>

              )}
            </div>

            <div className='w-1/3 mb-2'>
              <input
                type='text'
                placeholder='Enter text to search...'
                className='border rounded p-2 w-full'
                value={searchKeyword}
                onChange={handleSearchKeywordChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              {/* Display error message */}
              {errorMessage && (
                <div className='text-red-600 mt-2'>
                  {errorMessage}
                </div>
              )}
            </div>
            <div>
              <DataTable
                title='TestCase Status'
                columns={columns}
                data={report?.testCaseByApplication}
                pagination
                striped
                highlightOnHover
                responsive
                customStyles={customStyles}
                defaultSortFieldId='testcaseName'
                defaultSortAsc
                fixedHeader
                fixedHeaderScrollHeight='400px'
              />
            </div>
          </div>

        ) : (
          <p className='text-base font-DMSANS font-bold px-6 py-3'>Please select Application to get the details of testcases</p>
        )}

    </>
  )
}

export default TestCaseStatusReport
