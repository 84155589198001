import { documentTitles } from '../../constants/appConstants'
import { useState, useEffect, useCallback } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { GET, POST } from '../../config/api'
import { useSelector } from 'react-redux'
import TestCaseData from './TestCaseData'
import ApplicationData from './ApplicationData'
import UserProductivityReport from '../UserProductivityReport'

ChartJS.register(ArcElement, Tooltip, Legend)

function DashboardPage() {
  const sessionId = useSelector((state) => state.user.sessionId)
  const email = useSelector((state) => state.user.email);
  const { varificationcode } = useParams()
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  const [appLength, setAppLength] = useState(0)
  const [testPackLength, setTestPackLength] = useState(0)
  const [testRunLength, setTestRunLength] = useState(0)
  const [testRun, setTestRun] = useState([])
  const [tcLength, setTCLength] = useState(0)
  const [applications, setApplications] = useState([])

  const getTCLength = () => {
    GET('/getTCFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setTCLength(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(notificationTypes.ERROR, 'Error')
      })
  }

  const getAppLength = () => {
    GET('/getAppFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setAppLength(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(notificationTypes.ERROR, 'Error')
      })
  }

  const getTestPacks = () => {
    GET('/getTestpacks', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setTestPackLength(res.data.message.length)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const getApplications = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setApplications(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(notificationTypes.ERROR, 'Error')
      })
  }

  const getTestRuns = () => {
    GET('/gettestrun', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setTestRunLength(res.data.message.length)
          setTestRun(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Something went wrong'
        )
      })
  }

  const varifyemail = useCallback(
    debounce(async () => {
      try {
        if (isEmailVerified) {
          return
        }
        await POST(`/verifyemail/${varificationcode}`)
        openNotification(
          notificationTypes.SUCCESS,
          'Email verified successfully'
        )
      } catch (err) {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong while verifying the email'
        )
      }
    }, 500), // Adjust the debounce delay as per your needs
    [isEmailVerified, varificationcode]
  )

  const checkCompanyStatus = () => {
    GET(`/getuserdetails/${varificationcode}`, { sessionID: sessionId })
      .then((res) => {
        const companyStatus = res.data.company_status
        if (companyStatus) {
          console.log('Company status is true. Stay on the same URL.')
        } else {
          console.log('Company status is false. Redirecting to /company page.')
          window.location.href = '/company'
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Something went wrong'
        )
      })
  }

  useEffect(() => {
    document.title = documentTitles.DASHBOARD
    getAppLength()
    getTestPacks()
    getTestRuns()
    getTCLength()
    getApplications()

    if (varificationcode) {
      varifyemail()
      checkCompanyStatus()
    }
  }, [varificationcode, varifyemail])

  const data = {
    labels: ['Automatic', 'Manual'],
    datasets: [
      {
        data: [35, 65],
        backgroundColor: ['rgb(152, 235, 152,1)', 'rgb(64, 168, 123)'],
        borderColor: ['rgb(152, 235, 152,1)', 'rgb(64, 168, 123)'],
        borderWidth: 2
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        position: 'right',
        rtl: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
          padding: 10,
          font: {
            size: 14,
            color: 'black',
            family: 'DM Sans',
            weight: 'bold'
          }
        }
      }
    }
  }

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Dashboard
            </h1>
            <a
              className='text-blue-600 pb-2 text-lg font-semibold'
              href='/dashboard-release'
            >
              Release Dashboard
            </a>
            <h1 className='py-2 my-2 text-black font-DMSANS text-xl'>
              Application {'>'} Module {'>'} Scenario {'>'} Test Case
            </h1>
            <div className='grid grid-cols-10 gap-4 my-2 py-2'>
              <div className='col-span-7'>
                <div className='grid grid-cols-4 gap-2'>
                  <div>
                    <div className='mb-10 bg-white rounded-xl'>
                      <div className='flex flex-col justify-center'>
                        <div>
                          <h1 className='font-DMSANS text-center font-bold mt-2'>
                            Test Cases
                          </h1>
                        </div>
                        <div>
                          <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                            {tcLength === null ? 0 : tcLength}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='mb-4 bg-white rounded-xl'>
                      <div className='flex flex-col justify-center'>
                        <div>
                          <h1 className='font-DMSANS text-center font-bold mt-2'>
                            Applications
                          </h1>
                        </div>
                        <div>
                          <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                            {appLength === null ? 0 : appLength}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='mb-4 bg-white rounded-xl'>
                      <div className='flex flex-col justify-center'>
                        <div>
                          <h1 className='font-DMSANS text-center font-bold mt-2'>
                            Test Packs
                          </h1>
                        </div>
                        <div>
                          <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                            {testPackLength === null ? 0 : testPackLength}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='mb-4 bg-white rounded-xl'>
                      <div className='flex flex-col justify-center'>
                        <div>
                          <h1 className='font-DMSANS text-center font-bold mt-2'>
                            Test Runs
                          </h1>
                        </div>
                        <div>
                          <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                            {testRunLength === null ? 0 : testRunLength}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {applications === null
                    ? null
                    : applications.length > 0
                      ? (
                        <ApplicationData
                          id={applications[0].id}
                          applications={applications}
                        />
                      )
                      : (
                        <div>No applications made</div>
                      )}
                </div>
              </div>
              {testRun === null
                ? null
                : testRun.length > 0
                  ? (
                    <TestCaseData id={testRun[0].id} testRun={testRun} />
                  )
                  : (
                    <div />
                  )}
            </div>
          </div>
          <div className='grid grid-cols-3 gap-4 my-2 py-2'>
            <div className='col-span-10 mb-4 bg-white rounded-xl'>
              <UserProductivityReport isBar showAll={false} />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default DashboardPage
