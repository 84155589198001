import { GET, DELETE } from '../../../../config/api'
import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Arrow from '../../../../assets/Icons/arrow.png'
import Arrow1 from '../../../../assets/Icons/arrowright.png'
import User from '../../../../assets/Icons/app.png'
import rightclick from '../../../../assets/Icons/threedots.png'

import {
  notificationTypes,
  openNotification
} from '../../../../utils/notifications'
import Module from './module'
import ContextMenu from '../../../../components/ContextMenu'
import ModuleModal from '../../../../components/Modal/ModuleModal'
import UpdateAppModal from '../../../../components/Modal/UpdateApplication'

function Application (props) {
  const navigate = useNavigate()

  const sessionId = useSelector((state) => state.user.sessionId)

  const [module, setModule] = useState({})
  const containerRef = useRef(null)
  const [isModule, setIsModule] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const addModule = () => {
    setIsModal(true)
  }

  const viewApplication = () => {
    navigate(`/applications/${props.item.id}`)
  }

  const modifyApplication = () => {
    // PUT(`/updateApplication?AppID=${props.item.id}`, data, { sessionID: sessionId })
    setIsUpdateModal(true)
  }

  const deleteApplication = () => {
    DELETE(`/deleteApplication?AppID=${props.item.id}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Application deleted successfully'
          )
          // window.location.reload()
          GET('/getApplicationFromCompany', { sessionID: sessionId })
            .then((res) => {
              props.setApp(res.data.message)
            }
            )
            .catch((err) => {
              console.log(err)
              openNotification(
                notificationTypes.ERROR,
                'Something went wrong, please try again later'
              )
            })
        } else if (res.data.status === 401) {
          openNotification(
            notificationTypes.ERROR,
            'Session expired, please login again'
          )
        } else if (res.data.status === 406) {
          openNotification(
            notificationTypes.ERROR,
            'Unable to determine company of user'
          )
        } else if (res.data.status === 400) {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            res.data.message
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  const menuItems = [
    {
      id: 1,
      text: 'Add Module',
      handleFunction: addModule
    },
    {
      id: 2,
      text: 'View Application',
      handleFunction: viewApplication
    },
    {
      id: 3,
      text: 'Modify Application',
      handleFunction: modifyApplication
    },
    {
      id: 4,
      text: 'Delete Application',
      handleFunction: deleteApplication
    }
  ]

  const handleAppModules = (applicationId) => {
    GET(`/getModules?appID=${applicationId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setModule(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
    setIsModule(!isModule)
  }

  return (
    <div
      style={{ backgroundColor: isModule ? '#E5F0F8' : 'white' }}
      className='hover:bg-customBlueFour'
    >
      <div
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        className='py-1 font-DMSANS text-sm xl:text-base font-medium leading-none lg:leading-11'
      >
        <span class='flex hover:bg-customBlueFour items-center p-2 font-normal text-black rounded-lg' ref={containerRef} onClick={() => handleAppModules(props.item.id)}>
          {
            isModule
              ? (
                <img src={Arrow} className='ml-1 mr-2' alt='Arrow' />
                )
              : (
                <img src={Arrow1} className='ml-3 mr-2' alt='Arrow' />
                )
          }
          <img src={User} className='h-5 mr-2' alt='User' />
          {props.item.applicationName}&nbsp;
          <img
            style={{ visibility: isHover ? 'visible' : 'hidden' }}
            src={rightclick}
            className='ml-auto'
            alt='Dashboard'
          />
        </span>
        <ContextMenu
          parentRef={containerRef}
          items={menuItems}
        />
      </div>
      {
        isModule && (
          module === null
            ? (
              <h1 className='text-black py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>Loading...</h1>
              )
            : (
                module.length > 0
                  ? (
                      module.map((item, index) => (
                        <div>
                          <Module key={index} appID={props.item.id} item={item} setModule={setModule} applicationID={props.item.id} />
                        </div>
                      ))
                    )
                  : (
                    <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>No modules found</h1>
                    )
              )
        )
      }
      <ModuleModal
        showModal={isModal}
        setShowModal={setIsModal}
        appID={props.item.id}
        setModule={setModule}
      />
      <UpdateAppModal
        showModal={isUpdateModal}
        setShowModal={setIsUpdateModal}
        appID={props.item.id}
      />
    </div>
  )
}

export default Application
