import { useNavigate } from 'react-router-dom'
import { useState, useRef } from 'react'
import Item from './Item'
import { useSelector } from 'react-redux'
import Arrow from '../../../assets/Icons/arrow.png'
import module from '../../../assets/Icons/app.png'
import scenario from '../../../assets/Icons/scenario.png'

const TestPackItem = (props) => {
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const containerRef = useRef(null)

  const [isTestPack, setIsTestPack] = useState(false)

  const handleIsTestPack = () => {
    setIsTestPack(!isTestPack)
  }

  return (
    <>
      <div
        className='py-1 font-DMSANS text-sm xl:text-base font-medium leading-none lg:leading-11'
      >
        {
          Object.keys(props.testpack).map(function (keyName, keyIndex) {
            return (
              <div
                className='ml-2'
              >
                <span
                  class='hover:bg-customBlueFour flex xl:text-base items-center p-2 font-bold text-black rounded-lg'
                >
                  <img className='mr-2' src={Arrow} alt='Arrow' />
                  <img src={module} className='mr-2' alt='User' />
                  &nbsp;{keyName}
                </span>
                {
                  props.testpack[keyName].map((item, index) => {
                    return (
                      <div className='ml-3'>
                        <span class='hover:bg-customBlueFour flex items-center p-2 text-black rounded-lg' onClick={() => navigate(`/assigncxo/${item.id}`)} ref={containerRef}>
                          <img className='mr-2' src={Arrow} alt='Arrow' />
                          <img
                            src={scenario}
                            className='mr-2' alt='User'
                          />
                          &nbsp;{item.testpack_name}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default TestPackItem
