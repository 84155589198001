import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { GET } from '../../../config/api'
import { openNotification, notificationTypes } from '../../../utils/notifications'

import testcase from '../../../assets/Icons/testcase.png'
import rightclick from '../../../assets/Icons/threedots.png'
import Arrow from '../../../assets/Icons/arrow.png'
import Arrow1 from '../../../assets/Icons/arrowright.png'

import TestPackItem from '../TestPackItem'

function CXOTestPack () {
  const user = useSelector((state) => state.user)
  const sessionId = useSelector((state) => state.user.sessionId)

  const [isReq, setIsReq] = useState(false)
  const [manual, setManual] = useState({})
  const [automatic, setAutomatic] = useState({})
  const [testpack, setTestpack] = useState({})

  function groupBy (items) {
    return items.reduce((acc, curr) => {
      if (curr?.applicationName) {
        const currentItems = acc[curr.applicationName]

        return {
          ...acc,
          [curr.applicationName]: currentItems ? [...currentItems, curr] : [curr]
        }
      }
      return acc
    }, {})
  }

  const getTestPacks = () => {
    GET('/getTestpacks', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setTestpack(
            groupBy(res.data.message)
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleReqMan = () => {
    setIsReq(!isReq)
  }

  useEffect(() => {
    getTestPacks()
  }, [])

  return (
    <li style={{ backgroundColor: isReq ? '#E5F0F8' : 'white' }}>
      <div class='hover:bg-customBlueFour flex items-center py-2 pl-2 pr-6 text-base font-bold text-black rounded-lg'>
        {
          isReq
            ? (
              <img className='mr-2' src={Arrow} alt='Arrow' />
              )
            : (
              <img className='mr-2' src={Arrow1} alt='Arrow' />
              )
        }
        <img src={testcase} alt='Dashboard' />
        <span onClick={handleReqMan} class='flex-1 ml-3 whitespace-nowrap'>Test Pack management</span>
      </div>
      <div>
        {user.accountType === 0
          ? (
            <>
              {
                isReq && (
                  testpack === null
                    ? (
                      <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-2 leading-none lg:leading-11'>Loading...</h1>
                      )
                    : (
                      <div className='mx-2 bg-customBlueThree'>
                        <TestPackItem testpack={testpack} />
                      </div>
                      )
                )
              }
            </>
            )
          : (
            <div className='bg-customBlueThree'>
              {
                isReq && (
                  testpack === null
                    ? (
                      <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-2 leading-none lg:leading-11'>Loading...</h1>
                      )
                    : (
                      <div className='mx-2 bg-customBlueThree'>
                        <TestPackItem testpack={testpack} />
                      </div>
                      )
                )
              }
            </div>
            )}
      </div>
    </li>
  )
}

export default CXOTestPack
