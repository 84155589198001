import React, { createRef, useContext, useEffect, useState } from "react";
import { GET, POST } from "../../config/api";
import { useSelector } from "react-redux";
import { notificationTypes, openNotification } from "../../utils/notifications";
import { useDispatch } from 'react-redux'
import axios from 'axios';
import $ from "jquery";
import { MyContext } from "./context";
import ReactJson from "react-json-view";
import { setUser } from "../../redux/ducks/userDuck";
import CustomFieldModel from "../../components/Modal/CustomField model";
import { documentTitles } from "../../constants/appConstants";
window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const Settings = () => {
  const sessionId = useSelector((state) => state.user.sessionId);
  const sessionID = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.userId);
  const email = useSelector((state) => state.user.email);
  const sessionIdToken = useSelector((state) => state.user.sessionId);
  const username = useSelector((state) => state.user.username);
  const userAccountType = useSelector((state) => state.user.accountType);
  const formSettingData = useSelector((state) => state.user.settings || "");
  const id = useSelector((state) => state.user ? state.user.userId : "");
 // const companyId = useSelector((state) => state.user.settings ? state.user.settings.companyId : "");
  const [formBuilder, setFormBuilder] = useState();
  const companyId1 = useSelector((state) => state.user);
  const { form } = useContext(MyContext);
  const [customerId, setCustomerId] = useState(userId);
  const [settingData, setSettingData] = useState([]);
  const [accountType, setAccountType] = useState("greentick");
  const [userName, setUserName] = useState("");
  const [url, setURL] = useState(""); 
  const [token, setToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [zephyrUrl, setZephyrUrl] = useState("");
  const [zephyrToken, setZephyrToken] = useState("");
  const fb = createRef();
  const [defectsConfiguratedData, setDefectsConfiguratedData] = useState([]);
  const dispatch = useDispatch()
  const [isModal, setIsModal] = useState(false)
  const [customfieldData, setCustomfieldData] = useState([]);

  useEffect(() => {
    document.title = documentTitles.SETTINGS;
    console.log(accountType,"accountType")
    handleSelectedUser();
    getCustomfields();
    getCompany();
    // getSettings();
    if (formSettingData && formSettingData.accountType && formSettingData.accountType === 'jira' || formSettingData.accountType === 'greentick') {
      setAccountType(formSettingData.accountType);
    } else {
      console.log("line 46")
      setFormBuilder(
        $(fb.current).formBuilder({
          disabledActionButtons: ["data", "clear", "save"],
          fieldRemoveWarn: true,
          disabledAttrs: ['access', 'maxlength', 'type', 'inline', 'required', 'rows', 'max', 'min', 'step', 'description'],
          disableFields: ['autocomplete', 'button', 'checkbox-group', 'file', 'hidden', 'radio-group', 'paragraph', 'header'],
          formData: [],
        })
      );
    }
  }, []);

  async function getCompany() {
    await GET(`/getCompany`, { sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setCompanyId(res.data.message)
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleSelectedUser() {
    try {
      if (formSettingData) {
        const settingData = formSettingData;
        let def = [];
        console.log(settingData, "settingData 67");
        if (settingData.defectsConfiguration == false) {
          def = [];
        } else {
          def = settingData.defectsConfiguration;
        }

        if (settingData && settingData.userName) {
          setZephyrUrl(settingData.zephyrUrl);
          setZephyrToken(settingData.zephyrToken);
          setToken(settingData.token);
          setURL(settingData.url);
          setUserName(settingData.userName);
          setDefectsConfiguratedData(settingData.defectsConfiguration);
        }
        const jiraCheckbox = document.getElementById("jira");
        const greentickCheckbox = document.getElementById("greentick");

        if (settingData && settingData.accountType == "jira") {
          jiraCheckbox.checked = true;
        } else if (settingData && settingData.accountType == "greentick") {
          greentickCheckbox.checked = true;
        } else {
          greentickCheckbox.checked = true;
        }
        console.log(def, "def 98")
        const formData = def.map((item) => ({
          type: item.type ? item.type : "text",
          required: item.required,
          label: item.label,
          className: item.className,
          name: item.name,
          access: item.access,
          subtype: item.subtype,
          maxlength: item.maxlength,
          placeholder: item.placeholder,
          value: item.value,
          values: item.values ? item.values.map((i) => ({
            label: i.label,
            value: i.value,
            selected: i.selected
          })) : []
        }));

        console.log(formData, "formData line 92");
        if (formData && formData.length > 0) {
          setFormBuilder(
            $(fb.current).formBuilder({
              disabledActionButtons: ["data", "clear", "save"],
              fieldRemoveWarn: true,
              disabledAttrs: ['access', 'maxlength', 'type', 'inline', 'required', 'rows', 'max', 'min', 'step', 'description'],
              disableFields: ['autocomplete', 'button', 'checkbox-group', 'file', 'hidden', 'radio-group', 'paragraph', 'header'],
              formData: formData,
            })
          );
        }
        else {
          console.log("line 102");
          setFormBuilder(
            $(fb.current).formBuilder({
              disabledActionButtons: ["data", "clear", "save"],
              fieldRemoveWarn: true,
              disabledAttrs: ['access', 'maxlength', 'type', 'inline', 'required', 'rows', 'max', 'min', 'step', 'description'],
              disableFields: ['autocomplete', 'button', 'checkbox-group', 'file', 'hidden', 'radio-group', 'paragraph', 'header'],
              formData: [],
            })
          );
        }
      } else {
        const greentickCheckbox = document.getElementById("greentick");
        greentickCheckbox.checked = true;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function saveData() {
    let arr = JSON.parse(formBuilder.formData);

    let errorClass = false;
    let errorLabel = false;
    
    arr.forEach(item => {
      const specialCharactersClassName = item.className.match(/[^\w\s]/g);
      if (specialCharactersClassName && specialCharactersClassName.length > 3) {
        errorClass = true;
      }
      
      const specialCharactersLabel = item.label.match(/[^\w\s]/g);
      if (specialCharactersLabel && specialCharactersLabel.length > 3) {
        errorLabel = true;
      }
    });
    
    if (errorLabel) {
      openNotification(
        notificationTypes.ERROR,
        "More than 3 special characters found in label"
      );
      return;
    }if (errorClass) {
      openNotification(
        notificationTypes.ERROR,
        "More than 3 special characters found in className"
      );
      return;
    }
    
    
    let errorMsg = "Please fill out all required fields.";
    let found = false;
    for (let index = 0; index < arr.length; index++) {
      if (arr[index].type === "autocomplete") {
        found = true;
        errorMsg += arr[index].type + ", ";
      } else if (arr[index].type === "button") {
        found = true;
        errorMsg += arr[index].type + ", ";
      } else if (arr[index].type === "checkbox") {
        found = true;
        errorMsg += arr[index].type + ", ";
      } else if (arr[index].type === "file") {
        found = true;
        errorMsg += arr[index].type + ", ";
      } else if (arr[index].type === "hidden") {
        found = true;
        errorMsg += arr[index].type + ", ";
      } else if (arr[index].type === "radio") {
        found = true;
        errorMsg += arr[index].type + ", ";
      }
    }

      let requestData = {
        customerId: customerId,
        accountType: accountType,
        userName: userName,
        url: url,
        token: token,
        zephyrUrl: zephyrUrl,
        zephyrToken: zephyrToken,
        data: arr
      };

      let requestData1 = {
        id: id,
        companyId: companyId,
        customerId: customerId,
        accountType: accountType,
        userName: userName,
        url: url,
        token: token,
        zephyrUrl: zephyrUrl,
        zephyrToken: zephyrToken,
        defectsConfiguration: arr
      };
      console.log(requestData,"requestData requestData");
      
      if (accountType === 'jira') {
        if (found || userName == "" || url == "" || token == "") {
          console.log("if condition")
          openNotification(notificationTypes.ERROR, "error : " + errorMsg);
          return;
        }

        GET(`/getowneridsettings?userName=${userName}&token=${token}&url=${url}`, {
          sessionID: sessionId,
        })
          .then((res) => {
            if (res.data.message[0].ownerId) {
              let resData = res.data.message;
              console.log(resData, "resData 208");
              if (zephyrUrl || zephyrToken) {
                GET(`/getallfolderid?userName=${userName}&zephyrToken=${zephyrToken}`, {
                  sessionID: sessionId,
                })
                  .then((res) => {
                    if (res.data.message[0].folderId && zephyrUrl == "https://api.zephyrscale.smartbear.com/v2/testcases") {
                       POST("/settings", requestData, { sessionID: sessionID })
                        .then((res) => {
                          console.log(res.data.status,"res.data.status")
                          if (res.data.status === "ok") {
                            openNotification(
                              notificationTypes.SUCCESS,
                              "Jira Settings Updated Successfully"
                            );
                          }
                        })
                        .catch((err) => {
                          openNotification(
                            notificationTypes.ERROR,
                            "Something went wrong, Please try again later"
                          );
                        });
                      dispatch(
                        setUser({
                          userId: userId,
                          username: username,
                          email: email,
                          sessionId: sessionIdToken,
                          accountType: userAccountType,
                          settings: requestData1,
                        })
                      )
                    } else {
                      openNotification(
                        notificationTypes.ERROR,
                        "Please enter correct zephyr credentials"
                      );
                    }
                  })
                  .catch(() => {
                    openNotification(
                      notificationTypes.ERROR,
                      "Please enter correct zephyr credentials"
                    );
                  });
              } else {
                POST("/settings", requestData, { sessionID: sessionID })
                .then((res) => {
                  console.log(res.data.status,"res.data.status")
                  if (res.data.status === "ok") {
                    openNotification(
                      notificationTypes.SUCCESS,
                      "Jira Settings Updated Successfully"
                    );
                  }
                })
                .catch((err) => {
                  openNotification(
                    notificationTypes.ERROR,
                    "Something went wrong, Please try again later"
                  );
                });
              dispatch(
                setUser({
                  userId: userId,
                  username: username,
                  email: email,
                  sessionId: sessionIdToken,
                  accountType: userAccountType,
                  settings: requestData1,
                })
              )
              }
            } else {
              openNotification(
                notificationTypes.ERROR,
                "Please enter correct Jira credentials"
              );
            }
          })
          .catch(() => {
            openNotification(
              notificationTypes.ERROR,
              "Please enter correct Jira credentials"
            );
          });
      } else {
      console.log(requestData,"requestData2 greentick")
        POST("/settings", requestData, { sessionID: sessionID })
          .then((res) => {
            console.log(res.data.status,"res.data.status")
            if (res.data.status === "ok") {
              openNotification(
                notificationTypes.SUCCESS,
                "Greentick Settings Updated Successfully"
              );
            }
          })
          .catch((err) => {
            openNotification(
              notificationTypes.ERROR,
              "Something went wrong, Please try again later"
            );
          });
        dispatch(
          setUser({
            userId: userId,
            username: username,
            email: email,
            sessionId: sessionIdToken,
            accountType: userAccountType,
            settings: requestData1,
          })
        )
      }
  }

  function clearData() {
    var confirmation = window.confirm("Are you sure you want to clear fields?");
    if (confirmation) {
        formBuilder.actions.clearFields();
    }
}



  // const formEdit = () => {
  //   var fb = fb.current;
  //   var options = {
  //     disabledAttrs: ['name', 'access', 'maxlength', 'required', 'rows'],
  //     disableFields: ['autocomplete', 'button', 'checkbox-group', 'file', 'hidden', 'radio-group',]
  //   };

  //   if (fb) {
  //     const formBuilderInstance = new formBuilder(options);
  //     formBuilderInstance.ReactDOMRender(fb.current);
  //   }

  //   $(fb).formBuilder(options);
  // }

  async function getSettings() {
    await GET(`/getSettings?userId=${userId}`, { sessionID })
      .then((res) => {
        if (res.data.status === "ok") {
          dispatch(
            setUser({
              userId: userId,
              username: username,
              email: email,
              sessionId: sessionIdToken,
              accountType: userAccountType,
              settings: res.data.message,
            })
          )

        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const openHistoryPopup = () => {
    setIsModal(true);
  };

  const getCustomfields = async () => {
    GET(`/getcustomfields`, {
      sessionID: sessionIdToken,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setCustomfieldData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting customfields, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting customfields, Please try again"
        );
      });
  };

  return (
    <>
      <div>
        {/* <div className="col-span-10">
        <select
          style={{
            width: "50%",
            marginBottom: "15px",
          }}
          className="text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg border-2 border-gray-400"
          onChange={(e) => {
            handleSelectedUser(e);
          }}
        >
          <option value="">Select Customer</option>
          {customer.map((value) => (
            <option
              className="text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg"
              value={value.id}
              key={value.id}
            >
              {value.username}
            </option>
          ))}
        </select>
      </div> */}
        <div className="col-span-10">
          {"Choose Account Type : "}
          <label className="text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap">
            <input
              type="radio"
              id="jira"
              name="accountType"
              value="jira"
              defaultChecked={
                settingData &&
                settingData.length > 0 &&
                settingData[0].accountType === "jira"
              }
              onChange={(e) => {
                setAccountType(e.target.value);
              }}
            />
            &nbsp;&nbsp;{"Jira"}
          </label>
          <label className="text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap">
            <input
              type="radio"
              id="greentick"
              name="accountType"
              value="greentick"
              defaultChecked={
                settingData &&
                settingData.length > 0 &&
                settingData[0].accountType === "greentick"
              }
              onChange={(e) => {
                setAccountType(e.target.value);
              }}
            />
            &nbsp;&nbsp;{"Greentick"}
          </label>
        </div>
        {accountType === "jira" ? <>
          <div className="col-span-10 ">
            <div className="text-sm mt-2" style={{ color: "grey" }}>
              User Name<span style={{ color: "red" }}>*</span>
            </div>
            <h1 className="text-black">
              <input
                type="text"
                className="p-2 w-full border-2 rounded-lg"
                name="username"
                value={userName}
                required
                onChange={(e) => setUserName(e.target.value)}
              />
            </h1>
          </div>
          <div className="col-span-10 ">
            <div className="text-sm mt-2" style={{ color: "grey" }}>
              Add Jira URL<span style={{ color: "red" }}>*</span>
            </div>
            <h1 className="text-black">
              <input
                type="text"
                className="p-2 w-full border-2 rounded-lg"
                name="url"
                value={url}
                required
                onChange={(e) => setURL(e.target.value)}
              />
            </h1>
          </div>
          <div className="col-span-10 ">
            <div className="text-sm mt-2" style={{ color: "grey" }}>
              Add Jira Token<span style={{ color: "red" }}>*</span>
            </div>
            <h1 className="text-black">
              <input
                type="text"
                className="p-2 w-full border-2 rounded-lg"
                value={token}
                required
                onChange={(e) => setToken(e.target.value)}
              />
            </h1>
          </div>
          <div className="col-span-10 ">
            <div className="text-sm mt-2" style={{ color: "grey" }}>
              Add zephyr URL
            </div>
            <h1 className="text-black">
              <input
                type="text"
                className="p-2 w-full border-2 rounded-lg"
                name="zephyrUrl"
                value={zephyrUrl}
                required
                onChange={(e) => setZephyrUrl(e.target.value)}
              />
            </h1>
          </div>
          <div className="col-span-10 ">
            <div className="text-sm mt-2" style={{ color: "grey" }}>
              Add zephyr Token
            </div>
            <h1 className="text-black">
              <input
                type="text"
                className="p-2 w-full border-2 rounded-lg"
                name="zephyrToken"
                value={zephyrToken}
                required
                onChange={(e) => setZephyrToken(e.target.value)}
              />
            </h1>
          </div>
          <button
            onClick={openHistoryPopup}
            class="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
          >
            Get Custom fields
          </button>
        </> : ""}
        {/* {accountType === "jira" ? (
        <p>
          <b>Note</b>: copy the <b>Project Id </b>&& <b>Issue Type Id</b> field
          lable name
        </p>
      ) : (
        ""
      )} */}
        <h1 style={{ marginTop: "15px" }}>Create Form</h1>
        <div className="fb-editor" ref={fb} />
        <div>
          <div>
            {form && Object.keys(form).length > 0 && (<ReactJson src={JSON.parse(form)} />)}
          </div>

          <div>
            <button
              style={{
                margin: "20px",
                width: "150px",
                height: "40px",
                color: "whitesmoke",
                backgroundColor: "gray",
                cursor: "pointer",
                transition: "width 0.5s",
                border: "4px  solid #302e3b",
              }}
              onClick={clearData}
              type="button"
            >
              Clear
            </button>
            <button
              style={{
                margin: "20px",
                width: "150px",
                height: "40px",
                color: "whitesmoke",
                backgroundColor: "gray",
                cursor: "pointer",
                transition: "width 0.5s",
                border: "4px  solid #302e3b",
              }}
              onClick={saveData}
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <CustomFieldModel
        showModal={isModal}
        customfieldData={customfieldData}
        setShowModal={setIsModal}
      />
    </>
  );
};

export default Settings;
