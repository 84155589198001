import TextField from '../../InputComponents/InputField'
import { useState, useEffect, useRef } from 'react'
import styles from '../modal.module.css'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import { openNotification, notificationTypes } from '../../../utils/notifications'

const CustomFieldModel = ({
    showModal,
    setShowModal,
    customfieldData
}) => {
    const modalRef = useRef(null)
    const [customField, setCustomField] = useState("");
    console.log(customfieldData,"customfieldData")
    const [customfieldData1, setCustomFieldData] = useState([]);
    const [customfieldData1Duplicate, setCustomFieldDataDuplicate] = useState([]);
    const closeModal = () => {
        setShowModal(!showModal)
    }

    useEffect(() => {
        setCustomFieldData(customfieldData)
        setCustomFieldDataDuplicate(customfieldData)
        if (showModal) {
            modalRef.current.style.top = '0'
            modalRef.current.style.opacity = '1'
        } else {
            modalRef.current.style.top = '120vh'
            modalRef.current.style.opacity = '0'
        }
    }, [showModal])

    const filterDefects = () => {
        if (customField.trim() === "") {
            openNotification(notificationTypes.ERROR, "Please enter data to search");
            setCustomFieldData(customfieldData1Duplicate)
            return;
          }

        const filtered = customfieldData1.filter((D) => {
          return D.name.toLowerCase().includes(customField.toLowerCase())
        })
        setCustomFieldData(filtered)
      }

    return (
        <div ref={modalRef} className={styles.wrapper3} style={{}}>
            <div className={styles.cardContainer6}>
                <div className={styles.header}>
                    <div className={styles.header}>
                        <div>
                        <h2 className={styles.heading}>Jira Custom fields</h2>
                        </div>
                        <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                      <input
                        type="search"
                        class="relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        placeholder="Search for Custom field"
                        aria-label="Search"
                        aria-describedby="button-addon3"
                        value={customField}
                        onChange={(e) => setCustomField(e.target.value)}
                      />
                      <button
                        class="relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
                        type="button"
                        id="button-addon3"
                        data-te-ripple-init
                        onClick={filterDefects}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="h-5 w-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    </div>
                    <div className={styles.close}>
                        <a onClick={closeModal}>
                            <CloseCircleOutlined style={{ fontSize: '30px', color: 'black' }} />
                        </a>
                    </div>
                    <table className="table-auto w-full overflow-x-scroll border-2 bg-customWhite rounded-3xl border-customGreyThree editor_listing_table">
                        <thead class="text-center bg-customGrey rounded-2xl">
                            <tr>

                                <th
                                    scope="col"
                                    class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                                >
                                    S.No
                                </th>
                                <th
                                    scope="col"
                                    class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                                >
                                    Name{" "}
                                </th>
                                <th
                                    scope="col"
                                    class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                                >
                                    Label{" "}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customfieldData1 && customfieldData1.length === 0 ? (
                                    <tr>
                                        <td />
                                        <td className="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                            No customfields available
                                        </td>
                                    </tr>
                                ) : (
                                    customfieldData1 && customfieldData1?.map((obj, index) => {
                                        let num = index + 1;
                                        return (
                                            <tr className="border-b border-1 border-customGreyThree " key={index}>
                                                <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                                    {num}
                                                </td>
                                                <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                                    {obj.key}
                                                </td>
                                                <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                                    {obj.name}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default CustomFieldModel
