import { useNavigate } from 'react-router-dom'

const TestCase = (props) => {
  const navigate = useNavigate()
  
  return (
    <>
      <tr class='border-b border-1 border-customGreyThree'>
        <td class='text-base text-gray-600 font-medium px-8 py-1'>{props.tc.appName} {'>'} {props.tc.moduleName} {'>'} {props.tc.scenarioName}</td>
        <td
          class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-1'
          onClick={() => window.open(`/testcase/${props.tc.id}/${props.tc.scenario_id}`)}
        >
          <p className='break-words'>
            {props.tc.testcaseName}
          </p>
        </td>
        <td class='text-base text-gray-600 font-medium px-8 py-1'>
          <p className='break-words'>
            {props.tc.testCaseDescription}
          </p>
        </td>
      </tr>
    </>
  )
}

export default TestCase
