import { useNavigate } from 'react-router-dom'

import { routes } from '../../../constants/routes'
import Dashboard from '../../../assets/Icons/Dashboard.png'

function DashboardItem () {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(routes.DASHBOARD)}
      class='hover:bg-customBlueFour flex items-center p-2 text-base font-bold text-black rounded-lg'
    >
      <img className='ml-6' src={Dashboard} alt='Dashboard' />
      <span
        className='ml-3'
      >
        Dashboard
      </span>
    </div>
  )
}

export default DashboardItem
