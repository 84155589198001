import TextField from '../../InputComponents/InputField'
import SingleSelectComponent from '../../InputComponents/SingleSelectComponent'
import { TCtype, TCstatus } from '../../../constants/TestCase'
// import { DatePicker, Space } from "antd";
// import Label from "../../InputComponents/Label";
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { GET, POST } from '../../../config/api'
import styles from '../modal.module.css'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import moment from 'moment'

const TcModal = ({ showModal, setShowModal, id, moduleID, appID }) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)
  const currDate = moment()

  const [TCType, setTCType] = useState('')
  const [TCstat, setTCstatus] = useState('')
  const [tcName, setTcName] = useState('')
  const [tcDate, setTcDate] = useState('')
  const [tcID, setTCID] = useState('')
  const [tcDesc, setTcDesc] = useState('')
  const [tcid, setTCSID] = useState('')

  const [tcsId, setTcsID] = useState('')
  const [tcsName, setTcsName] = useState('')
  const [tcsDesc, setTcsDesc] = useState('')
  const [tcsExpRes, setTcsExpRes] = useState('')
  const [tcsStep, setTcsStep] = useState([])

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const conclusionModal = () => {
    setShowModal(!showModal)
    window.location.reload()
  }

  const addTC = (e) => {
    if (
      TCType.length > 0 &&
      TCstat.length > 0 &&
      tcName.length > 0 &&
      tcID.length > 0 &&
      tcDesc.length > 0
    ) {
      const Data = {
        testCaseID: tcID,
        testCaseName: tcName,
        testCaseDescription: tcDesc,
        testcaseType: TCType,
        testCaseStatus: TCstat,
        testCaseDate: currDate,
        moduleID,
        applicationID: appID
      }
      POST(`/postTestCase?scenarioID=${id}`, Data, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Test case added successfully'
            )
            setTCSID(res.data.message.id)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          console.log('tc not added')
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error',
        'Please enter all the details'
      )
    }
  }

  const addTCStep = (e) => {
    if (
      tcsId.length > 0 &&
      tcsName.length > 0 &&
      tcsDesc.length > 0 &&
      tcsExpRes.length > 0
    ) {
      const Data = {
        stepID: tcsId,
        stepName: tcsName,
        stepDescription: tcsDesc,
        expectedResult: tcsExpRes
      }
      POST(`/postTestcaseStep?testCaseID=${tcid}`, Data, {
        sessionID: sessionId
      })
        .then((res) => {
          openNotification(
            notificationTypes.SUCCESS,
            'Test case added successfully'
          )
          const arr = [...tcsStep]
          arr.push(Data)
          setTcsStep(arr)
          // window.location.reload()
          // setShowModal(!showModal)
        })
        .catch((err) => {
          console.log('tc not added')
        })
    }
  }

  // const onChange = (date, dateString) => {
  //   setTcDate(dateString);
  // };

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
    GET(`/getTestcaseStep?testCaseID=${tcid}`, { sessionID: sessionId })
      .then((res) => {
        setTcsStep.append(res.data.message)
      })
      .catch((err) => {
        console.log('error')
      })
    setTCType(TCtype[0].value)
    setTCstatus(TCstatus[0].value)
  }, [showModal, tcid, sessionId])

  return (
    <div ref={modalRef} className={styles.wrapper1}>
      <div className={styles.cardContainer1}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Add Test Case</h2>
          </div>
          <div className={styles.close}>
            <span onClick={closeModal}>
              <AiOutlineCloseCircle size={20} />
            </span>
          </div>
        </div>
        <div className={styles.TCgrid}>
          <div>
            <TextField
              label='Name of the Test Case'
              placeholder={tcName}
              value={tcName}
              onChangeHandler={(e) => setTcName(e.target.value)}
              maxLength={5000}
              type='text'
              required
            />
          </div>
          <div>
            <TextField
              label='Id of the Test Case'
              placeholder={tcID}
              value={tcID}
              onChangeHandler={(e) => setTCID(e.target.value)}
              maxLength={5000}
              type='text'
              required
            />
          </div>
        </div>
        <label
          for='exampleFormControlTextarea1'
          class='form-label inline-block mb-2 text-gray-700'
        >
          Test Case Description11111
        </label>
        <textarea
          class='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          value={tcDesc}
          onChange={(e) => setTcDesc(e.target.value)}
          id='Note'
          rows='2'
        />
        <div className={styles.TCgrid}>
          <div>
            <SingleSelectComponent
              value={TCType}
              onChangeHandler={(e) => setTCType(e.target.value)}
              options={TCtype}
              label='Type'
            />
          </div>
          <div>
            <SingleSelectComponent
              value={TCstat}
              onChangeHandler={(e) => setTCstatus(e.target.value)}
              options={TCstatus}
              label='Status'
            />
          </div>
          <div>
            <TextField
              label='Date of the Test Case'
              placeholder={currDate}
              value={currDate}
              onChangeHandler={(e) => setTcDate(currDate)}
              maxLength={5000}
              type='text'
              required
              disabled
            />
          </div>
        </div>
        <div class='flex items-center justify-between'>
          <button
            class='mt-5 w-1/4 bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            onClick={addTC}
          >
            <i style={{ color: 'white' }} class='fa-solid fa-plus' /> &nbsp; Add
            Test Case
          </button>
        </div>
        <h1 className={styles.heading_1}>Add Test Case Steps</h1>
        <div class='flex flex-col'>
          <div class='overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div class='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
              <div class='overflow-hidden'>
                <table class='min-w-full text-center border '>
                  <thead class='border-b'>
                    <tr>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                      >
                        Test Case Step ID
                      </th>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                      >
                        Test Case Step Name
                      </th>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                      >
                        Test Case Step Description
                      </th>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                      >
                        Test Case Expected Output
                      </th>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {tcsStep.length > 0
                      ? (
                          tcsStep.map((tcStep, index) => {
                            return (
                              <tr class='border-b'>
                                <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                  {tcStep.stepID}
                                </td>
                                <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                  {tcStep.stepName}
                                </td>
                                <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                  {tcStep.stepDescription}
                                </td>
                                <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                  {tcStep.expectedResult}
                                </td>
                              </tr>
                            )
                          })
                        )
                      : (
                        <div />
                        )}

                    <tr class='border-b'>
                      <td class='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        <input
                          class='shadow appearance-none bg-customGrey rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                          id='text'
                          type='text'
                          onChange={(e) => setTcsID(e.target.value)}
                        />
                      </td>
                      <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                        <input
                          class='shadow appearance-none bg-customGrey rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                          id='text'
                          type='text'
                          onChange={(e) => setTcsName(e.target.value)}
                        />
                      </td>
                      <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                        <input
                          class='shadow appearance-none bg-customGrey rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                          id='text'
                          type='text'
                          onChange={(e) => setTcsDesc(e.target.value)}
                        />
                      </td>
                      <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                        <input
                          class='shadow appearance-none bg-customGrey rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                          id='text'
                          type='text'
                          onChange={(e) => setTcsExpRes(e.target.value)}
                        />
                      </td>
                      <td class='text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap'>
                        <button
                          class='mb-3 w-full bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                          type='button'
                          onClick={addTCStep}
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class='flex items-center justify-between'>
          <button
            class='mt-5 w-full bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            onClick={conclusionModal}
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  )
}

export default TcModal
