import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { documentTitles } from '../../constants/appConstants'
import ReleaseInfo from '../AddReleasePlanner/ReleaseInfo'
import ReleaseEstimation from '../AddReleasePlanner/ReleaseEstimation'
import TestPackMapping from '../AddReleasePlanner/TestPackMapping'
import TestCompletionReport from '../AddReleasePlanner/TestCompletionReport'
import TestDownloadReport from '../AddReleasePlanner/DownloadReport'
import { useNavigate, useLocation } from 'react-router-dom'
import TestRun from '../AddReleasePlanner/TestRun'
import SecurityTestRelease from '../SecurityTestRelease'

const EditRelease = () => {
  const [data, setData] = useState(0)
  const location = useLocation()
  const sessionId = useSelector((state) => state.user.sessionId)
  const dataArray = location.state.message.split(',')
  const [name, setName] = useState(dataArray[1].trim())
  const [version, setVersion] = useState(dataArray[2].trim())
  const [releaseDate, setReleaseDate] = useState(dataArray[3].trim())
  const [description, setDescription] = useState(dataArray.slice(4, -4).join(', ').trim())
  const [overallStatus, setOverallStatus] = useState(dataArray[dataArray.length - 4].trim())
  const [devStartDate, setDevStartDate] = useState(dataArray[dataArray.length - 3].trim())
  const [devEndDate, setDevEndDate] = useState(dataArray[dataArray.length - 2].trim())
  const [effort, setEffort] = useState(dataArray[dataArray.length - 1].trim())

  const navigate = useNavigate()

  const handleEst = () => {
    setData(0)
  }

  const handlePlan = () => {
    setData(1)
  }

  const sectionTwo = () => {
    setData(2)
  }

  const sectionThree = () => {
    setData(3)
  }

  const sectionFour = () => {
    setData(4)
  }

  const sectionFive = () => {
    setData(5)
  }

  const sectionSix = () => {
    setData(6)
  }

  const sectionSeven = () => {
    setData(7)
  }

  useEffect(() => {
    document.title = documentTitles.RELEASEPLANNER
  }, [])

  // useEffect(() => {
  //   var d = {
  //     name,
  //     version,
  //     releaseDate,
  //     overallStatus,
  //     description,
  //   };
  //   d["devEndDate"] = devEndDate;
  //   console.log(d, featureId);
  // }, [
  //   name,
  //   version,
  //   releaseDate,
  //   description,
  //   devEndDate,
  //   devStartDate,
  //   overallStatus,
  //   featureId,
  // ]);

  return (
    <>
      <div>
        <div className='pb-52 bg-gray-100'>
          <div className='mr-22 grid grid-cols-10 gap-16'>
            <div className='ml-3 pb-32 col-span-10'>
              <div>
                <div className='grid grid-cols-10'>
                  <div className='col-span-6'>
                    <h1 className='mt-5 ml-5 pb-3 mb-4  text-black font-bold font-DMSANS text-base xl:text-4xl leading-none lg:leading-11'>
                      Release Management
                    </h1>
                  </div>
                  <div className='col-span-2' />
                  <div className='col-span-2'>
                    <div className='mt-5 ml-5 pb-3 mb-4'>
                      <button
                        onClick={() => navigate('/releaseplanner')}
                        class='mr-2 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 px-6 rounded'
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="grid grid-cols-6 gap-4 mt-5">
                  <div className="col-end-7">
                    <button
                      className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
                      type="button"
                      onClick={updateRelease}
                    >
                      Save
                    </button>
                  </div>
                </div> */}
                <div className='bg-customWhite p-10 rounded-xl shadow-xl'>
                  <div>
                    <div className='flex mb-5'>
                      <h1
                        onClick={handleEst}
                        className={
                          data === 0
                            ? 'underline underline-offset-4 text-lg text-customBlueFive mr-2'
                            : 'mr-2 text-lg text-black'
                        }
                      >
                        Release Info
                      </h1>
                      <h1
                        onClick={handlePlan}
                        className={
                          data === 1
                            ? 'ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive'
                            : 'ml-3 mr-2 text-lg text-black'
                        }
                      >
                        Release Feature
                      </h1>
                      {/* <h1
                        onClick={sectionTwo}
                        className={
                          data === 2
                            ? "ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive"
                            : "ml-3 mr-2 text-lg text-black"
                        }
                      >
                        Dev Estimate
                      </h1> */}
                      <h1
                        onClick={sectionThree}
                        className={
                          data === 3
                            ? 'ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive'
                            : 'ml-3 mr-2 text-lg text-black'
                        }
                      >
                        Test Pack Mapping
                      </h1>
                      <h1
                        onClick={sectionFour}
                        className={
                          data === 4
                            ? 'ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive'
                            : 'ml-3 mr-2 text-lg text-black'
                        }
                      >
                        Test Run
                      </h1>
                      <h1
                        onClick={sectionFive}
                        className={
                          data === 5
                            ? 'ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive'
                            : 'ml-3 mr-2 text-lg text-black'
                        }
                      >
                        Test Summary
                      </h1>
                      <h1
                        onClick={sectionSix}
                        className={
                          data === 6
                            ? 'ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive'
                            : 'ml-3 mr-2 text-lg text-black'
                        }
                      >
                        Report
                      </h1>
                      <h1
                        onClick={sectionSeven}
                        className={
                          data === 7
                            ? 'ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive'
                            : 'ml-3 mr-2 text-lg text-black'
                        }
                      >
                        Security Report
                      </h1>
                    </div>
                  </div>
                  {data === 0 ? (
                    <ReleaseInfo
                      name={name}
                      version={version}
                      releaseDate={releaseDate}
                      description={description}
                      overallStatus={overallStatus}
                      // devStartDate={devStartDate}
                      // devEndDate={devEndDate}
                      // effort={effort}
                      releaseId={location.state.message.split(',')[0]}
                    />
                  ) : data === 1 ? (
                    <ReleaseEstimation
                      releaseId={location.state.message.split(',')[0]}
                      // featureId={featureId}
                      // onSave={(selectedTestPack) => {
                      //   setFeatureId(selectedTestPack);
                      // }}
                    />
                  ) : data === 2 ? (
                    {
                      // <ReleasePlan
                      //   releaseId={location.state.message.split(",")[0]}
                      //   devStartDate={devStartDate}
                      //   devEndDate={devEndDate}
                      //   effort={effort}
                      //   onSave={(rdevStartDate, rdevEndDate, rEffort) => {
                      //     setDevStartDate(rdevStartDate);
                      //     setDevEndDate(rdevEndDate);
                      //     setEffort(rEffort);
                      //   }}
                      // />
                    }
                  ) : data === 3
                    ? (
                      <TestPackMapping
                        releaseId={location.state.message.split(',')[0]}
                      />
                      )
                    : data === 4
                      ? (
                        <TestRun releaseId={location.state.message.split(',')[0]} />
                        )
                      : data === 5
                        ? (
                          <TestCompletionReport
                            releaseId={location.state.message.split(',')[0]}
                          />
                          )
                        : data === 6
                          ? (
                            <TestDownloadReport
                              name={name}
                              version={version}
                              releaseDate={releaseDate}
                              description={description}
                              overallStatus={overallStatus}
                              devStartDate={devStartDate}
                              devEndDate={devEndDate}
                              effort={effort}
                              releaseId={location.state.message.split(',')[0]}
                            />
                            )
                          : data === 7
                            ? (
                              <SecurityTestRelease
                                releaseId={location.state.message.split(',')[0]}
                              />
                              )
                            : (
                              <></>
                              )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditRelease
