import { useState, useEffect } from 'react'
import { GET } from '../../../../../config/api'
import { useSelector } from 'react-redux'
import Test from './TestCase'
import { openNotification, notificationTypes } from '../../../../../utils/notifications'

const Scenario = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [testCases, setTestCases] = useState({})
  const [allTC, setAllTC] = useState({})
  const [isTestCases, setIsTestCases] = useState(false)

  const getTestCases = () => {
    GET(`/getTestCases?scenarioID=${props.scenario.id}`, { sessionID: sessionId })
      .then((res) => {
        setTestCases(res.data.message)
        setIsTestCases(true)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const getTC = () => {
    GET(`/getTestCasesfromTestPack?id=${props.testPackID}`, { sessionID: sessionId })
      .then((res) => {
        setAllTC(res.data.message)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getTestCases()
    getTC()
  }, [props.scenario.id, props.testPackID])

  return (
    <>
      <div className='ml-28 pt-2 font-DMSANS text-sm xl:text-2xl font-medium leading-none lg:leading-11'>
        <i class='fa-solid fa-angle-right' />&nbsp;&nbsp;
        <span>
          {props.scenario.scenarioName}&nbsp;
        </span>
        {
                    isTestCases && (
                      testCases.map((item) => (
                        <Test
                          tests={item}
                          scenarioID={props.scenario.id}
                          testcases={allTC}
                        />
                      ))
                    )
                }
      </div>
    </>
  )
}

export default Scenario
