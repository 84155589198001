import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../../../constants/routes'
import Arrow from '../../../../../../assets/Icons/arrow.png'
import User from '../../../../../../assets/Icons/testcaseicon.png'

const TestCase = (props) => {
  const navigate = useNavigate()

  return (
    <>
      <div className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-12 leading-none lg:leading-11'>
        <span class='flex items-center p-2 font-normal text-black rounded-lg' onClick={() => navigate(`${routes.VIEWMANUALRUN}/${props.manualrunID}/${props.testpackID}/${props.testCase.id}`)}>
          <img className='mr-2' src={Arrow} alt='Arrow' />
          <img src={User} className='h-9 mr-2' alt='User' />
          {props.testCase.testcase_name}&nbsp;
        </span>
      </div>
    </>

  )
}

export default TestCase
