import React from 'react'
import { RiArrowGoBackLine, RiArrowGoForwardFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

const Howitworks = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              How Greentick Works?
            </h1>
          </div>
          <div className='mb-10 col-span-10 my-4'>
            <ul className='flex flex-col gap-5'>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/Adding_a_User__CXO__in_Greentickme_Dashboard__n1lDVSlnTkynQ0yCUz5wHw'
                >
                  <button className='font-DMSANS w-80 h-20 mx-72 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'>
                    {' '}
                    Onboarding{' '}
                  </button>
                </a>
                <span>
                  <RiArrowGoForwardFill className='absolute  text-7xl mx-[45rem] font-extrabold rotate-180' />
                </span>
              </li>
              <li>
                <button
                  onClick={() => navigate('/createuserbutton')}
                  className='font-DMSANS w-80 h-20 mx-96 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'
                >
                  {' '}
                  Create User{' '}
                </button>
              </li>
              <li>
                <span>
                  <RiArrowGoBackLine className='absolute -my-5 text-7xl mx-48 font-extrabold rotate-180' />
                </span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Add_a_Test_Case_in_GreenTickme__qiJLtPhHSBy_JPxNSOo2zg'
                >
                  <button className='font-DMSANS w-80 h-20 mx-72 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'>
                    {' '}
                    Create Test Case{' '}
                  </button>
                </a>
                <span>
                  <RiArrowGoForwardFill className='absolute  text-7xl mx-[45rem] font-extrabold rotate-180' />
                </span>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Create_a_Test_Pack_in_GreenTickme__0Bfk6b1HQm-JtRB6see8KA'
                >
                  <button className='font-DMSANS w-80 h-20 mx-96 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'>
                    {' '}
                    Create Test Pack{' '}
                  </button>
                </a>
              </li>
              <li>
                <span>
                  <RiArrowGoBackLine className='absolute -my-5 text-7xl mx-48 font-extrabold rotate-180' />
                </span>
                <button
                  onClick={() => navigate('/runrequestbutton')}
                  className='font-DMSANS w-80 h-20 mx-72 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'
                >
                  {' '}
                  Run Request{' '}
                </button>
                <span>
                  <RiArrowGoForwardFill className='absolute  text-7xl mx-[45rem] font-extrabold rotate-180' />
                </span>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Execute_Test_Cases_as_Tester__3x21ymf8QP27VkZ-lEQMdg'
                >
                  <button className='font-DMSANS w-80 h-20 mx-96 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'>
                    {' '}
                    Execution{' '}
                  </button>
                </a>
              </li>
              {/* <li>
                                <span><RiArrowGoBackLine className='absolute -my-5 text-7xl mx-48 font-extrabold rotate-180'/></span>
                                <button className='w-80 h-20 mx-72 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black' > View Results </button>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Howitworks
