import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import TestCase from './TestCase'
import { documentTitles } from '../../constants/appConstants'
import sheet from '../../assets/Icons/sheets.png'
import ImportAllSuiteFromExcel from '../../components/Modal/ImportAllSuiteFromExcel'
import { HiOutlineViewGridAdd } from 'react-icons/hi'
import { MdOutlineLibraryAdd, MdOutlineAddBox } from 'react-icons/md'
import { AiOutlineFileAdd, AiOutlineInfoCircle } from 'react-icons/ai'
import ApplicationModalbutton from '../../components/Modal/ApplicationModalbutton'
import ModuleModalbutton from '../../components/Modal/ModuleModalbutton'
import ScenarioModalbutton from '../../components/Modal/ScenarioModalbutton'
import TestcaseModalbutton from '../../components/Modal/TestcaseModalbutton'

const TestCaseManagement = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [allTC, setAllTC] = useState({})
  const [appName, setAppName] = useState('')
  const [option, setOption] = useState(1)
  const [moduleName, setModuleName] = useState('')
  const [scenarioName, setScenarioName] = useState('')
  const [filteredTC, setFilteredTC] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [isModalModule, setIsModalModule] = useState(false)
  const [isModalScenario, setIsModalScenario] = useState(false)
  const [isModalTestCase, setIsModalTestCase] = useState(false)
  const [app, setApp] = useState(null)
  const [module, setModule] = useState(null)
  const [schedule, setSchedule] = useState([])

  const handleAppClick = () => {
    setIsModal(true)
  }

  const handleModuleClick = () => {
    setIsModalModule(true)
  }

  const handleScenarioClick = () => {
    setIsModalScenario(true)
  }

  const handleTestCaseClick = () => {
    setIsModalTestCase(true)
  }

  const getTC = () => {
    GET('/getTestCaseDetails', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setAllTC(res.data.message)
          setFilteredTC(res.data.message)
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const filterTC = () => {
    if (option === 1) {
      const filtered = allTC.filter((tc) => {
        return tc.appName.toLowerCase().includes(appName.toLowerCase())
      })
      setFilteredTC(filtered)
    } else if (option === 2) {
      const filtered = allTC.filter((tc) => {
        return tc.moduleName.toLowerCase().includes(moduleName.toLowerCase())
      })
      setFilteredTC(filtered)
    } else if (option === 3) {
      const filtered = allTC.filter((tc) => {
        return tc.scenarioName
          .toLowerCase()
          .includes(scenarioName.toLowerCase())
      })
      setFilteredTC(filtered)
    } else {
      const filtered = allTC.filter((tc) => {
        return tc.appName.toLowerCase().includes(appName.toLowerCase())
      })
      setFilteredTC(filtered)
    }
  }

  const options = [
    {
      id: 1,
      name: 'Application'
    },
    {
      id: 2,
      name: 'Module'
    },
    {
      id: 3,
      name: 'Scenario'
    }
  ]

  useEffect(() => {
    getTC()
    document.title = documentTitles.TESTCASEMANAGEMENT
  }, [])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Test Case Management
            </h1>
            <div className='flex items-center justify-center flex-wrap'>
              <h1 className='font-semibold text-lg w-full text-center'>
                How to
              </h1>
              <p className='flex text-customBlue hover:text-customBlueFive items-center justify-center text-lg w-full'>
                <AiOutlineInfoCircle className='mr-2' />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Add_a_Test_Case_in_GreenTickme__qiJLtPhHSBy_JPxNSOo2zg'
                >
                  Create a test case?
                </a>
              </p>
            </div>
          </div>
          <div className='grid grid-cols-10 items-center justify-between'>
            <div className='col-span-5'>
              <ul className='flex gap-5 my-2 py-2 items-center'>
                <li className='p-0'>
                  <select
                    className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg border-2 border-gray-400'
                    onChange={(e) => {
                      setOption(Number(e.target.value))
                    }}
                  >
                    {options.map((value) => (
                      <option
                        className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                        value={value.id}
                        key={value.id}
                      >
                        {value.name}
                      </option>
                    ))}
                  </select>
                </li>
                <li className='relative py-2 flex w-full flex-wrap items-stretch'>
                  {option === 1 && (
                    <input
                      type='search'
                      className='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                      placeholder='Search for applications'
                      aria-label='Search'
                      aria-describedby='button-addon3'
                      value={appName}
                      onChange={(e) => setAppName(e.target.value)}
                    />
                  )}
                  {option === 2 && (
                    <input
                      type='search'
                      className='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                      placeholder='Search for modules'
                      aria-label='Search'
                      aria-describedby='button-addon3'
                      value={moduleName}
                      onChange={(e) => setModuleName(e.target.value)}
                    />
                  )}
                  {option === 3 && (
                    <input
                      type='search'
                      className='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                      placeholder='Search for scenarios'
                      aria-label='Search'
                      aria-describedby='button-addon3'
                      value={scenarioName}
                      onChange={(e) => setScenarioName(e.target.value)}
                    />
                  )}
                  <button
                    className='relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0'
                    type='button'
                    id='button-addon3'
                    data-te-ripple-init
                    onClick={filterTC}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      className='h-5 w-5'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z'
                        clip-rule='evenodd'
                      />
                    </svg>
                  </button>
                </li>
              </ul>
              <ApplicationModalbutton
                showModal={isModal}
                setShowModal={setIsModal}
                setApp={setApp}
              />
              <ModuleModalbutton
                showModal={isModalModule}
                setShowModal={setIsModalModule}
                setModule={setModule}
              />
              <ScenarioModalbutton
                showModal={isModalScenario}
                setShowModal={setIsModalScenario}
                schedule={schedule}
                setSchedule={setSchedule}
              />
              <TestcaseModalbutton
                showModal={isModalTestCase}
                setShowModal={setIsModalTestCase}
              />
              <ul className='relative my-2 py-2 flex w-full items-stretch justify-between'>
                <li>
                  <button
                    className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-2 py-2 rounded-lg'
                    onClick={handleAppClick}
                  >
                    <HiOutlineViewGridAdd className='text-md' />
                    Application
                  </button>
                </li>
                <li>
                  <button
                    className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-2 py-2 rounded-lg'
                    onClick={handleModuleClick}
                  >
                    <MdOutlineLibraryAdd className='text-md' />
                    Module
                  </button>
                </li>
                <li>
                  <button
                    className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-2 py-2 rounded-lg'
                    onClick={handleScenarioClick}
                  >
                    <MdOutlineAddBox className='text-md' />
                    Scenario
                  </button>
                </li>
                <li>
                  <button
                    className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-1 py-2 rounded-lg'
                    onClick={handleTestCaseClick}
                  >
                    <AiOutlineFileAdd className='text-md' />
                    Test Case
                  </button>
                </li>
              </ul>
            </div>
            <div className='col-start-7 col-end-11 py-4 flex justify-end h-full items-end'>
              <button
                onClick={() => setShowModal(true)}
                className='flex py-2 my-2 hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6 h-fit'
              >
                <img className='h-6' src={sheet} alt='' /> &nbsp; Import from
                excel
              </button>
            </div>
            {/* <div className='col-span-1'></div> */}
          </div>
          <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl bg-customWhite'>
            <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
              <thead className='text-center bg-customGrey rounded-2xl'>
                <tr>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Application {'>'} Module {'>'} Scenario
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Test Case Name
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Test Case Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTC == null
                  ? null
                  : filteredTC.length > 0
                    ? (
                        filteredTC.map((tc) => <TestCase tc={tc} />)
                      )
                    : (
                      <tr className='border-b border-1 border-customGreyThree'>
                        <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                          No Test Cases Found
                        </td>
                        <td className='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>
                          -
                        </td>
                      </tr>
                      )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ImportAllSuiteFromExcel
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  )
}

export default TestCaseManagement