import FooterImage from '../../assets/Images/footerLogo.png'

import fb from '../../assets/Icons/facebook.png'
import insta from '../../assets/Icons/instagram.png'
import linkedin from '../../assets/Icons/linkedin.png'
import twitter from '../../assets/Icons/twitter.png'

import styles from './footer.module.css'

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <div className={styles.container}>
        <div className={styles.footerLogo}>
          <img className={styles.footerImage} src={FooterImage} alt='footer logo' />
          <div>
            <p className={styles.para}>The starting point for your next project.</p>
            <div class='flex flex-row'>
              <div>
                <img className={styles.img} src={fb} alt='fb' />
              </div>
              <div>
                <img className={styles.img} src={insta} alt='twitter' />
              </div>
              <div>
                <img className={styles.img} src={linkedin} alt='linkedin' />
              </div>
              <div>
                <img className={styles.img} src={twitter} alt='linkedin' />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.gridContainer}>
          <div className={styles.gridItem}>
            <h3>TESTBUDDY</h3>
            <ul>
              <li>About us</li>
              <li>Contact us</li>
              <li>FAQ</li>
            </ul>
          </div>
          <div className={styles.gridItem}>
            <h3>LEGAL</h3>
            <ul>
              <li>Terms of Condition</li>
              <li>Privacy Policy</li>
            </ul>
          </div>
          <div className={styles.gridItem}>
            <h3>CONTACT</h3>
            <ul>
              <li>darshan@greentick.me</li>
              <li>F1 , Sai Madhura Swarnima ,
                5th Cross venkatadri layout
                Bangalore 560076
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.lastSection}>
        <div className={styles.copyright}>
          <div>
            <h1>© 2021. All rights reserved</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
