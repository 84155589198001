import { useSelector } from 'react-redux'
import User1 from '../../../assets/Icons/user1.png'

const User = () => {
  const user = useSelector((state) => state.user)

  return (
    <li className='pt-2'>
      <div class='hover:bg-customBlueFour flex items-center p-2 text-base font-bold text-black rounded-lg'>
        <img src={User1} alt='User' />
        <span class='flex-1 ml-3 whitespace-nowrap'>{user === null ? '' : user.username}</span>
      </div>
    </li>
  )
}

export default User
