import { useState, useEffect } from 'react'
import { GET, DELETE, POST, PUT } from '../../config/api'
// import del from "../../assets/Icons/delete.png";
// import select from "../../assets/Icons/blackselect.png";
// import edit from "../../assets/Icons/edit.png";
import { useSelector } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { documentTitles } from '../../constants/appConstants'
import { FaUser, FaUserEdit, FaUserCheck, FaUserMinus } from 'react-icons/fa'
import { notificationTypes, openNotification } from '../../utils/notifications'

const UserManagement = () => {
  const navigate = useNavigate();

  const sessionID = useSelector((state) => state.user.sessionId);
  const user = useSelector((state) => state.user);

  const [users, setUsers] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const [editedUserId, setEditedUserId] = useState(null)
  const [editedUserRole, setEditedUserRole] = useState('')
  const [editedUsername, setEditedUsername] = useState('')

  const handleEditClick = (id, initialUsername, initialRole) => {
    setIsEditing(!isEditing)
    setEditedUserId(id)
    setEditedUsername(initialUsername)
    setEditedUserRole(initialRole)
  }

  useEffect(() => {
    const getUsers = () => {
      GET("/getUsers", { sessionID })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            setUsers(res.data.message);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUsers();
    document.title = documentTitles.USER;
  }, [sessionID]);

  const deleteUser = (userID) => {
    const confirmed = window.confirm('Are you sure you want to remove User?')
    if (confirmed) {
      DELETE(`/deleteUser?userID=${userID}`, { sessionID })
        .then((res) => {
          if (res.data.status === 200) {
            window.location.reload(true)
            openNotification(
              notificationTypes.SUCCESS,
              "User deleted Successfully"
            );
          }
        })
        .catch(() => {
          openNotification(notificationTypes.ERROR, "Error deleting User");
        });
    }
  }

  const updateUser = (userID) => {
    const data = {
      accountType: editedUserRole,
      username: editedUsername
    }
    if (editedUsername === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error',
        'Username cannot be empty'
      )
      window.location.reload()
      return
    }

    PUT(`/editUser?userID=${userID}`, data, {
      sessionID
    })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'User Role updated successfully'
          )
          window.location.reload()
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Error in updating User Role. Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Error in updating User Role. Please try again'
        )
      })
  }

  return (
    <>
      <div className="grid grid-cols-10 gap-1 m-2 p-2">
        <div className="col-span-10">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-black font-DMSANS text-4xl py-1">
              User Management
            </h1>
            <div className="flex items-center justify-center flex-wrap">
              <h1 className="font-semibold text-lg w-full text-center">
                How to
              </h1>
              <p className="flex text-customBlue hover:text-customBlueFive items-center justify-center text-lg w-full">
                <AiOutlineInfoCircle className="mr-2" />
                <a target="_blank" rel="noreferrer" href="/createuserbutton">
                  Create a user?
                </a>
              </p>
            </div>
          </div>
          <div className="mt-4 pb-6 min:h-2/5 mr-8">
            <button
              onClick={() => navigate("/addUser")}
              className="flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg"
            >
              <FaUser className="text-lg mr-1" />
              Add Users
            </button>
          </div>
          <div className='flex justify-between items-center col-span-10'>
            <div className='w-full bg-customWhite rounded-xl p-4 shadow-xl'>
              <div>
                <table className="w-full table-auto ml-1 border-1 rounded-3xl border-customGreyThree">
                  <thead class="text-center bg-customGrey rounded-2xl">
                    <tr>
                      <th
                        scope="col"
                        class="text-base font-medium text-gray-900 px-8 py-4 text-left"
                      >
                        User Name
                      </th>
                      <th
                        scope="col"
                        class="text-base font-medium text-gray-900 px-8 py-4 text-left"
                      >
                        User Email ID
                      </th>
                      <th
                        scope="col"
                        class="text-base font-medium text-gray-900 px-8 py-4 text-left"
                      >
                        User Role
                      </th>
                      <th
                        scope="col"
                        class="text-base font-medium text-gray-900 text-left"
                      >
                        Edit User
                      </th>
                      <th
                        scope='col'
                        class='text-base font-medium text-gray-900 text-left'
                      >
                        Remove User
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users === null
                      ? null
                      : users.length > 0
                        ? (
                            users.map((item, index) => (
                              <tr
                                key={item.id}
                                class='border-b border-1 border-customGreyThree'
                              >
                                <td class='text-base text-gray-900 font-light px-8 py-4 whitespace-nowrap'>
                                  {editedUserId === item.id
                                    ? (
                                      <input
                                        className='p-1 w-full border-2 rounded-lg'
                                        type='text'
                                        value={editedUsername}
                                        onChange={(e) => setEditedUsername(e.target.value)}
                                      />
                                      )
                                    : (
                                      <div class='text-sm text-gray-900'>{item.username}</div>
                                      )}
                                </td>
                                <td class='text-base text-gray-900 font-light px-8 py-4 whitespace-nowrap'>
                                  <div class='text-sm text-gray-900'>{item.email}</div>
                                </td>
                                <td class='text-base text-gray-900 font-light px-8 py-4 whitespace-nowrap'>
                                  {editedUserId === item.id
                                    ? (
                                      <select
                                        className='p-1 w-full border-2 rounded-lg'
                                        value={editedUserRole}
                                        onChange={(e) => setEditedUserRole(parseInt(e.target.value))}
                                      >
                                        <option value={0}>CXO</option>
                                        <option value={1}>Test Lead</option>
                                        <option value={2}>Tester</option>
                                      </select>
                                      )
                                    : (
                                        item.accountType === 0 ? 'CXO' : item.accountType === 1 ? 'Test Lead' : 'Tester'
                                      )}
                                </td>
                                <td class='text-base text-gray-900 font-light px-10 py-4 whitespace-nowrap'>
                                  {item.email === user.email
                                    ? null
                                    : (
                                      <div onClick={() => handleEditClick(item.id, item.username, item.accountType)} className='text-2xl cursor-pointer'>
                                        {isEditing && editedUserId === item.id
                                          ? (
                                            <FaUserCheck className='text-customGreen' onClick={() => updateUser(item.id)} />
                                            )
                                          : (
                                            <FaUserEdit className='text-customBlue' />
                                            )}
                                      </div>
                                      )}
                                </td>
                                <td class='text-base text-gray-900 font-light px-10 py-4 whitespace-nowrap'>
                                  {item.email === user.email
                                    ? null
                                    : (
                                      <div class='text-2xl text-red-500 cursor-pointer'>
                                        <FaUserMinus onClick={() => deleteUser(item.id)} />
                                      </div>
                                      )}
                                </td>
                              </tr>
                            ))
                          )
                        : (
                          <tr>
                            <td class='px-8 py-4 whitespace-nowrap'>
                              <div class='text-sm text-gray-900'>No users found</div>
                            </td>
                          </tr>
                          )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
