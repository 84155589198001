import SSComponent from '../../InputComponents/SingleSelectComponent/SScomponent'
import ModuleSelect from '../../InputComponents/SingleSelectComponent/ModuleSelect'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { GET } from '../../../config/api'
import styles from '../modal.module.css'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const TestPackModal = ({
  showModal,
  setShowModal
}) => {
  const modalRef = useRef(null)
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [applicationName, setApplicationNames] = useState([])
  const [appName, setAppName] = useState(1)

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const getApplicationNames = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setApplicationNames(res.data.message)
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
    getApplicationNames()
  }, [showModal, appName])

  return (
    <div ref={modalRef} className={styles.wrapper1}>
      <div className={styles.cardContainer1}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Add Test Pack</h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <AiOutlineCloseCircle size={20} />
            </a>
          </div>
        </div>
        <div className='my-10'>
          <SSComponent
            label='Application Name'
            value={appName}
            options={applicationName}
            onChangeHandler={(e) => setAppName(e.target.value)}
          />
        </div>
        <button
          className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
          onClick={() => navigate(`/createcxoview/${appName}`)}
        >
          Add TestCase
        </button>
      </div>
    </div>
  )
}

export default TestPackModal
