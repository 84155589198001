import React from 'react'
export const ModelContext = React.createContext()
export const ModelProvider = (props) => {
  const [modelstate, setModelState] = React.useState({
    modelType: '',
    isModal: false,
    itemID: '',
    scenarioID: '',
    appID: '',
    apitype: '',
    loading: false,
    sidebarExpanded: true
  })

  return (
    <ModelContext.Provider value={[modelstate, setModelState]}>
      {props.children}
    </ModelContext.Provider>
  )
}
