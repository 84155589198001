import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { openNotification, notificationTypes } from '../../utils/notifications'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../config/api'
import axios from 'axios'

const ViewManualRunReportPage = () => {
  const { testRunID, testCaseReportID, scenarioID, testCaseID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [tcSteps, setTCSteps] = useState([])
  const [tcDetails, setTCDetails] = useState([])
  const [editedSteps, setEditedSteps] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const [selectedFiles, setSelectedFiles] = useState([])
  const [testCaseRunFiles, setTestCaseRunFiles] = useState([])
  const [uploadFile, setUploadFile] = useState(null)
  const [fileInputsDisabled, setFileInputsDisabled] = useState(false)
  const [testSummaryStatus, setTestSummaryStatus] = useState('NA')

  const fileInputRef = useRef(null)
  const fileSizeLimit = 5 * 1024 * 1024

  const editAllSteps = () => {
    setIsEditing(true)
    const newEditedSteps = {}
    tcSteps.forEach((step) => {
      newEditedSteps[step.stepNumber] = step
    })
    setEditedSteps(newEditedSteps)
  }

  const saveAllSteps = () => {
    let successfulUpdates = 0
    let totalUpdates = 0
    const testCaseStepsStatus = tcSteps.map((step) => {
      const stepNumber = step.stepNumber
      const stepToUpdate = editedSteps[stepNumber]

      return stepToUpdate ? stepToUpdate.status : step.status
    })
    const updateRequests = [];

    for (let i = 0; i < tcSteps.length; i++) {
      const step = tcSteps[i];
      const stepNumber = step.stepNumber;
      const stepToUpdate = editedSteps[stepNumber];

      if (JSON.stringify(step) === JSON.stringify(stepToUpdate)) {
        continue;
      }

      updateRequests.push(
        POST('/updateTestRunStep', {
          testCaseReportId: testCaseReportID,
          testRunId: testRunID,
          testCaseId: testCaseID,
          stepNumber,
          enteredData: stepToUpdate.data,
          enteredActualOutput: stepToUpdate.actualResult,
          enteredComments: stepToUpdate.comments,
          enteredDefect: stepToUpdate.defect,
          enteredTestStepStatus: stepToUpdate.status,
          testCaseStepsStatus,
        }, {
          sessionID: sessionId,
        })
        .then(res => {
          if (res.data.status === 200 || res.data.status === 304) {
            return Promise.resolve('Success');
          } else {
            return Promise.reject('Error');
          }
        })
        .catch(err => {
          console.log(err);
          return Promise.reject('Error');
        })
      );

      totalUpdates++;
    }

    Promise.all(updateRequests)
      .then(results => {
        const successfulUpdates = results.filter(result => result === 'Success').length;
        if (successfulUpdates === totalUpdates && totalUpdates > 0) {
          openNotification(notificationTypes.SUCCESS, 'Success');
          window.location.reload()
        } else {
          openNotification(notificationTypes.ERROR, 'Error');
        }
      })
      .catch(() => {
        openNotification(notificationTypes.ERROR, 'Error');
      });

    
    if (totalUpdates === 0) {
      openNotification(notificationTypes.INFO, 'No Changes Were Made');
    }    
      
    setIsEditing(false)
    if (totalUpdates === 0) {
      openNotification(notificationTypes.INFO, 'No Changes Were Made')
    }
  }

  const handleInputChange = (e, field, stepNumber) => {
    setEditedSteps({
      ...editedSteps,
      [stepNumber]: {
        ...editedSteps[stepNumber],
        [field]: e.target.value
      }
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    const getTestCase = () => {
      GET(
        `getSpecificTestCase?testcaseID=${testCaseID}&scenarioID=${scenarioID}`,
        { sessionID: sessionId }
      )
        .then((res) => {
          if (
            res.data.status === 200 ||
            res.data.status === 304 ||
            res.data.status === 'ok'
          ) {
            setTCDetails(res.data.message)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              'Something went wrong'
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const getTestCaseSteps = () => {
      GET(`/getteststeps?id=${testCaseReportID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            const tcsteps = res.data.message
            setTCSteps(tcsteps)

            const statusesToCheck = ['Pass', 'Fail', 'On Hold', 'No Run']
            let testSummaryStatus = ''

            for (const statusToCheck of statusesToCheck) {
              const filteredSteps = tcsteps.filter(item => item.status === statusToCheck)
              console.log(filteredSteps)
              if (filteredSteps.length > 0) {
                if (statusToCheck === 'Fail') {
                  testSummaryStatus = 'Fail'
                  break // If any step is Fail, set status to Fail and exit loop
                } else if (statusToCheck === 'On Hold' && !testSummaryStatus) {
                  testSummaryStatus = 'On Hold' // Set to On Hold only if not set to Fail
                } else if (statusToCheck === 'No Run' && !testSummaryStatus) {
                  // testSummaryStatus = 'In Progress'
                  if (filteredSteps.length === tcsteps.length) {
                    testSummaryStatus = 'No Run'
                  } else {
                    testSummaryStatus = 'In Progress'
                  }
                }
              }
            }

            if (!testSummaryStatus) {
              testSummaryStatus = 'Pass' // If no Fail, On Hold, or No Run, set status to Pass
            }

            setTestSummaryStatus(testSummaryStatus)
          } else {
            openNotification(notificationTypes.ERROR, 'Error')
          }
        })
        .catch((err) => {
          console.log(err)
          openNotification(notificationTypes.ERROR, 'Error')
        })
    }
    const getTestCaseRunFiles = () => {
      GET(
        `/getTestCaseRunFiles/?testrunid=${testRunID}&testcasereportid=${testCaseReportID}`,
        { sessionID: sessionId }
      )
        .then((res) => {
          if (res.status === 200 || res.status === 304) {
            setTestCaseRunFiles(res.data)
          } else {
            openNotification(notificationTypes.ERROR, 'Error')
          }
        })
        .catch((err) => {
          console.log(err)
          openNotification(notificationTypes.ERROR, 'Error')
        })
    }
    getTestCase()
    getTestCaseSteps()
    getTestCaseRunFiles()
  }, [testCaseReportID, scenarioID, sessionId, testCaseID, testRunID])

  const handleUploadFile = () => {
    setSelectedFiles([])
    if (uploadFile === null) {
      openNotification(
        notificationTypes.ERROR,
        'Please select a file to upload.'
      )
      return
    }

    if (uploadFile.size > fileSizeLimit) {
      openNotification(
        notificationTypes.ERROR,
        'File size exceeds the limit (5MB).'
      )
      return
    }

    setFileInputsDisabled(true)
    GET(
      `/getFileUploadURL/?testrunid=${testRunID}&testcasereportid=${testCaseReportID}&filename=${uploadFile.name}&type=${uploadFile.type}`,
      {
        sessionID: sessionId
      }
    )
      .then((response) => {
        const preSignedURL = response.data.preSignedURL
        axios
          .put(preSignedURL, uploadFile, {
            headers: {
              'Content-Type': uploadFile.type
            }
          })
          .then((res) => {
            POST(
              '/postTestCaseRunFile',
              {
                testrunid: testRunID,
                testcasereportid: testCaseReportID,
                filename: uploadFile.name
              },
              { sessionID: sessionId }
            )
              .then((response) => {
                if (response.data.status === 200) {
                  setUploadFile(null)
                  fileInputRef.current.value = ''
                  setFileInputsDisabled(false)
                  setTestCaseRunFiles(
                    testCaseRunFiles.concat({
                      id: response.data.fileData.id,
                      filePath: response.data.fileData.filePath,
                      testCaseReportId: testCaseReportID,
                      testRunId: testRunID
                    })
                  )

                  openNotification(
                    notificationTypes.SUCCESS,
                    'File Uploaded Successfully'
                  )
                  GET('/exportToFile?activity=upload_testCaseFile', {
                    sessionID: sessionId
                  }).then(() => {
                    openNotification(
                      notificationTypes.SUCCESS,
                      'Success',
                      'File uploaded Sucessfully'
                    )
                  })
                } else {
                  openNotification(
                    notificationTypes.ERROR,
                    'Error Uploading File'
                  )
                }
              })
              .catch((error) => {
                console.log(error)
                openNotification(
                  notificationTypes.ERROR,
                  'File already exists'
                )
              })
          })
          .catch((error) => {
            openNotification(notificationTypes.ERROR, 'Error Uploading File')
            throw new Error(error)
          })
      })
      .catch((error) => {
        openNotification(notificationTypes.ERROR, 'Error Uploading File')
        throw new Error(error)
      })
      .finally(() => {
        setFileInputsDisabled(false)
      })
  }
  const handleFileSelect = (file) => {
    if (selectedFiles.includes(file)) {
      setSelectedFiles(
        selectedFiles.filter((selectedFile) => selectedFile !== file)
      )
    } else {
      setSelectedFiles([...selectedFiles, file])
    }
    console.log(selectedFiles)
  }

  const handleDeleteFiles = () => {
    POST('/deleteTestCaseRunFiles', selectedFiles, {
      sessionID: sessionId
    }).then((res) => {
      if (res.data.status === 200) {
        setTestCaseRunFiles((testCaseRunFiles) =>
          testCaseRunFiles.filter(
            (testCaseRunFile) =>
              !selectedFiles.some((selectedFile) =>
                selectedFile.filePath.includes(testCaseRunFile.filePath)
              )
          )
        )
        setSelectedFiles([])
        openNotification(
          notificationTypes.SUCCESS,
          'File(s) Deleted Successfully'
        )
      } else {
        openNotification(notificationTypes.ERROR, 'Error Deleting File(s)')
      }
    })
  }

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Test Case Management
            </h1>
          </div>
          <div className='flex flex-wrap w-auto justify-between col-span-10'>
            <div className='mt-1 flex flex-col'>
              <h1 className='mb-0 pt-3 font-DMSANS text-base xl:text-xl'>
                {' '}
                Test Case details:
              </h1>
              <p className='mb-0 pt-3 text-gray-600 pb-1'>
                {' '}
                Name: &nbsp;
                <span className='font-DMSANS xl:text-xl'>
                  <b>
                    {tcDetails && tcDetails.testcase_name
                      ? tcDetails.testcase_name
                      : 'NA'}
                  </b>
                </span>
              </p>
              <p className='mb-0 pt-3 text-gray-600 pb-4'>
                {' '}
                Description: &nbsp;
                <span className='font-DMSANS xl:text-xl'>
                  <b>
                    {tcDetails && tcDetails.testcase_description
                      ? tcDetails.testcase_description
                      : 'NA'}
                  </b>
                </span>
              </p>
              {/* <p>Status:&nbsp;{tcDetails && tcDetails.testcase_status ? tcDetails.testcase_status : 'NA'}</p> */}
              {testSummaryStatus && <p>Status:&nbsp;{testSummaryStatus}</p>}
            </div>
            <div className='mt-1 ml-3 flex flex-col'>
              <div className='flex justify-between items-center'>
                <span className='pt-3 font-DMSANS text-base xl:text-xl mb-2'>
                  Files:
                </span>
                {selectedFiles.length > 0 && (
                  <div className='w-1/3 flex justify-between items-center'>
                    <button
                      className='bg-customBlue text-white font-semibold px-2 rounded-full'
                      onClick={() => setSelectedFiles([])}
                    >
                      Cancel
                    </button>
                    <button
                      className='bg-customRed text-white font-semibold px-2 rounded-full'
                      onClick={handleDeleteFiles}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
              <div className='bg-customGreyOne overflow-y-scroll px-4 py-2 shadow-lg rounded-lg max-h-[130px]'>
                {testCaseRunFiles.length > 0
                  ? (
                      testCaseRunFiles.map((file) => {
                        const fileName = file.filePath.split('/').pop()
                        const fileType = file.filePath
                          .split('.')
                          .pop()
                          .toUpperCase()
                        return (
                          <>
                            <div
                              key={file.filePath}
                              className='flex items-center my-2'
                            >
                              <input
                                type='checkbox'
                                className='mr-2'
                                onChange={() => handleFileSelect(file)}
                                disabled={fileInputsDisabled}
                                checked={selectedFiles.includes(file)}
                              />
                              <div>
                                <a
                                  href={file.filePath}
                                  download
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {fileName} ({fileType} File)
                                </a>
                              </div>
                            </div>
                            <hr />
                          </>
                        )
                      })
                    )
                  : (
                    <div className='text-center'>No files uploaded</div>
                    )}
              </div>

              <div className='flex items-center mt-4'>
                <input
                  type='file'
                  className='font-semibold'
                  disabled={fileInputsDisabled}
                  onChange={(e) => setUploadFile(e.target.files[0])}
                  ref={fileInputRef}
                />
                <button
                  className={`ml-2 ${fileInputsDisabled
                    ? 'bg-customGreyTwo'
                    : 'bg-customGreenFive hover:bg-customGreen'
                    } text-white font-semibold px-4 py-1 rounded-full`}
                  onClick={handleUploadFile}
                  disabled={fileInputsDisabled}
                >
                  Upload File
                </button>
              </div>
            </div>
          </div>
          <div className='mb-0 pt-3 text-gray-600 pb-4'>
            {isEditing
              ? (
                <button
                  className='bg-customGreen text-customGrey px-4 py-1  rounded-lg text-base'
                  onClick={saveAllSteps}
                >
                  Save Test Case Steps
                </button>
                )
              : (
                <button
                  className='bg-customBlue text-customGrey px-4 py-1 rounded-lg text-base'
                  onClick={editAllSteps}
                >
                  Edit Test Case Steps
                </button>
                )}
          </div>
          <div>
            <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl'>
              <div>
                <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
                  <thead className=' bg-customGrey rounded-2xl'>
                    <tr>
                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 px-2 py-1 text-left min-w-[100px]'
                      >
                        Step ID
                      </th>
                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[300px]'
                      >
                        Step Description
                      </th>

                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[200px]'
                      >
                        Expected Output
                      </th>
                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[150px]'
                      >
                        Data
                      </th>
                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[200px]'
                      >
                        Actual Output
                      </th>
                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[200px]'
                      >
                        Comments
                      </th>

                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[150px]'
                      >
                        Defect
                      </th>
                      <th
                        scope='col'
                        className='text-base font-bold text-gray-900 p-2 text-left min-w-[150px]'
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tcSteps === null
                      ? null
                      : tcSteps.length > 0
                        ? (
                            tcSteps
                              .sort((a, b) => a.stepNumber - b.stepNumber)
                              .map((step) => (
                                <tr
                                  className='border-b border-1 border-customGreyThree'
                                  key={step.stepNumber}
                                >
                                  <td className='text-base text-gray-500 font-medium px-6 py-2'>
                                    {step.stepNumber}
                                  </td>
                                  <td className='text-base text-gray-500 font-medium p-2 break-words'>
                                    {step.stepDescription}
                                  </td>
                                  <td className='text-base text-gray-500 font-medium p-2 break-words'>
                                    {step.expectedResult}
                                  </td>
                                  <td className='text-base text-gray-500 font-medium p-2 break-words'>
                                    {isEditing
                                      ? (
                                        <textarea
                                          type='text'
                                          rows='4'
                                          placeHolder='Enter Step Data'
                                          className='border-2 w-full resize-none'
                                          defaultValue={step.data}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              'data',
                                              step.stepNumber
                                            )}
                                        />
                                        )
                                      : (
                                          step.data
                                        )}
                                  </td>
                                  <td className='text-base text-gray-500 font-medium p-2 break-words'>
                                    {isEditing
                                      ? (
                                        <textarea
                                          type='text'
                                          rows='4'
                                          placeHolder='Enter Actual Result'
                                          className='border-2 w-full resize-none'
                                          defaultValue={step.actualResult}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              'actualResult',
                                              step.stepNumber
                                            )}
                                        />
                                        )
                                      : (
                                          step.actualResult
                                        )}
                                  </td>
                                  <td className='text-base text-gray-500 font-medium p-2 break-words'>
                                    {isEditing
                                      ? (
                                        <textarea
                                          type='text'
                                          rows='4'
                                          placeHolder='Enter Comments'
                                          className='border-2 w-full resize-none'
                                          defaultValue={step.comments}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              'comments',
                                              step.stepNumber
                                            )
                                          }}
                                        />
                                        )
                                      : (
                                          step.comments
                                        )}
                                  </td>
                                  <td className='text-base text-gray-500 font-medium p-2 break-words'>
                                    {isEditing
                                      ? (
                                        <textarea
                                          type='text'
                                          rows='4'
                                          placeHolder='Enter Defect'
                                          className='border-2 w-full resize-none'
                                          defaultValue={step.defect}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              'defect',
                                              step.stepNumber
                                            )
                                          }}
                                        />
                                        )
                                      : (
                                          step.defect
                                        )}
                                  </td>
                                  <td className='text-base  font-medium p-2 break-words'>
                                    {isEditing
                                      ? (
                                        <select
                                          defaultValue={step.status}
                                          className='border-2'
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              'status',
                                              step.stepNumber
                                            )
                                          }}
                                        >
                                          <option
                                            className='text-customGreySeven'
                                            value='No Run'
                                          >
                                            No Run
                                          </option>
                                          <option
                                            className='text-customGreenThree'
                                            value='Pass'
                                          >
                                            Pass
                                          </option>
                                          <option
                                            className='text-customRed'
                                            value='Fail'
                                          >
                                            Fail
                                          </option>
                                          <option
                                            className='text-customOrange'
                                            value='On Hold'
                                          >
                                            On Hold
                                          </option>
                                        </select>
                                        )
                                      : (
                                        <span
                                          className={`text-${step.status === 'No Run'
                                          ? 'customGreySeven'
                                          : step.status === 'Pass'
                                            ? 'customGreenThree'
                                            : step.status === 'Fail'
                                              ? 'customRed'
                                              : step.status === 'On Hold'
                                                ? 'customOrange'
                                                : 'default'
                                          }`}
                                        >
                                          {step.status}
                                        </span>
                                        )}
                                  </td>
                                </tr>
                              ))
                          )
                        : (
                          <tr className='border-b border-1 border-customGreyThree text-center'>
                            <td colSpan='8'>No data</td>
                          </tr>
                          )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewManualRunReportPage
