import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import arrow from '../../../assets/Icons/arrow.png'
import Arrow1 from '../../../assets/Icons/arrowright.png'
import Dashboard from '../../../assets/Icons/blacksave.png'
import module from '../../../assets/Icons/scenario.png'

import { GET } from '../../../config/api'
import { openNotification, notificationTypes } from '../../../utils/notifications'

const TestExecution = () => {
  const sessionId = useSelector(state => state.user.sessionId)

  const navigate = useNavigate()

  const [isReport, setIsReport] = useState(false)
  const [run, setRun] = useState([])

  const handleReport = () => {
    setIsReport(!isReport)
  }

  const getTestRuns = () => {
    GET('/gettestrun', { sessionID: sessionId })
      .then(res => {
        if (res.data.status === 200) {
          setRun(res.data.message)
        } else {
          openNotification(notificationTypes.ERROR, 'Error', 'Something went wrong')
        }
      })
      .catch(err => {
        console.log(err)
        openNotification(notificationTypes.ERROR, 'Error', 'Something went wrong')
      })
  }

  useEffect(() => {
    getTestRuns()
  }, [])

  return (
    <div>
      <div
        onClick={handleReport}
        class='hover:bg-customBlueFour flex items-center mb-1 p-2 text-base font-bold text-black rounded-lg'
      >
        {
          isReport
            ? (
              <img className='mr-2' src={arrow} alt='arrow' />
              )
            : (
              <img className='mr-2' src={Arrow1} alt='Arrow' />
              )
        }
        <img className='h-6' src={Dashboard} alt='report' />
        <span
          className='ml-3'
        >
          Test Execution
        </span>
      </div>
      {
        isReport
          ? (
              run === null
                ? null
                : run.length > 0
                  ? (
                      run.map(
                        (item, index) => (
                          <div
                            onClick={() => navigate(`/viewMR/${item.id}/${item.testPackId}`)}
                            class='hover:bg-customBlueFour ml-5 flex items-center p-2 text-base text-black rounded-lg'
                          >
                            <img src={arrow} alt='arrow' />
                            <img className='ml-2 h-7' src={module} alt='report' />
                            <span class='ml-3'>
                              {item.name}&nbsp;
                            </span>
                          </div>
                        )
                      ))
                  : (
                    <div>
                      No Test Runs
                    </div>
                    )
            )
          : null
      }
    </div>
  )
}

export default TestExecution
