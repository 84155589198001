import { useEffect, useState } from 'react'
import { GET, POST } from '../../config/api'
import { useSelector } from 'react-redux'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { useNavigate } from 'react-router-dom'

const AddFeature = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [saveDetails, setSavedetails] = useState('')
  const [featureName, setFeatureName] = useState('')
  const [featureId, setFeatureId] = useState('')
  const [applicationId, setApplicationId] = useState(0)
  const [moduleId, setModuleId] = useState(0)
  const [AppName, setAppName] = useState([])
  const [modules, setModules] = useState([])
  const [selectedModule, setSelectedModule] = useState(0)
  const [selectedDevStatus, setSelectedDevStatus] = useState('')
  const [selectedCreationStatus, setSelectedCreationStatus] = useState('')
  const [selectedReviewStatus, setSelectedReviewStatus] = useState('')
  const [description, setDescription] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getApplication()
  }, [])

  useEffect(() => {
    if (applicationId !== 0) getModules()
  }, [applicationId])

  const getApplication = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        setAppName(res.data.message)
      })
      .catch((error) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const getModules = () => {
    GET(`/getModules?appID=${applicationId}`, { sessionID: sessionId })
      .then((res) => {
        console.log(res.data.message)
        setModules(res.data.message)
      })
      .catch((error) => {
        console.log(error)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleApplicationChange = (e) => {
    console.log(e.target.value, 'its aplication')
    setApplicationId(e.target.value)
    // current();
  }

  const handleModuleChange = (e) => {
    console.log(e.target.value)
    // Split the value into moduleName and id
    const [moduleName, moduleId] = e.target.value.split('-')
    setSelectedModule(moduleName)
    setModuleId(moduleId)
  }

  const postFeature = () => {
    const data = {
      name: featureName,
      featureId,
      moduleName: selectedModule,
      description,
      devStatus: selectedDevStatus,
      testCaseCreationStatus: selectedCreationStatus,
      testCaseReviewStatus: selectedReviewStatus
    }

    if (data.name === '' || data.featureId === '' || data.moduleName === '' || data.devStatus === '' ||
    data.testCaseCreationStatus === '' || data.testCaseReviewStatus === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error: 70010',
        'All fields are mandatory'
      )
      return
    }

    POST(`/feature?AppID=${applicationId}&&moduleID=${moduleId}`, data, { sessionID: sessionId })
      .then((res) => {
        console.log(res)
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Feature created Successfully'
          )
          navigate('/feature')
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error while creating Feature, Please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Error while creating Feature, Please try again later'
        )
      })
  }

  return (
    <>
      <div className='pb-5'>
        <div className='grid grid-cols-1 gap-1'>
          {/* <div className='col-span-2' /> */}
          <div className='mb-40 col-span-8'>
            <ul className='flex gap-[45rem]'>
              <li>
                <h1 className='text-4xl font-bold font-DMSANS'>Add Feature</h1>
              </li>
              <li>
                <button
                  className='bg-customGreenFour text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline'
                  type='button'
                  onClick={postFeature}
                >
                  Save
                </button>
              </li>
            </ul>
            <div className='border-2 border-customGreyTwo p-5 rounded-2xl pt-10 mb-4'>
              <ul className='flex flex-col'>
                <li>
                  <ul className='flex gap-10'>
                    <li>
                      <div>
                        <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                          Feature Name
                        </label>
                        <input
                          className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-64 p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                          type='text'
                          placeholder='Enter feature number'
                          onChange={(e) => setFeatureName(e.target.value)}
                        />
                      </div>
                    </li>

                    <li>
                      <div>
                        <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                          Feature Id
                        </label>
                        <input
                          className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-64 p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                          type='text'
                          placeholder='Enter feature id'
                          onChange={(e) => setFeatureId(e.target.value)}
                        />
                      </div>
                    </li>

                    <li>
                      <div>
                        <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                          Application Name
                        </label>
                        <select
                          className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                          name='ApplicationName'
                          id='ApplicationName'
                          onChange={handleApplicationChange}
                        >
                          <option
                            className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                            value='select'
                          >
                            select
                          </option>
                          {AppName.map((obj) => {
                            // console.log(obj)
                            return (
                              <option
                                className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                                key={obj.id}
                                value={obj.id}
                              >
                                {obj.applicationName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className='flex gap-10'>
                    <li>
                      <div>
                        <label
                          className='ml-2 block text-gray-700 text-sm font-bold mb-2'
                          for='password'
                        >
                          Module Name
                        </label>
                        <select
                          className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                          name='ModuleName'
                          id='ModuleName'
                          onChange={handleModuleChange}
                        >
                          <option
                            className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                            value='select'
                          >
                            select
                          </option>
                          {modules.map((obj) => {
                            const optionValue = `${obj.modelName}-${obj.id}`
                            return (
                              <option
                                className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                                key={obj.id}
                                value={optionValue}
                              >
                                {obj.modelName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                          DEV Status
                        </label>
                        <select
                          className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                          name='devStatus'
                          id='devStatus'
                          onChange={(e) => setSelectedDevStatus(e.target.value)}
                        >
                          <option
                            className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                            value='select'
                          >
                            select
                          </option>
                          <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-x-customGreyThree w-64 p-3 rounded-lg'>
                            New
                          </option>
                          <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                            Inprogress
                          </option>
                          <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                            Complete
                          </option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                          Test Case Creation Status
                        </label>
                        <select
                          className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                          name='creationStatus'
                          id='creationStatus'
                          onChange={(e) =>
                            setSelectedCreationStatus(e.target.value)}
                        >
                          <option
                            className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                            value='select'
                          >
                            select
                          </option>
                          <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-x-customGreyThree w-64 p-3 rounded-lg'>
                            New
                          </option>
                          <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                            Inprogress
                          </option>
                          <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                            Complete
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div>
                    <label className='ml-2 block text-gray-700 text-sm font-bold mb-2'>
                      Test Case Review Status
                    </label>
                    <select
                      className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                      name='reviewStatus'
                      id='reviewStatus'
                      onChange={(e) => setSelectedReviewStatus(e.target.value)}
                    >
                      <option
                        className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                        value='select'
                      >
                        select
                      </option>
                      <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-x-customGreyThree w-64 p-3 rounded-lg'>
                        New
                      </option>
                      <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                        Inprogress
                      </option>
                      <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                        Complete
                      </option>
                    </select>
                  </div>
                </li>
                <li>
                  <div>
                    <label className='ml-2 block text-gray-700 text-sm font-bold mb-2'>
                      Feature Description
                    </label>
                    <textarea
                      className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 pb-20 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                      type='text'
                      placeholder='Enter Description'
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFeature
