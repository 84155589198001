import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import BasicPageLayout from '../components/BasicPageLayout'
import ChartSection from '../components/ChartSection/ChartSection'

const styles = StyleSheet.create({
  grid1: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f5f6f9',
    marginTop: 10,
    padding: 6,
    borderRadius: 6
  },
  grid2: {
    display: 'flex',
    flexDirection: 'row',
    padding: 6,
    borderBottom: 1,
    borderColor: '#EFEFEF'
  },
  info: {
    flexBasis: '25%',
    fontSize: 12,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 6,
    color: '#B8B8B8',
    marginLeft: 4
  },
  info2: {
    flexBasis: '15%',
    fontSize: 12,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 6,
    color: '#B8B8B8',
    marginLeft: 4
  },
  infotitle: {
    color: '#4582ec'
  },
  greentext: {
    color: '#40a87b'
  },

  textBlack: {
    color: '#434343'
  }
})

const TestCasses = React.memo(({ data, title }) => {
  const totalPass = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.passCount
  }, 0)
  const totalFail = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.failCount
  }, 0)
  const totalNoRun = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.norunCount
  }, 0)
  const totalProgressCount = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.inprogressCount
  }, 0)

  const totalOnholdCount = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.onholdCount
  }, 0)

  return (
    <BasicPageLayout>
      <Text style={title}>Summary</Text>
      {data.length > 0 && (
        <ChartSection
          title='Summary'
          pass={totalPass}
          fail={totalFail}
          noRun={totalNoRun}
          progress={totalProgressCount}
          onhold={totalOnholdCount}
        />
      )}
      <View style={styles.grid1}>
        <Text style={{ ...styles.info, ...styles.textBlack }}>Run Name</Text>
        <Text style={{ ...styles.info2, ...styles.textBlack }}>Pass</Text>
        <Text style={{ ...styles.info2, ...styles.textBlack }}>Fail</Text>
        <Text style={{ ...styles.info2, ...styles.textBlack }}>No Run</Text>
        <Text style={{ ...styles.info2, ...styles.textBlack }}>
          In-progress
        </Text>
        <Text style={{ ...styles.info2, ...styles.textBlack }}>On hold</Text>
      </View>
      {data.length > 0
        ? (
            data.map((item) => (
              <View style={styles.grid2}>
                <Text style={styles.info}>{item.name}</Text>
                <Text style={styles.info2}>{item.passCount}</Text>
                <Text style={styles.info2}>{item.failCount}</Text>
                <Text style={styles.info2}>{item.norunCount}</Text>
                <Text style={styles.info2}>{item.inprogressCount}</Text>
                <Text style={styles.info2}>{item.onholdCount}</Text>
              </View>
            ))
          )
        : (
          <Text style={styles.info}>No Test Cases Found</Text>
          )}

      {/* {data.length > 0 &&
        data.map((item) => (
          <ChartSection
            pass={item.passCount}
            fail={item.failCount}
            noRun={item.norunCount}
            progress={item.inprogressCount}
            onhold={item.onholdCount}
          />
        ))} */}
    </BasicPageLayout>
  )
})

export default TestCasses
