import TextField from '../../InputComponents/InputField'
import { useState, useEffect, useRef } from 'react'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { useSelector } from 'react-redux'
import { POST, GET } from '../../../config/api'
import styles from '../modal.module.css'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const Modal = ({ showModal, setShowModal, setRefreshState }) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)
  const [appName, setAppName] = useState('')
  const [note, setNote] = useState('')
  const [app, setApp] = useState([])
  // const [isBtnDisabled, setIsBtnDisabled] = useState(true)

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const addApp = (e) => {
    e.preventDefault()
    if (appName.length > 0) {
      const Data = {
        applicationName: appName,
        applicationDescription: note
      }
      POST('/postApplication', Data, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            GET('/getApplicationFromCompany', { sessionID: sessionId })
              .then((res) => {
                setApp(res.data.message)
                openNotification(
                  notificationTypes.SUCCESS,
                  'Application added successfully'
                )
                setShowModal(!showModal)
                setRefreshState(true)
              })
              .catch((err) => {
                console.log(err)
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              })
          } else if (res.data.status === 401) {
            openNotification(
              notificationTypes.ERROR,
              'Session expired, please login again'
            )
          } else if (res.data.status === 406) {
            openNotification(
              notificationTypes.ERROR,
              'Unable to determine company of user'
            )
          } else if (res.data.status === 400) {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              res.data.message
            )
          } else {
            openNotification(notificationTypes.ERROR, res.data.message)
          }
          setAppName('')
          setNote('')
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error: 30210',
        'Please enter all the details'
      )
    }
    // setShowModal(!showModal)
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Add Application</h2>
          </div>
          <div className={styles.close}>
            <span onClick={closeModal}>
              <AiOutlineCloseCircle size={20} />
            </span>
          </div>
        </div>
        <TextField
          label='Name of the application'
          placeholder={appName}
          value={appName}
          onChangeHandler={(e) => setAppName(e.target.value)}
          maxLength={5000}
          type='text'
          required
        />
        <div>
          <label
            for='exampleFormControlTextarea1'
            class='form-label inline-block mb-2 text-gray-700'
          >
            Notes
          </label>
          <textarea
            class='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            value={note}
            onChange={(e) => setNote(e.target.value)}
            id='Note'
            rows='3'
          />
          <div class='flex items-center justify-between'>
            <button
              class='w-full bg-blue-500 hover:text-white border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='button'
              onClick={addApp}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
