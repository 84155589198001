export const accountType = [
  {
    id: 1,
    value: 0,
    label: 'CXO'
  },
  {
    id: 2,
    value: 1,
    label: 'Test Lead'
  },
  {
    id: 3,
    value: 2,
    label: 'Tester'
  }
]
