import { useState, useEffect } from 'react'
import { GET, POST, PUT } from '../../../config/api'

import appIcon from '../../../assets/Icons/app.png'
import module from '../../../assets/Icons/module.png'
import scenario from '../../../assets/Icons/scenario.png'
import tc from '../../../assets/Icons/testcaseicon.png'
import Arrow from '../../../assets/Icons/arrowright.png'
import Arrow1 from '../../../assets/Icons/arr.png'
import Arrow2 from '../../../assets/Icons/arr2.png'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'

const ReleasePlanner = ({ releaseId }) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [cxo, setCxo] = useState({})
  const [selectedFeature, setSelectedFeature] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [isDisabled1, setIsDisabled1] = useState(false)
  const [removed, setRemoved] = useState([])
  const [add, setAdd] = useState([])
  const [allTC, setAllTC] = useState({})
  const [applications, setApplications] = useState([])
  const [feature, setFeature] = useState([])
  const [appId, setAppId] = useState([])
  const [featureMap, setFeatureMap] = useState([])
  let count = 0
  const [mapFeature, setMapFeature] = useState([])

  useEffect(() => {
    getApplications()
  }, [])

  // useEffect(() => {
  //   getFeatureReleaseMap();
  // }, [appId]);

  // useEffect(() => {
  //   // mapFeature = [];
  //   selectedFeature.map((obj) => {
  //     setMapFeature((p) => {
  //       return [...p, obj.id];
  //     });
  //     // mapFeature.push(obj.id);
  //   });
  // }, [selectedFeature]);

  const addApplxn = (event, item) => {
    item.checked = !item.checked
    item.modules.map((modules) => {
      if (modules.checked === item.checked) modules.checked = !modules.checked
      modules.features.map((features) => {
        if ((features.checked === modules.checked) === item.checked) { features.checked = !features.checked }
      })
    })

    if (event.target.checked === false) {
      const res = add.filter((tc) => tc.id !== item.id)
      setAdd(res)
    } else {
      setAdd([...add, item])
    }
  }

  const addModl = (event, item) => {
    item.checked = !item.checked
    item.features.map((features) => {
      if (features.checked === item.checked) { features.checked = !features.checked }
    })

    if (event.target.checked === false) {
      const res = add.filter((tc) => tc.id !== item.id)
      setAdd(res)
    } else {
      setAdd([...add, item])
    }
  }

  const addfeatr = (event, item) => {
    item.checked = !item.checked

    if (event.target.checked === false) {
      const res = add.filter((tc) => tc.id !== item.id)
      setAdd(res)
    } else {
      setAdd([...add, item])
    }
  }

  const removeTC = (event, item) => {
    item.checked = !item.checked
    if (event.target.checked === false) {
      const res = removed.filter((tc) => tc.id !== item.id)
      setRemoved(res)
    } else {
      setRemoved([...removed, item])
    }
  }

  const sortFeature = (a, b) => {
    if (a.moduleName < b.moduleName) {
      return -1
    } else if (a.moduleName > b.moduleName) {
      return 1
    } else if (a.name < b.name) {
      return -1
    } else if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const handleAdd = () => {
    let res = selectedFeature
    if (selectedFeature) {
      const allItemstoAdd = add
      allItemstoAdd.map((itemToAdd) => {
        if (selectedFeature.findIndex((item) => item.id === itemToAdd.id) === -1) {
          res = res.concat(itemToAdd)
        }
      })
    } else {
      res = add
    }
    res.sort((a, b) => sortFeature(a, b))
    res = clearCheckMark(res)
    setSelectedFeature(res)
    setAdd([])
  }

  const clearCheckMark = (items) => {
    items.map((item) => item.checked = false)
    return items
  }
  const handleRemove = () => {
    // setIsDisabled(false);
    // setIsDisabled1(true);
    const res = selectedFeature.filter((tc) => !removed.includes(tc))
    setSelectedFeature(res)
    setRemoved([])

    let allfeatures = feature
    allfeatures = clearCheckMark(allfeatures)
    setFeature(allfeatures)
  }

  const getApplications = async () => {
    console.log('features', feature)
    await GET('getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        setApplications(res.data.message)
        getFeature(res.data.message)
        getAddedFeatures(res.data.message)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }

  const getFeatureReleaseMap = async () => {
    await GET(`/release/featuremap/${appId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          res.data.message.featureMap.application.forEach((relApplication) => {
            relApplication.checked = false
            relApplication.modules.forEach((relModules) => {
              relModules.checked = false
              relModules.features.forEach((relFeatures) => {
                relFeatures.checked = false
              })
            })
          })
          setFeatureMap(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, Please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }

  const getAddedFeatures = (applications) => {
    applications.map((obj) => {
      GET(`selectedFeature?releaseId=${releaseId}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.message) {
            res.data.message.featureId && res.data.message.featureId.forEach((features) => {
              if (features) { features.checked = false };
            })
            let items = res.data.message.featureId
            items = items.sort((a, b) => sortFeature(a, b))
            setSelectedFeature(items)
          } else {
            setSelectedFeature([])
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, Please try again later'
          )
        })
    })
  }

  const getFeature = (application) => {
    application.map((obj) => {
      GET(`feature?AppID=${obj.id}`, { sessionID: sessionId })
        .then((res) => {
          console.log('getFeature', res)
          res.data.message.forEach((features) => {
            features.checked = false
          })
          let allFeatures = feature
          res.data.message.map((obj) => {
            console.log('adding obj' + obj)
            if (allFeatures.findIndex((itemToSearch) => itemToSearch.id === obj.id) === -1) {
              allFeatures.push(obj)
            }
          })
          allFeatures = allFeatures.sort((a, b) => sortFeature(a, b))
          setFeature(allFeatures)
        })
        // setFeature(res.data.message);
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, Please try again later'
          )
        })
    })
  }

  const postFeatureMap = () => {
    POST(
      `/release/featuremap/${releaseId}`,
      { featureId: selectedFeature },
      {
        sessionID: sessionId
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'Release data Mapped successfully'
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Error in Mapping Release. Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Error in mapping Release. Please try again'
        )
      })
  }

  //   useEffect(() => {
  //     getAllTestPacks();
  //   }, []);

  return (
    <>
      <div className='grid grid-cols-5 gap-4 mt-5'>
        <div className='col-end-7'>
          {/* <button
            className="bg-customGreenFour text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end hidden"
            type="button"
            onClick={onSave(selectedFeature)}
          >
            Save
          </button> */}
          <button
            className='w-full bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end'
            type='button'
            onClick={postFeatureMap}
          >
            Map and Save
          </button>
        </div>
      </div>
      <div className='mt-5'>
        <div className='mx-2 grid grid-cols-10 gap-8'>
          <div className='col-span-4'>
            {/* style={{ opacity: isDisabled1 === false ? 1 : 0.3 }} */}
            <div
              style={{ opacity: isDisabled1 === false ? 1 : 0.3 }}
              className='bg-customGrey shadow-xl p-5 h-96 overflow-y-auto rounded-2xl'
            >
              {selectedFeature === null
                ? (
                  <div>Loading...</div>
                  )
                : selectedFeature && selectedFeature.length > 0
                  ? (
                      selectedFeature.map((item) => (
                        (item &&
                          <div class='flex my-2 form-check'>
                            <input
                              onClick={(event) => removeTC(event, item)}
                              class='h-6 w-6 mt-1'
                              type='checkbox'
                              value=''
                              checked={item.checked}
                              id={item.id}
                            />
                            <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                            <img src={scenario} className='mt-1 h-7 mr-2' />
                            <p class='mt-2'>{item.name} ({item.moduleName})</p>
                          </div>
                        )
                      ))
                    )
                  : (
                    <div>No Feature found</div>
                    )}
            </div>
          </div>
          <div className='my-auto col-span-2'>
            <div className='flex flex-col justify-center'>
              <button
                className={
                  isDisabled === true
                    ? 'mx-auto flex mb-2 px-5 py-2 rounded-xl bg-green-300 font-medium text-black opacity-50 cursor-not-allowed'
                    : 'mx-auto flex mb-2 px-5 py-2 rounded-xl bg-green-300 font-medium text-black'
                }
                onClick={handleAdd}
              >
                <img src={Arrow2} className='h-8' alt='arrow' />
                <span className='ml-2 text-xl'>Add</span>
              </button>
              <button
                className={
                  isDisabled1 === true
                    ? 'mx-auto flex mb-2 px-5 py-2 rounded-xl bg-red-300 font-medium text-black opacity-50 cursor-not-allowed'
                    : 'mx-auto flex mb-2 px-5 py-2 rounded-xl bg-red-300 font-medium text-black'
                }
                onClick={handleRemove}
              >
                <span className='ml-2 text-xl'>Remove</span>
                <img src={Arrow1} className='h-8' alt='arrow' />
              </button>
            </div>
          </div>
          <div className='col-span-4'>
            <div className='bg-customGrey shadow-xl p-5 h-96 overflow-y-auto rounded-2xl'>
              {feature === null
                ? (
                  <div>Loading...</div>
                  )
                : feature.length > 0
                  ? (
                      feature.map((item) => {
                        count++
                        if (count <= feature.length) {
                          return (
                            <div class='flex my-2 form-check'>
                              <input
                                onClick={(event) => addfeatr(event, item)}
                                class='h-6 w-6 mt-1'
                                type='checkbox'
                                value=''
                                checked={item.checked}
                                id={item.id + 'selected'}
                              />
                              <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                              <img src={scenario} className='mt-1 h-7 mr-2' />
                              <p class='mt-2'>{item.name} ({item.moduleName})</p>
                            </div>
                          )
                        }
                      })
                    )
                  : (
                    <div>No Feature found</div>
                    )}
            </div>

            {/* <div className="bg-customGrey shadow-xl p-5 h-96 overflow-y-auto rounded-2xl">
              {featureMap === null ? (
                <div>Loading...</div>
              ) : Object.keys(featureMap)?.length === 1 ? (
                Object.values(featureMap)?.map(
                  (item) =>
                    item.application?.map((application) => (
                      <div>
                        <div class="flex my-2 form-check text-xl">
                          <input
                            onClick={(event) => addApplxn(event, application)}
                            class="h-6 w-6 mt-1"
                            type="checkbox"
                            checked={application.checked}
                            id="flexCheckDefault"
                          />
                          <img className="ml-3 mt-2 h-4 mr-2" src={Arrow} />
                          <img src={scenario} className="mt-1 h-7 mr-2" />
                          {application.name}
                        </div>
                        {application.modules?.map((modules) => (
                          <div className="ml-5">
                            <div class="flex my-2 form-check text-xl">
                              <input
                                onClick={(event) => addModl(event, modules)}
                                class="h-6 w-6 mt-1"
                                type="checkbox"
                                checked={modules.checked}
                                id="flexCheckDefault"
                              />
                              <img className="ml-3 mt-2 h-4 mr-2" src={Arrow} />
                              <img src={scenario} className="mt-1 h-7 mr-2" />
                              {modules.moduleName}
                            </div>
                            {modules.features?.map((features) => (
                              <div className="ml-5">
                                <div class="flex my-2 form-check text-xl">
                                  <input
                                    onClick={(event) =>
                                      addfeatr(event, features)
                                    }
                                    class="h-6 w-6 mt-1"
                                    type="checkbox"
                                    checked={features.checked}
                                    id="flexCheckDefault"
                                  />
                                  <img
                                    className="ml-3 mt-2 h-4 mr-2"
                                    src={Arrow}
                                  />
                                  <img
                                    src={scenario}
                                    className="mt-1 h-7 mr-2"
                                  />
                                  {features.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))
                  // <div class="flex my-2 form-check text-xl">
                  //   <input
                  //     onClick={(event) => addTC(event, item)}
                  //     class="h-6 w-6 mt-1"
                  //     type="checkbox"
                  //     value=""
                  //     id="flexCheckDefault"
                  //   />
                  //   <img className="ml-3 mt-2 h-4 mr-2" src={Arrow} />
                  //   <img src={scenario} className="mt-1 h-7 mr-2" />
                  //   gsdgs
                  // </div>
                )
              ) : (
                <div>
                  No Feature found
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ReleasePlanner
