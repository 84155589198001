import { useState, useEffect, useRef } from 'react'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../../config/api'
import styles from '../modal.module.css'
import close from '../../../assets/Icons/close.png'

const AssignTesterModal = ({
  showModal,
  setShowModal,
  testpackId
}) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)

  const [getTesterName, setTesterName] = useState('')
  const [name, setName] = useState('')
  const [options, setOptions] = useState({})

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const assignTester = () => {
    const data = {
      name,
      testpack_id: testpackId,
      testerid: getTesterName
    }
    POST('/assignTester', data, { sessionID: sessionId })
      .then((res) => {
        openNotification(
          notificationTypes.SUCCESS,
          'Test Pack Assigned'
        )
        window.location.reload()
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
    GET('/getTester', { sessionID: sessionId })
      .then((res) => {
        setOptions(res.data.message)
        setTesterName(res.data.message[0].User.id)
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }, [showModal])

  return (
    <div className={styles.wrapper2} ref={modalRef}>
      <div className={styles.cardContainer3}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading1}>Users</h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <img className='h-12' src={close} alt='close' />
            </a>
          </div>
        </div>
        <h1 className='text-2xl font-DMSANS mt-2'>Select Users</h1>
        <div className={styles.modalBody}>
          <table className='mb-5 w-full table-auto mx-1 border-1 rounded-3xl border-customGreyThree'>
            <thead class='text-center bg-customGrey rounded-2xl'>
              <tr>
                <th scope='col' class='text-center text-base font-medium text-gray-900 px-8 py-4 text-left' />
                <th scope='col' class='text-center text-base font-medium text-gray-900 px-8 py-4 text-left'>
                  Name
                </th>
                <th scope='col' class='text-center text-base font-medium text-gray-900 px-8 py-4 text-left'>
                  Type
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center border-b border-1 border-customGreyThree'>
                <td className='text-center text-base font-medium text-gray-900 px-8 py-4 text-left'>
                  <input
                    className='h-6 w-6 mr-1'
                    type='checkbox'
                    name='tester'
                  />
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  Supreeth
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  CXO
                </td>
              </tr>
              <tr className='text-center border-b border-1 border-customGreyThree'>
                <td className='text-center text-base font-medium text-gray-900 px-8 py-4 text-left'>
                  <input
                    className='h-6 w-6 mr-1'
                    type='checkbox'
                    name='tester'
                  />
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  Partha
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  CXO
                </td>
              </tr>
              <tr className='text-center border-b border-1 border-customGreyThree'>
                <td className='text-center text-base font-medium text-gray-900 px-8 py-4 text-left'>
                  <input
                    className='h-6 w-6 mr-1'
                    type='checkbox'
                    name='tester'
                  />
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  ABC
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  Tester
                </td>
              </tr>
              <tr className='text-center border-b border-1 border-customGreyThree'>
                <td className='text-center text-base font-medium text-gray-900 px-8 py-4 text-left'>
                  <input
                    className='h-6 w-6 mr-1'
                    type='checkbox'
                    name='tester'
                  />
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  DEF
                </td>
                <td className='text-center text-lg font-medium text-gray-900 px-8 py-4 text-left'>
                  Tester
                </td>
              </tr>
            </tbody>
          </table>
          <div className='flex flex-col items-center'>
            <button
              className='bg-customGreenFour text-customGreenFive border-2 hover:border-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
              onClick={assignTester}
              type='button'
            >
              Add Test Pack
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignTesterModal
