import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  headerText: {
    fontWeight: 500,
    marginRight: 14,
    color: '#40A87B',
    fontSize: 18
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerLine: {
    flex: 1,
    backgroundColor: '#bfded3',
    height: 2,
    width: 100
  },
  headerLogo: {
    height: 36,
    marginLeft: 10
  }
})

const Header = (props) => {
  return (
    <View style={styles.header}>
      {props.title && <Text style={styles.headerText}>{props.title}</Text>}
      <View style={styles.headerLine} />
      <Image src='./GreentickLogo.png' style={styles.headerLogo} />
    </View>
  )
}

export default Header
