import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModelContext } from '../contexts/modelContext'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import useContextMenu from '../contexts/contextHook'
import { DELETE } from '../config/api'
import { notificationTypes, openNotification } from '../utils/notifications'
import {
  BsThreeDotsVertical,
  BsChevronDown,
  BsChevronRight
} from 'react-icons/bs'
import mouseicon from '../assets/Icons/mouseRightClick.png'

export default function Sidebaritem ({ item, index }) {
  const location = useLocation()
  const [isHovering, setIsHovering] = React.useState(false)
  const [modelstate, setModelState] = useContext(ModelContext)
  const sessionId = useSelector((state) => state.user.sessionId)
  const { pathname } = location
  const {
    clicked,
    points,
    contexName,
    itemID,
    moduleID,
    appID,
    scenarioID,
    isModal,
    setIsModal,
    isUpdateModal,
    setIsUpdateModal,
    setClicked,
    setPoints,
    setItems,
    setmoduleID,
    setappID,
    setitemID,
    setscenarioID,
    setModelType
  } = useContextMenu()
  const [open, setOpen] = React.useState(false)
  const history = useNavigate()
  // contextmenu
  const deleteApplication = () => {
    const confirmed = window.confirm('Are you sure you want to delete this Application?')

    if (confirmed) {
      DELETE(`/deleteApplication?AppID=${itemID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Application deleted successfully'
            )
          } else if (res.data.status === 401) {
            openNotification(
              notificationTypes.ERROR,
              'Session expired, please login again'
            )
          } else if (res.data.status === 406) {
            openNotification(
              notificationTypes.ERROR,
              'Unable to determine company of user'
            )
          } else if (res.data.status === 400) {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              res.data.message
            )
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting Application',
              'Application has dependency with Module or Feature'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    }
  }

  const deleteModule = () => {
    const confirmed = window.confirm('Are you sure you want to delete this Module?')

    if (confirmed) {
      DELETE(`/deleteModule?moduleID=${itemID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Module deleted successfully'
            )
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting Module',
              'Module has dependency with Scenario or Feature'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    }
  }

  const deleteScenario = () => {
    const confirmed = window.confirm('Are you sure you want to delete this Scenario?')

    if (confirmed) {
      DELETE(`/deleteScenario?scenarioID=${itemID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Scenario deleted successfully'
            )
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting Scenario',
              'Scenario has dependency with TestCase'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    }
  }

  const deleteTC = () => {
    const confirmed = window.confirm('Are you sure you want to delete this TestCase?')
    if (confirmed) {
      DELETE(`/deleteTestcase?testcaseID=${itemID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            openNotification(
              notificationTypes.SUCCESS,
              'Successfully deleted TestCase'
            )
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting TestCase',
              'TestCase has dependency with TestPack'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.INFO,
            'Error deleting TestCase',
            'TestCase has dependency with TestPack'
          )
        })
    }
  }

  const handleContexMenu = (type) => {
    console.log('types', type)
    setModelType('')
    setIsModal(false)
    setIsUpdateModal(false)
    switch (type) {
      case 'Application':
        console.log('types 1', type)
        setModelType('Application')
        setIsModal(!isModal)
        setModelState({
          modelType: 'Application',
          isModal: true,
          itemID,
          scenarioID,
          appID,
          sidebarExpanded: true
        })
        break
      case 'addModule':
        setModelType('addModule')
        setIsModal(!isModal)
        setModelState({
          modelType: 'addModule',
          isModal: true,
          itemID,
          scenarioID,
          appID
        })
        break
      case 'modifyApplication':
        setModelType('modifyApplication')
        setIsModal(!isModal)
        setModelState({
          modelType: 'modifyApplication',
          isModal: true,
          itemID,
          scenarioID,
          appID
        })
        break
      case 'deleteApplication':
        deleteApplication()
        break
      case 'addScenario':
        setModelType('addScenario')
        setIsModal(!isModal)
        setModelState({
          modelType: 'addScenario',
          isModal: true,
          itemID,
          scenarioID,
          appID
        })
        break
      case 'modifyModule':
        setModelType('modifyModule')
        setIsModal(!isModal)
        setModelState({
          modelType: 'modifyModule',
          isModal: true,
          itemID,
          scenarioID,
          appID
        })
        break
      case 'deleteModule':
        deleteModule()
        break
      case 'modifyScenario':
        setModelType('modifyScenario')
        setIsModal(!isModal)
        setModelState({
          modelType: 'modifyScenario',
          isModal: true,
          itemID,
          scenarioID,
          appID
        })
        break
      case 'deleteScenario':
        deleteScenario()
        break
      case 'modifyTC':
        console.log('modifyTC', itemID, scenarioID, appID)
        setModelType('modifyTC')
        setIsModal(!isModal)
        setModelState({
          modelType: 'modifyTC',
          isModal: true,
          itemID,
          scenarioID,
          appID
        })
        break
      case 'deleteTC':
        deleteTC()
        break
      default:
        return null
    }
  }

  const hideContext = (event) => {
    setClicked(false)
    setPoints({
      x: 0,
      y: 0
    })
    setItems([])
    setitemID('')
    setmoduleID('')
    setappID('')
    setscenarioID('')
  }
  const handleClick = (item) => {
    history(item.path)
  }

  if (item.submenu) {
    return (
      <li
        className={`${open ? 'open' : ''
          } pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 `}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => {
          setIsHovering(false)
          // setClicked(false);
        }}
      >
        <span
          onClick={(e) => {
            e.preventDefault()
            setOpen(!open)
            // setClicked(false);
            handleClick(item)
            // modelstate.sidebarExpanded
            //   ? handleClick(item)
            //   : setModelState({ ...modelstate, sidebarExpanded: true });
          }}
          className={`${open ? 'open' : ''
            } text-gray-900 transition duration-150 
              hover:text-green-400
             `}
        >
          <div className='flex items-center justify-between'>
            <div
              className='flex items-center'
              onContextMenu={(e) => {
                hideContext()
                if (item.contextMenu && item.contextMenu.length > 0) {
                  e.preventDefault()
                  console.log('x,y', e)

                  setPoints({
                    x: e.pageX,
                    y: e.pageY
                  })
                  setClicked(true)
                  setItems(item.contextMenu || [])
                  setitemID(item.id || '')
                  setmoduleID(item.module_id || '')
                  setappID(item.applicationId || '')
                  setscenarioID(item.scenario_id || '')
                }
              }}
            >
              {item.icon}

              {/* <svg
                className={`w-3 h-3 submenu-arraw shrink-0 ml-1 fill-current text-gray-400 ${
                  open && "rotate-180"
                }`}
                viewBox="0 0 12 12"
              >
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg> */}

              <div className='ml-3 lg:hidden lg:sidebar-expanded:block 2xl:block'>
                {!open
                  ? (
                    <BsChevronRight size={12} />
                    )
                  : (
                    <BsChevronDown size={12} />
                    )}
              </div>
              <span className='w-44 text-balance text-base font-DMSANS font-bold ml-1 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                {item.name}
              </span>
            </div>
            {/* arrow Icon */}
            {item.contextMenu && item.contextMenu.length > 0 && (
              <div className='flex shrink-0 ml-2'>
                <img
                  alt=''
                  src={mouseicon}
                  className='mr-2'
                  style={{ width: '12px', height: 'auto', opacity: 0.3 }}
                />

                {/* <svg
                    className={`w-3 h-3 submenu-arraw shrink-0 ml-1 fill-current text-gray-400 ${
                      open && "rotate-180"
                    }`}
                    viewBox="0 0 12 12"
                  >
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                  </svg> */}
              </div>
            )}
          </div>
        </span>
        <div className='lg:hidden lg:sidebar-expanded:block 2xl:block'>
          <ul
            className={`pl-1 ml-1 mt-4 border-l border-gray-300 ${!open && 'hidden'
              }`}
          >
            {item.dynamicMenu.map((child, ind) => (
              <Sidebaritem key={ind} item={child} index={ind} />
            ))}
          </ul>
        </div>
        {clicked && (
          <div
            onMouseLeave={() => {
              hideContext()
            }}
            className=' divide-y absolute custom-contexmenu z-40 text-base text-gray-900 duration-300 bg-white border border-gray-200 rounded-lg shadow-sm'
            style={{ top: points.y + 'px', left: '15px' }}
          >
            <ul className=''>
              {contexName.map((context, inx) => (
                <li
                  key={inx}
                  className='pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 '
                >
                  {context.model == true
                    ? (
                      <NavLink
                        end
                        className='block text-gray-900 cursor-pointer transition duration-150 '
                        onClick={(e) => handleContexMenu(context.catagory)}
                      >
                        <div className='flex items-center justify-between'>
                          <div className='grow flex items-center'>
                            <span className='w-44 text-balance text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                              {context.name}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                      )
                    : (
                      <NavLink
                        end
                        to={context.path}
                        className='block text-gray-900 cursor-pointer transition duration-150 '
                      >
                        <div className='flex items-center justify-between'>
                          <div className='grow flex items-center'>
                            <span className='w-44 text-balance text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                              {context.name}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                      )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </li>
    )
  } else {
    return (
      <li
        className={`pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 contextContainer ${pathname.includes(item.path) && 'text-green-100'
          }`}
      >
        <NavLink
          end
          to={item.path}
          className={`block text-gray-900 transition duration-150 hover:text-green-400 ${pathname.includes(item.path)
              ? 'hover:text-gray-400 text-green-400'
              : 'hover:text-gray'
            }`}
          onMouseEnter={() => {
            setIsHovering(true)
          }}
          onMouseLeave={() => {
            setIsHovering(false)
          }}
          onContextMenu={(e) => {
            if (item.contextMenu && item.contextMenu.length > 0) {
              e.preventDefault()
              console.log('x,y =>', e)

              setPoints({
                x: e.pageX,
                y: e.pageY
              })
              setClicked(true)
              console.log('Right Click 1== >', item.contextMenu)
              setItems(item.contextMenu || [])
              setitemID(item.id || '')
              setmoduleID(item.module_id || '')
              setappID(item.applicationId || '')
              setscenarioID(item.scenario_id || '')
            }
          }}
        >
          <div className='flex items-center justify-between'>
            <div className='grow flex items-center'>
              {item.icon}
              <span className='w-44 text-balance text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                {item.name}
              </span>
            </div>
            {item.contextMenu && item.contextMenu.length > 0 && (
              <div className='flex shrink-0 ml-2'>
                {/* {isHovering && <BsThreeDotsVertical size={15} />} */}
                <img
                  alt=''
                  src={mouseicon}
                  className='mr-2'
                  style={{ width: '12px', height: 'auto', opacity: 0.3 }}
                />
              </div>
            )}
          </div>
        </NavLink>
        {/* {clicked && ( */}
        <div
          onMouseLeave={() => {
            hideContext()
          }}
          className={`z-10 ${clicked ? 'show' : 'hidden'
            } divide-y absolute custom-contexmenu z-40 text-base text-gray-900 duration-300 bg-white border border-gray-200 rounded-lg shadow-sm`}
          style={{ top: points.y + 'px', left: '15px' }}
        >
          <ul className=''>
            {contexName.map((context, inx) => (
              <li
                key={inx}
                className='pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 '
              >
                {context.model
                  ? (
                    <NavLink
                      onClick={(e) => handleContexMenu(context.catagory)}
                      end
                      className='block text-gray-900 cursor-pointer transition duration-150 '
                    >
                      <div className='flex items-center justify-between'>
                        <div className='grow flex items-center'>
                          <span className='w-44 text-balance text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                            {context.name}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                    )
                  : (
                    <NavLink
                      end
                      to={context.path}
                      className='block text-gray-900 cursor-pointer transition duration-150 '
                    >
                      <div className='flex items-center justify-between'>
                        <div className='grow flex items-center'>
                          <span className='w-44 text-balance text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                            {context.name}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                    )}
              </li>
            ))}
          </ul>
        </div>
        {/* )} */}
      </li>
    )
  }
}
