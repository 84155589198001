import { useState, useEffect } from 'react'
import { GET, PUT, DELETE } from '../../config/api'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import name from '../../assets/Icons/T.png'
// import save from "../../assets/Icons/save.png";
// import user1 from "../../assets/Icons/user1.png";
import Arrow from '../../assets/Icons/arrowright.png'
import Arrow1 from '../../assets/Icons/arr.png'
import Arrow2 from '../../assets/Icons/arr2.png'
import { BiEdit, BiSave } from 'react-icons/bi'
import { MdDeleteOutline } from 'react-icons/md'
import { openNotification, notificationTypes } from '../../utils/notifications'
// import pencil from "../../assets/Icons/pencil.png";

import appIcon from '../../assets/Icons/app.png'
import module from '../../assets/Icons/module.png'
import scenario from '../../assets/Icons/scenario.png'
import tc from '../../assets/Icons/testcaseicon.png'

import * as _ from 'lodash'
import { FaPlay } from 'react-icons/fa'

const ViewCxoPackPage = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const sessionId = useSelector((state) => state.user.sessionId)
  const { ID } = useParams()
  const [testPackDetails, setTestPackDetails] = useState({})
  const [testPackData, setTestPackData] = useState([])
  const [selectedTestPack, setSelectedTestPack] = useState([])
  const [TPname, setTPName] = useState('')
  const [TPdesc, setTPDesc] = useState('')
  const [TPMexecution, setTPMExecution] = useState('')
  const [TPAexecution, setTPAExecution] = useState('')
  const [TPOCexecution, setTPOCExecution] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const handleCloud = () => {
    openNotification(
      notificationTypes.SUCCESS,
      'Success',
      'Please wait for the test to complete.'
    )
  }

  const selectItem = (event, item) => {
    item.checked = event.target.checked
    const tempData = _.cloneDeep(testPackData)
    setTestPackData(updateSelections(tempData, item))
  }

  const selectItemCurrentPack = (event, item) => {
    item.checked = event.target.checked
    const tempData = _.cloneDeep(selectedTestPack)
    setSelectedTestPack(updateSelections(tempData, item))
  }

  const updateSelections = (tempData, item) => {
    let appLevelChange = false
    let modLevelChange = false
    let scenLevelChange = false
    if (tempData.application.uid === item.uid) {
      tempData.application.checked = item.checked
      appLevelChange = true
    }
    tempData.application.modules.map((modules) => {
      if (appLevelChange || modules.uid === item.uid) {
        modules.checked = item.checked
        modLevelChange = true
      }
      modules.scenarios.map((eachScenario) => {
        if (modLevelChange || eachScenario.uid === item.uid) {
          eachScenario.checked = item.checked
          scenLevelChange = true
        }
        eachScenario.testCases.map((eachTestCase) => {
          if (scenLevelChange || eachTestCase.uid === item.uid) {
            eachTestCase.checked = item.checked
          }
        })
        scenLevelChange = false
      })
      modLevelChange = false
    })
    return tempData
  }

  const handleAdd = (event, itemToAdd) => {
    const res = event === null ? itemToAdd : _.cloneDeep(testPackData)
    let filteredScenario, filteredModules
    res.application.modules.map((modules) => {
      modules.scenarios.map((eachScenario) => {
        filteredScenario = eachScenario.testCases.filter((eachTestCase) => {
          return eachTestCase.checked === true
        })
        eachScenario.checked = filteredScenario.length > 0
        eachScenario.testCases = filteredScenario
      })
      filteredModules = modules.scenarios.filter((eachModule) => {
        return eachModule.checked === true
      })
      modules.checked = filteredModules.length > 0
      modules.scenarios = filteredModules
    })
    res.application.modules = res.application.modules.filter((eachModule) => {
      return eachModule.checked === true
    })

    res.application.checked = false
    res.application.modules.map((modules) => {
      modules.scenarios.map((eachScenario) => {
        eachScenario.testCases.map((eachTestCase) => {
          eachTestCase.checked = false
        })
        eachScenario.checked = false
      })
      modules.checked = false
    })
    setSelectedTestPack(res.application.modules.length > 0 ? res : null)
  }

  const handleRemove = () => {
    const tempTestPack = _.cloneDeep(selectedTestPack)
    const idsToRemove = []

    tempTestPack.application.modules.map((modules) => {
      if (modules.checked) {
        idsToRemove.push(modules.uid)
      }
      modules.scenarios.map((eachScenario) => {
        if (eachScenario.checked) {
          idsToRemove.push(eachScenario.uid)
        }
        eachScenario.testCases.map((eachTestCase) => {
          if (eachTestCase.checked) {
            idsToRemove.push(eachTestCase.uid)
          }
        })
      })
    })
    if (tempTestPack.application.checked) {
      idsToRemove.push(tempTestPack.application.uid)
    }

    let filteredScenario, filteredModules
    tempTestPack.application.modules.map((modules) => {
      modules.scenarios.map((eachScenario) => {
        filteredScenario = eachScenario.testCases.filter((eachTestCase) => {
          return eachTestCase.checked === false
        })
        eachScenario.checked =
          filteredScenario.length > 0 ? eachScenario.checked : true
        eachScenario.testCases = filteredScenario
      })
      filteredModules = modules.scenarios.filter((eachModule) => {
        return eachModule.checked === false
      })
      modules.checked = filteredModules.length > 0 ? modules.checked : true
      modules.scenarios = filteredModules
    })
    tempTestPack.application.modules = tempTestPack.application.modules.filter(
      (eachModule) => {
        return eachModule.checked === false
      }
    )
    tempTestPack.application.checked = false

    setSelectedTestPack(tempTestPack)

    const fullTestPack = _.cloneDeep(testPackData)
    fullTestPack.application.modules.map((modules) => {
      if (idsToRemove.includes(modules.uid)) {
        modules.checked = false
      }
      modules.scenarios.map((eachScenario) => {
        if (idsToRemove.includes(eachScenario.uid)) {
          eachScenario.checked = false
        }
        eachScenario.testCases.map((eachTestCase) => {
          if (idsToRemove.includes(eachTestCase.uid)) {
            eachTestCase.checked = false
          }
        })
      })
    })
    if (idsToRemove.includes(fullTestPack.application.uid)) {
      fullTestPack.application.checked = false
    }

    setTestPackData(fullTestPack)
  }

  const getTestPacks = () => {
    GET(`/getTestPack?testpackid=${ID}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          const allTestPacksSelected = []
          res.data.message.testPackData?.map((item) => {
            allTestPacksSelected.push(item.testcase_id)
          })
          setTestPackDetails(res.data.message)
          setTPName(res.data.message.testpack_name)
          setTPDesc(res.data.message.testpack_description)
          setTPMExecution(res.data.message.manualExecution)
          setTPAExecution(res.data.message.automaticExecution)
          setTPOCExecution(res.data.message.onCloudExecution)

          const appID = res.data.message.application_id
          GET(`/testCasesForTestPack?appid=${appID}`, { sessionID: sessionId })
            .then((res) => {
              res.data.message.application.checked = false
              res.data.message.application.modules.forEach((testModule) => {
                testModule.checked = false
                testModule.scenarios.forEach((testScenario) => {
                  testScenario.checked = false
                  testScenario.testCases.forEach((testCase) => {
                    testCase.checked = !!allTestPacksSelected.includes(
                      testCase.id
                    )
                  })
                })
              })
              setTestPackData(res.data.message)
              // console.log('res', res.data.message)

              handleAdd(null, _.cloneDeep(res.data.message))
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getTestPacks()
  }, [ID])

  const updateTestPackData = () => {
    return new Promise((resolve, reject) => {
      const data = {
        testpack_name: TPname,
        testpack_description: TPdesc,
        manualExecution: TPMexecution,
        automaticExecution: TPAexecution,
        onCloudExecution: TPOCexecution
      }

      if (TPname === '') {
        openNotification(
          notificationTypes.INFO,
          'TestPack Name should not be empty. Please try again'
        )
        reject('TestPack Name is empty')
        return
      }

      if (selectedTestPack === null || selectedTestPack === undefined) {
        openNotification(
          notificationTypes.ERROR,
          'ERROR: 40210',
          'Please select at least one test case'
        )
        reject('selectedTestPack is null or undefined')
        return
      }

      const testCaseArr = []
      const testpackID = ID

      selectedTestPack.application.modules.map((modules) =>
        modules.scenarios.map((eachScenario) =>
          eachScenario.testCases.map((eachTestCase) =>
            testCaseArr.push(eachTestCase)
          )
        )
      )

      console.log(selectedTestPack, 'selected app')

      const formdata = {
        testpackid: testpackID,
        testpackdata: testCaseArr
      }
      PUT(`/updateTPdata?tpId=${ID}`, data, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            PUT('/updatetestPack', formdata, { sessionID: sessionId })
              .then((res) => {
                if (res.data.status === 200 || res.data.status === 304) {
                  openNotification(
                    notificationTypes.SUCCESS,
                    'Success',
                    'TestPack data updated successfully'
                  )
                  getTestPacks()
                  resolve()
                  window.location.reload()
                } else {
                  openNotification(
                    notificationTypes.ERROR,
                    'An error has occurred'
                  )
                  reject('Error updating test pack data')
                }
              })
              .catch((error) => {
                openNotification(
                  notificationTypes.ERROR,
                  'An error has occurred'
                )
                reject(error)
              })
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              'Error in updating TestPackData. Please try again'
            )
            reject('Error updating test pack data')
          }
        })
        .catch((error) => {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Error in updating TestPackData. Please try again'
          )
          reject(error)
        })
    })
  }

  const handleClick = async () => {
    try {
      if (isEditing) {
        await updateTestPackData()
      }
      setIsEditing(!isEditing)
    } catch (error) {
      // add any error msg
    }
  }

  const deleteTestPack = () => {
    const confirmed = window.confirm('Are you sure you want to delete this TestPack?')
    if (confirmed) {
      DELETE(`/deleteTestPack?testpackID=${ID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Successfully deleted TestPack'
            )
            navigate('/testpackmanagement')
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting TestPack, TestPack has dependency with Release or TestRun'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.INFO,
            'Error deleting TestPack',
            'TestPack has dependency with Release or TestRun'
          )
        })
    }
  }

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              View CXO Pack
            </h1>
            {/* <button disabled className='ml-auto bg-customGreyOne border-2 text-gray-500 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline cursor-not-allowed'>
                <div className='flex'>
                  <img className='h-6 mr-2' src={user1} />
                  Assign User
                </div>
              </button> */}
            <span className='flex items-center justify-between'>
              <button
                onClick={handleClick}
                className={`mx-2 flex gap-1 items-center text-center text-base font-DMSANS font-semibold ${isEditing ? 'bg-customGreen' : 'bg-customBlueFive'} text-white px-4 py-2 rounded-lg`}
              >
                {isEditing
                  ? (
                    <>
                      Save
                      <BiSave />
                    </>
                    )
                  : (
                    <>
                      Edit
                      <BiEdit />
                    </>
                    )}
              </button>
              <button
                onClick={() => navigate(`/createtestrun/${ID}`)}
                className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg'
              >
                Run Now
                <FaPlay />
              </button>
              <button
                onClick={deleteTestPack}
                className='mx-2 flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customRed text-white px-4 py-2 rounded-lg'
              >
                Delete <MdDeleteOutline />
              </button>
            </span>
          </div>
          <div className='mt-6 pb-10 min:h-2/5'>
            <div>
              <div className='grid grid-cols-6 gap-2'>
                <div className='h-20 col-span-2 mb-5 bg-white rounded-xl'>
                  <div className='flex'>
                    <img className='m-3' src={name} alt='id' />
                    <div className='mt-1 ml-3 flex flex-col'>
                      <div>
                        <h1 className='mb-0 font-DMSANS text-customGreyTwo text-base'>
                          Test Pack Name &nbsp;
                        </h1>
                      </div>
                      <div>
                        {isEditing
                          ? (
                            <input
                              className='p-2 w-full border-2 rounded-lg'
                              value={TPname}
                              onChange={(e) => setTPName(e.target.value)}
                            />
                            )
                          : (
                            <h1 className='mb-0 pt-3 font-DMSANS font-bold text-base xl:text-xl'>
                              {TPname}
                            </h1>
                            )}
                      </div>
                    </div>
                    {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                  </div>
                </div>
                <div className='col-span-4 mb-5 p-1 bg-white rounded-xl'>
                  <div className='flex'>
                    <h1 className='font-DMSANS text-customGreyTwo text-base ml-5'>
                      {' '}
                      Test Pack Description &nbsp;
                    </h1>
                    {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                  </div>
                  <div>
                    {isEditing
                      ? (
                        <input
                          className='p-2 w-full border-2 rounded-lg'
                          value={TPdesc}
                          onChange={(e) => setTPDesc(e.target.value)}
                        />
                        )
                      : (
                        <h1 className='mb-0 font-DMSANS font-bold text-base xl:text-xl ml-5'>
                          {TPdesc}
                        </h1>
                        )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-2 mb-5 border-2 border-gray-300 shadow-xl rounded-xl'>
              <h1 className='font-bold pt-3 text-black font-DMSANS text-base xl:text-2xl pl-8'>
                Execution Type
              </h1>
              <div class='pl-5 flex pb-5'>
                <div class='my-2 form-check text-lg mx-3'>
                  <input
                    className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                    type='checkbox'
                    disabled={!isEditing}
                    checked={TPMexecution === 1}
                    onChange={() => setTPMExecution(TPMexecution === 1 ? 0 : 1)}
                    id='flexCheckDefault'
                  />
                  <label
                    className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                    htmlFor='flexCheckDefault'
                  >
                    Manual Execution
                  </label>
                </div>
                <div class='my-2 form-check text-lg mx-3'>
                  <input
                    className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                    type='checkbox'
                    disabled={!isEditing}
                    checked={TPAexecution === 1}
                    onChange={() => setTPAExecution(TPAexecution === 1 ? 0 : 1)}
                    id='flexCheckChecked'
                  />
                  <label
                    className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                    htmlFor='flexCheckChecked'
                  >
                    Automated Execution
                  </label>
                </div>
                <div class='my-2 form-check text-lg mx-3'>
                  <input
                    className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                    type='checkbox'
                    disabled={!isEditing}
                    checked={TPOCexecution === 1}
                    onChange={() => setTPOCExecution(TPOCexecution === 1 ? 0 : 1)}
                    id='flexCheckCloud'
                  />
                  <label
                    className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                    htmlFor='flexCheckCloud'
                  >
                    Execution on Cloud
                  </label>
                </div>
              </div>
            </div>
            <div className='mt-5'>
              <div className='mx-2 grid grid-cols-10 gap-8'>
                <div className='col-span-4'>
                  <div className='bg-customWhite p-5 h-96 overflow-y-auto rounded-2xl'>
                    {
                      <div>
                        <h1>
                          {selectedTestPack === null ? null : Object.keys(
                            selectedTestPack
                          ).length > 0 ? (
                            <div>
                              <div class='flex my-2 form-check text-xl'>
                                <input
                                  disabled={!isEditing}
                                  onClick={(event) =>
                                    selectItemCurrentPack(
                                      event,
                                      selectedTestPack.application
                                    )}
                                  class='h-6 w-6 mr-2'
                                  type='checkbox'
                                  value={selectedTestPack.application.name}
                                  checked={selectedTestPack.application.checked}
                                  id={selectedTestPack.application.uid}
                                />
                                {/* <img className='ml-1 mt-1 h-4 mr-2' src={Arrow} /> */}
                                <img
                                  className='ml-3 mt-2 h-4 mr-2'
                                  src={Arrow}
                                  alt='arrow_icon'
                                />
                                <img
                                  src={appIcon}
                                  className='mt-1 h-7 mr-2'
                                  alt='app_icon'
                                />
                                {selectedTestPack.application.name}
                              </div>
                              {selectedTestPack.application.modules.map(
                                (modules) => (
                                  <div className='ml-5'>
                                    <div class='flex my-2 form-check text-xl'>
                                      <input
                                        disabled={!isEditing}
                                        onClick={(event) =>
                                          selectItemCurrentPack(event, modules)}
                                        class='h-6 w-6 mt-1'
                                        type='checkbox'
                                        value={modules.name}
                                        checked={modules.checked}
                                        id={modules.uid}
                                      />
                                      {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                      <img
                                        className='ml-3 mt-2 h-4 mr-2'
                                        src={Arrow}
                                        alt='arrow_icon'
                                      />
                                      <img
                                        src={module}
                                        className='mt-1 h-7 mr-2'
                                        alt='module'
                                      />
                                      {modules.name}
                                    </div>
                                    {modules.scenarios.map((eachScenario) => (
                                      <div className='ml-5'>
                                        <div class='flex my-2 form-check text-xl'>
                                          <input
                                            disabled={!isEditing}
                                            onClick={(event) =>
                                              selectItemCurrentPack(
                                                event,
                                                eachScenario
                                              )}
                                            class='h-6 w-6 mt-1'
                                            type='checkbox'
                                            value={eachScenario.name}
                                            checked={eachScenario.checked}
                                            id={eachScenario.uid}
                                          />
                                          {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                          <img
                                            className='ml-3 mt-2 h-4 mr-2'
                                            src={Arrow}
                                            alt='arrow_icon'
                                          />
                                          <img
                                            src={scenario}
                                            className='mt-1 h-7 mr-2'
                                            alt='scenario'
                                          />
                                          {eachScenario.name}
                                        </div>
                                        {eachScenario.testCases.map(
                                          (eachTestCase) => (
                                            <div className='ml-5'>
                                              <div class='flex my-2 form-check text-xl'>
                                                <input
                                                  disabled={!isEditing}
                                                  onClick={(event) =>
                                                    selectItemCurrentPack(
                                                      event,
                                                      eachTestCase
                                                    )}
                                                  class='h-6 w-6 mt-1'
                                                  type='checkbox'
                                                  value={eachTestCase.name}
                                                  checked={eachTestCase.checked}
                                                  id={eachTestCase.uid}
                                                />
                                                {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                                <img
                                                  className='ml-3 mt-2 h-4 mr-2'
                                                  src={Arrow}
                                                  alt='arrow_icon'
                                                />
                                                <img
                                                  src={tc}
                                                  className='h-9 mr-1'
                                                  alt='tc'
                                                />
                                                <span className='mt-1'>
                                                  {eachTestCase.name}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )
                              )}
                            </div>
                              ) : (
                                <div>
                                  <div class='flex my-2 form-check text-xl'>
                                    <h1>No Data</h1>
                                  </div>
                                </div>
                              )}
                        </h1>
                      </div>
                    }
                  </div>
                </div>
                <div className='my-auto col-span-2'>
                  <div className='flex flex-col justify-center'>
                    <button
                      className={`mx-auto flex mb-2 px-5 py-2 rounded-xl bg-green-300 font-medium text-black ${isEditing ? '' : 'cursor-not-allowed'}`}
                      onClick={handleAdd}

                    >
                      <img src={Arrow2} className='h-8' alt='arrow' />
                      <span className='ml-2 text-xl'>Add</span>
                    </button>
                    <button
                      className={`mx-auto flex mb-2 px-5 py-2 rounded-xl bg-red-300 font-medium text-black ${isEditing ? '' : 'cursor-not-allowed'}`}
                      onClick={handleRemove}
                    >
                      <span className='ml-2 text-xl'>Remove</span>
                      <img src={Arrow1} className='h-8' alt='arrow' />
                    </button>
                  </div>
                </div>
                <div className='col-span-4'>
                  <div className='bg-customWhite p-5 h-96 overflow-y-auto rounded-2xl'>
                    {testPackData === null ? (
                      <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>
                        Loading...
                      </h1>
                    ) : Object.keys(testPackData).length > 0 ? (
                      <div>
                        <div class='flex my-2 form-check text-xl'>
                          <input
                            disabled={!isEditing}
                            onClick={(event) =>
                              selectItem(event, testPackData.application)}
                            class='h-6 w-6 mr-2'
                            type='checkbox'
                            value={testPackData.application.name}
                            checked={testPackData.application.checked}
                            id={testPackData.application.uid}
                          />
                          {/* <img className='ml-1 mt-1 h-4 mr-2' src={Arrow} /> */}
                          <img
                            className='ml-3 mt-2 h-4 mr-2'
                            src={Arrow}
                            alt='arrow_icon'
                          />
                          <img
                            src={appIcon}
                            className='mt-1 h-7 mr-2'
                            alt='app_icon'
                          />
                          {testPackData.application.name}
                        </div>
                        {testPackData.application.modules.map((modules) => (
                          <div className='ml-5'>
                            <div class='flex my-2 form-check text-xl'>
                              <input
                                disabled={!isEditing}
                                onClick={(event) => selectItem(event, modules)}
                                class='h-6 w-6 mt-1'
                                type='checkbox'
                                value={modules.name}
                                checked={modules.checked}
                                id={modules.uid}
                              />
                              {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                              <img
                                className='ml-3 mt-2 h-4 mr-2'
                                src={Arrow}
                                alt='arrow_icon'
                              />
                              <img
                                src={module}
                                className='mt-1 h-7 mr-2'
                                alt='module'
                              />
                              {modules.name}
                            </div>
                            {modules.scenarios.map((eachScenario) => (
                              <div className='ml-5'>
                                <div class='flex my-2 form-check text-xl'>
                                  <input
                                    disabled={!isEditing}
                                    onClick={(event) =>
                                      selectItem(event, eachScenario)}
                                    class='h-6 w-6 mt-1'
                                    type='checkbox'
                                    value={eachScenario.name}
                                    checked={eachScenario.checked}
                                    id={eachScenario.uid}
                                  />
                                  {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                  <img
                                    className='ml-3 mt-2 h-4 mr-2'
                                    src={Arrow}
                                    alt='arrow_icon'
                                  />
                                  <img
                                    src={scenario}
                                    className='mt-1 h-7 mr-2'
                                    alt='scenario'
                                  />
                                  {eachScenario.name}
                                </div>
                                {eachScenario.testCases.map((eachTestCase) => (
                                  <div className='ml-5'>
                                    <div class='flex my-2 form-check text-xl'>
                                      <input
                                        disabled={!isEditing}
                                        onClick={(event) =>
                                          selectItem(event, eachTestCase)}
                                        class='h-6 w-6 mt-1'
                                        type='checkbox'
                                        value={eachTestCase.name}
                                        checked={eachTestCase.checked}
                                        id={eachTestCase.uid}
                                      />
                                      {/* <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} /> */}
                                      <img
                                        className='ml-3 mt-2 h-4 mr-2'
                                        src={Arrow}
                                        alt='arrow_icon'
                                      />
                                      <img
                                        src={tc}
                                        className='h-9 mr-1'
                                        alt='tc'
                                      />
                                      <span className='mt-1'>
                                        {eachTestCase.name}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <div class='flex my-2 form-check text-xl'>
                          <h1>No Data</h1>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {user === null
              ? null
              : user.accountType === 2
                ? (
                  <div />
                  )
                : (
                  <div className='mt-5 flex float-right'>
                    <div className='ml-auto grid grid-cols-3 gap-4'>
                      {testPackDetails === null
                        ? (
                          <div />
                          )
                        : (
                          <div
                            style={{
                              visibility:
                                testPackDetails.onCloudExecution === 1
                                  ? 'visible'
                                  : 'hidden'
                            }}
                            className='col-span-2 ml-auto'
                          >
                            <button
                              className='px-4 py-2 text-lg rounded-xl bg-customBlue text-white focus:bg-customBlue focus:text-white text-center'
                              onClick={() => handleCloud()}
                            >
                              Schedule a Run
                            </button>
                          </div>
                          )}
                      <div className='col-span-1'>
                        <button
                          onClick={() => navigate(`/createtestrun/${ID}`)}
                          className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg'
                        >
                          <FaPlay className='mr-2' />
                          Run Now
                        </button>
                      </div>
                    </div>
                  </div>
                  )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewCxoPackPage
