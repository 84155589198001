import { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { PUT } from "../../../config/api";
import {
    notificationTypes,
    openNotification,
} from "../../../utils/notifications";

const AddTeststeps = ({
    title1,
    priority1,
    description1,
    status1,
    createdDate1,
    teststepsId,
    teststepDescription1,
    testData1,
    expectedResult1,
}) => {
    const navigate = useNavigate()

    const [title, setTitle] = useState(title1)
    const [priority, setPriority] = useState(priority1)
    const [description, setDescription] = useState(description1)
    const [status, setStatus] = useState(status1)
    const [createdDate, setCreatedDate] = useState(createdDate1);
    const [teststepDescription, setTeststepDescription] = useState(teststepDescription1);
    const [testData, setTestData] = useState(testData1);
    const [expectedResult, setExpectedResult] = useState(expectedResult1);

    const sessionId = useSelector((state) => state.user.sessionId);

    const selectOptions = ["New", "In-Progress", "Completed"];

    const selectOptions1 = ["High", "Medium", "Low"];

    const updateTeststeps = () => {
        const data = {
            title: title,
            createdDate: createdDate,
            priority: priority,
            description: description,
            status: status,
             teststepDescription:teststepDescription,
             testData: testData,
             expectedResult: expectedResult,
        };
        console.log(data," data data ")
        PUT(`/teststepsEditone/${teststepsId}`, data, {
            sessionID: sessionId,
        })
            .then((res) => {
                if (res.status === 200) {
                    openNotification(
                        notificationTypes.SUCCESS,
                        "Success",
                        "Teststeps data updated successfully"
                    );
                    navigate('/teststeps')
                } else {
                    openNotification(
                        notificationTypes.ERROR,
                        "Error: 82010",
                        "Error in updating Teststeps. Please try again"
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationTypes.ERROR,
                    "Error: 82010",
                    "Error in updating Teststeps. Please try again"
                );
            });
    };

    return (
        <>
            <div className="flex justify-end">
                <button
                    className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
                    type="button"
                    onClick={updateTeststeps}
                >
                    Save
                </button>
            </div>

            <div className="mt-5 bg-gray-200 p-4 rounded-xl">
                <div className="bg-white p-3 rounded-xl">
                    <div className="grid grid-col-10 gap-x-4 gap-y-2">
                        <div className="col-span-10">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                                Title &nbsp;
                            </div>
                            <h1 className="text-black">
                                <input
                                    type="text"
                                    className="p-2 w-full border-2 rounded-lg"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </h1>
                        </div>
                        <div className="col-span-3">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                                Created Date &nbsp;
                            </div>
                            <h1 className="text-black">
                                {createdDate ? (
                                    <input
                                        type="text"
                                        className="p-2 w-full border-2 rounded-lg"
                                        defaultValue={moment(createdDate).utc().format("DD-MM-YYYY")}
                                        onClick={() => {
                                            setCreatedDate(false);
                                        }}
                                    />
                                ) : (
                                    <input
                                        type="date"
                                        className="p-2 w-full border-2 rounded-lg"
                                        // value={moment(rDate).utc().format("DD-MM-YYYY")}
                                        onChange={(e) => setCreatedDate(e.target.value)}
                                    />
                                )}
                            </h1>
                        </div>
                        <div className="col-span-4">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                                Status &nbsp;
                            </div>
                            <h1 className="text-black">
                                <select
                                    className="p-2 w-full border-2 rounded-lg"
                                    name="overallStatus"
                                    id="overallStatus"
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    {selectOptions.map((obj) => {
                                        let selected = "";
                                        if (obj.trim().localeCompare(status.trim()) === 0) {
                                            selected = "selected";
                                        } else {
                                            selected = "";
                                        }
                                        return <option selected={selected}>{obj.trim()}</option>;
                                    })}
                                </select>
                            </h1>
                        </div>
                        <div className="col-span-3">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                                Priority &nbsp;
                            </div>
                            <h1 className="text-black">
                                <select
                                    className="p-2 w-full border-2 rounded-lg"
                                    name="overallStatus"
                                    id="overallStatus"
                                    onChange={(e) => setPriority(e.target.value)}
                                >
                                    {selectOptions1.map((obj) => {
                                        let selected = "";
                                        if (obj.trim().localeCompare(priority.trim()) === 0) {
                                            selected = "selected";
                                        } else {
                                            selected = "";
                                        }
                                        return <option selected={selected}>{obj.trim()}</option>;
                                    })}
                                </select>
                            </h1>
                        </div>
                        <div className="col-span-10">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                              Testcase  Description &nbsp;
                            </div>
                            <h1 className="text-black">
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter Description"
                                    className="p-2 w-full border-2 rounded-lg"
                                />
                            </h1>
                        </div>
                        <div className="col-span-10">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                            Teststep Description &nbsp;
                            </div>
                            <h1 className="text-black">
                                <textarea
                                    value={teststepDescription}
                                    onChange={(e) => setTeststepDescription(e.target.value)}
                                    placeholder="Enter Description"
                                    className="p-2 w-full border-2 rounded-lg"
                                />
                            </h1>
                        </div>
                        <div className="col-span-10">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                            TestData &nbsp;
                            </div>
                            <h1 className="text-black">
                                <textarea
                                    value={testData}
                                    onChange={(e) => setTestData(e.target.value)}
                                    placeholder="Enter Description"
                                    className="p-2 w-full border-2 rounded-lg"
                                />
                            </h1>
                        </div>
                        <div className="col-span-10">
                            <div className="text-sm mt-2" style={{ color: "grey" }}>
                            Expected Result &nbsp;
                            </div>
                            <h1 className="text-black">
                                <textarea
                                    value={expectedResult}
                                    onChange={(e) => setExpectedResult(e.target.value)}
                                    placeholder="Enter Description"
                                    className="p-2 w-full border-2 rounded-lg"
                                />
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="grid grid-cols-5 gap-4 mt-5">
        <div className="col-end-7">
          <button
            className="w-full bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end hidden"
            type="button"
            onClick={onSave(rName, rVersion, rDate, rDesc, rOverallStatus)}
          >
            Update
          </button>
        </div>
      </div> */}
        </>
    );
};

export default AddTeststeps;
