import ID from '../../assets/Icons/I.png'
import pencil from '../../assets/Icons/pencil.png'
import date from '../../assets/Icons/date.png'
import status from '../../assets/Icons/status.png'
import name from '../../assets/Icons/T.png'

import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import inputStyles from '../../components/InputComponents/Input.module.css'
import SingleSelectComponent from '../../components/InputComponents/SingleSelectComponent'
import { TCtype, TCstatus, TCpriorityVal } from '../../constants/TestCase'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { GET, POST } from '../../config/api'
import moment from 'moment'

const AddTestCasePage = () => {
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId)
  const formSettingData = useSelector((state) => state.user.settings);
  const { scenarioID, moduleID, applicationID} = useParams()
  const sessionID = useSelector((state) => state.user.sessionId);
  const userId = useSelector((state) => state.user.userId);
  const [accountType, setAccountType] = useState("greentick");
  const currDate = moment()

  const [TCType, setTCType] = useState(TCtype[0].value)
  const [TCstat, setTCstatus] = useState(TCstatus[0].value)
  const [TCpriority, setTCpriority] = useState(TCpriorityVal[0].value)
  const [tcName, setTcName] = useState('')
  const [tcDate, setTcDate] = useState('')
  const [tcID, setTCID] = useState('')
  const [tcDesc, setTcDesc] = useState('')
  const [jiraUrl, setJiraUrl] = useState("");
  const [projectId, setProjectId] = useState('');
  const [folderId, setFolderId] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [projectIdData, setProjectIdData] = useState([]);
  const [folderIdData, setFolderIdData] = useState([]);
  const [ownerIdData, setOwnerIdData] = useState([]);
  const [settingsData, setSettingsData] = useState([]);

  useEffect(() => {
    console.log(applicationID, scenarioID, moduleID, " scenarioID, moduleID, applicationID ")
    getSettings();
    if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
      setAccountType(formSettingData.accountType);
      getProjectIds(
        formSettingData.userName,
        formSettingData.zephyrToken);
      getOwnerId(
        formSettingData.userName,
        formSettingData.token
      )
      getFolderIds(
        formSettingData.userName,
        formSettingData.zephyrToken
      )
    }

  }, [])

  async function getSettings() {
    await GET(`/getSettings?userId=${userId}`, { sessionID })
      .then((res) => {
        if (res.data.status === "ok") {
          setSettingsData(res.data.message)
          setJiraUrl(res.data.message.url)
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getOwnerId = async (userName, token) => {
    GET(`/getownerid?userName=${userName}&token=${token}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          let resData = res.data.message;
          setOwnerIdData(resData[0].ownerId);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting owner id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting owner id, Please try again"
        );
      });
  };

  const getFolderIds = async (userName, zephyrToken) => {
    GET(`/getallfolderid?userName=${userName}&zephyrToken=${zephyrToken}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setFolderIdData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getProjectIds = async (userName, zephyrToken) => {
    GET(`/getzypherproject?userName=${userName}&zephyrToken=${zephyrToken}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setProjectIdData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const addTC = (e) => {
    if (TCType.length > 0 && TCstat.length > 0 && tcName.length > 0 && TCpriority.length > 0) {

      const Data = {
        testCaseID: tcID,
        testCaseName: tcName,
        testCaseDescription: tcDesc,
        testcaseType: TCType,
        testCaseStatus: TCstat,
        TCpriority: TCpriority,
        testCaseDate: currDate,
        moduleID: moduleID,
        applicationID: applicationID,
        projectId: projectId,
        projectKey: projectKey,
        folderId: folderId,
        ownerIdData: ownerIdData
      }

      POST(`/postTestCase?scenarioID=${scenarioID}`, Data, {
        sessionID: sessionId
      })
        .then((res) => {
          if (
            res.data.status === 200 ||
            res.data.status === 304 ||
            res.data.status === 'ok'
          ) {
            openNotification(
              notificationTypes.SUCCESS,
              'Test case added successfully'
            )
            navigate(`/testcase/${res.data.message.id}/${scenarioID}`)
          } else {
            openNotification(
              notificationTypes.ERROR,
              `Error: ${res.data.status} occured`,
              res.data.message || 'Please try again later'
            )
          }
        })
        .catch((err) => {
          console.log('tc not added')
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error: 30810',
        'Please enter all the details'
      )
    }
  }

  const getTestCaseID = () => {
    GET(`/getTestCases?scenarioID=${scenarioID}`, { sessionID: sessionId })
      .then((res) => {
        if (
          res.data.status === 200 ||
          res.data.status === 304 ||
          res.data.status === 'ok'
        ) {
          setTCID(res.data.message.length + 1)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log('tc not added')
      })
  }

  useEffect(() => {
    setTcDate(currDate)
    getTestCaseID()
  }, [])

  return (
    <>
      <div className='pb-5 bg-gray-100 pt-12'>
        <div className='mr-6 grid grid-cols-10 gap-24'>
          <div className='col-span-2' />
          <div className='mb-40 ml-14 col-span-8'>
            <div>
              <div>
                <div className='mt-10 flex'>
                  <button
                    onClick={addTC}
                    className='ml-auto mr-20 bg-customGreenFour hover:bg-green-300 border-2 hover:border-green-300 text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
                  >
                    Save Changes
                  </button>
                </div>
                <h1 className='pt-3 pb-2 mb-4 font-DMSANS font-bold text-base xl:text-5xl leading-none lg:leading-11'>
                  Add TestCase
                </h1>
                <div className='h-28 mb-3 bg-white rounded-xl'>
                  <div className='flex'>
                    <img className='m-5' src={name} alt='name' />
                    <div className='w-4/5 mt-1 ml-3 flex flex-col mr-5'>
                      <h1 className='mb-1 pt-3 font-DMSANS font-bold text-base xl:text-xl'>
                        {' '}
                        Name &nbsp;
                      </h1>
                      <h1 className='w-full mb-0 font-DMSANS text-customGreyTwo text-lg'>
                        <input
                          type='text'
                          className='p-2 w-full bg-transparent border-2 border-gray-200 rounded-lg outline-none'
                          value={tcName}
                          onChange={(e) => setTcName(e.target.value)}
                        />
                      </h1>
                    </div>
                    <img
                      src={pencil}
                      alt='edit'
                      className='h-5 mr-4 mt-3 ml-auto'
                    />
                  </div>
                </div>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='col-span-1 h-24 mb-3 bg-white rounded-xl'>
                    <div className='flex'>
                      <img className='m-5' src={ID} alt='id' />
                      <div className='mt-1 ml-3 flex flex-col'>
                        <h1 className='mb-1 pt-3 -ml-5 font-DMSANS font-bold text-base xl:text-xl'>
                          ID &nbsp;
                        </h1>
                        <h1 className='w-full mb-1 -ml-7 font-DMSANS text-black font-bold text-base'>
                          <input
                            type='text'
                            className='p-2 bg-gray-200 border-2 border-gray-200 rounded-lg outline-none'
                            value={tcID}
                            disabled
                          />
                        </h1>
                      </div>
                      <img src={pencil} alt='edit' className='h-5 mt-3 -ml-6' />
                    </div>
                  </div>
                  <div className='col-span-1 h-24 mb-3 bg-white rounded-xl'>
                    <div className='flex'>
                      <img className='m-3' src={date} alt='status' />
                      <div className='mt-1 ml-3 flex flex-col'>
                        <h1 className='mt-2 mb-0 font-DMSANS text-customGreyTwo text-base'>
                          <SingleSelectComponent
                            value={TCType}
                            onChangeHandler={(e) => setTCType(e.target.value)}
                            options={TCtype}
                            label='Type'
                          />
                        </h1>
                      </div>
                      <img
                        src={pencil}
                        alt='edit'
                        className='h-5 mr-4 mt-3 ml-auto'
                      />
                    </div>
                  </div>
                  <div className='col-span-1 h-24 mb-3 bg-white rounded-xl'>
                    <div className='flex'>
                      <div className='m-3'>
                        <img src={status} alt='status' />
                      </div>
                      <div className='col-span-2'>
                        <h1 className='mt-2 mb-1 me-2 font-DMSANS text-customGreyTwo text-base'>
                          <SingleSelectComponent
                            value={TCstat}
                            onChangeHandler={(e) => setTCstatus(e.target.value)}
                            options={TCstatus}
                            label='Status'
                          />
                        </h1>
                      </div>
                      {accountType === "jira" ? (
                      <div className='col-span-2'>
                        <h1 className='mt-2 mb-1 me-2 font-DMSANS text-customGreyTwo text-base'>
                          <SingleSelectComponent
                            value={TCpriority}
                            onChangeHandler={(e) => setTCpriority(e.target.value)}
                            options={TCpriorityVal}
                            label='Priority'
                          />
                        </h1>
                      </div>):("")}
                    </div>
                  </div>
                  {accountType === "jira" ? (
                    <>
                      <div className='col-span-1 h-24 mb-3 bg-white rounded-xl'>
                        <div className=''>
                          <div className='m-3'>
                          <label className={inputStyles.label}>Project</label>
                          </div>
                          <div className='col-span-2 ms-3'>
                            <h1 className='mt-2 mb-1 font-DMSANS text-customGreyTwo text-base'>
                              <select
                                className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
                                name="projectId"
                                style={{color:"black"}}
                                id="projectId"
                                onChange={(e) => {
                                  setProjectId(e.target.value)
                                  if(e.target.value){
                                    const projectData = projectIdData;
                                    console.log(projectData,"projectData471")
                                    for (let i = 0; i < projectData.length; i++) {
                                      if (e.target.value == projectData[i].id) {
                                        console.log(projectData[i].id,"projectData[i].id")
                                        setProjectKey(projectData[i].key);
                                      }
                                    }
                                  }
                                }}
                              >
                                <option>Select Project</option>
                                {projectIdData.map((items, index) => {
                                  return (
                                    <>
                                      <option value={items.id}>{items.key}</option>
                                    </>
                                  );
                                })}
                              </select>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className='col-span-1 h-24 mb-3 bg-white rounded-xl'>
                        <div className=''>
                          <div className='m-3'>
                          <label className={inputStyles.label}>Folder</label>
                          </div>
                          
                          <div className='col-span-2 ms-3'>
                            <h1 className='mt-2 mb-1 font-DMSANS text-customGreyTwo text-base'>
                              <select
                                className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
                                name="folderId"
                                id="folderId"
                                style={{color:"black"}}
                                onChange={(e) => {
                                  setFolderId(e.target.value)
                                }}
                              >
                                <option>Select Folder</option>
                                {folderIdData.map((items, index) => {
                                  return (
                                    <>
                                      <option value={items.folderId}>{items.folderName}</option>
                                    </>
                                  );
                                })}
                              </select>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : ("")}
                </div>
                <div className='mb-5'>
                  <div className='h-full w-full bg-white rounded-xl'>
                    <div className='flex'>
                      <h1 className='pt-3 font-bold font-DMSANS text-base xl:text-xl ml-5'>
                        Description &nbsp;
                      </h1>
                      <img
                        src={pencil}
                        alt='edit'
                        className='h-5 mr-4 mt-3 ml-auto'
                      />
                    </div>
                    <h1 className='pb-3 font-DMSANS text-customGreyTwo text-base ml-5'>
                      <textarea
                        value={tcDesc}
                        onChange={(e) => setTcDesc(e.target.value)}
                        placeholder='Enter Description'
                        className='mr-10 w-4/5 h-40 p-2 bg-transparent border-2 border-gray-200 rounded-lg outline-none'
                      />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTestCasePage
