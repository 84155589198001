import { Image, Text, View } from '@react-pdf/renderer'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import BasicPageLayout from '../components/BasicPageLayout'
import { GET } from '../../../../config/api'
import {
  notificationTypes,
  openNotification
} from '../../../../utils/notifications'

const FeaturesAdded = (props) => {
  const [selectedFeature, setSelectedFeature] = useState([])

  useLayoutEffect(() => {
    GET('getApplicationFromCompany', { sessionID: props.sessionId })
      .then((res) => {
        getAddedFeatures(res.data.message)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }, [props.sessionId])

  const sortFeature = (a, b) => {
    if (a.moduleName < b.moduleName) {
      return -1
    } else if (a.moduleName > b.moduleName) {
      return 1
    } else if (a.name < b.name) {
      return -1
    } else if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const getAddedFeatures = (applications) => {
    applications.map((obj) => {
      GET(`selectedFeature?releaseId=${props.releaseId}`, {
        sessionID: props.sessionId
      })
        .then((res) => {
          if (res.data.message) {
            res.data.message.featureId &&
              res.data.message.featureId.forEach((features) => {
                if (features) {
                  features.checked = false
                }
              })
            let items = res.data.message.featureId
            items = items.sort((a, b) => sortFeature(a, b))
            setSelectedFeature(items)
          } else {
            setSelectedFeature([])
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, Please try again later'
          )
        })
    })
  }

  return (
    <BasicPageLayout>
      <View style={props.greenContainer}>
        <Text style={props.title}>Features Added</Text>
        <View style={props.textCont}>
          {selectedFeature && selectedFeature.length > 0
            ? (
                selectedFeature.map((item) => (
                  <View style={props.point}>
                    <Image src='./check.png' style={props.checkImage} />
                    <Text>{item.name}</Text>
                  </View>
                ))
              )
            : (
              <View style={props.point}>
                <Text>No Feature found</Text>
              </View>
              )}
        </View>
        {/* <View style={props.textCont}>
          <View style={props.point}>
            <Text>P</Text>
            <Text>Lorem ipsum dolor sit amet consectetur.</Text>
          </View>
          <View style={props.point}>
            <Text>P</Text>
            <Text>Lorem ipsum dolor sit amet consectetur.</Text>
          </View>
        </View> */}
      </View>
    </BasicPageLayout>
  )
}

export default FeaturesAdded
