import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React, { useRef } from "react";
import { GET, POST, PUT } from "../../config/api";
import { documentTitles } from "../../constants/appConstants";
import { useSelector } from "react-redux";

import ID from "../../assets/Icons/I.png";
import name from "../../assets/Icons/T.png";
import status from "../../assets/Icons/status.png";
import date from "../../assets/Icons/date.png";
import arrow from "../../assets/Icons/arrowup.png";
import arrow1 from "../../assets/Icons/arrowdown.png";
// import copy from "../../assets/Icons/copy.png";
// import del from "../../assets/Icons/delete.png";
// import reorder from "../../assets/Icons/reorder.png";
// import select from "../../assets/Icons/select.png";
// import add from "../../assets/Icons/addbutton.png";
// import edit from "../../assets/Icons/edit.png";
// import pencil from "../../assets/Icons/pencil.png";
import sheet from "../../assets/Icons/sheets.png";
import exp from "../../assets/Icons/export.png";

import ImportExcel from "../../components/Modal/ImportExcel";
import { useNavigate, useLocation } from "react-router-dom";

import { read, utils, writeFile } from "xlsx";
import { notificationTypes, openNotification } from "../../utils/notifications";

const TestCasePage = () => {
  const navigate = useNavigate();
  const { tcID, scenarioID } = useParams();

  const sessionId = useSelector((state) => state.user.sessionId);

  const [showModal, setShowModal] = useState(false);
  const [tc, setTC] = useState({});
  const [isTestCase, setIsTestCase] = useState(false);
  const [tcSteps, setTCSteps] = useState([]);
  const [tcStepExternalId, setTcStepExternalId] = useState("");
  const [tcStepExternalKey, setTcStepExternalKey] = useState("");
  const [editedSteps, setEditedSteps] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [accountType, setAccountType] = useState("greentick");
  // const [jirKey, setJirKey] = useState("");
  const [testStepsJira, setTestStepsJira] = useState(null);
  const start = useRef();
  const endData = useRef();
  const jirKey = useRef();
  const [totalpages, setTotalpages] = useState();
  const [currentPagee, setCurrentPagee] = useState(1);

  const formSettingData = useSelector((state) => state.user.settings);

  useEffect(() => {
    getTestCase();
    if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
      setAccountType(formSettingData.accountType);
    }
    start.current = 1;
    endData.current = 5;
    jirKey.current = "";
  }, [tcID, scenarioID]);

  const editAllSteps = () => {
    setIsEditing(true);
    const newEditedSteps = {};
    tcSteps.forEach((step) => {
      newEditedSteps[step.step_id] = step;
    });
    setEditedSteps(newEditedSteps);
  };

  const saveAllSteps = () => {
    let successfulUpdates = 0;
    let totalUpdates = 0;

    tcSteps.forEach((step) => {
      const stepID = step.step_id;
      const stepToUpdate = editedSteps[stepID];

      if (JSON.stringify(step) === JSON.stringify(stepToUpdate)) {
        return;
      }

      setTCSteps((prevSteps) =>
        prevSteps.map((step) => (step.step_id === stepID ? stepToUpdate : step))
      );
      totalUpdates++;
      const dataArray = [];

      const data = {
        testCaseID: stepToUpdate.testcase_id,
        stepID,
        stepDescription: stepToUpdate.step_description,
        expectedResult: stepToUpdate.step_expected_result,
        enteredData: stepToUpdate.data,
      };

      dataArray.push(data);

      console.log(dataArray, "console.log(dataArray);");

      PUT(`/updateTestCaseStep?jirKey=${jirKey.current}`, dataArray, {
        // PUT('/updateTestCaseStep', data, {
        sessionID: sessionId,
      })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            successfulUpdates++;
            getTestCaseSteps();
          } else {
            openNotification(notificationTypes.ERROR, "Error");
          }
        })
        .catch((err) => {
          openNotification(notificationTypes.ERROR, "Error");
        })
        .finally(() => {
          if (successfulUpdates === totalUpdates && totalUpdates > 0) {
            openNotification(notificationTypes.SUCCESS, "Success");
          }
        });
      if (totalUpdates === 0) {
        openNotification(notificationTypes.INFO, "No Changes Were Made");
      }
    });

    setIsEditing(false);
    if (totalUpdates === 0) {
      openNotification(notificationTypes.INFO, "No Changes Were Made");
    }
  };

  const handleInputChange = (e, field, stepID) => {
    setEditedSteps((prevEditedSteps) => ({
      ...prevEditedSteps,
      [stepID]: {
        ...prevEditedSteps[stepID],
        [field]: e.target.value,
      },
    }));
  };

  const handleViewTestCase = () => {
    setIsTestCase(!isTestCase);
  };

  useEffect(() => {
    document.title = documentTitles.DASHBOARD;
  }, []);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const getTestCaseSteps = () => {
    console.log("fetching testcase steps");
    GET(`getTestcaseStep?testCaseID=${tcID}`, { sessionID: sessionId })
      .then((res) => {
        if (
          res.data.status === 200 ||
          res.data.status === 304 ||
          res.data.status === "ok"
        ) {
          setTCSteps(res.data.message);
          setTcStepExternalId(res.data.message.external_test_step_Id);
          setTcStepExternalKey(res.data.message.external_jira_id);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error",
            "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          notificationTypes.ERROR,
          "Error",
          "Something went wrong"
        );
      });
  };

  useEffect(() => {
    if (tcSteps.length > 0) {
      postTestStepsjira();
    }
  }, [tcSteps]);

  const postTestStepsjira = async () => {
    const tcStepsData = tcSteps;
    console.log(tcStepsData, " tcStepsData111111111111");
    const teststepsDataJira = [];
    tcStepsData.map((item, index) => {
      teststepsDataJira.push({
        testCaseID: item.testcase_id,
        stepDescription: item.step_description,
        expectedResult: item.step_expected_result,
        enteredData: item.data,
      });
    });
    console.log(teststepsDataJira, "teststepsDataJira22222222222222");
    POST(
      `/postTestcaseStepAfterUpdate?jirakey=${jirKey.current}`,
      teststepsDataJira,
      {
        sessionID: sessionId,
      }
    );
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        let flag = false;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          const updatedData = rows;
          for (let i = 0; i < rows.length; i++) {
            if (
              rows[i].step_id === null ||
              typeof rows[i].step_description === "undefined"
            ) {
              flag = true;
              break;
            }
          }

          for (let i = 0; i < rows.length - 1; i++) {
            if (
              rows[i].step_id === rows[i + 1].step_id &&
              i + 1 !== rows.length - 1
            ) {
              flag = true;
              break;
            }
          }

          if (flag === true) {
            openNotification(
              notificationTypes.ERROR,
              "Error",
              "Some information sent is not valid please upload another file"
            );
          } else {
            console.log(updatedData, "updatedData updatedData");
            //  POST(`/postTestcaseStepViaExcel?testCaseID=${tcID}&jirakey=${jirKey.current}&tcStepExternalId=${tcStepExternalId}`, updatedData, {
            POST(
              `/postTestcaseStepViaExcel?testCaseID=${tcID}&jirakey=${jirKey.current}`,
              updatedData,
              {
                sessionID: sessionId,
              }
            )
              .then(() => {
                openNotification(
                  notificationTypes.SUCCESS,
                  "Success",
                  "File Uploaded Sucessfully"
                );
                // window.location.reload()
                getTestCaseSteps();
              })
              .catch((err) => {
                openNotification(
                  notificationTypes.ERROR,
                  "Error",
                  "Something went wrong"
                );
              });
            setShowModal(false);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = () => {
    const headings = [
      ["step_id", "step_description", "step_expected_result", "data"],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    const outdata = JSON.stringify(tcSteps, [
      "step_id",
      "step_description",
      "step_expected_result",
      "data",
    ]);
    const output = JSON.parse(outdata);
    utils.sheet_add_json(ws, output, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, tc.testcase_name + "_TestCaseSteps.xlsx");
    // Add the activity parameter to the export API call
    GET("/exportToFile?activity=export_testCaseStepDescription", {
      sessionID: sessionId,
    }).then(() => {
      openNotification(
        notificationTypes.SUCCESS,
        "Success",
        "File Exported Sucessfully"
      );
    });
  };

  const getTestCase = () => {
    GET(`getSpecificTestCase?testcaseID=${tcID}&scenarioID=${scenarioID}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (
          res.data.status === 200 ||
          res.data.status === 304 ||
          res.data.status === "ok"
        ) {
          setTC(res.data.message);
          console.log(res.data.message,"res.data.message 337")
          jirKey.current = res.data.message.external_jira_id;
          getTestCaseSteps();
          //  getTestStepsJira();
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error",
            "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          notificationTypes.ERROR,
          "Error",
          "Something went wrong"
        );
      });
  };

  const getTestStepsJira = () => {
    GET(
      `/getteststepsjira?jirKey=${jirKey.current}&start=${start.current}&endData=${endData.current}`,
      {
        sessionID: sessionId,
      }
    )
      .then((res) => {
        if (res.data) {
          console.log(
            res.data.message.values,
            "get response of get teststeps jira"
          );
          const totalpage = Math.ceil(res.data.message.total / 5);
          setTotalpages(totalpage);
          setTestStepsJira(res.data.message.values);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting jira key, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const handlePageClick = (e) => {
    if (currentPagee < totalpages) {
      start.current = start.current + 5;
      console.log(start.current, " start.current  start.current front");
      setCurrentPagee(currentPagee + 1);
      getTestStepsJira(start.current);
    }
  };

  const handlePrevPageClick = (e) => {
    if (currentPagee >= 2) {
      start.current = start.current - 5;
      console.log(start.current, " start.current  start.current back");
      setCurrentPagee(currentPagee - 1);
      getTestStepsJira(start.current);
    }
  };

  const selectedEdit = () => {
    // const editdet = `${tc.name}, ${tc.key}, ${tc.objective}`;
    navigate("/addteststeps", {
      state: {
        message: tc,
      },
    });
  };
  return (
    <>
      <div className="m-2 p-2 grid grid-cols-10 gap-24 bg-customGrey">
        <div className="col-span-10">
          {tc === null ? (
            <h1>Test Case Not Found</h1>
          ) : (
            <div>
              <div>
                <h1 className="font-bold py-1 text-black font-DMSANS text-4xl">
                  Dashboard
                </h1>
                <h1 className="py-2 my-1 text-black font-DMSANS text-xl">
                  Application {">"} Module {">"} Scenario {">"} Test case
                </h1>
                <div>
                  <button
                    onClick={() => navigate("/testcasemanagement")}
                    className="bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded float-right me-5"
                  >
                    Back
                  </button>
                </div>
                {isTestCase === false ? (
                  <div>
                    <div>
                      <p className="py-2 my-2 font-DMSANS font-bold text-3xl leading-none">
                        <span className="flex items-center">
                          Test Case
                          <img
                            onClick={handleViewTestCase}
                            className="h-inherit mx-2 px-1"
                            src={arrow}
                            alt=""
                          />
                        </span>
                      </p>
                    </div>
                    <div className="h-20 my-2 py-1 bg-white rounded-xl">
                      <div className="flex items-center">
                        <img
                          className="m-1 p-2"
                          src={name}
                          alt="testCaseName"
                        />
                        <div className="mx-2 flex flex-col">
                          <h1 className="mb-0 font-DMSANS text-customGreyTwo text-base">
                            {" "}
                            Name &nbsp;
                          </h1>
                          <h1 className="my-1 py-1 font-DMSANS font-bold text-base xl:text-xl">
                            {tc.testcase_name}
                          </h1>
                        </div>
                        {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-2 h-20 mb-3 bg-white rounded-xl">
                        <div className="flex">
                          <img className="m-3" src={ID} alt="id" />
                          <div className="mt-1 ml-3 flex flex-col">
                            <h1 className="mb-0 font-DMSANS text-customGreyTwo text-base">
                              ID &nbsp;
                            </h1>
                            <h1 className="mb-0 pt-3 font-DMSANS font-bold text-base xl:text-xl">
                              {tc.testCase_id}
                            </h1>
                          </div>
                          {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                        </div>
                      </div>
                      <div className="col-span-1 h-20 mb-3 bg-white rounded-xl">
                        <div className="flex">
                          <img className="m-3" src={date} alt="status" />
                          <div className="mt-1 ml-3 flex flex-col">
                            <h1 className="mb-0 font-DMSANS text-customGreyTwo text-base">
                              {" "}
                              Date
                            </h1>
                            <h1 className="mb-0 pt-3 font-DMSANS font-bold text-base xl:text-xl">
                              {formatDate(new Date(tc.testcase_date))}
                            </h1>
                          </div>
                          {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                        </div>
                      </div>
                      <div className="col-span-1 h-20 mb-3 bg-customGreenTwo rounded-xl">
                        <div className="flex">
                          <div className="m-3">
                            <img src={status} alt="status" />
                          </div>
                          <div className="col-span-2">
                            <h1 className="mb-0 font-DMSANS text-white text-base">
                              {" "}
                              Status
                            </h1>
                            <h1 className="mb-0 pt-3 font-DMSANS font-bold text-base xl:text-xl">
                              {tc.testcase_status}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <div className="h-full w-full bg-white rounded-xl">
                        <div className="flex">
                          <h1 className="pt-3 font-DMSANS text-customGreyTwo text-base ml-5">
                            Description &nbsp;
                          </h1>
                          {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                        </div>
                        <h1 className="pb-3 font-bold font-DMSANS text-base xl:text-xl ml-5">
                          {tc.testcase_description}
                        </h1>
                      </div>
                    </div>
                    {accountType === "jira" ? (
                      <>
                        <div className="flex">
                          <div className="grid grid-cols-2 me-3 gap-1">
                            <div className="col-span-2 h-20 mb-3 bg-white rounded-xl">
                              <div className="flex">
                                <h1 className="pt-3 font-DMSANS text-customGreyTwo text-base ml-5">
                                  External TestCase Key &nbsp;
                                </h1>
                                {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                              </div>
                              <h1 className="pb-3 font-bold font-DMSANS text-base xl:text-xl ml-5">
                                {tc.external_jira_id}
                              </h1>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-1">
                            <div className="col-span-3 h-20 mb-3 pe-3 bg-white rounded-xl">
                              <div className="flex">
                                <h1 className="pt-3 font-DMSANS text-customGreyTwo text-base ml-5">
                                  Priority &nbsp;
                                </h1>
                                {/* <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' /> */}
                              </div>
                              <h1 className="pb-3 font-bold font-DMSANS text-base xl:text-xl ml-5">
                                {tc.testcase_priority}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <h1
                    onClick={handleViewTestCase}
                    className="py-3 mb-4 font-DMSANS font-bold text-base xl:text-3xl leading-none lg:leading-11"
                  >
                    <span className="flex">
                      Test Case&nbsp;{" "}
                      <img className="h-8" src={arrow1} alt="" />
                    </span>
                  </h1>
                )}
              </div>
              <div
                style={{ paddingTop: isTestCase === true ? "0px" : "40px" }}
                className="grid grid-cols-2 gap-4"
              >
                <div className="flex">
                  <button
                    onClick={() => setShowModal(true)}
                    className="flex py-2 my-4 hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6 mr-2"
                  >
                    <img className="h-6" src={sheet} alt="" /> &nbsp; Import
                    from excel
                  </button>
                  <button
                    onClick={handleExport}
                    className="flex py-2 my-4 hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6"
                  >
                    <img className="h-5" src={exp} alt="export" /> &nbsp; Export
                    to excel
                  </button>
                  {/* <button
                    onClick={() => selectedEdit(tc)}
                    style={{ height: "50px", width: "200px" }}
                    class="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded ms-2 mt-3"
                  >
                    Add TestSteps
                  </button> */}
                </div>
                {/* <div className='mt-3 flex justify-end'>
                        <button disabled className='mx-1'>
                          <div className='opacity-40 border-2 border-gray-300 bg-gray-300 p-2 rounded-xl focus:outline-none'>
                            <img className='h-4' src={edit} alt='edit' />
                          </div>
                        </button>
                        <button className='mx-1'>
                          <div className='opacity-40 border-2 border-gray-300 bg-gray-300 p-2 rounded-xl focus:outline-none'>
                            <img className='h-4' src={copy} alt='copy' />
                          </div>
                        </button>
                        <button className='mx-1'>
                          <div className='opacity-40 border-2 border-gray-300 bg-gray-300 p-2 rounded-xl focus:outline-none'>
                            <img className='h-4' src={del} alt='delete' />
                          </div>
                        </button>
                        <button className='mx-1'>
                          <div className='opacity-40 border-2 border-gray-300 bg-gray-300 p-2 rounded-xl focus:outline-none'>
                            <img className='h-4' src={reorder} alt='reorder' />
                          </div>
                        </button>
                        <button className='mx-1'>
                          <div className='border-2 bg-customBlueTwo hover:bg-customBlueFour border-black py-2 px-3 rounded-lg'>
                            <img className='h-4' src={select} alt='select' />
                          </div>
                        </button>
                      </div> */}
              </div>
              {tcSteps === null ? (
                <h1>Loading</h1>
              ) : tcSteps.length > 0 ? (
                <div className="pt-3 pb-2 h-full">
                  <div className="mb-0 pt-3 text-gray-600 px-4 pb-4">
                    {isEditing ? (
                      <button
                        className="bg-customGreen text-customGrey px-4 py-1  rounded-lg text-base"
                        onClick={saveAllSteps}
                      >
                        Save Test Case Details
                      </button>
                    ) : (
                      <button
                        className="bg-customBlue text-customGrey px-4 py-1 rounded-lg text-base"
                        onClick={editAllSteps}
                      >
                        Edit Test Case Steps
                      </button>
                    )}
                  </div>
                  <div className="w-full bg-customWhite p-4 border-2 border-customGreyThree rounded-3xl">
                    <div>
                      <table className="w-full table-auto mx-1 border-1 rounded-3xl border-customGreyThree">
                        <thead className="text-center bg-customGrey rounded-2xl">
                          <tr>
                            <th
                              scope="col"
                              className="text-base font-medium text-gray-900 px-2 py-1 text-left min-w-[100px]"
                            >
                              Step ID
                            </th>
                            <th
                              scope="col"
                              className="text-base font-medium text-gray-900 px-2 py-1 text-left break-words min-w-[300px]"
                            >
                              Description
                            </th>
                            <th
                              scope="col"
                              className="text-base font-medium text-gray-900 px-2 py-1 text-left break-words min-w-[200px]"
                            >
                              Expected Output
                            </th>
                            <th
                              scope="col"
                              className="text-base font-medium text-gray-900 px-2 py-1 text-left break-words min-w-[200px]"
                            >
                              Data
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tcSteps
                            .sort((a, b) => a.step_id - b.step_id)
                            .map((step) => {
                              return (
                                <tr className="border-b border-1 border-customGreyThree">
                                  <td className="text-base text-gray-900 font-light px-2 py-1 break-words ">
                                    {step.step_id}
                                  </td>
                                  <td className="text-base text-gray-900 font-light px-2 py-1 break-words ">
                                    {isEditing ? (
                                      <textarea
                                        type="text"
                                        rows="4"
                                        placeHolder="Enter Step Description"
                                        className="border-2 w-full resize-none"
                                        defaultValue={step.step_description}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "step_description",
                                            step.step_id
                                          )
                                        }
                                      />
                                    ) : (
                                      step.step_description
                                    )}
                                  </td>
                                  <td className="overflow-clip text-base text-gray-900 font-light px-2 py-1 break-words ">
                                    {isEditing ? (
                                      <textarea
                                        type="text"
                                        rows="4"
                                        placeHolder="Enter Expected Output"
                                        className="border-2 w-full resize-none"
                                        defaultValue={step.step_expected_result}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "step_expected_result",
                                            step.step_id
                                          )
                                        }
                                      />
                                    ) : (
                                      step.step_expected_result
                                    )}
                                  </td>
                                  <td className="overflow-clip text-base text-gray-900 font-light px-2 py-1 break-words ">
                                    {isEditing ? (
                                      <textarea
                                        type="text"
                                        rows="4"
                                        placeHolder="Enter Step Data"
                                        className="border-2 w-full resize-none"
                                        defaultValue={step.data}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "data",
                                            step.step_id
                                          )
                                        }
                                      />
                                    ) : (
                                      step.data
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-5 py-2 min:h-2/5">
                  <div>
                    <h1 className="py-3 mb-4 text-customGreyTwo font-DMSANS text-base xl:text-xl ml-5 leading-none lg:leading-11">
                      No Test case steps
                    </h1>
                  </div>
                </div>
              )}
              {/* {accountType == "jira" ? (
              <>
                <div className='grid grid-cols-10 gap-1 m-2 p-2'>
                  <div className='col-span-10'>
                    <div className='flex justify-between items-center'>
                      <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
                        Test Steps
                      </h1>
                    </div>
                    <div>
                    </div>
                    <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl bg-customWhite '>
                      <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
                        <thead className='text-center bg-customGrey rounded-2xl'>
                          <tr>
                            <th
                              scope='col'
                              className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                            >
                              description
                            </th>
                            <th
                              scope='col'
                              className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                            >
                              expectedResult
                            </th>
                            <th
                              scope='col'
                              className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                            >
                              testData
                            </th>

                          </tr>
                        </thead>
                        <tbody>
                          {!testStepsJira || testStepsJira.length <= 0 ? (
                            <tr>
                              <td />
                              <td className="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                {testStepsJira ? "No TestSteps to display" : "Loading..."}
                              </td>
                            </tr>
                          ) : (
                            testStepsJira.map((obj) => {
                              return (
                                <tr class='border-b border-1 border-customGreyThree'>
                                  <td class='text-base text-gray-600 font-medium px-8 py-1'
                                  >
                                    <p className='break-words'
                                      style={{ width: "300px" }}
                                    >{obj.inline.description}</p>
                                  </td>
                                  <td
                                    class='text-base text-gray-600 font-medium px-8 py-1'

                                  >
                                    <p className='break-words'
                                      style={{ width: "300px" }}
                                    >
                                      {obj.inline.expectedResult}
                                    </p>
                                  </td>
                                  <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                    <p className='break-words' style={{ width: "300px" }}>
                                      {obj.inline.testData}
                                    </p>
                                  </td>

                                </tr>
                              );
                            })
                          )
                          }
                        </tbody>
                      </table>
                      <div className="flex justify-center mt-4 float-right me-5">
                        <button
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mx-1 rounded"
                          onClick={() => handlePrevPageClick()}
                        >
                          Previous
                        </button>
                        <span className="mx-2">
                          Page {currentPagee} of {totalpages}
                        </span>
                        <button
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mx-1 rounded"
                          onClick={() => handlePageClick()}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>) : ("")} */}
            </div>
          )}
          {/* <button className='float-right border-2 mx-2 py-2 border-black hover:bg-customBlueFour rounded-xl'>
              <img className='px-4 h-5' src={add} alt='add' />
            </button> */}
        </div>
      </div>
      <ImportExcel
        showModal={showModal}
        setShowModal={setShowModal}
        ImportExcel={handleImport}
      />
    </>
  );
};

export default TestCasePage;
