import { useState, useEffect, useRef } from "react";
import { documentTitles } from "../../constants/appConstants";
import { useSelector } from "react-redux";
import pencil from "../../assets/Icons/pencil.png";
import { POST, GET } from "../../config/api";
import { notificationTypes, openNotification } from "../../utils/notifications";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import { Buffer } from "buffer";

const AddTestSteps = () => {
  const location = useLocation();
  const inputRef = useRef(null);
  const sessionId = useSelector((state) => state.user.sessionId);
  // console.log(sessionId,"sessionId sessionId")
  const dataArray = location.state.message;

  console.log(dataArray, "dataArray vdataArray");
  console.log(dataArray.external_jira_id, "dataArray vdataArray");
  const formSettingData = useSelector((state) => state.user.settings);

  const [testCaseKey, setTestCaseKey] = useState(dataArray.external_jira_id);
  const [title, setTitle] = useState(dataArray.testcase_name);
  const [createdDate, setCreatedDate] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState(
    dataArray.testcase_description
  );
  const [status, setStatus] = useState("");
  const [teststepDescription, setTeststepDescription] = useState("");
  const [testData, setTestData] = useState("");
  const [expectedResult, setExpectedResult] = useState("");

  const [testCaseKeys, setTestCaseKeys] = useState();

  const [formData, setFormData] = useState([]);
  const [accountType, setAccountType] = useState("greentick");

  const [fileData, setFileData] = useState("");

  const navigate = useNavigate();

  const [jsonB, setJSONB] = useState([]);

  useEffect(() => {
    getTestCasesJiraa();

    document.title = documentTitles.RELEASEPLANNER;

    if (Object.keys(formSettingData).length > 0) {
      const form =
        formSettingData &&
        formSettingData.defectsConfiguration &&
        formSettingData.defectsConfiguration;
      setFormData(form.length > 0 ? form : []);

      if (formSettingData.accountType === "jira") {
        setAccountType(formSettingData.accountType);
      }
    }
  }, []);

  const postRelease = () => {
    const data = {
      title,
      testCaseKey,
      description,
      priority,
      createdDate,
      status,
      teststepDescription,
      testData,
      expectedResult,
    };
    console.log(data, "data data");
    if (title === "" || priority === "") {
      openNotification(
        notificationTypes.ERROR,
        "Error: 80210",
        "Please fill all the fields"
      );
      return;
    }

    if (status === "") {
      openNotification(
        notificationTypes.ERROR,
        "Error: 80220",
        "Please select a status"
      );
      return;
    }

    POST("/teststeps", data, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            "Test step created Successfully"
          );
          navigate("/testcasemanagement");
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error while creating Defects, Please try again later"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error while creating Defects, Please try again later"
        );
      });
  };

  const handleFormJSONData = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let found = false;
    if (jsonB.length > 0) {
      const newState = jsonB.map((obj) => {
        if (obj.name === name) {
          found = true;
          return { ...obj, value: value };
        }
        return obj;
      });
      setJSONB(newState);
    }

    let arr = [...jsonB];
    if (!found) {
      arr.push({
        name: name,
        value: value,
      });

      setJSONB(arr);
    }
  };

  const getTestCasesJiraa = async () => {
    console.log("get API called");
    GET("/gettestcasesjira", {
      sessionID: sessionId,
    })
      .then((res) => {
        //  console.log(res, "get response 123");
        if (res) {
          console.log(res.data.message.values, "get response 123");

          const keys = res.data.message.values.map((testCase) => testCase.key);
          setTestCaseKeys(keys);
          console.log("All Keys:", keys);
        } else {
          openNotification(
            console.log("Error in getting jira key, Please try again"),
            notificationTypes.ERROR,
            "Error in getting jira key, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  return (
    <div className="bg-customGrey p-3">
      <div className="grid grid-cols-10 gap-x-4 gap-y-2">
        <div className="col-span-6">
          <h1 className="text-5xl ml-2 font-bold font-DMSANS">Add Teststeps</h1>
        </div>
        <div className="col-span-3" />

        <div className="col-span-10 me-3 mt-5 flex justify-end">
          <button
            onClick={() => navigate("/defects")}
            className="bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
          >
            Back
          </button>
          <button
            className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
            type="button"
            onClick={postRelease}
          >
            Save
          </button>
        </div>

        <div className="col-span-10 mt-5 bg-gray-200 p-4 rounded-xl">
          <div className="bg-white p-3 rounded-xl">
            <div className="grid grid-col-10 gap-x-4 gap-y-2">
              {/* <div className="col-span-10 ">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Defect
                </div>
                <h1 className="text-black">
                  <input
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={defect}
                    required
                    onChange={(e) => setDefect(e.target.value)}
                  />
                </h1>
              </div> */}
              <div className="col-span-10">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Title
                </div>
                <h1 className="text-black">
                  <input
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={title}
                    required
                    // onChange={(e) => setTitle(e.target.value)}
                  />
                </h1>
              </div>
              <div className="col-span-2">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  TestCase Key &nbsp;
                </div>
                <h1 className="text-black">
                  <input
                    type="input"
                    className="p-2 w-full border-2 rounded-lg"
                    value={testCaseKey}
                  />
                </h1>
              </div>

              <div className="col-span-2">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Created Date &nbsp;
                </div>
                <h1 className="text-black">
                  <input
                    type="date"
                    className="p-2 w-full border-2 rounded-lg"
                    value={createdDate}
                    onChange={(e) => setCreatedDate(e.target.value)}
                  />
                </h1>
              </div>

              <div className="col-span-2">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Status &nbsp;
                </div>
                <h1 className="text-black">
                  <select
                    className="p-2 w-full border-2 rounded-lg"
                    name="overallStatus"
                    id="overallStatus"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option>select</option>
                    <option>New</option>
                    <option>In-Progress</option>
                    <option>Completed</option>
                  </select>
                </h1>
              </div>
              <div className="col-span-3">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  priority &nbsp;
                </div>
                <h1 className="text-black">
                  <select
                    className="p-2 w-full border-2 rounded-lg"
                    name="overallStatus"
                    id="overallStatus"
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    <option>select</option>
                    <option>High</option>
                    <option>medium</option>
                    <option>Low</option>
                  </select>
                </h1>
              </div>

              <div className="col-span-10">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Test Case Description &nbsp;
                </div>
                <h1 className="text-black">
                  <textarea
                    value={description}
                    //  onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter Description"
                    className="p-2 w-full border-2 rounded-lg"
                  />
                </h1>
              </div>
              <div className="col-span-10">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Test Step Description &nbsp;
                </div>
                <h1 className="text-black">
                  <textarea
                    value={teststepDescription}
                    placeholder="Enter Description"
                    className="p-2 w-full border-2 rounded-lg"
                    onChange={(e) => setTeststepDescription(e.target.value)}
                  />
                </h1>
              </div>
              <div className="col-span-10">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Test Data &nbsp;
                </div>
                <h1 className="text-black">
                  <textarea
                    value={testData}
                    placeholder="Enter Description"
                    className="p-2 w-full border-2 rounded-lg"
                    onChange={(e) => setTestData(e.target.value)}
                  />
                </h1>
              </div>
              <div className="col-span-10">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Expected Result &nbsp;
                </div>
                <h1 className="text-black">
                  <textarea
                    value={expectedResult}
                    placeholder="Enter Description"
                    className="p-2 w-full border-2 rounded-lg"
                    onChange={(e) => setExpectedResult(e.target.value)}
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestSteps;
