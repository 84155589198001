import { GET, DELETE } from '../../../../../config/api'
import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  notificationTypes,
  openNotification
} from '../../../../../utils/notifications'
import Scenario from './Scenario'
import ContextMenu from '../../../../ContextMenu'
import ScenarioModal from '../../../../Modal/ScenarioModal'
import UpdateModuleModal from '../../../../Modal/updateModuleModal'
import Arrow from '../../../../../assets/Icons/arrow.png'
import Arrow1 from '../../../../../assets/Icons/arrowright.png'
import User from '../../../../../assets/Icons/module.png'
import rightclick from '../../../../../assets/Icons/threedots.png'

function Module (props) {
  const navigate = useNavigate()
  const contRef = useRef(null)

  const sessionId = useSelector((state) => state.user.sessionId)

  const [schedule, setSchedule] = useState({})
  const [isSchedule, setIsSchedule] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const addScenario = () => {
    setIsModal(true)
  }

  const modifyModule = () => {
    setIsUpdateModal(true)
  }

  const viewModule = () => {
    navigate(`/module/${props.item.id}`)
  }

  const deleteModule = () => {
    DELETE(`/deleteModule?moduleID=${props.item.id}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Module deleted successfully'
          )
          // window.location.reload()
          GET(`/getModules?appID=${props.applicationID}`, { sessionID: sessionId })
            .then((res) => {
              if (res.data.status === 200 || res.data.status === 304) {
                props.setModule(res.data.message)
              } else {
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              }
            }
            )
            .catch((err) => {
              openNotification(
                notificationTypes.ERROR,
                'Something went wrong, please try again later'
              )
            })
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  const menuItems = [
    {
      id: 1,
      text: 'Add Scenario',
      handleFunction: addScenario
    },
    {
      id: 2,
      text: 'View Module',
      handleFunction: viewModule
    },
    {
      id: 3,
      text: 'Modify Module',
      handleFunction: modifyModule
    },
    {
      id: 4,
      text: 'Delete Module',
      handleFunction: deleteModule
    }
  ]

  const handleModScenario = (moduleId) => {
    GET(`/getScenario?moduleID=${moduleId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setSchedule(res.data.message)
        } else {
          // console.log(res)
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
    setIsSchedule(!isSchedule)
  }

  return (
    <>
      <div
        className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-2 leading-none lg:leading-11'
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
      >
        <span class='flex hover:bg-customBlueFour items-center p-1 font-normal text-black rounded-lg' ref={contRef} onClick={() => handleModScenario(props.item.id)}>
          {
            isSchedule
              ? (
                <img src={Arrow} className='ml-1 mr-2' alt='Arrow' />
                )
              : (
                <img src={Arrow1} className='ml-3 mr-2' alt='Arrow' />
                )
          }
          <img src={User} className='h-5 mr-2' alt='User' />
          {props.item.modelName}
          <img
            style={{ visibility: isHover ? 'visible' : 'hidden' }}
            src={rightclick}
            className='ml-auto'
            alt='Dashboard'
          />
        </span>
        <ContextMenu
          parentRef={contRef}
          items={menuItems}
        />
      </div>
      {
        isSchedule && (
          schedule === null
            ? (
              <h1 className='text-black py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>Loading...</h1>
              )
            : (
                schedule.length > 0
                  ? (
                      schedule.map((item, index) => (
                        <Scenario key={index} applicationID={props.appID} moduleID={props.item.id} item={item} setSchedule={setSchedule} />
                      ))
                    )
                  : (
                    <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>No scenarios found</h1>
                    )
              )
        )
      }
      <ScenarioModal
        showModal={isModal}
        setShowModal={setIsModal}
        moduleID={props.item.id}
        applicationID={props.appID}
        schedule={schedule}
        setSchedule={setSchedule}
      />
      <UpdateModuleModal
        showModal={isUpdateModal}
        setShowModal={setIsUpdateModal}
        moduleID={props.item.id}
      />
    </>
  )
}

export default Module
