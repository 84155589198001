import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { POST } from '../../../config/api'
import { notificationTypes, openNotification } from '../../../utils/notifications'
import { routes } from '../../../constants/routes'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { token } = useParams()
  const navigate = useNavigate()

  const resetPassword = async (event) => {
    event.preventDefault()
    try {
      await POST(`/resetpassword/${token}`, {
        password,
        confirmpassword: confirmPassword
      })
      openNotification(
        notificationTypes.SUCCESS,
        'Password Updated Successfully'
      )
      setTimeout(() => {
        navigate(routes.SIGN_IN)
      }, 500)
    } catch (err) {
      openNotification(
        notificationTypes.ERROR,
        "Passwords doesn't match",
        'check once again'
      )
    };
  }

  return (
    <div>
      <form className='rounded px-2 lg:px-8 pt-6 pb-8 mb-4' onSubmit={resetPassword}>
        <div className='mb-4'>
          <label className='ml-2 block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
            Password
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            type='password'
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='mb-4'>
          <label className='ml-2 block text-gray-700 text-sm font-bold mb-2' htmlFor='confirmPassword'>
            Confirm Password
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            type='password'
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div>
          <button
            className='w-full bg-customGreenFour hover:bg-green-300 border-2 hover:border-black text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
            type='submit'
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
