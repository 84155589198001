const Test = (props) => {
  return (
    <>
      <div className='ml-12 pt-2 font-DMSANS text-sm xl:text-2xl font-medium leading-none lg:leading-11'>
        {
            typeof props.testcases === 'undefined'
              ? (
                  null
                )
              : (
                  props.testcases.length > 0
                    ? (
                        props.testcases.includes(props.tests.id) === true
                          ? (
                            <span>
                              <i class='fa-solid fa-angle-right' />&nbsp; &nbsp; {props.tests.testcase_name}
                            </span>
                            )
                          : null
                      )
                    : (
                        null
                      )
                )
          }
      </div>
    </>
  )
}

export default Test
