import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import TestRunSelect from '../../../components/InputComponents/SingleSelectComponent/TestRun'
import { useEffect, useState } from 'react'
import { GET } from '../../../config/api'
import { useSelector } from 'react-redux'
import {
  openNotification,
  notificationTypes
} from '../../../utils/notifications'

const TestCaseData = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [data, setData] = useState(props.id)
  const [pass, setPass] = useState(0)
  const [fail, setFail] = useState(0)
  const [onHold, setOnHold] = useState(0)
  const [progress, setProgress] = useState(0)
  const [noresult, setNoresult] = useState(0)

  const getTC = () => {
    GET(`/gettestcasedata?id=${data}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setPass(res.data.message.filter((tc) => tc.status === 'Pass').length)
          setFail(res.data.message.filter((tc) => tc.status === 'Fail').length)
          setProgress(
            res.data.message.filter((tc) => tc.status === 'In-Progress').length
          )
          setOnHold(
            res.data.message.filter(
              (tc) =>
                tc.status === 'On Hold' ||
                tc.status === 'On hold' ||
                tc.status === 'On Hold '
            ).length
          )
          setNoresult(
            res.data.message.length -
              (res.data.message.filter((tc) => tc.status === 'Pass').length +
                res.data.message.filter(
                  (tc) =>
                    tc.status === 'On Hold' ||
                    tc.status === 'On hold' ||
                    tc.status === 'On Hold '
                ).length +
                res.data.message.filter((tc) => tc.status === 'Fail').length +
                res.data.message.filter((tc) => tc.status === 'In-Progress')
                  .length)
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getTC()
  }, [data, pass, fail, noresult])

  const Testdata = {
    labels: ['No Run', 'Fail', 'Pass', 'On Hold', 'In-Progress'],
    datasets: [
      {
        data: [noresult, fail, pass, onHold, progress],
        backgroundColor: [
          'rgb(192,192,192)',
          'rgb(199, 40, 22)',
          'rgb(64, 168, 123)',
          'rgb(252, 132, 3)',
          'rgb(255,204,0)'
        ],
        borderColor: [
          'rgb(192,192,192)',
          'rgb(199, 40, 22)',
          'rgb(64, 168, 123)',
          'rgb(252, 132, 3)',
          'rgb(255,204,0)'
        ],
        borderWidth: 2
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        rtl: false,
        labels: {
          // usePointStyle: true,
          pointStyle: 'rect',
          padding: 5,
          font: {
            size: 12,
            color: 'black',
            family: 'DM Sans',
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0 // or >= 1 or ...
        },
        labels: {
          value: {
            color: 'white'
          }
        }
      }
    }
  }

  return (
    <div className='h-full col-span-3'>
      <div className='h-full p-4 mb-10 bg-white rounded-xl'>
        <h1 className='text-3xl font-bold font-DMSANS text-center mb-4'>
          Test Runs
        </h1>
        <div className='mb-4'>
          <TestRunSelect
            value={data}
            onChangeHandler={(e) => setData(e.target.value)}
            options={props.testRun}
            label=''
          />
        </div>

        {pass === null && fail === null && noresult === null
          ? null
          : (
            <>
              <h1 className='text-xl font-DMSANS text-center mb-2'>
                Total:&nbsp;{noresult + fail + pass + onHold + progress}
              </h1>
              <Doughnut
                data={Testdata}
                plugins={[ChartDataLabels]}
                options={options}
              />
            </>
            )}
      </div>
    </div>
  )
}

export default TestCaseData
