import { useNavigate } from 'react-router-dom'
import exp from '../../../assets/Icons/export.png'
import { useState } from 'react'
import { GET, POST } from '../../../config/api'
import { useSelector } from 'react-redux'
import { read, utils, writeFile } from 'xlsx'
import sheet from '../../../assets/Icons/sheets.png'
import {
  openNotification,
  notificationTypes
} from '../../../utils/notifications'

import ImportExcelReport from '../../../components/Modal/ImportExcelReport'

const TableItem = (props) => {
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [showModal, setShowModal] = useState(false)

  const handleImport = ($event) => {
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        let flag = false

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          const updatedData = rows

          for (let i = 0; i < rows.length; i++) {
            if (
              typeof rows[i].status === 'undefined' ||
              rows[i].status === null
            ) {
              flag = true
            }
          }

          if (flag == true) {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              'Some information sent is not valid please upload another file'
            )
          } else {
            console.log(updatedData,props.testRunID, props.tc.id,"props.tc.id")
            POST(
              `/importexceltestruncasestep?testRunId=${props.testRunID}&testCaseId=${props.tc.id}`,
              updatedData,
              { sessionID: sessionId }
            )
              .then((res) => {
                if (
                  res.data.status === 200 ||
                  res.data.status === 304 ||
                  res.data.status === 'ok'
                ) {
                  openNotification(notificationTypes.SUCCESS, 'Success')
                  window.location.reload()
                } else {
                  openNotification(
                    notificationTypes.ERROR,
                    'Please check your data. It is not in the correct format.'
                  )
                  window.location.reload()
                }
              })
              .catch((err) => {
                openNotification(
                  notificationTypes.ERROR,
                  'Please check your data. It is not in the correct format.'
                )
              })
            setShowModal(!showModal)
            openNotification(
              notificationTypes.SUCCESS,
              'Sucess',
              'File Uploaded Sucessfully'
            )
          }
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const handleExport = () => {
    GET(`/getteststeps?id=${props.tc.id}`, { sessionID: sessionId }).then(
      (res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          const headings = [
            [
              'stepNumber',
              'stepDescription',
              'expectedResult',
              'data',
              'actualResult',
              'comments',
              'status',
              'defect'
            ]
          ]
          const wb = utils.book_new()
          const ws = utils.json_to_sheet([])
          utils.sheet_add_aoa(ws, headings)
          const outdata = JSON.stringify(res.data.message, [
            'stepNumber',
            'stepDescription',
            'expectedResult',
            'data',
            'actualResult',
            'comments',
            'status',
            'defect'
          ])
          const output = JSON.parse(outdata)
          utils.sheet_add_json(ws, output, { origin: 'A2', skipHeader: true })
          utils.book_append_sheet(wb, ws, 'Report')
          writeFile(wb, props.tc.testcaseName + '_' + 'TestCaseSteps.xlsx')
          GET('/exportToFile?activity=export_testCaseToExcel', {
            sessionID: sessionId
          }).then(() => {
            openNotification(
              notificationTypes.SUCCESS,
              'Success',
              'File downloaded Sucessfully'
            )
          })
        } else {
          openNotification(notificationTypes.ERROR, 'error')
        }
      }
    )
  }

  return (
    <tr class='border-b border-1 border-customGreyThree'>
      <td class='text-base text-gray-600 font-medium px-2 py-2'>
        {props.tc.applicationName} {'>'} {props.tc.moduleName} {'>'}{' '}
        {props.tc.scenarioName}
      </td>
      <td class='text-base text-green-600 underline underline-offset-4 font-medium px-2 py-2'>
        <a
          className='text-green-600'
          href={`/viewmanualrunreport/${props.tc.testRunID}/${props.tc.id}/${props.tc.scenarioID}/${props.tc.testcaseID}`}
          target='_blank' rel='noreferrer'
        >
          {props.tc.testcaseName}
        </a>
      </td>
      <td class='text-base text-black font-medium p-2 '>
        {props.tc.testcaseDescription}
      </td>
      <td class='text-base text-black font-medium p-2'>{props.tc.status}</td>
      <td>
        <button
          onClick={handleExport}
          className='ml-5 flex hover:bg-customBlueFour rounded-xl text-black font-bold font-DMSANS text-base border-2 border-black p-1'
          title='Export to Excel'
        >
          <img className='h-5' src={exp} alt='export' />
        </button>
      </td>
      <td>
        <button
          onClick={() => setShowModal(true)}
          className='flex hover:bg-customBlueFour rounded-xl text-black font-bold font-DMSANS text-base border-2 border-black p-1'
          title='Import from Excel'
        >
          <img className='h-6' src={sheet} alt='sheet' />
        </button>
      </td>
      <ImportExcelReport
        showModal={showModal}
        setShowModal={setShowModal}
        ImportExcel={handleImport}
      />
    </tr>
  )
}

export default TableItem
