import ChartDataLabels from 'chartjs-plugin-datalabels'
import TestRunSelect from '../../../components/InputComponents/SingleSelectComponent/TestRun'
import { useEffect, useState } from 'react'
import { GET } from '../../../config/api'
import { useSelector } from 'react-redux'
import {
  openNotification,
  notificationTypes
} from '../../../utils/notifications'
import { Pie } from 'react-chartjs-2'
import { BarLoader } from 'react-spinners'

const TestCaseData = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [selectedTestRunId, setSelectedTestRunId] = useState(props.id)
  const [pass, setPass] = useState(0)
  const [fail, setFail] = useState(0)
  const [onHold, setOnHold] = useState(0)
  const [progress, setProgress] = useState(0)
  const [noresult, setNoresult] = useState(0)
  const [filteredTC, setFilteredTC] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const allStatus = ['No Run', 'Fail', 'Pass', 'On Hold', 'In-Progress']

  const getTC = () => {
    setIsLoading(true)

    GET(`/gettestcasedata?id=${selectedTestRunId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setPass(res.data.message.filter((tc) => tc.status === 'Pass').length)
          setFail(res.data.message.filter((tc) => tc.status === 'Fail').length)
          setProgress(
            res.data.message.filter((tc) => tc.status === 'In-Progress').length
          )
          setOnHold(
            res.data.message.filter(
              (tc) =>
                tc.status === 'On Hold' ||
                tc.status === 'On hold' ||
                tc.status === 'On Hold '
            ).length
          )
          setNoresult(
            res.data.message.length -
              (res.data.message.filter((tc) => tc.status === 'Pass').length +
                res.data.message.filter(
                  (tc) =>
                    tc.status === 'On Hold' ||
                    tc.status === 'On hold' ||
                    tc.status === 'On Hold '
                ).length +
                res.data.message.filter((tc) => tc.status === 'Fail').length +
                res.data.message.filter((tc) => tc.status === 'In-Progress')
                  .length)
          )
          setFilteredTC(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const calculateCount = (status) => {
    if (status === 'Pass') {
      return pass
    } else if (status === 'Fail') {
      return fail
    } else if (status === 'In-Progress') {
      return progress
    } else if (status === 'On Hold' || status === 'On hold' || status === 'On Hold ') {
      return onHold
    } else if (status === 'No Run') {
      return noresult
    }

    return 0
  }
  const getStatusColor = (status) => {
    switch (status) {
      case 'No Run':
        return 'rgb(192, 192, 192)'
      case 'Fail':
        return 'rgb(199, 40, 22)'
      case 'Pass':
        return 'rgb(64, 168, 123)'
      case 'On Hold':
        return 'rgb(252, 132, 3)'
      case 'In-Progress':
        return 'rgb(255, 204, 0)'
      default:
        return 'gray'
    }
  }

  useEffect(() => {
    getTC()
  }, [selectedTestRunId])

  const Testdata = {
    labels: allStatus.map((status) => `${status} - ${calculateCount(status)}`),
    datasets: [
      {
        data: [noresult, fail, pass, onHold, progress],
        backgroundColor: [
          'rgb(192,192,192)',
          'rgb(199, 40, 22)',
          'rgb(64, 168, 123)',
          'rgb(252, 132, 3)',
          'rgb(255,204,0)'
        ],
        borderColor: [
          'rgb(192,192,192)',
          'rgb(199, 40, 22)',
          'rgb(64, 168, 123)',
          'rgb(252, 132, 3)',
          'rgb(255,204,0)'
        ],
        borderWidth: 2
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        rtl: false,
        labels: {
          pointStyle: 'rect',
          padding: 10,
          font: {
            size: 12,
            color: 'black',
            family: 'DM Sans',
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0
        },
        formatter: (value, context) => {
          const percentage = ((value / totalTestCases) * 100).toFixed(0) + '%'
          return `${value} (${percentage})`
        },
        anchor: 'end',
        align: 'start',
        offset: 10,
        labels: {
          value: {
            color: 'white',
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        }
      }
    }
  }
  const totalTestCases = noresult + fail + pass + onHold + progress
  const uniqueTestRunNames = [...new Set(filteredTC.map((tc) => tc.name))]
  return (
    <div>
      <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
        Test Run Status Distribution for: {uniqueTestRunNames}
      </h1>
      <div className='flex gap-4 grid grid-cols-9'>
        <div className='h-full col-span-4'>
          <div className='p-2 mb-2 bg-white rounded-xl'>
            <h1 className='text-xl font-bold font-DMSANS text-center'>
              Test Runs
            </h1>
            <div className='mb-4'>
              <TestRunSelect
                value={selectedTestRunId}
                onChangeHandler={(e) => {
                  if (!isLoading) {
                    setSelectedTestRunId(e.target.value)
                  }
                }}
                options={props.testRun}
                label=''
                disabled={!!isLoading}
              />
            </div>
            {isLoading
              ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <BarLoader loading={isLoading} />
                  <p>Loading...</p>
                </div>
                )
              : (
                <div>
                  <h1 className='text-xl font-DMSANS text-center mb-2'>
                    Total: {noresult + fail + pass + onHold + progress}
                  </h1>
                  <Pie
                    data={Testdata}
                    plugins={[ChartDataLabels]}
                    options={options}
                  />
                </div>
                )}
          </div>
        </div>

        {!isLoading && (
          <div className='col-span-4'>
            <div className='p-2 mb-2 bg-white rounded-xl'>
              <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
                <thead className='text-center bg-customGrey rounded-2xl'>
                  <tr>
                    <th
                      scope='col'
                      className='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[100px]'
                    >
                      Status
                    </th>
                    <th
                      scope='col'
                      className='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[100px]'
                    >
                      Count
                    </th>
                    <th
                      scope='col'
                      className='text-base font-bold text-gray-900 px-2 py-2 text-left min-w-[50px]'
                    >
                      Percentage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredTC) && filteredTC.length > 0
                    ? (
                        allStatus.map((status) => (
                          <tr
                            key={status}
                            class='border-b border-1 border-customGreyThree'
                          >
                            <td class='text-base text-black font-medium p-2 '>
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: '12px',
                                  height: '12px',
                                  marginRight: '8px',
                                  backgroundColor: getStatusColor(status)
                                }}
                              />
                              {status}
                            </td>
                            <td class='text-base text-black font-medium p-2 '>
                              {calculateCount(status)}
                            </td>
                            <td class='text-base text-black font-medium p-2 '>
                              {(
                                (calculateCount(status) / totalTestCases) *
                            100
                              ).toFixed(0)}
                              %
                            </td>
                          </tr>
                        ))
                      )
                    : (
                      <tr>
                        <td colSpan='4'>Invalid data format</td>
                      </tr>
                      )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TestCaseData
