import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import BasicPageLayout from '../components/BasicPageLayout'
import { GET } from '../../../../config/api'
import {
  notificationTypes,
  openNotification
} from '../../../../utils/notifications'
import ChartSection from '../components/ChartSection/ChartSection'

const styles = StyleSheet.create({
  grid1: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f5f6f9',
    marginTop: 10,
    padding: 6,
    borderRadius: 6
  },
  grid2: {
    display: 'flex',
    flexDirection: 'row',
    padding: 6,
    borderBottom: 1,
    borderColor: '#EFEFEF'
  },
  info: {
    flexBasis: '16.25%',
    fontSize: 12,
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 6,
    color: '#B8B8B8',
    marginLeft: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  infoBig: {
    flexBasis: '25%'
  },
  small: {
    flexBasis: '10%'
  },
  infotitle: {
    color: '#4582ec'
  },
  greentext: {
    color: '#40a87b'
  },
  redtext: {
    color: '#E39B8A'
  },
  progress: {
    color: '#FFCC00'
  },
  hold: { color: '#FFE2C3' },
  bold: {
    fontWeight: 'bold'
  }
})

const TestCasses = React.memo(
  ({ id, sessionId, name, title, pass, fail, norun, inprogress, onhold }) => {
    const [allTC, setAllTC] = useState({})

    useLayoutEffect(() => {
      GET(`/gettestcasedata?id=${id}`, {
        sessionID: sessionId
      })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            console.log(res.data.message)
            setAllTC(res.data.message)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    }, [id, sessionId])

    return (
      <BasicPageLayout>
        <Text style={title}>Test Run - {name}</Text>
        <ChartSection
          title={name}
          pass={pass}
          fail={fail}
          noRun={norun}
          progress={inprogress}
          onhold={onhold}
        />
        <Text style={title}>{name} - Test Cases</Text>
        <View style={styles.grid1}>
          <Text
            style={{ ...styles.info, ...styles.infotitle, ...styles.small }}
          >
            ID
          </Text>
          <Text style={{ ...styles.info, ...styles.infotitle }}>
            Application
          </Text>
          <Text style={{ ...styles.info, ...styles.infotitle }}>Module</Text>
          <Text style={{ ...styles.info, ...styles.infotitle }}>Scenario</Text>
          <Text
            style={{ ...styles.info, ...styles.infotitle, ...styles.infoBig }}
          >
            Description
          </Text>
          <Text style={{ ...styles.info, ...styles.infotitle }}>Result</Text>
        </View>
        {allTC.length > 0
          ? (
              allTC.map((tc) => (
                <View style={styles.grid2}>
                  <Text
                    style={{ ...styles.info, ...styles.greentext, ...styles.small }}
                  >
                    {tc.testcaseID}
                  </Text>
                  <Text style={styles.info}>{tc.applicationName}</Text>
                  <Text style={styles.info}>{tc.moduleName}</Text>
                  <Text style={styles.info}>{tc.scenarioName}</Text>
                  <Text style={{ ...styles.info, ...styles.infoBig }}>
                    {tc.testcaseDescription}
                  </Text>
                  {tc.status === 'Pass'
                    ? (
                      <Text
                        style={{
                          ...styles.info,
                          ...styles.bold,
                          ...styles.greentext
                        }}
                      >
                        {tc.status}
                      </Text>
                      )
                    : tc.status === 'Fail'
                      ? (
                        <Text
                          style={{ ...styles.info, ...styles.bold, ...styles.redtext }}
                        >
                          {tc.status}
                        </Text>
                        )
                      : tc.status === 'No Run'
                        ? (
                          <Text style={{ ...styles.info, ...styles.bold }}>
                            {tc.status}
                          </Text>
                          )
                        : tc.status === 'On Hold'
                          ? (
                            <Text
                              style={{ ...styles.info, ...styles.bold, ...styles.hold }}
                            >
                              {tc.status}
                            </Text>
                            )
                          : (
                            <Text
                              style={{ ...styles.info, ...styles.bold, ...styles.progress }}
                            >
                              {tc.status}
                            </Text>
                            )}
                </View>
              ))
            )
          : (
            <Text style={styles.info}>No Test Cases Found</Text>
            )}
      </BasicPageLayout>
    )
  }
)

export default TestCasses
