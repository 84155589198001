import { GET, DELETE } from '../../../../../../config/api'
import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  notificationTypes,
  openNotification
} from '../../../../../../utils/notifications'
import ContextMenu from '../../../../../ContextMenu'

import UpdateScenarioModal from '../../../../../Modal/updateScenario'
import TcModal from '../../../../../Modal/TestCaseModal'

import TestCase from './TestCase'
import Arrow from '../../../../../../assets/Icons/arrow.png'
import Arrow1 from '../../../../../../assets/Icons/arrowright.png'
import User from '../../../../../../assets/Icons/scenario.png'
import rightclick from '../../../../../../assets/Icons/threedots.png'

function Scenario (props) {
  const navigate = useNavigate()

  const sessionId = useSelector((state) => state.user.sessionId)
  const conRef = useRef(null)

  const [isModal, setIsModal] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [schedule, setSchedule] = useState({})
  const [isSchedule, setIsSchedule] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const addTestCase = () => {
    navigate(`/addTestCase/${props.item.id}/${props.moduleID}/${props.applicationID}`)
  }

  const viewScenario = () => {
    navigate(`/scenario/${props.item.id}`)
  }

  const modifyScenario = () => {
    setIsUpdateModal(true)
  }

  const deleteScenario = () => {
    DELETE(`/deleteScenario?scenarioID=${props.item.id}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Scenario deleted successfully'
          )
          // window.location.reload()
          GET(`/getScenario?moduleID=${props.moduleID}`, { sessionID: sessionId })
            .then((res) => {
              if (res.data.status === 200 || res.data.status === 304) {
                props.setSchedule(res.data.message)
              } else {
                // console.log(res)
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              }
            })
            .catch((err) => {
              console.log(err)
              openNotification(
                notificationTypes.ERROR,
                'Something went wrong, please try again later'
              )
            })
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  const menuItems = [
    {
      id: 1,
      text: 'Add Test Case',
      handleFunction: addTestCase
    },
    {
      id: 2,
      text: 'View Scenario',
      handleFunction: viewScenario
    },
    {
      id: 3,
      text: 'Modify Scenario',
      handleFunction: modifyScenario
    },
    {
      id: 4,
      text: 'Delete Scenario',
      handleFunction: deleteScenario
    }
  ]

  const handleTC = (scenarioID) => {
    GET(`/getTestCases?scenarioID=${scenarioID}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setSchedule(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
    setIsSchedule(!isSchedule)
  }

  return (
    <>
      <div
        className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-4 leading-none lg:leading-11'
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
      >
        <span class='flex hover:bg-customBlueFour items-center p-1 font-normal text-black rounded-lg' ref={conRef} onClick={() => handleTC(props.item.id)}>
          {
            isSchedule
              ? (
                <img src={Arrow} className='ml-1 mr-2' alt='Arrow' />
                )
              : (
                <img src={Arrow1} className='ml-3 mr-2' alt='Arrow' />
                )
          }
          <img src={User} className='h-5 mr-2' alt='User' />
          {props.item.scenarioName}
          <img
            style={{ visibility: isHover ? 'visible' : 'hidden' }}
            src={rightclick}
            className='ml-auto'
            alt='Dashboard'
          />
        </span>
        <ContextMenu
          parentRef={conRef}
          items={menuItems}
        />
      </div>
      {
        isSchedule && (
          schedule === null
            ? (
              <h1 className='text-black py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>Loading...</h1>
              )
            : (
                schedule.length > 0
                  ? (
                      schedule.map((item, index) => (
                        <TestCase key={index} scenarioID={props.item.id} item={item} setSchedule={setSchedule} />
                      ))
                    )
                  : (
                    <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>No Test Cases found</h1>
                    )
              )
        )
      }
      <UpdateScenarioModal
        showModal={isUpdateModal}
        setShowModal={setIsUpdateModal}
        scenarioID={props.item.id}
      />
      <TcModal
        showModal={isModal}
        setShowModal={setIsModal}
        id={props.item.id}
        moduleID={props.moduleID}
        appID={props.applicationID}
      />
    </>
  )
}

export default Scenario
