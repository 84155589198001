import TextField from '../../components/InputComponents/InputField'
import { useState, useEffect } from 'react'

const EnquiryPage = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className=' pb-10'>
      <div className='pt-24 mx-24'>
        <div className='shadow-2xl bg-customWhite p-10 rounded-2xl'>
          <h1
            className='py-3 mb-4 text-black font-bold font-DMSANS text-base xl:text-6xl leading-none lg:leading-11'
          >
            Reach out to us
          </h1>
          <div className='flex flex-col mr-20'>
            <form
              action='https://formspree.io/f/xdovkgwq'
              method='POST'
            >
              <div className='grid grid-cols-2 gap-4'>
                <TextField
                  label='Name'
                  name='name'
                  placeholder={name}
                  value={name}
                  maxLength={5000}
                  type='text'
                  onChangeHandler={(e) => setName(e.target.value)}
                  required
                />
                <TextField
                  label='Email'
                  name='email'
                  placeholder={email}
                  value={email}
                  maxLength={5000}
                  type='text'
                  onChangeHandler={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label for='exampleFormControlTextarea1' class='form-label inline-block mb-2 text-gray-700'>
                  Message
                </label>
                <textarea
                  class='rounded-2xl form-control block w-full px-3 py-3 mb-5 text-base font-bold text-gray-700 bg-clip-padding border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                  value={message}
                  name='message'
                  onChange={(e) => setMessage(e.target.value)}
                  id='message'
                  rows='3'
                />
              </div>
              <button
                className='w-24 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnquiryPage
