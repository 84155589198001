import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DELETE, GET } from "../../config/api";
// import TestCase from "./TestCase";
import { documentTitles } from "../../constants/appConstants";
import { useNavigate } from "react-router-dom";
import { notificationTypes, openNotification } from "../../utils/notifications";
import { MdOutlineDeleteOutline } from "react-icons/md";
import moment from "moment";
import CustomPagination from "./CustomPagination";

const Defects = () => {
  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.user.sessionId);

  const [appName, setAppName] = useState("");
  const [defects, setDefects] = useState([]);
  const [defectsDuplicate, setDefectsDuplicate] = useState([]);
  const [accountType, setAccountType] = useState("greentick");

  const formSettingData = useSelector((state) => state.user.settings);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getDefects(currentPage);
    if (
      formSettingData &&
      formSettingData.accountType &&
      formSettingData.accountType === "jira"
    ) {
      setAccountType(formSettingData.accountType);
    }
  }, []);

  const getDefects = async (page) => {
    try {
      const res = await GET(`/getDefects`, {
        sessionID: sessionId,
        page: page,
      });

      if (res.data.status === 200) {
        console.log(res.data.message,"res.data.message.defects")

        const filteredDefects = res.data.message.defects.filter(
          (defect) => defect.id >= 1
        );
        filteredDefects.sort((a, b) => b.id - a.id);

        setDefects(filteredDefects);
        setDefectsDuplicate(filteredDefects);
        setCurrentPage(parseInt(res.data.message.currentPage));
        setTotalPages(parseInt(res.data.message.totalPages));
        setTotalCount(parseInt(res.data.message.totalDefects));
      } else {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting defects, please try again"
        );
      }
    } catch (error) {
      openNotification(
        notificationTypes.ERROR,
        "Error in getting defects, please try again"
      );
    }
  };

  const selectedEdit = (obj) => {
    const editdet = `${obj.id}, ${obj.title}, ${obj.priority}, ${obj.description}, ${obj.status}, ${obj.createdDate},${obj.projectId},
    ${obj.issueTypeId},${obj.externalDefectId},${obj.externalDefectKey},${obj.dueDate},${obj.reporter}, ${obj.assignee},${obj.image[0]}  `;

    let imageData =
      obj.image.length > 0
        ? JSON.stringify(obj.image)
        : JSON.stringify([{ key: "", value: "" }]);
    localStorage.setItem("defectsImage", imageData);

    let data =
      obj.other.length > 0
        ? JSON.stringify(obj.other)
        : JSON.stringify([{ key: "", value: "" }]);
    localStorage.setItem("defectsFormValue", data);

    navigate("/editdefects", {
      state: {
        message: editdet,
      },
    });
  };

  const deleteDefects = (defectsID, externalDefectId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this defects?"
    );
    // const defectsID = editDetails.split(',')
    if (confirmed) {
      DELETE(
        `/deleteDefects/${defectsID}?userName=${formSettingData.userName}&externalDefectId=${externalDefectId}&url=${formSettingData.url}&token=${formSettingData.token}&accountType=${formSettingData.accountType}`,
        { sessionID: sessionId }
      )
        .then((res) => {
          if (res.data.status === 200) {
            window.location.reload(true);
            openNotification(
              notificationTypes.SUCCESS,
              "Successfully deleted defects"
            );
          } else if (res.data.status === 2000) {
            res.data.errors.forEach((errorMessage) => {
              openNotification(notificationTypes.INFO, errorMessage);
            });
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            "Error deleting defects, Please try again"
          );
        });
    }
  };

  const filterDefects = () => {
    if (appName.trim() === "") {
      openNotification(notificationTypes.ERROR, "Please enter data to search");
      setDefects(defectsDuplicate)
      return;
    }

    const filtered = defects.filter(
      (D) =>
        (D.createdDate &&
          D.createdDate.toString().includes(appName.toString())) ||
        (D.title && D.title.toLowerCase().includes(appName.toLowerCase())) ||
        (D.priority &&
          D.priority.toLowerCase().includes(appName.toLowerCase())) ||
        (D.status && D.status.toLowerCase().includes(appName.toLowerCase())) ||
        (D.externalDefectKey &&
          D.externalDefectKey.toLowerCase().includes(appName.toLowerCase()))
    );

    setDefects(filtered);
  };

  useEffect(() => {
    document.title = documentTitles.DEFECTS;
  }, []);

  return (
    <>
      <div className="grid grid-cols-10 gap-1 m-2 p-2">
        <div className="col-span-10">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-black font-DMSANS text-4xl py-1">
              Defects
            </h1>
          </div>
          <div className="col-span-10">
            <div className="my-4 grid grid-cols-2 gap-4">
              <div className="col-span-1">
                <div>
                  <div class="mb-3 w-full">
                    <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                      <input
                        type="search"
                        class="relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        placeholder="Search for Defects"
                        aria-label="Search"
                        aria-describedby="button-addon3"
                        value={appName}
                        onChange={(e) => setAppName(e.target.value)}
                      />
                      <button
                        class="relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
                        type="button"
                        id="button-addon3"
                        data-te-ripple-init
                        onClick={filterDefects}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="h-5 w-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute right-0 ml-20 mr-16 col-span-1 grid grid-cols-1 gap-4">
                <div className="col-span-1">
                  <button
                    onClick={() => navigate("/adddefects")}
                    class="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
                  >
                    Add Defects
                  </button>
                  {/* <button
                    onClick={() => {
                      editDetails
                        ? navigate("/editdefectsplanner", {
                          state: {
                            message: editDetails,
                          },
                        })
                        : navigate("/defectsplanner");
                    }}
                    class={`mr-1 bg-blue-500 text-lg text-white font-bold p-2 rounded ${editDetails ? `hover:bg-blue-700` : `cursor-not-allowed`
                      }`}
                  >
                    Edit Defects
                  </button>
                  <button
                    onClick={deleteDefects}
                    class="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
                  >
                    Delete
                  </button> */}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="mt-2 overflow-x-auto">
                  <table className="table-auto w-full overflow-x-scroll border-2 bg-customWhite rounded-3xl border-customGreyThree editor_listing_table">
                    <thead class="text-center bg-customGrey rounded-2xl">
                      <tr>
                        {/* <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 text-left'
                        /> */}
                        {/* <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Number
                        </th> */}
                        {/* <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Defect{" "}
                        </th> */}
                        <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Title{" "}
                        </th>
                        <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Priority{" "}
                        </th>
                        <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Status{" "}
                        </th>
                        <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Created Date{" "}
                        </th>
                        {/* <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                        >
                          Description{" "}
                        </th> */}
                        {accountType === "jira" ? (
                          <th
                            scope="col"
                            class="text-xl font-bold text-gray-900 px-8 py-4 text-left"
                          >
                            External Defect Key{" "}
                          </th>
                        ) : (
                          ""
                        )}
                        <th
                          scope="col"
                          class="text-xl font-bold text-gray-900 text-left"
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {// filteredTC == null
                      //   ? null
                      //   : filteredTC.length > 0
                      //   ? filteredTC.map((tc) => <TestCase tc={tc} />)
                      // : <tr class='border-b border-1 border-customGreyThree'>
                      //   <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>No Features Found</td>
                      //  <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>-</td>
                      // <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>-</td>
                      defects && defects.length === 0 ? (
                        <tr>
                          <td />
                          <td className="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                            Please add defects to display
                          </td>
                        </tr>
                      ) : (
                        defects &&
                        defects?.map((obj) => {
                          const defectsDate = moment(obj.defectsDate)
                            .utc()
                            .format("DD-MM-YYYY");

                          const createdDate = moment(obj.createdDate)
                            .utc()
                            .format("DD-MM-YYYY");

                          const defectsID = obj.id;
                          const externalDefectId = obj.externalDefectId;
                          return (
                            <tr className="border-b border-1 border-customGreyThree ">
                              {/* <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                {defectsID}
                              </td> */}
                              {/* <td
                                  class="underline cursor-pointer text-base text-blue-600 font-medium px-8 py-4 whitespace-nowrap"
                                  onClick={() => selectedEdit(obj)}
                                >
                                  {obj.defect}
                                </td> */}
                              <td
                                class="underline cursor-pointer text-base text-blue-600 font-medium px-8 py-4 whitespace-nowrap"
                                onClick={() => selectedEdit(obj)}
                              >
                                {obj.title}
                              </td>
                              <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                {obj.priority}
                              </td>
                              <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                {obj.status}
                              </td>
                              <td class="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                                {createdDate}
                              </td>
                              {/* <td class="text-base text-gray-600 font-medium px-8 py-4">
                                  {obj.description}
                                </td> */}
                              {accountType === "jira" ? (
                                <td class="text-base text-gray-600 font-medium px-8 py-4">
                                  {obj.externalDefectKey}
                                </td>
                              ) : (
                                ""
                              )}
                              <td class="text-2xl text-customRed font-medium px-8 py-4 whitespace-nowrap -z-50 cursor-pointer">
                                <MdOutlineDeleteOutline
                                  onClick={() =>
                                    deleteDefects(defectsID, externalDefectId)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={getDefects}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Defects;
