import { useSelector } from 'react-redux'
import { useState } from 'react'

import { PUT } from '../../../config/api'

import { statusType } from '../../../constants/testcaseStatus'
import SingleSelectComponent from '../../../components/InputComponents/SingleSelectComponent'
import { openNotification, notificationTypes } from '../../../utils/notifications'

const TableItems = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [actualOutput, setActualOutput] = useState(props.item.actualResult)
  const [comments, setComments] = useState(props.item.comments)
  const [status, setStatus] = useState(props.item.status)

  const handleSubmit = () => {
    const data = {
      enteredActualOutput: actualOutput,
      enteredComments: comments,
      enteredTestStepStatus: status
    }

    PUT(`/updateTestSteps?id=${props.item.id}`, data, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'Test Case Step Completed Successfully'
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Something went wrong'
        )
      }
      )
  }

  return (
    <tr class='border-b border-1 border-customGreyThree'>
      <td class='text-base text-gray-900 font-medium p-3 whitespace-nowrap'>{props.item.stepNumber}</td>
      <td class='text-base text-gray-900 font-medium py-3 px-2 whitespace-nowrap'>{props.item.stepDescription}</td>
      <td class='text-base text-gray-900 font-medium py-3 px-2 whitespace-nowrap'>{props.item.expectedResult}</td>
      <td class='text-md text-gray-900 font-medium py-3 px-2 whitespace-nowrap'>
        <textarea
          class='overflow-y-auto form-control block w-full px-3 py-1.5 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          value={actualOutput}
          onChange={(e) => setActualOutput(e.target.value)}
          id='exampleFormControlTextarea1'
          rows='3'
          placeholder='Your message'
        />
      </td>
      <td class='text-md text-gray-900 font-medium py-3 px-2 whitespace-nowrap'>
        <textarea
          class='overflow-y-auto form-control block w-full px-3 py-1.5 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          id='exampleFormControlTextarea1'
          rows='3'
          placeholder='Your message'
        />
      </td>
      <td class='text-md text-gray-900 font-medium p-3 whitespace-nowrap'>
        <div className='mt-3'>
          <SingleSelectComponent
            value={status}
            onChangeHandler={(e) => setStatus(e.target.value)}
            options={statusType}
          />
        </div>
      </td>
      <td class='text-center text-base text-gray-900 font-medium p-3 whitespace-nowrap'>{props.item.isComplete == 1 ? 'yes' : 'no'}</td>
      <td class='text-lg text-gray-900 font-medium py-3 px-2 whitespace-nowrap'>
        <button
          onClick={handleSubmit}
          className='bg-customBlue text-white font-medium px-3 py-1 rounded'
        >
          Save
        </button>
      </td>
    </tr>
  )
}

export default TableItems
