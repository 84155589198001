const status = {
  PASS: 'Pass',
  FAIL: 'Fail',
  ONHOLD: 'On Hold',
  NA: 'N/A'
}

export const statusType = [
  {
    id: 1,
    value: 0,
    label: 'Pass'
  },
  {
    id: 2,
    value: 1,
    label: 'Fail'
  },
  {
    id: 3,
    value: 2,
    label: 'On Hold'
  },
  {
    id: 4,
    value: 3,
    label: 'N/A'
  }
]
