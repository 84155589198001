import Sidebar from '../../components/sidebarnav'
import { GET } from '../../config/api'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const AutomaticRunResultsPage = () => {
  const sessionId = useSelector(state => state.user.sessionId)
  const { testpackID } = useParams()

  const [testPack, setTestPack] = useState({})

  const getTestPackData = () => {
    GET(`/getTestPackById?id=${testpackID}`, { sessionID: sessionId })
      .then((res) => {
        setTestPack(res.data.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getTestPackData()
  }, [testpackID])

  return (
    <>
      {
        testPack !== null
          ? (
              testPack.is_cloud === false
                ? (
                  <div className='pb-52 bg-gray-100 pt-16'>
                    <div className='mr-12 grid grid-cols-10 gap-24'>
                      <div className='col-span-2' />
                      <div className='w-1/2 mt-10 bg-customWhite rounded-2xl ml-12 mb-32 pb-12 col-span-8'>
                        <div className='mx-12 mb-24'>
                          <h1 className='font-DMSANS text-gray-400 ml-5 xl:mt-16 text-lg xl:text-5xl font-regular'>
                            View Results
                          </h1>
                          <a href={`http://3.91.253.67:8000/getAutomaticRunResults?testpackID=${testpackID}`} download='Results.zip' target='_blank' rel='noreferrer'>
                            <button
                              className='mt-12 bg-black w-1/2 text-white font-bold py-2 px-4 rounded'
                            >
                              Download Results
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                : (
                  <div className='pb-60 bg-gray-100 pt-16'>
                    <div className='mr-12 grid grid-cols-10 gap-24'>
                      <div className='col-span-2' />
                      <div className='mb-48 ml-20 mt-10 bg-customWhite pb-44 col-span-8 rounded-2xl'>
                        <div className='px-10 pt-12'>
                          <h1 className='font-DMSANS text-gray-400 ml-5 xl:mt-3 text-lg xl:text-3xl font-regular'>
                            Please wait while the execution is in progress ...
                          </h1>
                          <h1 className='mt-5 font-DMSANS text-gray-400 ml-5 xl:mt-3 text-lg xl:text-3xl font-regular'>
                            The results will appear here when the execution is complete.
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))
          : (
            <div />
            )
      }
    </>
  )
}

export default AutomaticRunResultsPage
