import { useState, useEffect } from 'react'
import moment from 'moment'

import { useSelector } from 'react-redux'
import { PUT } from '../../../config/api'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'

const AddReleasePlanner = ({
  name,
  version,
  releaseDate,
  description,
  overallStatus,
  devStartDate,
  devEndDate,
  effort,
  releaseId
}) => {
  const [rName, setRName] = useState(name)
  const [rVersion, setRversion] = useState(version)
  const [rDate, setRDate] = useState(releaseDate)
  const [rDesc, setRDesc] = useState(description)
  const [rOverallStatus, setROverallStatus] = useState(
    overallStatus ? overallStatus.trim() : ''
  )
  const [textDate, setTextDate] = useState(true)

  const sessionId = useSelector((state) => state.user.sessionId)

  const selectOptions = ['New', 'In-Progress', 'Completed']

  const updateRelease = () => {
    const data = {
      name: rName,
      version: rVersion,
      releaseDate: rDate,
      overallStatus: rOverallStatus,
      description: rDesc
    }
    if (devStartDate && devStartDate.trim().localeCompare('null')) {
      data.devStartDate = devStartDate
    }
    if (devEndDate && devEndDate.trim().localeCompare('null')) {
      data.devEndDate = devEndDate
    }
    if (effort && effort.trim().localeCompare('null')) data.effort = effort

    PUT(`/release/${releaseId}`, data, {
      sessionID: sessionId
    })
      .then((res) => {
        if (res.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'Release data updated successfully'
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error: 82010',
            'Error in updating Release. Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error: 82010',
          'Error in updating Release. Please try again'
        )
      })
  }

  return (
    <>
      <div className='flex justify-end'>
        <button
          className='bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end'
          type='button'
          onClick={updateRelease}
        >
          Save
        </button>
      </div>

      <div className='mt-5 bg-gray-200 p-4 rounded-xl'>
        <div className='bg-white p-3 rounded-xl'>
          <div className='grid grid-col-10 gap-x-4 gap-y-2'>
            <div className='col-span-10 '>
              <div className='text-sm mt-2' style={{ color: 'grey' }}>
                Release Name
              </div>
              <h1 className='text-black'>
                <input
                  type='text'
                  className='p-2 w-full border-2 rounded-lg'
                  value={rName}
                  onChange={(e) => setRName(e.target.value)}
                />
              </h1>
            </div>
            <div className='col-span-3'>
              <div className='text-sm mt-2' style={{ color: 'grey' }}>
                Release Version &nbsp;
              </div>
              <h1 className='text-black'>
                <input
                  type='text'
                  className='p-2 w-full border-2 rounded-lg'
                  value={rVersion}
                  onChange={(e) => setRversion(e.target.value)}
                />
              </h1>
            </div>
            <div className='col-span-3'>
              <div className='text-sm mt-2' style={{ color: 'grey' }}>
                Release Date &nbsp;
              </div>
              <h1 className='text-black'>
                {textDate ? (
                  <input
                    type='text'
                    className='p-2 w-full border-2 rounded-lg'
                    defaultValue={moment(rDate).utc().format('DD-MM-YYYY')}
                    onClick={() => {
                      setTextDate(false)
                    }}
                  />
                ) : (
                  <input
                    type='date'
                    className='p-2 w-full border-2 rounded-lg'
                    // value={moment(rDate).utc().format("DD-MM-YYYY")}
                    onChange={(e) => setRDate(e.target.value)}
                  />
                )}
              </h1>
            </div>
            <div className='col-span-4'>
              <div className='text-sm mt-2' style={{ color: 'grey' }}>
                Status &nbsp;
              </div>
              <h1 className='text-black'>
                <select
                  className='p-2 w-full border-2 rounded-lg'
                  name='overallStatus'
                  id='overallStatus'
                  onChange={(e) => setROverallStatus(e.target.value)}
                >
                  {selectOptions.map((obj) => {
                    let selected = ''
                    if (obj.trim().localeCompare(rOverallStatus.trim()) === 0) {
                      selected = 'selected'
                    } else {
                      selected = ''
                    }
                    return <option selected={selected}>{obj.trim()}</option>
                  })}
                </select>
              </h1>
            </div>
            <div className='col-span-10'>
              <div className='text-sm mt-2' style={{ color: 'grey' }}>
                Description &nbsp;
              </div>
              <h1 className='text-black'>
                <textarea
                  value={rDesc}
                  onChange={(e) => setRDesc(e.target.value)}
                  placeholder='Enter Description'
                  className='p-2 w-full border-2 rounded-lg'
                />
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-5 gap-4 mt-5">
        <div className="col-end-7">
          <button
            className="w-full bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end hidden"
            type="button"
            onClick={onSave(rName, rVersion, rDate, rDesc, rOverallStatus)}
          >
            Update
          </button>
        </div>
      </div> */}
    </>
  )
}

export default AddReleasePlanner
