import SecondSectionImage from '../../../assets/Images/img3.webp'
import rightArrow from '../../../assets/Icons/rightarrow.png'
import EnquiryModal from '../../../components/Modal/EnquiryModal'
import { useState } from 'react'

function FourthSection () {
  const [isModal, setIsModal] = useState(false)

  return (
    <div>
      <div className='grid grid-rows-1 md:grid-rows-0 grid-cols-0 md:grid-cols-2 gap-0'>
        <div className='py-8 md:py-6 xl:py-12 pl-6 md:pl-8 xl:pl-16 pr-6 md:pr-4 xl:pr-24 bg-customGreyFour'>
          <h1 className='text-5xl lg:text-7xl font-heading font-black mb-12'>Product Strengthening</h1>
          <p className='md:text-lg xl:text-xl font-para font-medium md:mr-4 lg:mr-20 mb-12'>Let's bring your company to the next level.</p>
          <p className='md:text-lg xl:text-xl font-para font-medium md:mr-4 lg:mr-20 mb-12'>
            Products can continuously be improved and made stronger. To ensure the quality of the product we do a comprehensive testing of the product and make it rock hard.
          </p>
          <button onClick={() => setIsModal(true)} className='flex font-para font-bold px-4 md:px-6 xl:px-8 py-2 md:py-4 xl:py-6 border-2 border-black'>
            Learn More
            <img src={rightArrow} className='ml-5 h-6' alt='arrow' />
          </button>
        </div>
        <div className='hidden md:block my-auto xl:mx-28 col-span-1'>
          <img src={SecondSectionImage} alt='SecondSectionImage' />
        </div>
      </div>
      <EnquiryModal
        showModal={isModal}
        setShowModal={setIsModal}
      />
    </div>
  )
}

export default FourthSection
