import React, { useState, useEffect } from 'react'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { GET } from '../../config/api'
import { useSelector } from 'react-redux'
import { BiRefresh } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const SecurityScan = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [urlList, setUrlList] = useState([])
  const [isTableVisible, setTableVisible] = useState(true)
  const navigate = useNavigate()

  const getApplications = () => {
    GET('security', { sessionID: sessionId })
      .then((res) => {
        console.log(res.data)
        setUrlList(res.data)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong. Please try again later.'
        )
      })
  }

  const handleRefresh = () => {
    setTableVisible(false)
    getApplications()
    setTimeout(() => {
      setTableVisible(true)
    }, 500)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh()
    }, 6000000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getApplications()
  }, [])

  const navigateToEachDomainReport = (domain) => {
    navigate(`/securityscans?domain=${domain}`)
  }

  console.log(urlList)

  return (
    <div>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Security Report
            </h1>
          </div>
          {urlList && urlList.length > 0 ? (
            <div className='mt-4'>
              <div className='flex gap-2'>
                <h3 className='text-lg font-bold mb-2'>Scaned Domains:</h3>
                <button onClick={() => handleRefresh()} className='text-customBlue text-3xl pb-3'><BiRefresh /></button>
              </div>
              {isTableVisible ? (
                <div className=' gap-4 grid grid-cols-4'>
                  {urlList.map((data, index) => (
                    <div className=' bg-blue-900 hover:bg-customWhite shadow-md hover:shadow-[rgba(0,_0,_0,_0.24)_0px_5px_11px] rounded-md text-white hover:text-black min-w-[200px] h-[120px] cursor-pointer' onClick={() => navigateToEachDomainReport(data)}>
                      {/* <div className='font-bold text-lg p-4'>Domain</div> */}
                      <ul key={index} className='font-bold text-base p-4 overflow-x-hidden'>
                        {data}
                      </ul>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Loding...</p>
              )}
              {/* {isTableVisible ? (
                <table className="border-collapse">
                  <thead>
                    <tr>
                      <th className="border border-gray-400 text-sm px-4 py-2">Domain</th>
                      {/* <th className="border border-gray-400 text-sm px-4 py-2">Created Time</th>
                      <th className="border border-gray-400 text-sm px-4 py-2">Updated Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {urlList.map((data, index) => (
                      // <tr key={index}>
                      //   <td
                      //     className="border border-gray-400 px-4 py-2 text-blue-500 cursor-pointer underline"
                      //     onClick={() => navigateToEachDomainReport(data)}
                      //   >
                      //     {data}
                      //   </td>
                        {/* <td className="border border-gray-400 px-4 py-2">
                          {new Date(data.createdTime).toLocaleString('en-US', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                          })}</td>
                        <td className="border border-gray-400 px-4 py-2">
                          {new Date(data.updatedTime).toLocaleString('en-US', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                          })}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Loading...</p>
              )} */}
            </div>
          ) : (
            <p>No scan report available.</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SecurityScan
