import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PUT } from "../../../config/api";
import { defectsImageUrl } from "../../../config/config.js";
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver'
import {
    notificationTypes,
    openNotification,
} from "../../../utils/notifications";

const Attachments = ({
    externalDefectId,
    imageNames,
    defectsId,
    title1,
    priority1,
    description1,
    status1,
    createdDate1,
    externalDefectKey,
    projectId1,
    issueTypeId1,
    dueDate1,
    reporter1,
    assignee1,
    jsonBData
}) => {
    console.log(imageNames, "imageNames 123")
    const navigate = useNavigate();
    const sessionId = useSelector((state) => state.user.sessionId);
    const formSettingData = useSelector((state) => state.user.settings);
    const inputRef = useRef(null);
    const [dueDate, setDueDate] = useState(dueDate1);
    const [reporter, setReporter] = useState(reporter1);
    const [assignee, setAssignee] = useState(assignee1);

    const [imageName, setImageName] = useState(imageNames);

    const [companyId, setCompanyId] = useState("");
    const [accountType, setAccountType] = useState("greentick");
    const [jsonB, setJSONB] = useState(jsonBData);

    const [fileData, setFileData] = useState("");
    const [title, setTitle] = useState(title1);
    const [priority, setPriority] = useState(priority1);
    const [description, setDescription] = useState(description1);
    const [status, setStatus] = useState(status1);
    const [createdDate, setCreatedDate] = useState(createdDate1);
    const [projectId, setProjectId] = useState(projectId1);
    const [issueTypeId, setIssueTypeId] = useState(issueTypeId1);

    useEffect(() => {
        if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
            setAccountType(formSettingData.accountType);
            setCompanyId(formSettingData.companyId);
        }
    }, []);

    const handleFileChange = async (e) => {
        const fileObj = e.target.files;
        if (!fileObj) {
            return;
        }
        setFileData(fileObj);
    };

    const updateDefects = () => {
        if (fileData === "") {
            openNotification(
                notificationTypes.ERROR,
                "Error: 80210",
                "Please select the file"
            );

            return;
        }

        let formsData = new FormData();
        for (let i = 0; i < fileData.length; i++) {
            formsData.append('image', fileData[i]);}
        formsData.append("title", title);
        formsData.append("description", description);
        formsData.append("priority", priority);
        formsData.append("createdDate", createdDate);
        formsData.append("status", status);
        formsData.append("projectId", projectId);
        formsData.append("issueTypeId", issueTypeId);
        formsData.append("other", JSON.stringify(jsonB));
        formsData.append("companyId", companyId);
        formsData.append("externalDefectId", externalDefectId);
        formsData.append("externalDefectKey", externalDefectKey);
        formsData.append("dueDate", dueDate);
        formsData.append("reporter", reporter);
        formsData.append("assignee", assignee);

        PUT(`/defectsEdit/${defectsId}`, formsData, {
            sessionID: sessionId,
        })
            .then((res) => {
                if (res.status === 200) {
                    openNotification(
                        notificationTypes.SUCCESS,
                        "Success",
                        "Defects data updated successfully"
                    );
                    navigate("/defects");
                } else {
                    openNotification(
                        notificationTypes.ERROR,
                        "Error: 82010",
                        "Error in updating Defects. Please try again"
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationTypes.ERROR,
                    "Error: 82010",
                    "Error in updating Defects. Please try again"
                );
            });
    };

    const downloadImageAsPDF = (path) => {
        const pdf = new jsPDF();
        pdf.addImage(`${defectsImageUrl}${path}`, 'JPEG', 10, 10, 190, 130);
        pdf.save(`${path}_download.pdf`);
    };

    return (
        <>
            <div >
                <div className="col-span-10 me-3 mb-2 flex justify-end">
                    <button
                        onClick={() => navigate("/defects")}
                        class="mr-2 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 px-6 rounded"
                    >
                        Back
                    </button>
                    <button
                        className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
                        type="button"
                        onClick={updateDefects}
                    >
                        Save
                    </button>
                </div>
                <div className="">
                    <div>
                        <div className="col-span-10 mt-5 bg-gray-200 p-4 rounded-xl">
                            <label
                                style={{ color: "grey" }}
                                className="text-sm mt-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                htmlFor="multiple_files"
                            >
                                Add Attachements
                            </label>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                id="multiple_files"
                                type="file"
                                multiple
                                ref={inputRef}
                                onChange={handleFileChange}
                            />

                            {console.log(defectsImageUrl + imageName[0].path, "llllllllllllllllll")}
                        </div>
                        <div className="mt-5 bg-gray-200  p-4 rounded-xl"
                            style={{
                                marginBottom: "30px"
                            }}
                        >
                            <h3>Attachments available</h3>
                            {imageName[0]?.name ? (
                                imageName.map((image, index) => (
                                    <div key={index} className="image-container">
                                        <img style={{ marginTop: "30px" }} src={defectsImageUrl + image.path} alt={image.name} />
                                        <button
                                            className="download-button "
                                            onClick={() => downloadImageAsPDF(image.path)}
                                            style={{
                                                padding: "10px",
                                                margin: "25px 20px 20px 0px",
                                                fontSize: "19px",
                                                borderRadius: "5px",
                                                float: "right",
                                                color: "white",
                                                background: "#3b82f6",
                                                fontWeight: "600"
                                            }}
                                        >
                                            Download
                                        </button>
                                    </div>
                                ))
                            ) : (
                                "No attachments available"
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Attachments;
