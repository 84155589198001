const TestCase = (props) => {
  return (
    <>
      <tr className='border-b border-1 border-customGreyThree'>
        <td className='text-base text-gray-600 font-medium px-2 py-2 break-words'>
          {props.tc.applicationName} {'>'} {props.tc.moduleName} {'>'}{' '}
          {props.tc.scenarioName}
        </td>
        <td className='text-base text-green-600 underline underline-offset-4 font-medium px-2 py-2 break-words'>
          <a
            className='text-green-600'
            href={`/viewmanualrunreport/${props.tc.testRunID}/${props.tc.id}/${props.tc.scenarioID}/${props.tc.testcaseID}`}
            target='_blank'
            rel='noreferrer'
          >
            {props.tc.testcaseName}
          </a>
        </td>
        <td className='text-base text-gray-600 font-medium px-2 py-2 break-words'>
          {props.tc.testcaseDescription}
        </td>
        <td className='text-base font-medium px-2 py-2 break-words'>
          {props.tc.status}
        </td>
      </tr>
    </>
  )
}

export default TestCase
