import Application from './application'
import ContextMenu from '../../ContextMenu'

import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { GET } from '../../../config/api'
import { notificationTypes, openNotification } from '../../../utils/notifications'
import Modal from '../../Modal/ApplicationModal'

import Arrow from '../../../assets/Icons/arrow.png'
import Arrow1 from '../../../assets/Icons/arrowright.png'
import testcase from '../../../assets/Icons/testcase.png'
import rightclick from '../../../assets/Icons/threedots.png'

function TestCaseManagement () {
  const containerRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)
  const navigate = useNavigate()

  const [isReq, setIsReq] = useState(false)
  const [app, setApp] = useState({})
  const [isModal, setIsModal] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const handleReqMan = () => {
    setIsReq(!isReq)
  }

  const handleApplication = () => {
    setIsModal(!isModal)
  }

  const handleUser = (e) => {
    if (e.button === 0) {
      navigate('/testcasemanagement')
    }
  }

  const menuItems = [
    {
      id: 1,
      text: 'Add Application',
      handleFunction: handleApplication
    }
  ]

  const getApplications = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        console.log(res.data.message)
        setApp(res.data.message)
      }
      )
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getApplications()
  }, [])

  return (
    <>
      <li
        ref={containerRef}
      >
        <div>
          <div
            class='hover:bg-customBlueFour'
            onMouseOver={MouseOver}
            onMouseOut={MouseOut}
          >
            <div
              onClick={(e) => handleUser(e)}
              className='flex items-center p-2 text-base font-bold text-black rounded-lg'
            >
              {
                isReq
                  ? (
                    <img className='mr-2' src={Arrow} alt='Arrow' />
                    )
                  : (
                    <img className='mr-2' src={Arrow1} alt='Arrow' />
                    )
              }
              <img src={testcase} alt='Dashboard' />
              <span
                onClick={handleReqMan}
                class='ml-3'
              >
                Test Case management
              </span>
              <img
                src={rightclick}
                className='ml-5'
                alt='Dashboard'
                style={{ visibility: isHover ? 'visible' : 'hidden' }}
              />
            </div>
            <ContextMenu
              parentRef={containerRef}
              items={menuItems}
            />
          </div>
          {

            isReq && (
              app === null
                ? (
                  <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-4 leading-none lg:leading-11'>Loading...</h1>
                  )
                : app.length > 0
                  ? (
                    <div className='ml-2'> {
                      app.map((item, index) => (
                        <Application key={index} item={item} setApp={setApp} />
                      ))
                    }
                    </div>
                    )
                  : (
                    <div className='mb-6 mx-6 bg-customWhite'>
                      <h1 className='py-1 font-DMSANS text-sm xl:text-lg font-medium ml-5 leading-none lg:leading-11'>No application found</h1>
                    </div>
                    )
            )
          }
        </div>
        <Modal
          showModal={isModal}
          setShowModal={setIsModal}
          setApp={setApp}
        />
      </li>
    </>
  )
}

export default TestCaseManagement
