export const parentRoutes = {
  APP: "/applications",
  MODULE: "/module",
  SCENARIO: "/scenario",
  TC: "/testcase",
  ASSIGNCXO: "/assigncxo",
  VIEWMANUALRUN: "/viewmanualrun",
  VIEWMR: "/viewMR",
  VIEWTESTPACK: "/viewtestpack",
  VIEWMANUALRUN_RESULTS: "/viewmanualrunresults",
  VIEWAUTOMATICRUN: "/viewautomaticrun",
  POSTAUTOMATICRUN_RESULTS: "/postautomaticresults",
  VIEWAUTOMATICRUN_RESULTS: "/viewautomaticrunresults",
  VIEWCXOPACK: "/viewcxopack",
  CREATECXOVIEW: "/createcxoview",
  CREATETESTRUN: "/createtestrun",
  VIEWMANUALRUN_REPORT: "/viewmanualrunreport",
  VIEWEXECUTIONDATA: "/viewexecutiondata",
  addTestCase: "/addTestCase",
};

export const routes = {
  HOME: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  PRICING: "/pricing",
  APP_DETAILS: `${parentRoutes.APP}/:appID`,
  MODULE_DETAILS: `${parentRoutes.MODULE}/:moduleID`,
  SCENARIO_DETAILS: `${parentRoutes.SCENARIO}/:scenarioID`,
  TC_DETAILS: `${parentRoutes.TC}/:tcID/:scenarioID`,
  ASSIGNCXO_DETAILS: `${parentRoutes.ASSIGNCXO}/:assigncxoID`,
  VIEWCXOPACK_DETAILS: `${parentRoutes.VIEWCXOPACK}/:ID`,
  VIEWMANUALRUN_DETAILS: `${parentRoutes.VIEWMANUALRUN}/:tcID`,
  VIEWMR_DETAILS: `${parentRoutes.VIEWMR}/:manualrunID/:testpackID`,
  VIEWTESTPACK_DETAILS: `${parentRoutes.VIEWTESTPACK}/:testpackID`,
  CREATETESTRUNDETAILS: `${parentRoutes.CREATETESTRUN}/:testpackID`,
  VIEWEXECUTIONDATA_DETAILS: `${parentRoutes.VIEWEXECUTIONDATA}/:id`,
  TESTCASEMANAGEMENT: '/testcasemanagement',
  TESTPACKMANAGEMENT: '/testpackmanagement',
  REPORT: '/report',
  EXECUTION: '/execution',
  VIEWMANUALRUN: '/viewmanualrun',
  addTestCase: `${parentRoutes.addTestCase}/:scenarioID/:moduleID/:applicationID`,
  VIEWMANUALRUN_RESULTSDETAILS: `${parentRoutes.VIEWMANUALRUN_RESULTS}/:viewmanualrunresultsID`,
  VIEWMANUALRUN_REPORT: `${parentRoutes.VIEWMANUALRUN_REPORT}/:testRunID/:testCaseReportID/:scenarioID/:testCaseID`,
  USERMANAGEMENT: "/usermanagement",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  FORGETPASSWORD: "/forgetpassword",
  RESETPASSWORD: "/resetpassword/:token",
  ADDUSERCREATEPASSWORD: "/addusercreatepassword/:verificationcode",
  ADDUSER: "/addUser",
  RELEASEPLANNER: "/releaseplanner",
  TEST_STEPS:"/teststeps",
  ADDRELEASEPLANNER: "/addreleaseplanner",
  EDITRELEASEPLANNER: "/editreleaseplanner",
  ADDFEATURE: "/addfeature",
  FEATURE: "/feature",
  HOWITWORKS: "/howitworks",
  EACHFEATURE: "/eachfeature",
  CREATEUSERBUTTON: "/createuserbutton",
  RUNREQUESTBUTTON: "/runrequestbutton",
  CREATECXOVIEW: `${parentRoutes.CREATECXOVIEW}/:applicationID`,
  VIEWAUTOMATICRUN_DETAILS: `${parentRoutes.VIEWAUTOMATICRUN}/:testpackID`,
  POSTAUTOMATICRUN_RESULT: `${parentRoutes.POSTAUTOMATICRUN_RESULTS}/:testpackID`,
  VIEWAUTOMATICRUN_RESULTSDETAILS: `${parentRoutes.VIEWAUTOMATICRUN_RESULTS}/:testpackID`,
  DASHBOARD: "/dashboard",
  DASHBOARDRELEASE: "/dashboard-release",
  EMAILVARIFICATION: "/dashboard/:varifyemail/:varificationcode",
  COMPANY: "/company",
  ENQUIRY: "/enquiry",
  SECURITYTEST: "/securitytest",
  EACHDOMAINREPORT: "/eachdomainreport",
  SECURITYSCANTABLE: "/securityscans",
  SETTINGS: "/settings",
  DEFECTS: '/defects',
  ADD_DEFECTS: '/adddefects',
  EDIT_DEFECTS: '/editdefects',
  EDIT_TEST_STEPS: '/editteststeps',
  ADD_TEST_STEPS: '/addteststeps',
  VIEW_TEST_STEPS: '/viewteststeps',
  TESTRUNSUMMARY: '/testrunsummary',
  REPORTCHART: '/getreportdashboard',
  USERPRODUCTIVITYREPORT: '/userproductivityreport',
  FEATUREMANAGE: '/featuremanagement',
  TESTCASESTATUSREPORT: '/testcasestatusreport'

};
