import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GET } from '../../config/api'
import { useSelector } from 'react-redux'
import { documentTitles } from '../../constants/appConstants'
import Modal from '../../components/Modal/ApplicationModal'
import name from '../../assets/Icons/T.png'
import pencil from '../../assets/Icons/pencil.png'

const ApplicationPage = () => {
  const { appID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)
  const [app, setApp] = useState({})
  const [isModal, setIsModal] = useState(false)

  useEffect(() => {
    document.title = documentTitles.APPLICATION
  }, [])

  const getAPPData = () => {
    GET(`getSpecificApp?appID=${appID}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setApp(res.data.message)
        } else {
          console.log('error')
        }
      }
      )
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAPPData()
    document.title = documentTitles.APPLICATION
  }, [appID])

  return (
    <>
      <div className='pb-5'>
        <div className='grid grid-cols-1 gap-1'>
          {/* <div className='col-span-2' /> */}
          <div className='mb-40 col-span-8'>
            {
              app === null
                ? (
                  <div className='pt-48 pb-24 min:h-2/5'>
                    App not found
                  </div>
                  )
                : (
                  <div className='mt-5 py-10 min:h-2/5'>
                    <div>
                      <h1 className='py-3 mb-4 text-black font-bold font-DMSANS text-base xl:text-3xl ml-2 leading-none lg:leading-11'>
                        Application
                      </h1>
                      <div>
                        <div className='grid grid-cols-6 gap-2'>
                          <div className='h-20 col-span-2 mb-5 bg-white rounded-xl'>
                            <div className='flex'>
                              <div className='m-3'>
                                <img src={name} alt='id' />
                              </div>
                              <div className='col-span-2'>
                                <h1 className='mb-0 pt-3 font-DMSANS font-bold text-base xl:text-xl'>
                                  Application Name &nbsp;
                                </h1>
                                <h1 className='mb-0 font-DMSANS text-customGreyTwo text-base'>
                                  <span className='font-regular'>
                                    {app.applicationName}
                                  </span>
                                </h1>
                              </div>
                              <img src={pencil} alt='edit' className='h-5 mr-4 mt-3 ml-auto' />
                            </div>
                          </div>
                          <div className='col-span-4 mb-5 bg-white rounded-xl'>
                            <div className='flex'>
                              <h1 className='pt-3 font-DMSANS font-bold text-base xl:text-xl ml-5'> Application Description  &nbsp;</h1>
                              <img src={pencil} alt='edit' className='h-5 mt-2 mr-4 ml-auto' />
                            </div>
                            <h1 className='pb-3 font-DMSANS text-customGreyTwo text-base ml-5'>{app.applicationDescription}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
            }
          </div>
        </div>
      </div>
      <Modal
        showModal={isModal}
        setShowModal={setIsModal}
      />
    </>
  )
}

export default ApplicationPage
