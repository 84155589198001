import TextField from '../../InputComponents/InputField'
import SingleSelectComponent from '../../InputComponents/SingleSelectComponent'
import { TCtype, TCstatus, TCpriorityVal } from '../../../constants/TestCase'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { GET, POST } from '../../../config/api'
import styles from '../modal.module.css'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const TestcaseModalbutton = ({ showModal, setShowModal }) => {
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId);
  const formSettingData = useSelector((state) => state.user.settings);
  const sessionID = useSelector((state) => state.user.sessionId);
  const userId = useSelector((state) => state.user.userId);
  const [accountType, setAccountType] = useState("greentick");
  const currDate = moment()
  const modalRef = useRef(null)

  const [TCType, setTCType] = useState(TCtype[0].value)
  const [TCstat, setTCstatus] = useState(TCstatus[0].value)
  const [TCpriority, setTCpriority] = useState(TCpriorityVal[0].value)
  const [tcName, setTcName] = useState('')
  const [tcDate, setTcDate] = useState('')
  const [tcID, setTCID] = useState('')
  const [tcDesc, setTcDesc] = useState('')
  const [applications, setApplications] = useState([])
  const [modules, setModules] = useState([])
  const [scenarios, setScenarios] = useState([])
  const [folderIdData, setFolderIdData] = useState([]);
  const [ownerIdData, setOwnerIdData] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState('')
  const [selectedModId, setSelectedModId] = useState('')
  const [selectedScenarioId, setSelectedScenarioId] = useState('')
  const [jiraUrl, setJiraUrl] = useState("");
  const [projectId, setProjectId] = useState('');
  const [folderId, setFolderId] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [settingsData, setSettingsData] = useState([]);
  const [projectIdData, setProjectIdData] = useState([]);

  const closeModal = () => {
    setShowModal(!showModal)
  }
  useEffect(() => {
    getSettings();
    if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
      setAccountType(formSettingData.accountType);
      getOwnerId(
        formSettingData.userName,
        formSettingData.token
      )
      getFolderIds(
        formSettingData.userName,
        formSettingData.zephyrToken
      )
      getProjectIds(
        formSettingData.userName,
        formSettingData.zephyrToken);
        
      if (formSettingData.url === "") {
        openNotification(
          notificationTypes.ERROR,
          "Error: 80220",
          "Please add jira url in settings"
        );
        return;
      }
    }

  }, [])

  const getFolderIds = async (userName, zephyrToken) => {
    GET(`/getallfolderid?userName=${userName}&zephyrToken=${zephyrToken}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setFolderIdData(res.data.message);
          console.log(res.data.message,"res.data.message 85")
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getOwnerId = async (userName, token) => {
    GET(`/getownerid?userName=${userName}&token=${token}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          let resData = res.data.message;
          setOwnerIdData(resData[0].ownerId);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting owner id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting owner id, Please try again"
        );
      });
  };

  async function getSettings() {
    await GET(`/getSettings?userId=${userId}`, { sessionID })
      .then((res) => {
        if (res.data.status === "ok") {
          setSettingsData(res.data.message)
          setJiraUrl(res.data.message.url)
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getProjectIds = async (userName, zephyrToken) => {
    GET(`/getzypherproject?userName=${userName}&zephyrToken=${zephyrToken}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res,"res.data.message144")
          setProjectIdData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const addTC = async (e) => {
    if (accountType === "jira" && projectKey === '') {
      openNotification(
        notificationTypes.ERROR,
        "Please select a project"
      );
      return;
    } else if (accountType === "jira" && folderId === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error: 30840',
        'Please select a folder'
      )
    }
    if (selectedAppId.length > 0 && selectedModId.length > 0 && selectedScenarioId.length > 0
      && tcName.length > 0 && TCType.length > 0 && TCstat.length > 0 && tcDesc.length > 0) {
      console.log(projectKey,"projectData[i].key")
      const Data = {
        testCaseID: tcID,
        testCaseName: tcName,
        testCaseDescription: tcDesc,
        testcaseType: TCType,
        testCaseStatus: TCstat,
        TCpriority:TCpriority,
        testCaseDate: currDate,
        moduleID: selectedModId,
        applicationID: selectedAppId,
        projectId: projectId,
        projectKey: projectKey,
        folderId: folderId,
        ownerIdData: ownerIdData
      }

      await POST(`/postTestCase?scenarioID=${selectedScenarioId}`, Data, {
        sessionID: sessionId
      })
        .then((res) => {
          if (
            res.data.status === 200 ||
            res.data.status === 304 ||
            res.data.status === 'ok'
          ) {
            openNotification(
              notificationTypes.SUCCESS,
              'Test case added successfully'
            )
            navigate(`/testcase/${res.data.message.id}/${selectedScenarioId}`)
          } else {
            let err = res.data.message
            openNotification(
              notificationTypes.ERROR,
              `Error: ${res.data.status}`,
              res.data.message.errorOfTestcase || 'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong, please try again later'
          )
        })

    } else {
      if (selectedAppId === '') {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30820',
          'Please select an application'
        )
      } else if (selectedModId === '') {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30830',
          'Please select a module'
        )
      } else if (selectedScenarioId === '') {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30840',
          'Please select a scenario'
        )
      } else {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30810',
          'Please enter all the details'
        )
      }
    }
  }

  const getTestCaseID = (scenarioID) => {
    GET(`/getTestCases?scenarioID=${scenarioID}`, { sessionID: sessionId })
      .then((res) => {
        if (
          res.data.status === 200 ||
          res.data.status === 304 ||
          res.data.status === 'ok'
        ) {
          setTCID(res.data.message.length + 1)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log('tc not added')
      })
  }

  const getApplications = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setApplications(res.data.message || [])
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleAppModules = (appId) => {
    GET(`/getModules?appID=${appId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setModules(res.data.message || [])
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleModScenario = (moduleId) => {
    GET(`/getScenario?moduleID=${moduleId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setScenarios(res.data.message)
          setTCID('')
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getApplications()
  }, [])

  useEffect(() => {
    if (selectedAppId) {
      handleAppModules(selectedAppId)
    }
  }, [selectedAppId])

  useEffect(() => {
    if (selectedModId) {
      handleModScenario(selectedModId)
    }
  }, [selectedModId])

  useEffect(() => {
    if (selectedScenarioId) {
      getTestCaseID(selectedScenarioId)
    }
  }, [selectedScenarioId])

  useEffect(() => {
    setTcDate(currDate)
  }, [selectedScenarioId])

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])

  return (
    <div ref={modalRef} className={styles.wrapper1}>
      <div className={styles.cardContainer1}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Add Test Case</h2>
          </div>
          <div className={styles.close}>
            <span onClick={closeModal}>
              <CloseCircleOutlined
                style={{ fontSize: '30px', color: 'black' }}
              />
            </span>
          </div>
        </div>
        <ul className='flex gap-7'>
          <li>
            <select
              className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => {
                const selectedAppId = e.target.value
                const selectedApp = applications.find(
                  (app) => app.id === selectedAppId
                )
                setSelectedAppId(selectedAppId, selectedApp)
                setSelectedModId('')
              }}
              value={selectedAppId || ''}
            >
              <option value=''>Select Application</option>
              {applications.map((value) => (
                <option
                  className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.applicationName}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => {
                const selectedModId = e.target.value
                const selectedModule = modules.find(
                  (mod) => mod.id === selectedModId
                )
                setSelectedModId(selectedModId, selectedModule)
                setSelectedScenarioId('')
              }}
              value={selectedModId || ''}
            >
              <option value=''>Select Module</option>
              {modules.map((value) => (
                <option
                  className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.modelName}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => setSelectedScenarioId(e.target.value)}
              value={selectedScenarioId || ''}
            >
              <option value=''>Select Scenario</option>
              {scenarios.map((value) => (
                <option
                  className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.scenarioName}
                </option>
              ))}
            </select>
          </li>
        </ul>
        {accountType === "jira" ? (

          <ul className='flex gap-7'>
            <li>
              <select
                className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
                name="projectId"
                id="projectId"
                onChange={(e) => {
                  setProjectId(e.target.value)
                  if(e.target.value){
                    const projectData = projectIdData;
                    console.log(projectData,"projectData471")
                    for (let i = 0; i < projectData.length; i++) {
                      if (e.target.value == projectData[i].id) {
                        console.log(projectData[i].key,"projectData[i].key")
                        setProjectKey(projectData[i].key);
                      }
                    }
                  }
                }}
              >
                <option>Select Project</option>
                {projectIdData.map((items, index) => {
                  return (
                    <>
                      <option value={items.id}>{items.key}</option>
                    </>
                  );
                })}
              </select>
            </li>
            <li>
              <select
                className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
                name="projectId"
                id="projectId"
                onChange={(e) => {
                  setFolderId(e.target.value)
                }}
              >
                <option>Select Folder</option>
                {folderIdData.map((items, index) => {
                  return (
                    <>
                      <option value={items.folderId}>{items.folderName}</option>
                    </>
                  );
                })}
              </select>
            </li>
          </ul>
        ) : ("")}
        <div className={styles.TCgrid}>
          <div>
            <h1 className='mb-1 pt-3 font-DMSANS font-bold text-base xl:text-xl'>
              {' '}
              TestCaseName &nbsp;
            </h1>
            <h1 className='w-full mb-0 font-DMSANS text-customGreyTwo text-lg'>
              <input
                type='text'
                className='p-2 w-full bg-transparent border-2 border-gray-200 rounded-lg outline-none'
                value={tcName}
                onChange={(e) => setTcName(e.target.value)}
              />
            </h1>
          </div>
        </div>
        <label
          for='exampleFormControlTextarea1'
          class='mt-4 form-label inline-block mb-2 text-gray-700'
        >
          Test Case Description
        </label>
        <textarea
          class='form-control block w-full px-3 py-3 mb-5 text-md font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          value={tcDesc}
          onChange={(e) => setTcDesc(e.target.value)}
          placeholder='Enter Description'
          rows='3'
        />
        <ul className='flex gap-10'>
          <li>
            <h1 className='font-DMSANS font-bold text-base xl:text-xl'>
              ID &nbsp;
            </h1>
            <h1 className='w-full font-DMSANS text-black font-bold text-base'>
              <input
                type='text'
                className='p-2 -mt-3 bg-gray-200 border-2 border-gray-200 rounded-lg outline-none'
                value={tcID}
                disabled
              />
            </h1>
          </li>
          <li className={styles.TCgrid}>
            <div>
              <SingleSelectComponent
                value={TCType}
                onChangeHandler={(e) => setTCType(e.target.value)}
                options={TCtype}
                label='Type'
              />
            </div>
                <div>
              <SingleSelectComponent
                value={TCstat}
                onChangeHandler={(e) => setTCstatus(e.target.value)}
                options={TCstatus}
                label='Status'
              />
              </div>
            {accountType === "jira" ? (
            <div>
              <SingleSelectComponent
                value={TCpriority}
                onChangeHandler={(e) => setTCpriority(e.target.value)}
                options={TCpriorityVal}
                label='Priority'
              />
            </div>):("")}
          </li>
        </ul>
        <div class='flex items-center justify-between'>
          <button
            class='w-full bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            onClick={addTC}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default TestcaseModalbutton
