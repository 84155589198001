import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { signUp } from '../../../redux/ducks/userDuck'
import { notificationTypes, openNotification } from '../../../utils/notifications'
import { getPasswordStrength } from '../../../utils/user'
import arrow from '../../../assets/Icons/greenarrow.png'

function SignUpForm () {
  const dispatch = useDispatch()

  const [username, setUserName] = useState('')
  const [accountType, setAccountType] = useState(1)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [btnText, setBtnText] = useState('Continue')
  const [isColor1, setisColor1] = useState(false)

  useEffect(() => {
    if (email && password && confirmPassword) {
      setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [email, password, confirmPassword])

  const setTestLead = () => {
    setAccountType(1)
    setisColor1(true)
  }

  const handleSignUp = (e) => {
    e.preventDefault()

    if (username === '' || password === '' || confirmPassword === '' || email === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error: 10010',
        'Please ensure that the passwords match.'
      )
    }

    if (password !== confirmPassword) {
      openNotification(
        notificationTypes.ERROR,
        'Error: 10030',
        'Please ensure that the passwords match.'
      )
    } else {
      setBtnText('Signing Up...')
      setIsBtnDisabled(true)
      dispatch(
        signUp({
          email,
          username,
          password,
          accountType
        })
      )
    }

    setBtnText('Signing Up...')
    setIsBtnDisabled(true)
  }

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Enter') {
      document.getElementById('myButton').click()
    }
  })

  return (
    <div className='mr-12'>
      <form className='rounded px-2 lg:px-8 pt-6 pb-3 mb-4'>
        <div className='mb-4'>
          <p className='ml-2 text-2xl font-bold font-DMSANS mb-4'>I am a,</p>
          <button
            type='button'
            className='px-3 py-1 font-DMSANS border-2 border-customGreySix text-base rounded-xl font-bold text-center mr-4'
            style={isColor1 === true ? { backgroundColor: '#3481E9', color: 'white' } : { backgroundColor: '#E0E0E0', color: 'black' }}
            onClick={setTestLead}
          >
            Test Lead
          </button>
        </div>
        <div className='mb-4'>
          <label className='ml-2 block text-gray-700 text-sm font-bold mb-2' for='username'>
            Username
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            id='username'
            placeholder='Supreeth'
            type='text'
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className='mb-4'>
          <label className='ml-2 block text-gray-700 text-sm font-bold mb-2' for='username'>
            Enter Email ID
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            id='email'
            type='email'
            placeholder='Delllaptops1920@gmail.com'
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='mb-4'>
          <label className='ml-2 block text-gray-700 text-sm font-bold mb-2' for='password'>
            Password
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            id='password'
            type='password'
            placeholder='Enter the Password'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='mb-6'>
          <label className='ml-2 block text-gray-700 text-sm font-bold mb-2' for='password'>
            Confirm Password
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            id='confirmPassword'
            type='password'
            placeholder='Confirm Password'
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className='flex justify-end'>
          <button
            id='myButton'
            className={
              isBtnDisabled === true
                ? 'bg-customGreyOne border-2 text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline cursor-not-allowed'
                : 'bg-customGreenFour hover:bg-green-300 border-2 hover:border-black text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
            }
            type='button'
            onClick={handleSignUp}
            disabled={isBtnDisabled}
          >
            <div className='flex'>
              {btnText}
              <img className='mr-1' src={arrow} />
            </div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignUpForm
