import IMAGE from '../../../assets/Images/carousel1.png'
import IMAGE1 from '../../../assets/Images/Carousel2.png'
import IMAGE2 from '../../../assets/Images/carousel3.png'
import IMAGE3 from '../../../assets/Images/Carousel4.png'
import IMAGE4 from '../../../assets/Images/carousel5.png'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EnquiryModal from '../../../components/Modal/EnquiryModal'

function FirstSection () {
  const Navigate = useNavigate()
  const [isModal, setIsModal] = useState(false)

  return (
    <div className='pt-12 md:pt-14 bg-customGreyFour'>
      <Carousel
        autoPlay
        infiniteLoop
        interval={1000000}
        showThumbs={false}
        showArrows
        showStatus={false}
        showIndicators={false}
      >
        <div onClick={() => setIsModal(true)}>
          <img src={IMAGE} />
        </div>
        <div onClick={() => setIsModal(true)}>
          <img src={IMAGE1} />
        </div>
        <div>
          <img src={IMAGE2} />
        </div>
        <div onClick={() => setIsModal(true)}>
          <img src={IMAGE3} />
        </div>
        <div onClick={() => setIsModal(true)}>
          <img src={IMAGE4} />
        </div>
      </Carousel>
      <EnquiryModal showModal={isModal} setShowModal={setIsModal} />
    </div>
  )
}

export default FirstSection
