import { documentTitles } from '../../constants/appConstants'
import { useState, useEffect } from 'react'
import CompanyOnboardingImage from '../../assets/Images/companyOnboarding.png'
import STARTUP from '../../assets/Images/startup.png'
import ENTERPRISE from '../../assets/Images/enterprise.png'
import MIDSIZE from '../../assets/Images/midsize.png'

import SingleSelectComponent from '../../components/InputComponents/SingleSelectComponent'
import TextField from '../../components/InputComponents/InputField'
import { companyType } from '../../constants/company'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../utils/notifications'
import { routes } from '../../constants/routes'
import { POST } from '../../config/api'
import arrow from '../../assets/Icons/greenarrow.png'

function CompanyOnboarding () {
  const sessionId = useSelector((state) => state.user.sessionId)
  const user = useSelector((state) => state.user)

  const [compType, setCompType] = useState(companyType[0].value)
  const [compName, setCompName] = useState('')
  const [address, setAddress] = useState('')
  const [compWebsite, setCompWebsite] = useState('')
  const [contact, setContact] = useState('')
  const [licences, setLicences] = useState('')
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {
    document.title = documentTitles.COMPANY_ONBOARDING
  }, [])

  useEffect(() => {
    if (compType.length > 0 && compName.length > 0 && address.length > 0 && compWebsite.length > 0 && contact.length > 0 && licences.length > 0) {
      setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [compType, compName, compWebsite, contact, licences])

  const handleCompanyOnboarding = (e) => {
    e.preventDefault()

    if (compType.length > 0 && compName.length > 0 && address.length > 0 && compWebsite.length > 0 && contact.length > 0 && licences.length > 0) {
      const Data = {
        companyType: compType,
        companyName: compName,
        companyWebsite: compWebsite,
        companyAddress: address,
        companyContact: contact,
        companyLicences: licences
      }

      if (Data.companyType == 'None') {
        openNotification(
          notificationTypes.ERROR,
          'ERROR: 10230',
          'The Company Type is not selected. Please choose a company type.'
        )
        return
      }

      POST('/postCompany', Data, { sessionID: sessionId })
        .then((res) => {
          console.log(res)
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Your Company has been registered successfully'
            )
            window.history.replaceState({}, 'Dashboard', routes.DASHBOARD)
            window.location.reload()
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error: 10220',
              'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Error: 10220',
            'Something went wrong, please try again later'
          )
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error',
        'Please enter all the details'
      )
    }
  }

  return (
    <div className='bg-gray-100 pt-8 px-28'>
      <div className='flex items-center justify-center h-screen grid grid-cols-1 lg:grid-cols-9 gap-24'>
        <div className='col-span-4 border-r border-customGreenSix'>
          {
            compType === 'Startup' &&
            (
              <div>
                <img src={STARTUP} alt='Company Onboarding' className='w-96' />
                <h1 className='text-5xl mb-1'>
                  Startup
                </h1>
                <p className='mt-5 mr-24'>
                  Testing is a critical component of success for startup companies.
                  CXO Dashboard helps founders in assessing risk and provide key information
                  which will improve the quality of their products, reduce costs, increase customer
                  satisfaction, ensure compliance, and enable continuous improvement.
                  This can help to build a strong foundation for long-term success in the marketplace.
                </p>
              </div>
            )

          }
          {
            compType === 'Enterprise' &&
            (
              <div>
                <img src={ENTERPRISE} alt='Company Onboarding' className='w-96' />
                <h1 className='text-5xl mb-4'>
                  Enterprise
                </h1>
                <p className='mt-5 mr-24'>
                  CXO dashboard is a valuable tool for enterprises to help them manage and optimize their testing efforts.
                  By providing centralized testing data, real-time metrics, improved collaboration, better resource management,
                  enhanced decision-making, and advanced analytics and visualization capabilities, an enterprise testing dashboard can help enterprises
                  to ensure that their testing efforts are effective and efficient, enabling them to achieve their project goals.
                </p>
              </div>
            )
          }
          {
            compType === 'SMB' &&
            (
              <div>
                <img src={MIDSIZE} alt='Company Onboarding' className='w-96' />
                <h1 className='mt-5 text-5xl mb-4'>
                  SMB
                </h1>
                <h1 className='mt-2 text-3xl mb-4'>
                  Small and Medium-sized Business
                </h1>
                <p className='mt-5 mr-24'>
                  CXO dashboard can be a useful tool for small and medium-sized businesses (SMBs) to help them track
                  and manage their testing efforts. By providing centralized testing data, real-time metrics,
                  improved collaboration, better resource management, and enhanced decision-making, a testing dashboard
                  can help SMBs to ensure that their testing efforts are effective and efficient, enabling
                  them to achieve their project goals.
                </p>
              </div>)
          }
          {
            compType === 'None' &&
            (
              <div>
                <img src={CompanyOnboardingImage} alt='Company Onboarding' />
                <h1 className='text-4xl mb-4'>
                  {
                    user !== null
                      ? (
                          user.accountType === 0 ? 'CXO' : 'Test Lead'
                        )
                      : (
                          'Null account'
                        )
                  }
                </h1>
                <p className='mt-5 mr-24'>
                  CXO Dashboard helps Test Lead plays a crucial role in increasing the planning and productivity of
                  the testing team. Dashboard helps test lead do release based planning for developing test plans,
                  managing test execution, and continuously improving the process, the Test Lead can ensure that the
                  testing team is working efficiently and effectively. This, in turn, can help to ensure the quality
                  and success of the project
                </p>
              </div>
            )
          }
        </div>
        <div className='col-span-5 ml-16'>
          <div>
            <div className='grid grid-cols-2 gap-8'>
              <TextField
                label='Company Name'
                placeholder='Eg: Dell'
                value={compName}
                onChangeHandler={(e) => setCompName(e.target.value)}
                type='text'
              />
              <SingleSelectComponent
                value={compType}
                onChangeHandler={(e) => setCompType(e.target.value)}
                options={companyType}
                label='Type of the company'
              />
            </div>
            <div className='grid grid-cols-2 gap-8'>
              <TextField
                label='Company website'
                placeholder='Delllaptops1920@gmail.com'
                value={compWebsite}
                onChangeHandler={(e) => setCompWebsite(e.target.value)}
                type='text'
              />
              <TextField
                label='Contact'
                placeholder='+91 99999 99999'
                value={contact}
                onChangeHandler={(e) => setContact(e.target.value)}
                type='text'
              />
            </div>
            <form>
              <div className='grid grid-cols-3 gap-4'>
                <div class='col-span-2 mb-3 xl:w-full'>
                  <label for='exampleFormControlTextarea1' class='ml-1 font-medium text-base font-DMSANS form-label inline-block mb-2 text-black'>
                    Address
                  </label>
                  <textarea
                    class='form-control block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-400 rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                    value={address}
                    placeholder='Bangalore'
                    onChange={(e) => setAddress(e.target.value)}
                    id='company address'
                    rows='1'
                  />
                </div>
                <TextField
                  label='Number of licences'
                  value={licences}
                  onChangeHandler={(e) => setLicences(e.target.value)}
                  type='text'
                  placeholder='Eg: 10'
                />
              </div>
              <div class='mt-5 flex justify-end'>
                <button
                  class='bg-customGreenFour hover:bg-green-300 border-2 hover:border-black text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
                  type='button'
                  onClick={handleCompanyOnboarding}
                >
                  <div className='flex'>
                    Continue
                    <img className='mr-1' src={arrow} />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyOnboarding
