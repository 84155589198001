import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  footerText: {
    fontWeight: 600,
    marginRight: 10,
    color: '#28804D'
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 'auto'
  },
  footerLine: {
    flex: 1,
    backgroundColor: '#E5F2EC',
    height: 2,
    width: 100
  }
})

const Footer = (props) => {
  return (
    <View style={styles.footer}>
      {props.title && <Text style={styles.footerText}>{props.title}</Text>}
      <View style={styles.footerLine} />
    </View>
  )
}

export default Footer
