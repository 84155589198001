import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import TestCase from './TestCase'
import { documentTitles } from '../../constants/appConstants'

const ReportLandingPage = () => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [allTC, setAllTC] = useState({})
  const [appName, setAppName] = useState('')
  const [filteredTC, setFilteredTC] = useState({})

  const getTC = () => {
    GET('/gettestrundetails', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setAllTC(res.data.message)
          setFilteredTC(res.data.message)
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const filterTC = () => {
    const filtered = allTC.filter((tc) => {
      return tc.applicationName.toLowerCase().includes(appName.toLowerCase())
    })
    setFilteredTC(filtered)
  }

  useEffect(() => {
    getTC()
    document.title = documentTitles.REPORT
  }, [])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Reports
            </h1>
          </div>
          <div className='flex justify-between items-center col-span-5 my-4'>
            <div>
              <div class='mb-3 w-full'>
                <div class='relative mb-4 flex w-full flex-wrap items-stretch'>
                  <input
                    type='search'
                    class='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                    placeholder='Search by applications'
                    aria-label='Search'
                    aria-describedby='button-addon3'
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                  />
                  <button
                    class='relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0'
                    type='button'
                    id='button-addon3'
                    data-te-ripple-init
                    onClick={filterTC}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      class='h-5 w-5'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z'
                        clip-rule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='overflow-x bg-customWhite rounded-xl p-4 shadow-xl'>
            <table className='table-auto w-full overflow-x-scroll mx-1 border-1 rounded-3xl border-customGreyThree'>
              <thead class='text-center bg-customGrey rounded-2xl'>
                <tr>
                  <th
                    scope='col'
                    class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Application Name
                  </th>
                  <th
                    scope='col'
                    class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Test Run Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTC == null
                  ? null
                  : filteredTC.length > 0
                    ? (
                        filteredTC.map((tc) => <TestCase tc={tc} />)
                      )
                    : (
                      <tr class='border-b border-1 border-customGreyThree'>
                        <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                          No Test Cases Found
                        </td>
                        <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>
                          -
                        </td>
                      </tr>
                      )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportLandingPage
