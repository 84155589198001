import { useNavigate } from 'react-router-dom'

const TestCase = (props) => {
  const navigate = useNavigate()

  return (
    <>
      <tr class='border-b border-1 border-customGreyThree'>
        <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>{props.tc.applicationName}</td>
        <td target='_blank' onClick={() => window.open(`/viewmanualrunresults/${props.tc.id}`, '_blank')} class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>{props.tc.name}</td>
      </tr>
    </>
  )
}

export default TestCase
