import './App.css'
import 'antd/dist/antd.min.css'
import { useContext } from 'react'
import HashLoader from 'react-spinners/HashLoader'
import NavBar from './components/Navbar'
// import SignedInNavbar from "./components/Navbar/SignedInNavbar";
import Footer from './components/Footer'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
  signedOutRoutes,
  signedInRoutes,
  renderAlwaysRoutes
} from './constants/appConstants'
import { useSelector } from 'react-redux'
// import Sidebar from "./components/sidebarnav";
import { useEffect } from 'react'
import { routes } from './constants/routes'
import CustomLayout from './container/Layout.js'
import { ModelContext } from '././contexts/modelContext'

const override = {
  display: 'block',
  margin: '0 auto'
  // borderColor: "rgb(54, 214, 60)",
}
function App () {
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()
  const curUrL = window.location.pathname
  const urlText = curUrL.split('/')[2]
  const [modelstate] = useContext(ModelContext)
  useEffect(() => {
    if (urlText && urlText.localeCompare('varifyemail') === 0) {
      if (user === null) {
        navigate(routes.SIGN_IN)
      }
    }
  }, [])

  return user ? (
    <>
      {modelstate.loading && (
        <div className='loader-screen'>
          <div style={{ margin: '15% auto' }}>
            <HashLoader
              color='#36d7b7'
              loading={modelstate.loading}
              cssOverride={override}
              size={80}
              aria-label='Loading'
              data-testid='loader'
            />
          </div>
        </div>
      )}
      <CustomLayout />
      {/* <div className="body">
        <div className="grid grid-cols-8 gap-8">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-6 mx-auto">
            <SignedInNavbar />
          </div>
        </div>
        <Routes>
          {user === null ? (
            <>
              {signedOutRoutes.map((route, routeIdx) => (
                <Route
                  key={routeIdx}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </>
          ) : (
            <>
              {signedInRoutes.map((route, routeIdx) => (
                <Route
                  key={routeIdx}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </>
          )}
          {renderAlwaysRoutes.map((route, routeIdx) => (
            <Route key={routeIdx} path={route.path} element={route.element} />
          ))}
        </Routes>
      </div> */}
    </>
  ) : (
    <div className='body'>
      <NavBar />
      <Routes>
        {user === null
          ? (
            <>
              {signedOutRoutes.map((route, routeIdx) => (
                <Route key={routeIdx} path={route.path} element={route.element} />
              ))}
            </>
            )
          : (
            <>
              {signedInRoutes.map((route, routeIdx) => (
                <Route key={routeIdx} path={route.path} element={route.element} />
              ))}
            </>
            )}
        {renderAlwaysRoutes.map((route, routeIdx) => (
          <Route key={routeIdx} path={route.path} element={route.element} />
        ))}
      </Routes>
      <div className='footer'>
        <Footer />
      </div>
    </div>
  )
  // <div className='body'>
  //   {user === null
  //     ? <NavBar />
  //     : (
  //       <div className='grid grid-cols-8 gap-8'>
  //         <div className='col-span-2'>
  //           <Sidebar />
  //         </div>
  //         <div className='col-span-6 mx-auto'>
  //           <SignedInNavbar />
  //         </div>
  //       </div>
  //       )}

  //   <Routes>
  //     {user === null
  //       ? (
  //         <>
  //           {signedOutRoutes.map((route, routeIdx) => (
  //             <Route key={routeIdx} path={route.path} element={route.element} />
  //           ))}
  //         </>
  //         )
  //       : (
  //         <>
  //           {signedInRoutes.map((route, routeIdx) => (
  //             <Route key={routeIdx} path={route.path} element={route.element} />
  //           ))}
  //         </>
  //         )}
  //     {renderAlwaysRoutes.map((route, routeIdx) => (
  //       <Route key={routeIdx} path={route.path} element={route.element} />
  //     ))}
  //   </Routes>
  //   <div className='footer'>
  //     <Footer />
  //   </div>
  // </div>
}

export default App
