import { take, takeLatest } from 'redux-saga/effects'

import {
  handleSignIn,
  handleSignOut,
  handleSignUp
} from './handlers/userHandlers'

import { SIGN_UP, SIGN_IN, SIGN_OUT } from '../ducks/userDuck'

export function * watcherSaga () {
  yield takeLatest(SIGN_IN, handleSignIn)
  yield takeLatest(SIGN_UP, handleSignUp)
  yield takeLatest(SIGN_OUT, handleSignOut)
}
