import Application from '../ViewManualTestCases/Application'
import { useState, useEffect } from 'react'
import { GET, POST } from '../../config/api'
import Sidebar from '../../components/sidebarnav'
import { useSelector } from 'react-redux'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { useParams, useNavigate } from 'react-router-dom'
import name from '../../assets/Icons/name.png'

const ViewAutomaticTestCases = () => {
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)
  const sessionId = useSelector((state) => state.user.sessionId)
  const { testpackID } = useParams()

  const [testPack, setTestPack] = useState({})
  const [application, setApplication] = useState({})
  const [tpID, settpID] = useState('')

  useEffect(() => {
    GET(`/getTestPackById?id=${testpackID}`, { sessionID: sessionId })
      .then((res) => {
        setTestPack(res.data.message)
        settpID(res.data.message.id)

        GET(`/getSpecificApp?appID=${res.data.message.application_id}`, { sessionID: sessionId })
          .then((res) => {
            setApplication(res.data.message)
          }
          )
          .catch((err) => {
            console.log(err)
          }
          )
      }
      )
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }, [])

  return (
    <>
      <div className='pb-52 bg-gray-100 pt-16'>
        <div className='mr-12 grid grid-cols-10 gap-24'>
          <div className='col-span-2' />
          <div className='ml-12 pb-32 col-span-8'>
            <div className='mt-5 py-10 min:h-2/5'>
              <div className='mr-20 grid grid-cols-2 gap-8'>
                <div>
                  <h1 className='font-DMSANS text-black font-bold xl:mt-3 text-lg xl:text-5xl'>Test Pack Details</h1>
                </div>
                {
                  user === null
                    ? null
                    : user.accountType === 2
                      ? (
                        <div className='ml-auto mt-3'>
                          <button onClick={() => navigate(`/postautomaticresults/${testpackID}`)} className='bg-customBlue text-white font-bold py-2 px-4 rounded'>
                            Execute Automatic Run
                          </button>
                        </div>
                        )
                      : null
                }
              </div>
              {
                testPack === null
                  ? null
                  : (
                    <div>
                      <div className='grid grid-cols-6 gap-2'>
                        <div className='col-span-2 mb-5 bg-white rounded-xl'>
                          <div className='grid grid-cols-3 gap-0'>
                            <div className='m-auto'>
                              <img src={name} alt='id' />
                            </div>
                            <div className='col-span-2'>
                              <h1 className='pt-5 font-DMSANS font-bold text-base xl:text-xl'>
                                Test Pack Name &nbsp;
                              </h1>
                              <h1 className='pb-3 font-DMSANS text-customGreyTwo text-base'>
                                <span className='font-regular'>
                                  {testPack.testpack_name}
                                </span>
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-4 mb-5 bg-white rounded-xl'>
                          <h1 className='pt-3 font-DMSANS font-bold text-base xl:text-xl ml-5'> Test Pack Description  &nbsp;</h1>
                          <h1 className='pb-3 font-DMSANS text-customGreyTwo text-base ml-5'>{testPack.testpack_description}</h1>
                        </div>
                      </div>
                    </div>
                    )
              }
              <div className='bg-customWhite p-5 h-96 overflow-y-auto mb-10 rounded-2xl'>
                {
                  application == null
                    ? null
                    : (
                      <Application app={application} />
                      )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAutomaticTestCases
