import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import FooterImage from '../../assets/Images/logo.png'
import Reports from './Reports'
import DashboardItem from './DashboardItem'
import DefectItem from './Defects/Index'
import TestCaseManagement from './TestCaseManagement'
import CxoView from './CxoView'
import UserManagement from './UserManagement'
import Setting from './Setting'
import User from './User'
import CXOTestPack from './CXOTestPack'
import TestExecution from './TestExecution'
import ReleasePlanner from './ReleasePlanner'
import Feature from './Feature'
import Howitworks from './Howitworks'
import SecurityTest from './SecurityTest'

const Sidebar = () => {
  const { pathname } = useLocation()

  const user = useSelector((state) => state.user)

  return (
    <>
      {pathname === '/company'
        ? null
        : (
          <aside
            className='absolute left-0 top-0 pt-4 bg-white w-81 pr-5'
            aria-label='Sidebar'
          >
            <div className='py-3 overflow-y-auto'>
              <div className='grid grid-rows-8 gap-8'>
                <div className='row-span-4'>
                  <a className='flex items-center pl-7 mb-5' href='/'>
                    <img src={FooterImage} alt='Greentick Logo' />
                  </a>
                </div>
                <div className='row-span-4'>
                  <ul className='space-y-2'>
                    <li>
                      <DashboardItem />
                    </li>
                    {user === null
                      ? null
                      : user.accountType === 2 ||
                    user.accountType === 0
                        ? (
                          <TestCaseManagement />
                          )
                        : (
                          <div />
                          )}
                    {user === null
                      ? null
                      : user.accountType === 1
                        ? (
                          <TestCaseManagement />
                          )
                        : (
                          <CXOTestPack />
                          )}
                    {user === null
                      ? null
                      : user.accountType === 1
                        ? (
                          <CxoView />
                          )
                        : (
                          <div />
                          )}
                    {user === null
                      ? null
                      : user.accountType === 1
                        ? (
                          <UserManagement />
                          )
                        : (
                          <div />
                          )}
                    {user === null
                      ? null
                      : user.accountType === 2
                        ? (
                          <li>
                            <TestExecution />
                          </li>
                          )
                        : (
                          <div />
                          )}

                    <li
                      className={
                        user === null
                          ? null
                          : user.accountType === 3
                            ? 'pb-48 mb-24 border-b-2 border-customGreyThree'
                            : ''
                      }
                    >
                      <Reports />
                    </li>
                    {user === null
                      ? null
                      : user.accountType !== 3
                        ? (
                          <ReleasePlanner />
                          )
                        : (
                          <div />
                          )}
                    {user === null
                      ? null
                      : user.accountType !== 3
                        ? (
                          <Feature />
                          )
                        : (
                          <div />
                          )}
                          {user === null
                      ? null
                      : user.accountType === 1
                        ? (
                          <DefectItem />
                          )
                        : (
                          <div />
                          )}
                    {user === null
                      ? null
                      : user.accountType !== 3
                        ? (
                          <Howitworks />
                          )
                        : (
                          <div />
                          )}
                    <User />
                    <Setting />
                  </ul>
                </div>
                <ul>
                  {user === null
                    ? null
                    : user.accountType !== 3
                      ? (
                        <SecurityTest />
                        )
                      : (
                        <div />
                        )}
                  {user === null
                    ? null
                    : user.accountType !== 3
                      ? (
                        <ReleasePlanner />
                        )
                      : (
                        <div />
                        )}
                  {user === null
                    ? null
                    : user.accountType !== 3
                      ? (
                        <Feature />
                        )
                      : (
                        <div />
                        )}
                  {user === null
                    ? null
                    : user.accountType !== 3
                      ? (
                        <Howitworks />
                        )
                      : (
                        <div />
                        )}

                  <User />
                  <Setting />
                </ul>
              </div>
            </div>
          </aside>
          )}
    </>
  )
}

export default Sidebar
