import { Image, Page, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  footerPage: {
    backgroundColor: '#E5F2EC'
  }
})

const FooterPage = () => {
  return (
    <Page size='A4' style={styles.footerPage}>
      <Image src='./ReportFooter.png' />
    </Page>
  )
}

export default FooterPage
