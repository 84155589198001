import { useState, useEffect, useRef } from 'react'
import styles from '../modal.module.css'
import close from '../../../assets/Icons/close.png'
import sheet from '../../../assets/Icons/sheets.png'
import sample from '../../../assets/Excel/sample_sheet.xlsx'
import summarysheet from '../../../assets/Excel/Summary.xlsx'
import { useSelector } from 'react-redux'

import { read, utils } from 'xlsx'
import {
  notificationTypes,
  openNotification,
  showErrorNotification
} from '../../../utils/notifications'
import { GET, POST } from '../../../config/api'

const ImportAllSuiteFromExcel = ({ showModal, setShowModal }) => {
  const modalRef = useRef(null)
  const hiddenFileInput = useRef(null)

  const sessionId = useSelector((state) => state.user.sessionId)

  const [totalTestCase, setTotalTestCase] = useState(0)
  const [currentTestCase, setCurrentTestCase] = useState(0)
  const [importLogs, setImportLogs] = useState([])

  const closeModal = () => {
    setShowModal(false)
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const fileInputClick = (event) => {
    event.target.value = ''
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])

  const clearCount = () => {
    setTotalTestCase(0)
  }

  const StartImporting = async ($event) => {
    const chosenFiles = Array.prototype.slice.call($event.target.files)
    let currTestCase = 0
    if (chosenFiles.length) {
      setCurrentTestCase(0)
      setTotalTestCase(chosenFiles.length - 1)
      // identify template file
      const templateFile = await chosenFiles.filter((file) => {
        return file.name === 'Summary.xlsx'
      })
      if (templateFile == null) {
        showErrorNotification(
          notificationTypes.ERROR,
          'Error: 30920',
          'Summary.xlsx file is not available'
        )
        return
      }

      let templateFileData = null
      // process template file
      templateFileData = await readUploadedFileAsText(templateFile[0])

      if (templateFileData == null) {
        showErrorNotification(
          notificationTypes.ERROR,
          'Error: 30910',
          'All fields in Summary.xlsx are mandatory'
        )
        clearCount()
        return
      }
      if (templateFileData.length - 1 !== chosenFiles.length - 1 - 1) {
        showErrorNotification(
          notificationTypes.ERROR,
          'Error: 30965',
          'Number of files selected and testcases entered do not match'
        )
        clearCount()
        return
      }

      // check each file as per the template
      let doNotProcess = false
      for (const eachTestCase of templateFileData) {
        const tempData = chosenFiles.filter((i) => {
          return (
            i.name.substring(0, i.name.indexOf('.')) === eachTestCase.TestCase
          )
        })
        if (tempData.length === 0) {
          showErrorNotification(
            notificationTypes.ERROR,
            'Error: 30925',
            'Name mismatch - ' + eachTestCase.TestCase
          )
          doNotProcess = true
        }
      }
      if (doNotProcess) {
        return
      }

      // remove template from list
      const filesToProcess = chosenFiles.filter((i) => {
        return i.name !== 'Summary.xlsx'
      })
      // get each file as per the template
      for (const eachTestCase of templateFileData) {
        setCurrentTestCase(++currTestCase)
        const fileToProcess = chosenFiles.filter((i) => {
          const nameVal = i.name.substring(0, i.name.lastIndexOf('.'))
          return nameVal === eachTestCase.TestCase
        })
        console.log('call processEachTestCase')
        await processEachTestCase(eachTestCase, fileToProcess[0])
      }

      //         setShowModal(false)
    }
  }

  const processEachTestCase = async (testCase, fileToProcess) => {
    // check if combination is valid
    // create structure
    // get testcase id
    console.log(testCase,fileToProcess,"fileToProcess fileToProcess")
    const dataToCheck = {
      applicationName: testCase.Application,
      applicationDescription: testCase.Desc,
      moduleName: testCase.Module,
      scenarioName: testCase.Scenario,
      testCaseName: testCase.TestCase,
      testCaseNumber: testCase.Number
    }
    await POST('/validateAndCreateStructure', dataToCheck, {
      sessionID: sessionId
    })
      .then((data) => {
        const tcId = data.data.message.id
        const tcName = data.data.message.name
        console.log(data.data.message,"data.data.message 154")
        if (tcId !== null) {
          // open test case file and import all data using existing code
          updateTestCaseSteps(tcId, fileToProcess)
        } else {
          showErrorNotification(
            notificationTypes.ERROR,
            'Error: 30950',
            'could not get test case id for test case ' +
              tcName +
              '. Steps are not updated'
          )
        }
      })
      .catch((err) => {
        showErrorNotification(
          notificationTypes.ERROR,
          'Error',
          'Something went wrong ' + err
        )
      })
  }

  const updateTestCaseSteps = async (tcId, fileToProcess) => {
    const updatedData = await readUploadedFileAsText(fileToProcess)
    if (updatedData === null) {
      return
    }
    await POST(`/postTestcaseStepViaExcel?testCaseID=${tcId}`, updatedData, {
      sessionID: sessionId
    })
      .then(() => {
        openNotification(
          notificationTypes.SUCCESS,
          'Success',
          'File Uploaded Sucessfully - ' + fileToProcess.name
        )
      })
      .catch((err) => {
        showErrorNotification(
          notificationTypes.ERROR,
          'Error: 30935',
          'Updation of test case steps failed - ' + fileToProcess.name
        )
      })
  }

  const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader()
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort()
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Problem parsing input file ' + inputFile.name
        )
        reject(null)
      }

      temporaryFileReader.onload = (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        let flag = false
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          const updatedData = rows

          if (inputFile.name === 'Summary.xlsx') {
            for (let i = 0; i < rows.length; i++) {
              if (
                rows[i].Application === null ||
                rows[i].Desc === null ||
                rows[i].Module === null ||
                rows[i].Scenario === null ||
                rows[i].TestCase === null ||
                rows[i].Number === null
              ) {
                flag = true
                break
              }
            }
          } else {
            for (let i = 0; i < rows.length; i++) {
              if (
                rows[i].step_id === null ||
                typeof rows[i].step_description === 'undefined'
              ) {
                flag = true
                break
              }
            }
            for (let i = 0; i < rows.length - 1; i++) {
              if (
                rows[i].step_id === rows[i + 1].step_id &&
                i + 1 !== rows.length - 1
              ) {
                flag = true
                break
              }
            }
          }

          if (flag === true) {
            showErrorNotification(
              notificationTypes.ERROR,
              'Error',
              'Some information sent is not valid please upload another file' +
                inputFile.name
            )
            resolve(null)
          } else {
            // return updatedData;
            console.log('completed', 'read data ' + inputFile.name)
            resolve(updatedData)
          }
        } else {
          showErrorNotification(
            notificationTypes.ERROR,
            'Error',
            'Could not find sheet in file' + inputFile.name
          )
          resolve(null)
        }
      }
      temporaryFileReader.readAsArrayBuffer(inputFile)
    })
  }
  const downloadSummary = () => {
    GET('/exportToFile?activity=download_summary', { sessionID: sessionId })
      .then(() => {
        openNotification(
          notificationTypes.SUCCESS,
          'Success',
          'Downloaded summary sheet sucessfully'
        )
      })
  }
  const downloadTestCase = () => {
    GET('/exportToFile?activity=download_testcase', { sessionID: sessionId })
      .then(() => {
        openNotification(
          notificationTypes.SUCCESS,
          'Success',
          'Downloaded testcase sucessfully'
        )
      })
  }

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer1}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className='pb-3 mt-2 underline underline-offset-4 ml-4 text-black font-bold font-DMSANS text-base text-customGreenThree xl:text-2xl leading-none lg:leading-11'>
              Import TestSuite from excel
            </h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <img src={close} alt='close' className='h-12' />
            </a>
          </div>
        </div>
        <div className='pt-5 pl-5 rounded-3xl'>
          <div>
            To import from excel and establish a testing structure in GreenTick,
            follow the steps outlined below:
            <br />
            1. Download and update Summary.xlsx file. This file requires
            following data-
            <br />
            &nbsp;&nbsp;&nbsp;• "Number": indicating the number of test cases to
            be imported.
            <br />
            &nbsp;&nbsp;&nbsp;• "Application": indicating the name of the
            application.
            <br />
            &nbsp;&nbsp;&nbsp;• "Module": indicating the name of the module.
            <br />
            &nbsp;&nbsp;&nbsp;• "Scenario": indicating the name of the scenario.
            <br />
            &nbsp;&nbsp;&nbsp;• "Test Case": indicating the name of the test
            case.
            <br />
            &nbsp;&nbsp;&nbsp;• "Desc": indicating a brief description of the
            test case.
            <br />
            2. Download testcase.xlsx and update the file name to match the
            testcase name. This file requires the following data-
            <br />
            &nbsp;&nbsp;&nbsp;• "step_id"
            <br />
            &nbsp;&nbsp;&nbsp;• "step_description"
            <br />
            &nbsp;&nbsp;&nbsp;• "step_expected_result"
            <br />
            &nbsp;&nbsp;&nbsp;• "data"
            <br />
            Note: There should be one test case file for each test case to be
            imported and the filename should match the test case name provided
            in Summary.xlsx (exclude file extension)
            <br />
            3.Press Import button and select all the files(Summary.xlsx and all
            testcase files). You may place all files in one folder and select
            all for easy selection.
            <br />
            <br />
          </div>
          <div className='grid grid-cols-3 gap-4'>
            <div className='mt-4' onClick={downloadSummary}>
              <a
                href={summarysheet}
                download='Summary.xlsx'
                className='ml-10 mt-1 bg-blue-300 hover:bg-green-300 border-2 text-black font-bold py-5 px-4 text-base rounded-xl focus:outline-none focus:shadow-outline'
              >
                1. Download Summary.xlsx
              </a>
            </div>
            <div className='mt-4' onClick={downloadTestCase}>
              <a
                href={sample}
                download='sample_sheet.xlsx'
                className='ml-10 mt-1 bg-blue-300 hover:bg-green-300 border-2 text-black font-bold py-5 px-4 text-base rounded-xl focus:outline-none focus:shadow-outline'
              >
                2. Download testcase.xlsx
              </a>
            </div>
            <div>
              <button
                onClick={handleClick}
                className='flex hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6 py-4'
              >
                <input
                  type='file'
                  multiple
                  ref={hiddenFileInput}
                  name='file'
                  required
                  onChange={(event) => StartImporting(event)}
                  onClick={(event) => fileInputClick(event)}
                  id='fu'
                  style={{ display: 'none' }}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />
                <img className='h-6' src={sheet} /> &nbsp; 3. Start Import
              </button>
            </div>

            <div className='flex items-center'>
              {totalTestCase !== 0 && (
                <>
                  Processing {currentTestCase} / {totalTestCase}
                </>
              )}
            </div>

            <div>
              {importLogs && importLogs.length > 0 && (
                <>
                  {importLogs.map((i) => {
                    <>
                      <div>i</div>
                    </>
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportAllSuiteFromExcel
