import React, { useState } from 'react'
import {
  PDFViewer,
  Document,
  Page,
  Image,
  PDFDownloadLink
} from '@react-pdf/renderer'
import PDFTemplate from './pdfTemplate'
import { useSelector } from 'react-redux'
import ReportTemplate from './ReportTemplate'
import PDF from './PDF'
import { GET } from '../../../config/api'
import { notificationTypes, openNotification } from '../../../utils/notifications'
function TestDownloadReport (props) {
  const sessionId = useSelector((state) => state.user.sessionId)
  const downloadReport = () => {
    GET('/exportToFile?activity=download_report', { sessionID: sessionId })
      .then(() => {
        openNotification(
          notificationTypes.SUCCESS,
          'Success',
          'Report downloaded Sucessfully'
        )
      })
  }
  return (
    <>
      <div className='flex items-center justify-center' onClick={downloadReport}>
        <PDFDownloadLink
          className='bg-customGreenFour text-black text-lg mb-10 hover:bg-green-300 py-1 px-4 rounded-xl font-medium'
          document={
            <PDF
              releaseId={props.releaseId}
              sessionId={sessionId}
              name={props.name}
              version={props.version}
              releaseDate={props.releaseDate}
              description={props.description}
              overallStatus={props.overallStatus}
            />
          }
          fileName={props.name}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download now!'}
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{ width: '100%', height: '1000px' }}>
        <PDF
          releaseId={props.releaseId}
          sessionId={sessionId}
          name={props.name}
          version={props.version}
          releaseDate={props.releaseDate}
          description={props.description}
          overallStatus={props.overallStatus}
        />
      </PDFViewer>

      {/* <div className="grid grid-cols-9 gap-2 place-content-center align-center">
        <div className="col-span-3" />
        <div className="col-span-3">
          <button
            onClick={togglePdfView}
            type="button"
            className=" bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
          >
            {showPdf ? "Close PDF" : "Open PDF"}
          </button>
        </div>
        <div className="col-span-3" />
        {showPdf && (
          <div className="col-span-9">
            <PDFViewer style={{ width: "100%", height: "600px" }}>
              <PDFTemplate releaseId={releaseId} sessionId={sessionId} />
            </PDFViewer>
          </div>
        )}
      </div> */}
    </>
  )
}

export default TestDownloadReport
