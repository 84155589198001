import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { documentTitles } from '../../constants/appConstants'
import TestCaseData from './TestCaseData'

const TestRunSummary = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [testRun, setTestRun] = useState([])

  const getTestRuns = () => {
    GET('/gettestrun', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setTestRun(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Something went wrong'
        )
      })
  }

  useEffect(() => {
    document.title = documentTitles.TESTRUNSUMMARY
    getTestRuns()
  }, [])

  return (
    <>
      <div className="grid grid-cols-9 gap-1 m-2 p-2">
        <div className="col-span-9">
          {testRun?.length === 0 ? (
              <div className="font-bold text-black font-DMSANS text-4xl py-1 text-center">No Run Created</div>
            ) : testRun?.length > 0 ? (
              <TestCaseData id={testRun[0].id} testRun={testRun} />
            ) :
              <div className="font-bold text-black font-DMSANS text-4xl py-1 text-center">No Run Created</div>
            }
        </div>
      </div>
    </>
  )
}

export default TestRunSummary
