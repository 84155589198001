import { Image, Text, View } from '@react-pdf/renderer'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import BasicPageLayout from '../components/BasicPageLayout'
import { GET } from '../../../../config/api'

const TestPack = (props) => {
  const [selectedTestPack, setSelectedTestPack] = useState([])

  const sortTestPack = (a, b) => {
    if (a.applicationName < b.applicationName) {
      return -1
    } else if (a.applicationName > b.applicationName) {
      return 1
    } else if (a.testpack_name < b.testpack_name) {
      return -1
    } else if (a.testpack_name > b.testpack_name) {
      return 1
    }
    return 0
  }

  const getSelectedTestPacks = () => {
    GET(`/release/testPack/${props.releaseId}`, { sessionID: props.sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          let seltestPack = res.data.message
          seltestPack = seltestPack.sort((a, b) => sortTestPack(a, b))
          setSelectedTestPack(seltestPack)
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useLayoutEffect(() => {
    getSelectedTestPacks()
  }, [])

  return (
    <BasicPageLayout>
      <View style={props.greenContainer}>
        <Text style={props.title}>Test Pack</Text>
        <View style={props.textCont}>
          {selectedTestPack.length > 0
            ? (
                selectedTestPack.map((item) => (
                  <View style={props.point}>
                    <Image src='./check.png' style={props.checkImage} />
                    <Text>
                      {item.testpack_name} ({item.applicationName})
                    </Text>
                  </View>
                ))
              )
            : (
              <View style={props.point}>
                <Text>No Test-Packs found</Text>
              </View>
              )}
        </View>
      </View>
    </BasicPageLayout>
  )
}

export default TestPack
