import TextField from '../../InputComponents/InputField'
import SingleSelectComponent from '../../InputComponents/SingleSelectComponent'
import { TCtype, TCstatus, TCpriorityVal } from '../../../constants/TestCase'
import { DatePicker, Space } from 'antd'
import Label from '../../InputComponents/Label'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { GET, PUT } from '../../../config/api'
import styles from '../modal.module.css'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { accountType } from '../../../constants/accType'

const UpdateTcModal = ({
  showModal,
  setShowModal,
  id,
  scenarioID,
  setRefreshState
}) => {
  const modalRef = useRef(null)
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId)
  const formSettingData = useSelector((state) => state.user.settings);
  const [ownerIdData, setOwnerIdData] = useState([]);
  const [ststusIdData, setStatusIdData] = useState([]);
  const [priorityIdData, setPriorityIdData] = useState([]);

  const [TCType, setTCType] = useState(TCtype[0].value)
  const [TCstat, setTCstatus] = useState(TCstatus[0].value)
  const [TCpriority, setTCpriority] = useState('');
  const [TCpriorityId, setTCpriorityId] = useState('');
  const [accountTypes, setAccountTypes] = useState("greentick");
  const [tcName, setTcName] = useState('')
  const [tcDate, setTcDate] = useState('')
  const [tcID, setTCID] = useState('')
  const [tcFolderId, setFolderId] = useState('')
  const [tcProjectId, setProjectId] = useState('')
  const [tcJiraKey, setJiraKey] = useState('')
  const [tcJiraId, setJiraId] = useState('')
  const [tcDesc, setTcDesc] = useState('')
  const [filterJiraStatusId, setFilterJiraStatusId] = useState();
  const [folderName, setFolderName] = useState('')
  const [projectName, setProjectName] = useState('')
  const [applications, setApplications] = useState([])
  const [modules, setModules] = useState([])
  const [scenarios, setScenarios] = useState([])
  const [selectedAppId, setSelectedAppId] = useState('')
  const [selectedModId, setSelectedModId] = useState('')
  const [selectedScenarioId, setSelectedScenarioId] = useState('')

  const closeModal = () => {
    setShowModal(!showModal)
  }
  useEffect(() => {
    if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
      setAccountTypes(formSettingData.accountType);
      getOwnerId(
        formSettingData.userName,
        formSettingData.token
      )
      // handleStatusId(TCstatus[0].value)
    }
  }, [])
  const getOwnerId = async (userName, token) => {
    GET(`/getownerid?userName=${userName}&token=${token}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          let resData = res.data.message;
          setOwnerIdData(resData[0].ownerId);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting owner id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting owner id, Please try again"
        );
      });
  };


  const updateTC = (e) => {
    if (selectedAppId && selectedModId && selectedScenarioId
      && tcName.length > 0 && tcDesc.length > 0) {
      console.log("if condition")
      const Data = {
        testCaseID: tcID,
        testCaseName: tcName,
        testCaseDescription: tcDesc,
        testcaseType: TCType,
        testCaseStatus: TCstat,
        testCaseDate: tcDate,
        application_id: selectedAppId,
        module_id: selectedModId,
        scenario_id: selectedScenarioId,
        tcFolderId: tcFolderId,
        TCpriorityId: TCpriorityId,
        TCpriority: TCpriority,
        tcProjectId: tcProjectId,
        tcJiraId: tcJiraId,
        tcJiraKey: tcJiraKey,
        ownerIdData: ownerIdData,
        filterJiraStatusId: filterJiraStatusId
      };
  
      PUT(`/updateTestCase?testcaseID=${tcID}`, Data, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'TestCase updated successfully'
            );
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error: ' + res.data.status,
              'Failed to update TestCase'
            );
          }
          setShowModal(!showModal);
          setRefreshState(true);
          navigate(`/testcase/${tcID}/${selectedScenarioId}`);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          );
        });
    } else {
      console.log("else condition")
      if (selectedAppId === '') {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30820',
          'Please select an application'
        );
      } else if (selectedModId === '') {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30830',
          'Please select a module'
        );
      } else if (selectedScenarioId === '') {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30840',
          'Please select a scenario'
        );
      } else {
        openNotification(
          notificationTypes.ERROR,
          'Error: 30810',
          'Please enter all the details'
        );
      }
    }
  };
  

  const getApplications = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setApplications(res.data.message || [])
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleAppModules = (appId) => {
    GET(`/getModules?appID=${appId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setModules(res.data.message || [])
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleModScenario = (moduleId) => {
    GET(`/getScenario?moduleID=${moduleId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setScenarios(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getApplications()
  }, [])

  useEffect(() => {
    if (selectedAppId) {
      handleAppModules(selectedAppId)
    }
  }, [selectedAppId])

  useEffect(() => {
    if (selectedModId) {
      handleModScenario(selectedModId)
    }
  }, [selectedModId])

  const onChange = (date, dateString) => {
    setTcDate(dateString)
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0';
      modalRef.current.style.opacity = '1';
    } else {
      modalRef.current.style.top = '120vh';
      modalRef.current.style.opacity = '0';
    }

    GET(`/getSpecificTestcase?testcaseID=${id}&scenarioID=${scenarioID}`, {
      sessionID: sessionId
    })
      .then((res) => {
        console.log(res.data.message, "line 180")
        setSelectedAppId(res.data.message.application_id);
        setSelectedModId(res.data.message.module_id);
        setSelectedScenarioId(res.data.message.scenario_id);
        setTCID(res.data.message.id);
        setFolderId(res.data.message.folder_id);
        setProjectId(res.data.message.project_id);
        setJiraKey(res.data.message.external_jira_id);
        setJiraId(res.data.message.external_test_case_id)
        setTcName(res.data.message.testcase_name);
        setTcDesc(res.data.message.testcase_description);
        setTCType(res.data.message.testcase_type);
        setTCstatus(res.data.message.testcase_status);
        setTCpriority(res.data.message.testcase_priority);
        setTcDate(res.data.message.testcase_date);

        const folderId1 = res.data.message.folder_id;
        const ProjectId1 = res.data.message.project_id;
        console.log(res.data.message.project_id, "res.data.message.project_id")
        const statusVal = res.data.message.testcase_status;
        const priorityVal = res.data.message.testcase_priority;

        if (formSettingData && formSettingData.userName) {
          const userName = formSettingData.userName;
          const zephyrToken = formSettingData.zephyrToken;

          const getFolderIds = async (userName, zephyrToken) => {
            try {
              const res = await GET(`/getallfolderid?userName=${userName}&zephyrToken=${zephyrToken}`, {
                sessionID: sessionId,
              });
              if (res.data.status === 200 && res.data.message) {
                const projectData = res.data.message;
                const matchingFolder = projectData.find(data => data.folderId === folderId1);
                if (matchingFolder) {
                  setFolderName(matchingFolder.folderName);
                } else {
                  openNotification(notificationTypes.ERROR, "Folder ID not found.");
                }
              } else {
                openNotification(notificationTypes.ERROR, "Error in getting folder IDs. Please try again.");
              }
            } catch (error) {
              console.error(error);
              openNotification(notificationTypes.ERROR, "Error in getting folder IDs. Please try again.");
            }
          };

          const getProjectIds = async (userName, zephyrToken) => {
            try {
              const res = await GET(`/getzypherproject?userName=${userName}&zephyrToken=${zephyrToken}`, {
                sessionID: sessionId,
              });
              if (res.data.status === 200 && res.data.message) {
                const projectData = res.data.message;
                const matchingProject = projectData.find(data => data.id === ProjectId1);
                if (matchingProject) {
                  setProjectName(matchingProject.key);
                } else {
                  openNotification(notificationTypes.ERROR, "Project ID not found.");
                }
              } else {
                openNotification(notificationTypes.ERROR, "Error in getting project IDs. Please try again.");
              }
            } catch (error) {
              console.error(error);
              openNotification(notificationTypes.ERROR, "Error in getting project IDs. Please try again.");
            }
          };

          const getStatusId = async (userName, zephyrToken) => {
            GET(`/getallstatusid?userName=${userName}&zephyrToken=${zephyrToken}`, {
              sessionID: sessionId,
            })
              .then((res) => {
                if (res.data.status === 200) {
                  const ststusData = res.data.message;
                  console.log(ststusData, "ststusData")
                  let resData = ststusData.filter(item => {
                    return item.projectId === ProjectId1;
                  });
                  setStatusIdData(resData);
                  const filteredStatusId = resData.filter((item) => item.statusName === statusVal);
                  if (filteredStatusId.length > 0) {
                    setFilterJiraStatusId(filteredStatusId);
                    console.log(filteredStatusId, "entered value");
                  }
                  console.log(resData, "91");
                } else {
                  openNotification(
                    notificationTypes.ERROR,
                    "Error in getting ststus, Please try again"
                  );
                }
              })
              .catch(() => {
                openNotification(
                  notificationTypes.ERROR,
                  "Error in getting owner id, Please try again"
                );
              });
          };

          const getPriorityId = async (userName, zephyrToken) => {
            GET(`/getallpriorityid?userName=${userName}&zephyrToken=${zephyrToken}`, {
              sessionID: sessionId,
            })
              .then((res) => {
                if (res.data.status === 200) {
                  const priorityData = res.data.message;
                  console.log(priorityData, "priorityData")
                  let resData = priorityData.filter(item => {
                    return item.projectId === ProjectId1;
                  });
                  console.log(resData, "350")
                  setPriorityIdData(resData);
                  const filteredPriorityId = resData.filter((item) => item.statusName === priorityVal);
                  if (filteredPriorityId.length > 0) {
                    setTCpriorityId(filteredPriorityId[0].PriorityId);
                    console.log(filteredPriorityId[0].PriorityId, "selected  value ");
                  }
                  console.log(resData, "9111");
                } else {
                  openNotification(
                    notificationTypes.ERROR,
                    "Error in getting ststus, Please try again"
                  );
                }
              })
              .catch(() => {
                openNotification(
                  notificationTypes.ERROR,
                  "Error in getting owner id, Please try again"
                );
              });
          };

          if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
            getFolderIds(userName, zephyrToken);
            getProjectIds(userName, zephyrToken);
            getStatusId(userName, zephyrToken);
            getPriorityId(userName, zephyrToken);
          }
        }


      })
      .catch((err) => {
        console.log(err);
        openNotification(notificationTypes.ERROR, 'Something went wrong, please try again later');
      });
  }, [showModal, formSettingData]);

  const handleStatusId = (e) => {
    const filteredStatusId = ststusIdData.filter((item) => item.statusName === e);
    if (filteredStatusId.length > 0) {
      setFilterJiraStatusId(filteredStatusId);
      console.log(filteredStatusId, "entered value");
    } else {
      console.log("No matching item found for", e);
    }
  };

  const handlePriorityId = (e) => {
    const filteredPriorityId = priorityIdData.filter((item) => item.statusName === e);
    if (filteredPriorityId && filteredPriorityId.length > 0) {
      setTCpriorityId(filteredPriorityId[0].PriorityId)
      console.log(filteredPriorityId[0].PriorityId, "entered value");
    } else {
      console.log("No matching item found for", e);
    }
  };

  return (
    <div ref={modalRef} className={styles.wrapper1}>
      <div className={styles.cardContainer1}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Update Test Case</h2>
          </div>
          <div className={styles.close}>
            <span onClick={closeModal}>
              <AiOutlineCloseCircle size={20} />
            </span>
          </div>
        </div>
        <ul className='flex gap-7'>
          <li>
            <select
              className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => {
                const selectedAppId = e.target.value
                const selectedApp = applications.find(
                  (app) => app.id === selectedAppId
                )
                setSelectedAppId(selectedAppId, selectedApp)
                setSelectedModId('')
              }}
              value={selectedAppId || ''}
            >
              <option value=''>Select Application</option>
              {applications.map((value) => (
                <option
                  className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.applicationName}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => {
                const selectedModId = e.target.value
                const selectedModule = modules.find(
                  (mod) => mod.id === selectedModId
                )
                setSelectedModId(selectedModId, selectedModule)
                setSelectedScenarioId('')
              }}
              value={selectedModId || ''}
            >
              <option value=''>Select Module</option>
              {modules.map((value) => (
                <option
                  className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.modelName}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => setSelectedScenarioId(e.target.value)}
              value={selectedScenarioId || ''}
            >
              <option value=''>Select Scenario</option>
              {scenarios.map((value) => (
                <option
                  className='text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.scenarioName}
                </option>
              ))}
            </select>
          </li>
        </ul>
        {accountTypes === "jira" ? (
          <ul className='flex gap-7'>
            <li>
              <TextField
                disabled
                label='Project'
                placeholder={projectName}
                value={projectName}
                type='text'
                required
              />
            </li>

            <li>
              <TextField
                disabled
                label='Folder'
                placeholder={folderName}
                value={folderName}
                type='text'
                required
              />
            </li>
          </ul>
        ) : ('')}
        <div className={styles.TCgrid}>
          <div>
            <TextField
              label='Name of the Test Case'
              placeholder={tcName}
              value={tcName}
              onChangeHandler={(e) => setTcName(e.target.value)}
              maxLength={5000}
              type='text'
              required
            />
          </div>
          <div>
            <TextField
              disabled
              label='Id of the Test Case'
              placeholder={tcID}
              value={tcID}
              onChangeHandler={(e) => setTCID(e.target.value)}
              maxLength={5000}
              type='text'
              required
            />
          </div>
          {accountTypes === "jira" ? (
            <div>
              <TextField
                disabled
                label='Zephyr Id'
                placeholder={tcJiraKey}
                value={tcJiraKey}
                type='text'
                required
              />
            </div>
          ) : ("")}
        </div>
        <label
          for='exampleFormControlTextarea1'
          class='form-label inline-block mb-2 text-gray-700'
        >
          Test Case Description
        </label>
        <textarea
          class='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          value={tcDesc}
          onChange={(e) => setTcDesc(e.target.value)}
          id='Note'
          rows='3'
        />
        <div className={styles.TCgrid}>
          <div>
            <SingleSelectComponent
              value={TCType}
              onChangeHandler={(e) => setTCType(e.target.value)}
              options={TCtype}
              label='Type'
            />
          </div>
          <div>
            <SingleSelectComponent
              value={TCstat}
              onChangeHandler={(e) => {
                setTCstatus(e.target.value);
                handleStatusId(e.target.value)
              }}
              options={TCstatus}
              label='Status'
            />
          </div>
          {accountTypes === "jira" ? (
            <div>
              <SingleSelectComponent
                value={TCpriority}
                onChangeHandler={(e) => {
                  setTCpriority(e.target.value);
                  handlePriorityId(e.target.value)
                }}
                options={TCpriorityVal}
                label='Priority'
              />
            </div>) : ("")}
        </div>
        <div className={styles.TCgrid}>
          <div className={styles.dateWrapper}>
            <Label label='Date' required={false} />
            <DatePicker
              onChange={onChange}
              style={{
                height: '100%%',
                width: '100%',
                color: '#2c2c2c',
                border: '2px solid #F4F4F4',
                borderRadius: '0px',
                cursor: 'pointer',
                fontSize: '17px',
                backgroundColor: 'rgb(209 213 219)',
                borderRadius: '10px',
                margin: '0px',
                padding: '13px'
              }}
            />
          </div>
        </div>
        <div class='flex items-center justify-between'>
          <button
            class='mt-5 w-full bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            onClick={updateTC}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default UpdateTcModal
