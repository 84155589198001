import React from 'react'

const CreateUserButton = () => {
  return (
    <div className='bg-customGrey pb-5 pt-20'>
      <div className='mr-2 grid grid-cols-10 gap-24'>
        <div className='mt-6 mb-40 ml-10 mr-10 col-span-10'>
          <h1 className='text-3xl ml-2 -mt-10 mb-10 font-bold font-DMSANS'>
            Create User
          </h1>
          <div className='flex flex-col gap-10 mt-28'>
            <ul className='flex justify-center gap-20'>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/Adding_a_New_User__Tester__to_GreenTickme__j2Ei3ta2TiuXvVQkVHD5Hg'
                >
                  <button className='w-80 h-20 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'>
                    {' '}
                    CREATE TESTER{' '}
                  </button>
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/Adding_a_User__CXO__in_Greentickme_Dashboard__n1lDVSlnTkynQ0yCUz5wHw'
                >
                  <button className='w-80 h-20 bg-blue-400 hover:bg-blue-600 rounded-lg text-3xl text-white border-solid border-2 border-black'>
                    {' '}
                    CREATE CXO{' '}
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateUserButton
