import React, { useState, useEffect, useRef } from 'react'
import { GET, PUT, POST } from '../../../config/api'
import { useSelector } from 'react-redux'
import { notificationTypes, openNotification } from '../../../utils/notifications'

function TestCompletionReport (releaseId) {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [aboutTheRelease, setAboutTheRelease] = useState()
  const [conclusion, setConclusion] = useState()

  const refConclusion = useRef(null)
  const refAbtRelease = useRef(null)

  useEffect(() => {
    const getReleaseSummary = async () => {
      GET(`/release/${releaseId.releaseId}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            setConclusion(res.data.message.conclusion)
            setAboutTheRelease(res.data.message.aboutTheRelease)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error in getting run completion details, Please try again'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting run completion, Please try again'
          )
        })
    }
    getReleaseSummary()
  }, [])

  const saveTestSummary = () => {
    POST('/release/summary',
      {
        releaseId: releaseId.releaseId,
        aboutTheRelease,
        conclusion
      },
      {
        sessionID: sessionId
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'Release data Mapped successfully'
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error: 82610',
            'Error in Mapping Release. Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error: 82610',
          'Error in mapping Release. Please try again'
        )
      })
  }

  return (
    <>
      <div className='flex justify-end'>
        <button
          className='bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end'
          onClick={saveTestSummary}
        >
          Save
        </button>
      </div>
      <div className='bg-gray-200 mt-5 p-4 rounded-xl'>
        <div className='mb-5'>
          <div className='h-full w-full bg-white rounded-xl'>
            <div className='flex'>
              <div className='ml-5 text-sm mt-2' style={{ color: 'grey' }}>
                About the Release &nbsp;
              </div>
            </div>
            <h1 className='pb-3 mr-5 font-DMSANS text-black text-base ml-5'>
              <textarea
                id='aboutTheRelease'
                ref={refAbtRelease}
                placeholder='Enter about the Release'
                value={aboutTheRelease}
                className='mr-10 w-full h-40 p-2 bg-transparent border-2 border-gray-200 rounded-lg outline-none'
                onChange={(e) => { setAboutTheRelease(e.target.value) }}
              />
            </h1>
            <div className='flex'>
              <div className='ml-5 text-sm mt-2' style={{ color: 'grey' }}>
                Conclusion &nbsp;
              </div>
            </div>
            <h1 className='pb-3 mr-5 font-DMSANS text-black text-base ml-5'>
              <textarea
                id='conclusion'
                ref={refConclusion}
                placeholder='Enter Concluston'
                value={conclusion}
                className='mr-10 w-full h-40 p-2 bg-transparent border-2 border-gray-200 rounded-lg outline-none'
                onChange={(e) => { setConclusion(e.target.value) }}
              />
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}
export default TestCompletionReport
