import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Document, StyleSheet } from '@react-pdf/renderer'
import FooterPage from './pages/FooterPage'
import HeaderPage from './pages/HeaderPage'
import TableOfContentPage from './pages/TableOfContentPage'
import AboutTheProduct from './pages/AboutTheProduct'
import Conclusion from './pages/Conclusion'
import FeaturesAdded from './pages/FeaturesAdded'
import TestPack from './pages/TestPack'
import ReleaseInfo from './pages/ReleaseInfo'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { GET } from '../../../config/api'
import TestRun from './pages/TestRun'
import TestCasses from './pages/TestCasses'

const styles = StyleSheet.create({
  greenContainer: {
    backgroundColor: '#F0F8F4',
    padding: 20,
    paddingBottom: 14,
    borderRadius: 18
  },
  textCont: {
    color: '#929292',
    backgroundColor: 'white',
    marginTop: 10,
    padding: 10,
    fontSize: 12,
    borderRadius: 10,
    border: 1.5,
    borderColor: '#b0b0b0'
  },
  point: {
    flexDirection: 'row',
    columnGap: 10,
    marginBottom: 8
  },
  title: {
    color: '#434343',
    fontSize: 16,
    fontWeight: 500
  },
  checkImage: { width: 16, height: 16 }
})

const PDF = (props) => {
  const [conclusion, setConclusion] = useState()
  const [aboutTheRelease, setAboutTheRelease] = useState()
  const [testRun, setTestRun] = useState([])

  useLayoutEffect(() => {
    const getReleaseSummary = async () => {
      GET(`/release/${props.releaseId}`, {
        sessionID: props.sessionId
      })
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message)
            setConclusion(res.data.message.conclusion)
            setAboutTheRelease(res.data.message.aboutTheRelease)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error in getting run completion details, Please try again'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting run completion, Please try again'
          )
        })
    }
    getReleaseSummary()
  }, [props.sessionId, props.releaseId])

  useLayoutEffect(() => {
    const getTestRun = async () => {
      GET(`/release/testrundetails/${props.releaseId}`, {
        sessionID: props.sessionId
      })
        .then((res) => {
          if (res.data.status === 200) {
            setTestRun(res.data.message)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error in getting TestRuns, Please try again'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting Releases, Please try again'
          )
        })
    }
    getTestRun()
  }, [])

  return (
    <Document>
      <HeaderPage name={props.name} title={styles.title} />
      <TableOfContentPage />
      <ReleaseInfo
        greenContainer={styles.greenContainer}
        textCont={styles.textCont}
        data={props.data}
        releaseId={props.releaseId}
        sessionId={props.sessionId}
        name={props.name}
        version={props.version}
        releaseDate={props.releaseDate}
        description={props.description}
        overallStatus={props.overallStatus}
      />
      <AboutTheProduct
        greenContainer={styles.greenContainer}
        textCont={styles.textCont}
        title={styles.title}
        aboutTheRelease={aboutTheRelease}
      />
      <Conclusion
        greenContainer={styles.greenContainer}
        textCont={styles.textCont}
        title={styles.title}
        conclusion={conclusion}
      />
      <FeaturesAdded
        greenContainer={styles.greenContainer}
        textCont={styles.textCont}
        point={styles.point}
        title={styles.title}
        checkImage={styles.checkImage}
        sessionId={props.sessionId}
        releaseId={props.releaseId}
      />
      <TestPack
        greenContainer={styles.greenContainer}
        textCont={styles.textCont}
        point={styles.point}
        title={styles.title}
        checkImage={styles.checkImage}
        sessionId={props.sessionId}
        releaseId={props.releaseId}
      />
      <TestRun
        title={styles.title}
        sessionId={props.sessionId}
        releaseId={props.releaseId}
        data={testRun}
      />
      {testRun.map((item, index) => (
        <TestCasses
          title={styles.title}
          id={item.id}
          sessionId={props.sessionId}
          key={index}
          name={item.name}
          pass={item.passCount}
          fail={item.failCount}
          norun={item.norunCount}
          inprogress={item.inprogressCount}
          onhold={item.onholdCount}
        />
      ))}
      <FooterPage />
    </Document>
  )
}

export default PDF
