import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useLayoutEffect, useState } from 'react'
import BasicPageLayout from '../components/BasicPageLayout'
import { GET } from '../../../../config/api'
import {
  notificationTypes,
  openNotification
} from '../../../../utils/notifications'
import moment from 'moment'

const styles = StyleSheet.create({
  textFlexWM: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  textFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    width: 140,
    fontSize: 12,
    fontWeight: 500,
    color: '#434343'
  },
  textCont: {
    color: '#929292',
    backgroundColor: 'white',
    padding: 10,
    fontSize: 12,
    borderRadius: 10,
    border: 1.5,
    borderColor: '#b0b0b0',
    flex: 1
  }
})

const ReleaseInfo = (props) => {
  return (
    <BasicPageLayout>
      <View style={props.greenContainer}>
        <View style={styles.textFlex}>
          <Text style={styles.title}>Release Name</Text>
          <Text style={styles.textCont}>{props.name}</Text>
        </View>
        <View style={styles.textFlexWM}>
          <Text style={styles.title}>Release Version</Text>
          <Text style={styles.textCont}>{props.version}</Text>
        </View>
        <View style={styles.textFlexWM}>
          <Text style={styles.title}>Release Date</Text>
          <Text style={styles.textCont}>
            {moment(props.releaseDate).utc().format('DD-MM-YYYY')}
          </Text>
        </View>
        <View style={styles.textFlexWM}>
          <Text style={styles.title}>Status</Text>
          <Text style={styles.textCont}>{props.overallStatus}</Text>
        </View>
        <View style={styles.textFlexWM}>
          <Text style={styles.title}>Description</Text>
          <Text style={styles.textCont}>{props.description}</Text>
        </View>
      </View>
    </BasicPageLayout>
  )
}

export default ReleaseInfo
