const labelToDescriptionMap = {
  login_success: 'Logged in',
  login_failed: 'Login failed',
  upload_release: 'Add release',
  upload_application: 'Add application',
  upload_module: 'Add module',
  upload_scenario: 'Add scenario',
  upload_testpack: 'Add test pack',
  upload_feature: 'Add feature',
  upload_user: 'Add user',
  delete_user: 'Deleted user',
  upload_featuremaprelease: 'Add feature map release',
  upload_testpackmapping: 'Add test pack mapping',
  upload_company: 'Add company',
  update_application: 'Updated application',
  delete_application: 'Deleted application',
  update_module: 'Updated module',
  delete_module: 'Deleted module',
  update_scenario: 'Updated scenario',
  delete_scenario: 'Deleted scenario',
  upload_automaticrun: 'Add automaticrun',
  import_feature: 'Imported feature',
  update_feature: 'Updated feature',
  delete_feature: 'Deleted feature',
  upload_manualrunreport: 'Add manualrunreport',
  upload_testcase: 'Add testcase',
  update_testcase: 'Updated testcase',
  delete_testcase: 'Deleted testcase',
  upload_testcasesteps: 'Add testcasesteps',
  upload_testcasestepsviaexcel: 'Add testcasestepsviaexcel',
  update_testcasesteps: 'Updated testcasesteps',
  update_testcasestepsviaexcel: 'Updated testcasestepsviaexcel',
  delete_testcasesteps: 'Deleted testcasesteps',
  upload_testerdata: 'Add testerdata',
  update_testpack: 'Updated testpack',
  delete_testpack: 'Deleted testpack',
  assign_testpack: 'Assign testpack',
  assign_testrun: 'Assign testrun',
  delete_testrun: 'Deleted testrun',
  update_testrun: 'Updated testrun',
  update_testrunstep: 'Updated testrunstep',
  upload_testcaserunfile: 'Add testcaserunfile',
  import_exceltestruncasestep: 'Imported exceltestruncasestep',
  create_securityscans: 'security scans',
  delete_testcaserunfile: 'Deleted testcaserunfile',
  delete_release: 'Deleted release',
  update_releaseTestSummary: 'Updated release test summary',
  export_testCaseToExcel: 'Exported testcase to excel',
  export_testCaseStepDescription: 'Exported testcase step description',
  download_summary: 'Download summary',
  download_testcase: 'Download testcase',
  download_testCaseStepsExcelSheet: 'Download testCaseSteps ExcelSheet',
  download_featureStepsSampleSheet: 'Download featureSteps SampleSheet',
  update_releaseInfo: 'Updated releaseInfo',
  signUp: 'SignUp',
  upload_testCaseFile: 'Upload testCaseFile',
  download_report: 'Download report',
  logOut: 'LogOut',
  export_featuretoexcel: 'Export feature to excel'
}

export default labelToDescriptionMap

  // 'create_securityReportRelease':'securityReportRelease',
  // 'get_getReleaseReport':'Fetch get release report',
