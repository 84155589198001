import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { PUT } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
// import './style.css'

const Eachfeature = () => {
  const location = useLocation()
  const sessionId = useSelector((state) => state.user.sessionId)
  const [featureName, setFeatureName] = useState(location.state.message[1])
  const [featureId, setFeatureId] = useState(location.state.message[2])
  const [selectedDevStatus, setSelectedDevStatus] = useState(
    location.state.message[5].trim()
  )
  const [selectedCreationStatus, setSelectedCreationStatus] = useState(
    location.state.message[6].trim()
  )
  const [selectedReviewStatus, setSelectedReviewStatus] = useState(
    location.state.message[7].trim()
  )
  const [description, setDescription] = useState(
    location.state.message[8] ? location.state.message[8] : ''
  )
  const navigate = useNavigate()

  const selectOptions = ['New', 'Inprogress', 'Complete']

  const updateFeature = () => {
    const data = {
      name: featureName,
      featureId,
      description,
      devStatus: selectedDevStatus,
      testCaseCreationStatus: selectedCreationStatus,
      testCaseReviewStatus: selectedReviewStatus
    }

    if (featureName === '' || featureId === '' || selectedDevStatus === '' ||
      selectedCreationStatus === '' || selectedReviewStatus === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error: 70210',
        'Error in updating Feature. Please try again'
      )
      return
    }

    PUT(`/feature/${location.state.message[0]}`, data, {
      sessionID: sessionId
    })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'Feature data updated successfully'
          )
          navigate('/feature')
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Error in updating Feature. Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Error in updating Feature. Please try again'
        )
      })
  }

  return (
    <>
      <div className='pb-5'>
        <div className='grid grid-cols-1 gap-1'>
          {/* <div className='col-span-2' /> */}
          <div className='mb-40 col-span-8'>
            <ul className='flex gap-[40rem]'>
              <li>
                <h1 className='text-2xl font-bold font-DMSANS'>Edit Feature</h1>
              </li>
              <li>
                <button
                  className='bg-customGreenFour text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline'
                  type='button'
                  onClick={updateFeature}
                >
                  Save
                </button>
              </li>
            </ul>
            <ul className='flex flex-col'>
              <li>
                <ul className='flex gap-10'>
                  <li>
                    <div>
                      <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                        Feature Name
                      </label>
                      <input
                        className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-64 p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                        type='text'
                        value={featureName}
                        onChange={(e) => setFeatureName(e.target.value)}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                        Feature ID
                      </label>
                      <input
                        className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-64 p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                        type='text'
                        value={featureId}
                        onChange={(e) => setFeatureId(e.target.value)}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                        Application Name
                      </label>
                      <input
                        className='bg-gray-300 cursor-not-allowed appearance-none border-2 border-gray-200 rounded-lg w-64 p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                        type='text'
                        defaultValue={location.state.message[3]}
                        readOnly
                      />
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <ul className='flex gap-10'>
                  <li>
                    <div>
                      <label
                        className='ml-2 block text-gray-700 text-sm font-bold mb-2'
                        for='password'
                      >
                        Module Name
                      </label>
                      <input
                        className='bg-gray-300 cursor-not-allowed appearance-none border-2 border-gray-200 rounded-lg w-64 p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                        type='text'
                        defaultValue={location.state.message[4]}
                        readOnly
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                        DEV Status
                      </label>
                      <select
                        className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                        name='devStatus'
                        id='devStatus'
                        onChange={(e) => setSelectedDevStatus(e.target.value)}
                      >
                        <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                          select
                        </option>
                        {selectOptions.map((obj) => {
                          let selected = ''
                          if (
                            obj
                              .trim()
                              .localeCompare(
                                location.state.message[5].trim()
                              ) === 0
                          ) {
                            selected = 'selected'
                          } else {
                            selected = ''
                          }
                          return (
                            <option
                              className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                              selected={selected}
                            >
                              {obj.trim()}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </li>
                  <li>
                    <div>
                      <label className='ml-1 block text-gray-700 text-sm font-bold mb-2'>
                        Test Case Creation Status
                      </label>
                      <select
                        className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                        name='creationStatus'
                        id='creationStatus'
                        onChange={(e) =>
                          setSelectedCreationStatus(e.target.value)}
                      >
                        <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                          select
                        </option>
                        {selectOptions.map((obj) => {
                          let selected = ''
                          if (
                            obj
                              .trim()
                              .localeCompare(
                                location.state.message[6].trim()
                              ) === 0
                          ) {
                            selected = 'selected'
                          } else {
                            selected = ''
                          }
                          return (
                            <option
                              className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                              selected={selected}
                            >
                              {obj.trim()}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div>
                  <label className='ml-2 block text-gray-700 text-sm font-bold mb-2'>
                    Test Case Review Status
                  </label>
                  <select
                    className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline bg-white w-64 p-3 rounded-lg'
                    name='reviewStatus'
                    id='reviewStatus'
                    onChange={(e) => setSelectedReviewStatus(e.target.value)}
                  >
                    <option className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'>
                      select
                    </option>
                    {selectOptions.map((obj) => {
                      let selected = ''
                      if (
                        obj
                          .trim()
                          .localeCompare(location.state.message[7].trim()) === 0
                      ) {
                        selected = 'selected'
                      } else {
                        selected = ''
                      }
                      return (
                        <option
                          className='mb-3 text-base font-medium focus:outline-none focus:shadow-outline border-2 border-customGreyThree w-64 p-3 rounded-lg'
                          selected={selected}
                        >
                          {obj.trim()}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </li>
              <li>
                <div>
                  <label className='ml-2 block text-gray-700 text-sm font-bold mb-2'>
                    Feature Description
                  </label>
                  <textarea
                    className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-4/5 p-3 pb-20 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                    type='text'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={5000}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Eachfeature
