import { call, put } from "redux-saga/effects";

import { setCookie, deleteCookie } from "../../../utils/cookie";
import { routes } from "../../../constants/routes";
import { statusCodes } from "../../../constants/api";
import {
  notificationTypes,
  openNotification,
} from "../../../utils/notifications";

import { setUser } from "../../ducks/userDuck";

import {
  signInUser,
  signUpUser,
  signOut,
  getCompanystatus,
} from "../requests/userRequests";

export function* handleSignIn(action) {
  try {
    const response = yield call(signInUser, action.payload);

    if (response.data.status === statusCodes.SUCCESS) {
      setCookie(action.payload.email);

      yield put(
        setUser({
          userId: response.data.message.userId,
          username: response.data.message.username,
          email: action.payload.email,
          sessionId: response.data.message.sessionID,
          accountType: response.data.message.accountType,
          settings: response.data.message.settings,
        })
      );

      const companyStatusResponse = yield call(
        getCompanystatus,
        action.payload.email
      );
      const companyStatus = companyStatusResponse.data.company_status;
      if (companyStatus === true) {
        window.history.replaceState({}, "Dashboard", routes.DASHBOARD);
        window.location.reload();
      } else {
        window.history.replaceState({}, "Company", routes.COMPANY);
        window.location.reload();
      }
      window.location.reload()
    } else if (response.data.status === 12010) {
      openNotification(
        notificationTypes.ERROR,
        'Error: 12010',
        'The provided username is invalid. Please enter a valid username.'
      )
    } else if (response.data.status === 12420) {
      openNotification(
        notificationTypes.ERROR,
        'Error: 12420',
        'The password entered is invalid. Please provide a valid password.'
      )
    } else {
      openNotification(
        notificationTypes.ERROR,
        "Error",
        "Please ensure that the email and password match, Or check if account exists."
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* handleSignUp(action) {
  try {
    const response = yield call(signUpUser, action.payload);

    if (response.data.status === statusCodes.SUCCESS) {
      setCookie(action.payload.email);

      yield put(
        setUser({
          userId: response.data.data.userId,
          username: response.data.data.username,
          email: action.payload.email,
          sessionId: response.data.data.sessionID,
          accountType: response.data.data.accountType,
        })
      );
      openNotification(
        notificationTypes.SUCCESS,
        "Verification Email sent",
        "Check your Inbox"
      );
      setTimeout(() => {
        window.history.replaceState({}, "Company Onboarding", routes.COMPANY);
        window.location.reload();
      }, 1000);
    } else if (response.data.status === statusCodes.ALREADY_EXISTS) {
      openNotification(
        notificationTypes.WARNING,
        "Account Already Exists!",
        response.data.message
      );
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    } else if (response.data.status === 10020) {
      openNotification(
        notificationTypes.ERROR,
        'Error: 10020',
        'The provided email ID is already registered. Please use a different email ID.'
      )
    } else if (response.data.status === 10040) {
      openNotification(
        notificationTypes.ERROR,
        'Error: 10040',
        'We encountered an issue while sending the email. Please try again later.'
      )
    } else if (response.data.status === statusCodes.BAD_REQUEST) {
      openNotification(
        notificationTypes.ERROR,
        "Oops! Something went wrong",
        "Please try again later."
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else if (response.data.status === statusCodes.SERVER_DOWN) {
      openNotification(
        notificationTypes.ERROR,
        "Oops! Something went wrong",
        "Please try again later."
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      openNotification(
        notificationTypes.ERROR,
        "Oops! Something went wrong",
        "Could not sign in. Please try again later."
      );
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* handleSignOut() {
  try {
    yield call(signOut);
    deleteCookie();
    window.history.replaceState({}, "Home", routes.HOME);
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
}
