import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import BasicPageLayout from '../components/BasicPageLayout'

const AboutTheProduct = (props) => {
  return (
    <BasicPageLayout>
      <View style={props.greenContainer}>
        <Text style={props.title}>About the Release</Text>
        <Text style={props.textCont}>
          {props.aboutTheRelease ? props.aboutTheRelease : 'No Data Found'}
        </Text>
      </View>
    </BasicPageLayout>
  )
}

export default AboutTheProduct
