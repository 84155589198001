import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import TestCase from './TestCase'
import TestPackModal from '../../components/Modal/TestPackModal'
import { documentTitles } from '../../constants/appConstants'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { notificationTypes, openNotification } from '../../utils/notifications'

const TestPackManagement = () => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [allTC, setAllTC] = useState({})
  const [appName, setAppName] = useState('')
  const [filteredTC, setFilteredTC] = useState({})

  const [showAppModal, setShowAppModal] = useState(false)

  const filterTC = () => {
    const filtered = allTC.filter((tc) => {
      return tc.applicationName.toLowerCase().includes(appName.toLowerCase())
    })
    if (filtered.length === 0) {
      openNotification(
        notificationTypes.ERROR,
        'Error: 40010',
        'No Test Packs Found'
      )
    }

    setFilteredTC(filtered)
  }

  useEffect(() => {
    const getTC = () => {
      GET('/getTestpackDetails', { sessionID: sessionId })
        .then((res) => {
          console.log(res.data.message)
          if (res.data.status === 200 || res.data.status === 304) {
            setAllTC(res.data.message)
            setFilteredTC(res.data.message)
          } else {
            console.log('error')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getTC()
    document.title = documentTitles.TESTPACKMANAGEMENT
  }, [sessionId])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Test Pack Management
            </h1>
            <div className='flex justify-center flex-wrap items-start'>
              <h1 className='font-semibold text-lg w-full text-center'>
                How to
              </h1>
              <p className='flex text-customBlue hover:text-customBlueFive items-center justify-center text-lg w-full'>
                <AiOutlineInfoCircle className='mr-2' />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Create_a_Test_Pack_in_GreenTickme__0Bfk6b1HQm-JtRB6see8KA'
                >
                  Create a test pack?
                </a>
              </p>
              <p className='flex text-customBlue hover:text-customBlueFive items-center justify-center text-lg w-full'>
                <AiOutlineInfoCircle className='mr-2' />
                <a target='_blank' rel='noreferrer' href='/runrequestbutton'>
                  Run Request
                </a>
              </p>
            </div>
          </div>
          <div className='grid justify-between items-center grid-cols-10'>
            <div className='col-span-5'>
              <div class='mb-3 w-full'>
                <div class='relative mb-4 flex w-full flex-wrap items-stretch'>
                  <input
                    type='search'
                    class='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                    placeholder='Search for applications'
                    aria-label='Search'
                    aria-describedby='button-addon3'
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                  />
                  <button
                    class='relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0'
                    type='button'
                    id='button-addon3'
                    data-te-ripple-init
                    onClick={filterTC}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      class='h-5 w-5'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z'
                        clip-rule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/*
                <div className='absolute right-0 ml-20 mr-16 col-span-1 grid grid-cols-1 gap-4'>
                  <div className='col-span-1'>
                    <button onClick={() => setShowAppModal(!showAppModal)} class='mr-1 bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded'>
                        Add Test Pack
                                        </button>
                  </div>
                </div> */}
          </div>
          <div className='overflow-x bg-customWhite rounded-xl p-4 shadow-xl'>
            <table className='table-auto w-full overflow-x-scroll mx-1 border-1 rounded-3xl border-customGreyThree'>
              <thead class='text-center bg-customGrey rounded-2xl'>
                <tr>
                  <th
                    scope='col'
                    class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Application Name
                  </th>
                  <th
                    scope='col'
                    class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Test Pack Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTC == null
                  ? null
                  : filteredTC.length > 0
                    ? (
                        filteredTC.map((tc) => <TestCase tc={tc} />)
                      )
                    : (
                      <tr class='border-b border-1 border-customGreyThree'>
                        <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                          No Test Packs Found
                        </td>
                        <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>
                          -
                        </td>
                      </tr>
                      )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TestPackModal showModal={showAppModal} setShowModal={setShowAppModal} />
    </>
  )
}

export default TestPackManagement
