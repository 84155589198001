import React from 'react'
import BasicPageLayout from '../components/BasicPageLayout'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: { marginVertical: 20 },
  text: { color: '#929191', fontSize: 16 },
  textContainer: {
    marginTop: 20,
    paddingBottom: 10,
    borderBottom: 2,
    borderBottomStyle: 'dashed',
    borderBottomColor: '#D9D9D9'
  },
  firstTextContainer: {
    paddingBottom: 10,
    borderBottom: 2,
    borderBottomStyle: 'dashed',
    borderBottomColor: '#D9D9D9'
  }
})

const TextBlock = (props) => {
  return (
    <View
      style={
        props.index === 0 ? styles.firstTextContainer : styles.textContainer
      }
    >
      <Text style={styles.text}>
        {props.index}. {props.title}
      </Text>
    </View>
  )
}

const TableOfContentPage = () => {
  const array = [
    'Release Info',
    'About the Release',
    'Conclusion',
    'Features',
    'Test Pack',
    'Test Run',
    'Test Cases'
  ]

  return (
    <BasicPageLayout headerTitle='Table of Content'>
      {array.map((item, index) => (
        <TextBlock key={index} index={index + 1} title={item} />
      ))}
    </BasicPageLayout>
  )
}

export default TableOfContentPage
