import { useState, useEffect, useRef } from 'react'
import styles from '../../modal.module.css'
import close from '../../../../assets/Icons/close.png'
import sheet from '../../../../assets/Icons/sheets.png'
import sample from '../../../../assets/Excel/sample_sheetforFM.xlsx'
import { GET } from '../../../../config/api'
import { notificationTypes, openNotification } from '../../../../utils/notifications'
import { useSelector } from 'react-redux'

const ImportExcel = ({
  showModal,
  setShowModal,
  ImportExcel
}) => {
  const modalRef = useRef(null)
  const hiddenFileInput = useRef(null)

  const closeModal = () => {
    setShowModal(false)
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const fileInputClick = (event) => {
    event.target.value = ''
  }
  const sessionId = useSelector((state) => state.user.sessionId)

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])
  const downloadSample = () => {
    GET('/exportToFile?activity=download_featureStepsSampleSheet', { sessionID: sessionId })
      .then(() => {
        openNotification(
          notificationTypes.SUCCESS,
          'Success',
          'Downloaded featureSteps sample sheet sucessfully'
        )
      })
  }
  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer5}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2
              className='pb-3 mt-2 underline underline-offset-4 ml-4 font-bold font-DMSANS text-base text-customGreenThree xl:text-2xl leading-none lg:leading-11'
            >
              Import Feature Steps from Excel Sheet
            </h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <img src={close} alt='close' className='h-12' />
            </a>
          </div>
        </div>
        <div className='pt-5 pl-5 rounded-3xl'>
          <div className='grid grid-cols-2 gap-4'>
            <div className='mt-4' onClick={downloadSample}>
              <a
                href={sample}
                download='sample_sheet.xlsx'
                className='ml-10 mt-1 bg-blue-300 hover:bg-green-300 border-2 text-black font-bold py-5 px-4 text-base rounded-xl focus:outline-none focus:shadow-outline'
              >
                Download Sample sheet
              </a>
            </div>
            <div>
              <button
                onClick={handleClick}
                className='flex hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6 py-4'
              >
                <input
                  type='file'
                  ref={hiddenFileInput}
                  name='file'
                  required
                  onChange={(event) => ImportExcel(event)}
                  onClick={(event) => fileInputClick(event)}
                  id='fu'
                  style={{ display: 'none' }}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />
                <img className='h-6' src={sheet} /> &nbsp; Import from excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportExcel
