import { useState, useEffect } from 'react'
import { GET, POST } from '../../config/api'
import { useSelector } from 'react-redux'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { useParams } from 'react-router-dom'
import Sidebar from '../../components/sidebarnav'

const AutomaticRunExecutionPage = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const { testpackID } = useParams()

  const [selectedFile, setSelectedFile] = useState()
  const [testPack, setTestPack] = useState({})

  const getTestPackData = () => {
    GET(`/getTestPackById?id=${testpackID}`, { sessionID: sessionId })
      .then((res) => {
        setTestPack(res.data.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getTestPackData()
  }, [testpackID])

  const handleSubmission = () => {
    const formData = new FormData()
    formData.append('file', selectedFile)
    fetch(
      `http://http://3.91.253.67:8000/postAutomaticRunResults?testpackID=${testpackID}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          sessionID: sessionId
        }
      }
    )
      .then((response) => response.json())
      .then((result) => {
        openNotification(
          notificationTypes.SUCCESS,
          'Success',
          'Results uploaded successfully'
        )
      })
      .catch((error) => {
        openNotification(
          notificationTypes.ERROR,
          'Error',
          'Error uploading results'
        )
      })
  }

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <>
      {testPack !== null ? (
        testPack.is_cloud === false ? (
          <div className='pb-5'>
            <div className='grid grid-cols-1 gap-1'>
              {/* <div className='col-span-2' /> */}
              <div className='mb-40 col-span-8'>
                <div className='mt-5 py-10 min:h-2/5 bg-customWhite shadow-xl'>
                  <div>
                    <h1 className='font-DMSANS text-gray-400 ml-5 xl:mt-3 text-lg xl:text-5xl font-regular'>
                      Upload Results
                    </h1>
                  </div>
                  <div class='font-sans text-gray-900 border-box'>
                    <div class='flex justify-center w-full mx-auto sm:max-w-lg'>
                      <div class='flex flex-col items-center justify-center w-full h-auto my-5 bg-white border border-2 border-customGreyThree'>
                        <div class='my-5 text-center'>
                          <h2 class='text-2xl font-semibold mb-2'>
                            Upload results of Automatic Run.
                          </h2>
                          <p class='text-xs font-medium text-black'>
                            File should be of format .zip
                          </p>
                        </div>
                        <div class='relative w-4/5 h-32 max-w-xs mb-5 bg-white bg-gray-100 rounded-lg shadow-inner'>
                          <input
                            className='bg-customBlue text-white font-bold py-2 px-4 rounded'
                            type='file'
                            name='file'
                            onChange={changeHandler}
                          />
                          <label
                            for='file-upload'
                            class='z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer'
                          >
                            <p class='z-10 text-xs font-medium text-center text-black'>
                              Drag & Drop your files here
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='mt-10 mx-10'>
                      <button
                        onClick={handleSubmission}
                        className='bg-customBlue w-full text-white font-bold py-2 px-4 rounded'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='pb-5 bg-gray-100 pt-16'>
            <div className='mr-12 grid grid-cols-6 gap-4'>
              <div className='col-span-2'>
                <Sidebar />
              </div>
              <div className='col-span-4'>
                <div className='mt-5 mb-20 py-10 min:h-2/5 bg-customWhite shadow-xl'>
                  <h1 className='font-DMSANS text-gray-400 ml-5 xl:mt-3 text-lg xl:text-3xl font-regular'>
                    Please wait while the execution is in progress ...
                  </h1>
                  <h1 className='mt-5 font-DMSANS text-gray-400 ml-5 xl:mt-3 text-lg xl:text-3xl font-regular'>
                    The results will appear here when the execution is complete.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div />
      )}
    </>
  )
}

export default AutomaticRunExecutionPage
