import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../config/api'
import { useNavigate } from 'react-router-dom'
import { notificationTypes, openNotification } from '../../utils/notifications'
import inputStyles from '../../components/InputComponents/Input.module.css'
import moment from 'moment'
import Arrow from '../../assets/Icons/arrowright.png'

function DashboardReleasePage () {
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [release, setRelease] = useState([])
  const [currRelease, setCurrRelease] = useState()
  const [releaseInfo, setReleaseInfo] = useState()
  const [selectedFeature, setSelectedFeature] = useState([])
  const [selectedTestPack, setSelectedTestPack] = useState([])
  const [testRuns, setTestRuns] = useState([])

  useEffect(() => {
    const getRelease = async () => {
      GET('/release', { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            setRelease(res.data.message)
            if (res.data.message && res.data.message.length > 0) {
              udpateDataBasedOnReleaseId(res.data.message[0].id)
            }
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error in getting release, Please try again'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting Releases, Please try again'
          )
        })
    }
    getRelease()
  }, [])

  const relaseSelectHandler = (item) => {
    const releaseId = item.target.value
    udpateDataBasedOnReleaseId(releaseId)
  }

  const udpateDataBasedOnReleaseId = (releaseId) => {
    setCurrRelease(releaseId)
    getTestRunDetails(releaseId)
    getSelectedFeature(releaseId)
    getSelectedTestPack(releaseId)
    getTestRuns(releaseId)
  }

  const getTestRunDetails = async (releaseId) => {
    GET(`/release/${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setReleaseInfo(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting run details, Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error in getting run details, Please try again'
        )
      })
  }

  const getSelectedFeature = (releaseId) => {
    GET(`selectedFeature?releaseId=${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        try {
          let items = res.data.message?.featureId
          items = items?.sort((a, b) => sortFeature(a, b))
          setSelectedFeature(items)
        } catch (ex) {
          setSelectedFeature([])
          console.log(ex)
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Error in getting test features, Please try again'
        )
      })
  }

  const getSelectedTestPack = (releaseId) => {
    GET(`/release/testPack/${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        try {
          let seltestPack = res.data.message
          seltestPack = seltestPack.sort((a, b) => sortTestPack(a, b))
          setSelectedTestPack(seltestPack)
        } catch (ex) {
          setSelectedTestPack([])
          console.log(ex)
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Error in getting test pack, Please try again'
        )
      })
  }

  const getTestRuns = (releaseId) => {
    GET(`/release/testrundetails/${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        try {
          setTestRuns(res.data.message)
        } catch (ex) {
          setTestRuns([])
          console.log(ex)
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Error in getting test runs, Please try again'
        )
      })
  }

  const sortFeature = (a, b) => {
    if (a.moduleName < b.moduleName) {
      return -1
    } else if (a.moduleName > b.moduleName) {
      return 1
    } else if (a.name < b.name) {
      return -1
    } else if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const sortTestPack = (a, b) => {
    if (a.applicationName < b.applicationName) {
      return -1
    } else if (a.applicationName > b.applicationName) {
      return 1
    } else if (a.testpack_name < b.testpack_name) {
      return -1
    } else if (a.testpack_name > b.testpack_name) {
      return 1
    }
    return 0
  }

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        {/* <div className='col-span-2' /> */}
        <div className='col-span-10'>
          <div>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Release Dashboard
            </h1>
            <a
              className='text-blue-600 pb-2 text-lg font-semibold'
              href='/dashboard'
            >
              Dashboard
            </a>

            <h1 className='pt-2 pb-1 mt-2 mb-1 text-black font-DMSANS font-semibold text-xl'>
              {' '}
              Select Release
            </h1>
            <div className='grid grid-cols-10 gap-4 my-2 py-2'>
              <div className='col-span-4'>
                <select
                  className={inputStyles.container1}
                  onChange={relaseSelectHandler}
                  value={currRelease}
                >
                  {release.map((option) => (
                    <option
                      key={option.id}
                      className={inputStyles.option}
                      value={option.id}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='grid grid-cols-10 gap-4 my-2 py-2'>
              {currRelease ? (
                <>
                  <div className='my-1 col-span-10'>
                    <h1 className='pt-3 text-black font-DMSANS font-semibold text-base xl:text-xl'>
                      Release Details
                    </h1>
                  </div>
                  {releaseInfo
                    ? (
                      <>
                        <div className='col-span-4'>
                          <div className='bg-white rounded-xl'>
                            <div className='flex flex-col justify-center'>
                              <div>
                                <h1 className='font-DMSANS text-center font-bold mt-2'>
                                  Name
                                </h1>
                              </div>
                              <div>
                                <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-2xl'>
                                  {releaseInfo.name}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <div className='mb-4 bg-white rounded-xl'>
                            <div className='flex flex-col justify-center'>
                              <div>
                                <h1 className='font-DMSANS text-center font-bold mt-2'>
                                  Version
                                </h1>
                              </div>
                              <div>
                                <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-2xl'>
                                  {releaseInfo.version}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <div className='mb-4 bg-white rounded-xl'>
                            <div className='flex flex-col justify-center'>
                              <div>
                                <h1 className='font-DMSANS text-center font-bold mt-2'>
                                  Date
                                </h1>
                              </div>
                              <div>
                                <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-2xl'>
                                  {moment(releaseInfo.releaseDate)
                                    .utc()
                                    .format('DD-MM-YYYY')}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <div className='mb-4 bg-white rounded-xl'>
                            <div className='flex flex-col justify-center'>
                              <div>
                                <h1 className='font-DMSANS text-center font-bold mt-2'>
                                  Status
                                </h1>
                              </div>
                              <div>
                                <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-2xl'>
                                  {releaseInfo.overallStatus}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-10'>
                          <div className='mb-4 bg-white rounded-xl'>
                            <div className='flex flex-col justify-center'>
                              <div>
                                <h1 className='font-DMSANS text-center font-bold mt-2'>
                                  Description
                                </h1>
                              </div>
                              <div>
                                <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-2xl'>
                                  {releaseInfo.description}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      )
                    : (
                      <>Release information is not available</>
                      )}

                  <div className='col-span-5'>
                    <h1 className='pt-3 pb-5 font-semibold text-black font-DMSANS text-base xl:text-xl'>
                      Features Covered
                    </h1>
                    {selectedFeature && selectedFeature.length > 0
                      ? (
                        <div className='bg-white shadow-xl mr-5 p-5 h-96 overflow-y-auto rounded-2xl'>
                          {selectedFeature.map(
                            (item) =>
                              item && (
                                <div class='flex my-2 form-check text-xl'>
                                  <img
                                    alt=''
                                    className='ml-3 mt-2 h-4 mr-2'
                                    src={Arrow}
                                  />
                                  <p>
                                    {item.name} ({item.moduleName})
                                  </p>
                                </div>
                              )
                          )}
                        </div>
                        )
                      : (
                        <>Features are not available</>
                        )}
                  </div>

                  <div className='col-span-5'>
                    <h1 className='pt-3 pb-5 font-semibold text-black font-DMSANS text-base xl:text-xl'>
                      Test Packs
                    </h1>
                    {selectedTestPack && selectedTestPack.length > 0
                      ? (
                        <div className='bg-white shadow-xl mr-5 p-5 h-96 overflow-y-auto rounded-2xl'>
                          {selectedTestPack.map((item) => (
                            <div class='flex my-2 form-check text-xl'>
                              <img
                                alt=''
                                className='ml-3 mt-2 h-4 mr-2'
                                src={Arrow}
                              />
                              {item.testpack_name} ({item.applicationName})
                            </div>
                          ))}
                        </div>
                        )
                      : (
                        <> Test packs are not available </>
                        )}
                  </div>

                  <div className='col-span-10'>
                    <h1 className='pt-3 pb-5 font-semibold text-black font-DMSANS text-base xl:text-xl'>
                      Test Runs
                    </h1>
                    {testRuns && testRuns.length > 0 ? (
                      <>
                        <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl'>
                          {/* <table className="bg-gray-200 border-2 border-gray-400 p-4 mr-16 rounded-xl w-full"> */}
                          <table className='overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
                            <thead class='text-center bg-customGrey rounded-2xl'>
                              <tr>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Run Name
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Build Version
                                </th>

                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Exe Env
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Pass
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Fail
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  No Run
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  In-Progress
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  On Hold
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Start Date
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  End Date
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Test Type
                                </th>
                                <th
                                  scope='col'
                                  class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                                >
                                  Notes
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {testRuns?.map((obj) => {
                                return (
                                  <tr class='border-b border-1 border-customGreyThree'>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        <a
                                          href={`/viewmanualrunresults/${obj.id}`}
                                        >
                                          {' '}
                                          {obj.name}{' '}
                                        </a>
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.buildVersion}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.executionEnvironment}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.passCount}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.failCount}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.norunCount}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.inprogressCount}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.onholdCount}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      {moment(obj.startDate)
                                        .utc()
                                        .format('DD-MM-YYYY')}
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      {moment(obj.endDate)
                                        .utc()
                                        .format('DD-MM-YYYY')}
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.testType}
                                      </p>
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                      <p className='break-words'>
                                        {obj.description}
                                      </p>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>Runs are not available</>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='mt-10 col-span-10'>
                    <h1 className='pt-3 text-black font-DMSANS text-base xl:text-xl'>
                      'Loading releases...Please wait'
                    </h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardReleasePage
