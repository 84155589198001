import { useState, useEffect } from 'react'
import SSComponent from '../../../components/InputComponents/SingleSelectComponent/SScomponent'
import { useSelector } from 'react-redux'
import { GET } from '../../../config/api'
import {
  openNotification,
  notificationTypes
} from '../../../utils/notifications'

const ApplicationData = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [data, setData] = useState(props.id)
  const [result, setResult] = useState([])
  const [currentAppId, setCurrentAppId] = useState(null)

  const getData = (localData) => {
    const appId = localData === null ? data : localData
    setCurrentAppId(appId)
    localData && setData(localData)
    setResult({ modules: '-', scenarios: '-', testcases: '-' })
    GET(`/getData?appID=${appId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setResult(res.data.message)
        } else {
          openNotification(notificationTypes.ERROR, 'Error')
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(notificationTypes.ERROR, 'Error')
      })
  }
  useEffect(() => {
    getData(null)
  }, [])

  return (
    <div className='p-5 bg-white rounded-xl'>
      <h1 className='text-3xl font-bold font-DMSANS mb-8'>Application Data</h1>
      <SSComponent
        label='Application'
        value={data}
        onChangeHandler={(e) => getData(e.target.value)}
        options={props.applications}
      />
      {result === null
        ? null
        : (
          <div className='mt-10 grid grid-cols-3 gap-6'>
            <div className='border-2 border-gray-200 shadow-xl mb-10 bg-white rounded-xl'>
              <div className='flex flex-col justify-center'>
                <div>
                  <h1 className='font-DMSANS text-center font-bold mt-2'>
                    Modules
                  </h1>
                </div>
                <div>
                  <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                    {result.modules}
                  </h1>
                </div>
              </div>
            </div>
            <div className='border-2 border-gray-200 shadow-xl mb-10 bg-white rounded-xl'>
              <div className='flex flex-col justify-center'>
                <div>
                  <h1 className='font-DMSANS text-center font-bold mt-2'>
                    Scenarios
                  </h1>
                </div>
                <div>
                  <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                    {result.scenarios}
                  </h1>
                </div>
              </div>
            </div>
            <div className='border-2 border-gray-200 shadow-xl mb-10 bg-white rounded-xl'>
              <div className='flex flex-col justify-center'>
                <div>
                  <h1 className='font-DMSANS text-center font-bold mt-2'>
                    Test Cases
                  </h1>
                </div>
                <div>
                  <h1 className='mt-1 font-DMSANS font-bold text-customGreenTwo text-center xl:text-4xl'>
                    {result.testcases}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          )}
    </div>
  )
}

export default ApplicationData
