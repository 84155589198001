import TextField from '../../InputComponents/InputField'
import { useState, useEffect, useRef } from 'react'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { useSelector } from 'react-redux'
import { POST, GET } from '../../../config/api'
import styles from '../modal.module.css'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'

const ModuleModalbutton = ({ showModal, setShowModal, setModule }) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)

  const [moduleName, setModuleName] = useState('')
  const [note, setNote] = useState('')
  const [applications, setApplications] = useState([])
  const [selectedAppID, setSelectedAppID] = useState('')

  const closeModal = () => {
    setShowModal(!showModal)
  }
  const addModule = (e) => {
    e.preventDefault()
    if (moduleName.length > 0) {
      const Data = {
        moduleName,
        moduleDescription: note
      }
      POST(`/postModule?AppID=${selectedAppID}`, Data, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Module added successfully'
            )
            window.location.reload()
            setShowModal(!showModal)
          } else if (res.data.status === 30420) {
            e.target.disabled = false
            openNotification(
              notificationTypes.ERROR,
              'Error: 30420',
              'An application is not selected. Please choose an application.'
            )
          } else if (res.data.status === 401) {
            openNotification(
              notificationTypes.ERROR,
              'Session expired, please login again'
            )
          } else if (res.data.status === 406) {
            openNotification(
              notificationTypes.ERROR,
              'Unable to determine company of user'
            )
          } else if (res.data.status === 400) {
            openNotification(
              notificationTypes.ERROR,
              'Error',
              res.data.message
            )
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error',
        'Please enter all the details'
      )
    }
  }

  const getApplications = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        setApplications(res.data.message || {})
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getApplications()
  }, [])

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Add Module</h2>
          </div>
          <div className={styles.close}>
            <div onClick={closeModal}>
              <CloseCircleOutlined
                style={{ fontSize: '30px', color: 'black' }}
              />
            </div>
          </div>
        </div>
        <div>
          <select
            className='mb-7 text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
            value={selectedAppID}
            onChange={(e) => setSelectedAppID(e.target.value)}
          >
            <option value=''>Select Application</option>
            {applications.map((application) => (
              <option
                className='mb-3 text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                key={application.id}
                value={application.id}
              >
                {application.applicationName}
              </option>
            ))}
          </select>
        </div>
        <TextField
          label='Name of the Module'
          placeholder={moduleName}
          value={moduleName}
          onChangeHandler={(e) => setModuleName(e.target.value)}
          maxLength={5000}
          type='text'
          required
        />
        <div>
          <label
            for='exampleFormControlTextarea1'
            class='form-label inline-block mb-2 text-gray-700'
          >
            Notes
          </label>
          <textarea
            class='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            value={note}
            onChange={(e) => setNote(e.target.value)}
            id='Note'
            rows='3'
          />
          <div class='flex items-center justify-between'>
            <button
              class='w-full bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='button'
              onClick={addModule}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModuleModalbutton
