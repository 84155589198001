import { useNavigate } from 'react-router-dom'

const TestCase = (props) => {
  const navigate = useNavigate()

  return (
    <>
      <tr class='border-b border-1 border-customGreyThree'>
        <td class='text-base text-gray-600 font-medium px-8 whitespace-nowrap'>{props.tc.applicationName}</td>
        <td onClick={() => navigate(`/viewcxopack/${props.tc.id}`)} class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-1 whitespace-nowrap'>{props.tc.testpack_name}</td>
      </tr>
    </>
  )
}

export default TestCase
