import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../../config/api'
import { openNotification, notificationTypes } from '../../../../utils/notifications'
import Application from './Application'

const TestPack = () => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [app, setApp] = useState({})

  // function groupBy (items) {
  //   return items.reduce((acc, curr) => {
  //     if (curr.application?.applicationName) {
  //       const { applicationName } = curr.application
  //       const currentItems = acc[applicationName]

  //       return {
  //         ...acc,
  //         [applicationName]: currentItems ? [...currentItems, curr] : [curr]
  //       }
  //     }
  //     return acc
  //   }, {})
  // }

  useEffect(() => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        setApp(res.data.message)
        // console.log(res.data.message)
      }
      )
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }, [])

  return (
    <>
      <div
        className='pb-3 font-DMSANS text-sm xl:text-base font-medium ml-5 leading-none lg:leading-11'
      >
        {
          app === null
            ? null
            : app.length > 0
              ? (
                  app.map((item, index) => (
                    <Application key={index} item={item} />
                  ))
                )
              : (
                <div className='hover:bg-customBlueFour flex flex-row items-center'>
                  No applications found
                </div>
                )
        }
      </div>
    </>
  )
}

export default TestPack
