import { useState, useEffect } from 'react'
import { GET, POST } from '../../../config/api'

import appIcon from '../../../assets/Icons/app.png'
import module from '../../../assets/Icons/module.png'
import scenario from '../../../assets/Icons/scenario.png'
import tc from '../../../assets/Icons/testcaseicon.png'
import Arrow from '../../../assets/Icons/arrowright.png'
import Arrow1 from '../../../assets/Icons/arr.png'
import Arrow2 from '../../../assets/Icons/arr2.png'
import { useSelector } from 'react-redux'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'

const TestPackMapping = ({ releaseId }) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [cxo, setCxo] = useState({})
  const [selectedTestPack, setSelectedTestPack] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [isDisabled1, setIsDisabled1] = useState(false)
  const [removed, setRemoved] = useState([])
  const [add, setAdd] = useState([])
  const [allTC, setAllTC] = useState({})

  const addTC = (event, item) => {
    item.checked = !item.checked
    if (event.target.checked === false) {
      const res = add.filter((tc) => tc.id !== item.id)
      setAdd(res)
    } else {
      setAdd([...add, item])
    }
  }

  const removeTC = (event, item) => {
    item.checked = !item.checked
    if (event.target.checked === false) {
      const res = removed.filter((tc) => tc.id !== item.id)
      setRemoved(res)
    } else {
      setRemoved([...removed, item])
    }
  }

  const handleAdd = () => {
    let res = selectedTestPack.concat(add)
    res = [...new Set(res)]
    res = clearCheckMark(res)
    res = res.sort((a, b) => sortTestPack(a, b))
    setSelectedTestPack(res)
    setAdd([])

    let allTestPack = allTC
    allTestPack = clearCheckMark(allTestPack)
    setAllTC(allTestPack)
  }

  const handleRemove = () => {
    const res = selectedTestPack.filter((tc) => !removed.includes(tc))
    setSelectedTestPack(res)
    setRemoved([])

    let allTestPack = allTC
    allTestPack = clearCheckMark(allTestPack)
    setAllTC(allTestPack)
  }
  const sortTestPack = (a, b) => {
    if (a.applicationName < b.applicationName) {
      return -1
    } else if (a.applicationName > b.applicationName) {
      return 1
    } else if (a.testpack_name < b.testpack_name) {
      return -1
    } else if (a.testpack_name > b.testpack_name) {
      return 1
    }
    return 0
  }

  const clearCheckMark = (items) => {
    items.map((item) => (item.checked = false))
    return items
  }

  const getAllTestPacks = () => {
    GET('/getTestpackDetails', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          let alltestPack = res.data.message
          alltestPack = alltestPack.sort((a, b) => sortTestPack(a, b))
          setAllTC(alltestPack)
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSelectedTestPacks = () => {
    GET(`/release/testPack/${releaseId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          let seltestPack = res.data.message
          seltestPack = seltestPack.sort((a, b) => sortTestPack(a, b))
          setSelectedTestPack(seltestPack)
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const postTestPackMapping = () => {
    POST(
      '/release/testPack',
      {
        releaseId,
        testPackId: selectedTestPack
      },
      {
        sessionID: sessionId
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Success',
            'Release data Mapped successfully'
          )
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error: 82410',
            'Error in Mapping Release. Please try again'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error: 82410',
          'Error in mapping Release. Please try again'
        )
      })
  }

  useEffect(() => {
    getAllTestPacks()
    getSelectedTestPacks()
  }, [])

  return (
    <>
      <div className='grid grid-cols-5 gap-4 mt-5'>
        <div className='col-end-7'>
          <button
            className='w-full bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end'
            type='button'
            onClick={postTestPackMapping}
          >
            Map and Save
          </button>
        </div>
      </div>
      <div className='mt-5'>
        <div className='mx-2 grid grid-cols-10 gap-8'>
          <div className='col-span-4'>
            {/* style={{ opacity: isDisabled1 === false ? 1 : 0.3 }} */}
            <div
              style={{ opacity: isDisabled1 === false ? 1 : 0.3 }}
              className='bg-customGrey shadow-xl p-5 h-96 overflow-y-auto rounded-2xl'
            >
              {selectedTestPack === null
                ? (
                  <div>Loading...</div>
                  )
                : selectedTestPack.length > 0
                  ? (
                      selectedTestPack.map((item) => (
                        <div class='flex my-2 form-check '>
                          <input
                            onClick={(event) => removeTC(event, item)}
                            class='h-4 mt-2'
                            type='checkbox'
                            value=''
                            checked={item.checked}
                            id={item.id}
                          />
                          <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                          <img src={scenario} className='mt-1 h-7 mr-2' />
                          <div class='mt-2'>
                            {item.testpack_name} ({item.applicationName})
                          </div>
                        </div>
                      ))
                    )
                  : (
                    <div>No Test-Packs found</div>
                    )}
            </div>
          </div>
          <div className='my-auto col-span-2'>
            <div className='flex flex-col justify-center'>
              <button
                className='mx-auto flex mb-2 px-5 py-2 rounded-xl bg-green-300 font-medium text-black'
                onClick={handleAdd}
              >
                <img src={Arrow2} className='h-8' alt='arrow' />
                <span className='ml-2 text-xl'>Add</span>
              </button>
              <button
                className='mx-auto flex mb-2 px-5 py-2 rounded-xl bg-red-300 font-medium text-black'
                onClick={handleRemove}
              >
                <span className='ml-2 text-xl'>Remove</span>
                <img src={Arrow1} className='h-8' alt='arrow' />
              </button>
            </div>
          </div>
          <div className='col-span-4'>
            <div className='bg-customGrey shadow-xl p-5 h-96 overflow-y-auto rounded-2xl'>
              {allTC === null
                ? (
                  <div>Loading...</div>
                  )
                : allTC.length > 0
                  ? (
                      allTC.map((item) => (
                        <div class='flex my-2 form-check '>
                          <input
                            onClick={(event) => addTC(event, item)}
                            class='h-4 mt-2'
                            type='checkbox'
                            value=''
                            checked={item.checked}
                            id={item.id + '1'}
                          />
                          <img className='ml-3 mt-2 h-4 mr-2' src={Arrow} />
                          <img src={scenario} className='mt-1 h-7 mr-2' />
                          <div class='mt-2'>
                            {item.testpack_name} ({item.applicationName})
                          </div>
                        </div>
                      ))
                    )
                  : (
                    <div>No Test-Packs found</div>
                    )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestPackMapping
