import React, { useState } from 'react'
import { POST } from '../../../config/api'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'

const ForgetPassword = () => {
  const [email, setEmail] = useState('')

  const forgetPassword = async (event) => {
    event.preventDefault()
    try {
      const res = await POST('/forgetpassword', { email })
      if (res.data.status === 14010) {
        openNotification(
          notificationTypes.ERROR,
          'Error: 14010',
          'The provided email ID is incorrect. Please enter a valid email address.'
        )
      } else if (res.data.status === 14020) {
        openNotification(
          notificationTypes.ERROR,
          'Error: 14020',
          'We encountered an issue while sending the email. Please try again later.'
        )
      } else if (res.data.status === 200) {
        openNotification(
          notificationTypes.SUCCESS,
          'Password reset mail sent Successfully',
          'Check your Inbox'
        )
        event.target.disabled = false
      } else {
        openNotification(
          notificationTypes.ERROR,
          'Check if this Email Exists',
          'If not, please Sign Up'
        )
      }
    } catch (err) {
      openNotification(
        notificationTypes.ERROR,
        'Check if this Email Exists',
        'If not, please Sign Up'
      )
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.target.disabled = true
      forgetPassword(event)
    }
  }

  return (
    <div>
      <form
        className='rounded px-2 lg:px-8 pt-6 pb-8 mb-4'
        onSubmit={forgetPassword}
      >
        <div className='mb-4'>
          <label
            className='ml-2 block text-gray-700 text-sm font-bold mb-2'
            htmlFor='email'
          >
            Enter Email ID
          </label>
          <input
            className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            type='email'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div>
          <button
            className='w-full bg-customGreenFour hover:bg-green-300 border-2 text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
            type='submit'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default ForgetPassword
