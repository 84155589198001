import TextField from '../../InputComponents/InputField'
import { useState, useEffect, useRef } from 'react'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { useSelector } from 'react-redux'
import { GET, PUT } from '../../../config/api'
import styles from '../modal.module.css'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const UpdateScenarioModal = ({
  showModal,
  setShowModal,
  scenarioID,
  setRefreshState
}) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)

  const [scenarioName, setScenarioName] = useState('')
  const [note, setNote] = useState('')

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const updateScenario = () => {
    if (scenarioName.length > 0) {
      const Data = {
        scenarioName,
        scenarioDescription: note
      }
      PUT(`/updateScenario?scenarioID=${scenarioID}`, Data, {
        sessionID: sessionId
      })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Scenario updated successfully'
            )
            window.location.reload()
            setShowModal(!showModal)
            setRefreshState(true)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error',
        'Please enter all the details'
      )
    }
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
    GET(`/getSpecificScenario?scenarioID=${scenarioID}`, {
      sessionID: sessionId
    })
      .then((res) => {
        setScenarioName(res.data.message.scenarioName)
        setNote(res.data.message.scenarioDescription)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }, [showModal])

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Update Scenario</h2>
          </div>
          <div className={styles.close}>
            <span onClick={closeModal}>
              <AiOutlineCloseCircle size={20} />
            </span>
          </div>
        </div>
        <TextField
          label='Name of the Scenario'
          placeholder={scenarioName}
          value={scenarioName}
          onChangeHandler={(e) => setScenarioName(e.target.value)}
          maxLength={5000}
          type='text'
          required
        />
        <div>
          <label
            for='exampleFormControlTextarea1'
            class='form-label inline-block mb-2 text-gray-700'
          >
            Notes
          </label>
          <textarea
            class='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            value={note}
            onChange={(e) => setNote(e.target.value)}
            id='Note'
            rows='3'
          />
          <div class='flex items-center justify-between'>
            <button
              class='w-full bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='button'
              onClick={updateScenario}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateScenarioModal
