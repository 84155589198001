import { useState } from 'react'
import Arrow1 from '../../../assets/Icons/arrowright.png'
import rightclick from '../../../assets/Icons/threedots.png'
import User from '../../../assets/Icons/calendar.png'
import { useNavigate } from 'react-router-dom'

const ReleasePlanner = () => {
  const navigate = useNavigate()

  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  return (
    <li>
      <div
        onClick={() => navigate('/releaseplanner')}
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        class='hover:bg-customBlueFour flex items-center p-2 text-base font-bold text-black rounded-lg'
      >
        <img className='mr-2' src={Arrow1} alt='Arrow' />
        <img src={User} alt='User' />
        <span class='mt-1 flex-1 ml-3 whitespace-nowrap'>ReleasePlanner</span>
        <img
          src={rightclick}
          style={{ visibility: isHover ? 'visible' : 'hidden' }}
          className='ml-5'
          alt='Dashboard'
        />
      </div>
    </li>
  )
}

export default ReleasePlanner
