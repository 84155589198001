import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import sheet from '../../assets/Icons/sheets.png'
import exp from '../../assets/Icons/export.png'

import { DELETE, GET, POST } from '../../config/api'
import ImportExcel from '../../components/Modal/ImportExcel/addfeature'
import { read, utils, writeFile } from 'xlsx'
import { notificationTypes, openNotification } from '../../utils/notifications'

const Feature = () => {
  const { tcID, scenarioID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [showModal, setShowModal] = useState(false)
  const [tc, setTC] = useState({})
  const [fmSteps, setFMSteps] = useState([])

  const [data, setData] = useState([])

  const handleImport = ($event) => {
    // Log a message to confirm that the function was called
    console.log('handleImport() called')

    const files = $event.target.files

    if (files.length > 0) {
      const file = files[0]

      if (
        file.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/vnd.ms-excel' &&
        file.type !== '.csv'
      ) {
        openNotification(
          notificationTypes.ERROR,
          'Error: 70410',
          'Invalid file type. Please select an Excel file and try again.'
        )
        return
      }

      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          // Use helper function XLSX.read instead of read here
          const wb = read(event.target.result, { type: 'array' })
          const sheets = wb.SheetNames

          let flag = false

          if (sheets.length > 0) {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
            console.log('r', rows)
            const updatedData = []

            // Check that all rows have required properties
            for (let i = 0; i < rows.length; i++) {
              const {
                id,
                importType,
                name,
                featureId,
                applicationName,
                applicationId,
                moduleName,
                moduleId,
                description,
                devStatus,
                testCaseCreationStatus,
                testCaseReviewStatus
              } = rows[i]

              if (
                !importType ||
                !name ||
                !featureId ||
                !applicationName ||
                !applicationId ||
                !moduleName ||
                !moduleId ||
                !devStatus ||
                !testCaseCreationStatus ||
                !testCaseReviewStatus
              ) {
                flag = true
                break
              }

              if (id) {
                updatedData.push({
                  id,
                  importType: 'Old',
                  name,
                  featureId,
                  applicationId,
                  moduleName,
                  description,
                  devStatus,
                  testCaseCreationStatus,
                  testCaseReviewStatus
                })
              } else {
                updatedData.push({
                  importType,
                  name,
                  featureId,
                  applicationId,
                  moduleName,
                  description,
                  devStatus,
                  testCaseCreationStatus,
                  testCaseReviewStatus
                })
              }
            }

            if (flag) {
              openNotification(
                notificationTypes.ERROR,
                'Error: 70421',
                'Invalid data in Excel file. Please check your data and try again.'
              )
            } else {
              // Assume that POST is defined elsewhere
              POST('feature/import', updatedData, { sessionID: sessionId })
                .then((res) => {
                  if (res.data.status === 200) {
                    openNotification(
                      notificationTypes.SUCCESS,
                      'Success',
                      'File uploaded successfully.'
                    )
                    window.location.reload()
                  } else if (res.data.status === 70430) {
                    openNotification(
                      notificationTypes.ERROR,
                      'Error: 70430',
                      res.data.message
                    )
                  } else if (res.data.status === 70431) {
                    openNotification(
                      notificationTypes.ERROR,
                      'Error: 70431',
                      res.data.message
                    )
                  } else if (res.data.status === 70440) {
                    openNotification(
                      notificationTypes.ERROR,
                      'Error: 70440',
                      res.data.message
                    )
                  } else if (res.data.status === 70450) {
                    openNotification(
                      notificationTypes.ERROR,
                      'Error: 70450',
                      res.data.message
                    )
                  } else {
                    openNotification(
                      notificationTypes.ERROR,
                      'Error: 70421',
                      'Something has gone wrong. Please check the values in the file'
                    )
                  }
                })
                .catch((err) => {
                  openNotification(
                    notificationTypes.ERROR,
                    'Error: 70421',
                    'Something has gone wrong.'
                  )
                })

              // Assume that setShowModal is defined and used to hide the file upload modal
              setShowModal(false)
            }
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error: 70420',
              'The Excel file does not contain any sheets. Please check your data and try again.'
            )
          }
        } catch (e) {
          openNotification(
            notificationTypes.ERROR,
            'Error: 70410',
            `Failed to parse Excel file: ${e.message}`
          )
        }
      }
      reader.readAsArrayBuffer(file)
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error: 70420',
        'No file was selected. Please select a file and try again.'
      )
    }
  }

  const handleExportInsert = () => {
    const exportModules = []
    applications.map((obj) => {
      GET(`getModules?appID=${obj.id}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.message.length !== 0) {
            res.data.message.map((obj) => {
              console.log(obj.id)
              if (obj.id) exportModules.push(obj)
            })
          }
          if (applications[applications.length - 1].id === obj.id) {
            // console.log("ssss", applications.pop().id, obj.id);
            handleExport(exportModules)
          }
        })
        .catch((err) => {
          console.log(err)
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, Please try again later'
          )
        })
    })
    // handleExport(exportModules);
  }

  const handleExport = (exportmodules) => {
    // console.log(p);
    const data = []
    let exportCount = 0
    feature?.map((obj) => {
      console.log(obj)
      exportCount++
      if (exportCount <= feature.length) {
        const moduleName = exportmodules.find((item) => {
          console.log(item.modelName, obj.moduleName)
          return item.modelName === obj.moduleName
        })
        const applicationName = applications.find((item) => {
          return item.id === obj.applicationId
        })
        console.log('www', moduleName, applicationName)
        const object = {
          id: obj.id,
          importType: obj.importType,
          name: obj.name,
          featureId: obj.featureId,
          applicationName: applicationName.applicationName,
          applicationId: obj.applicationId,
          moduleName: obj.moduleName,
          moduleId: moduleName.id,
          description: obj.description,
          devStatus: obj.devStatus,
          testCaseCreationStatus: obj.testCaseCreationStatus,
          testCaseReviewStatus: obj.testCaseReviewStatus
        }
        data.push(object)
      }
    })

    console.log(data)

    const headings = [
      [
        'id',
        'importType',
        'name',
        'featureId',
        'applicationName',
        'applicationId',
        'moduleName',
        'moduleId',
        'description',
        'devStatus',
        'testCaseCreationStatus',
        'testCaseReviewStatus'
      ]
    ]
    const wb = utils.book_new()
    const ws = utils.json_to_sheet([])
    utils.sheet_add_aoa(ws, headings)
    const outdata = JSON.stringify(data, [
      'id',
      'importType',
      'name',
      'featureId',
      'applicationName',
      'applicationId',
      'moduleName',
      'moduleId',
      'description',
      'devStatus',
      'testCaseCreationStatus',
      'testCaseReviewStatus'
    ])
    const output = JSON.parse(outdata)
    utils.sheet_add_json(ws, output, { origin: 'A2', skipHeader: true })
    utils.book_append_sheet(wb, ws, 'Report')
    writeFile(wb, 'FeatureManagement.xlsx')
    GET('/exportToFile?activity=export_featuretoexcel', {
      sessionID: sessionId
    }).then(() => {
      openNotification(
        notificationTypes.SUCCESS,
        'Success',
        'Feature Exported Sucessfully'
      )
    })
  }

  const navigate = useNavigate()
  // const [allTC, setAllTC] = useState({});
  const [appName, setAppName] = useState('')
  const [filteredTC, setFilteredTC] = useState({})

  // const filterTC = () => {
  //   const filtered = allTC.filter((tc) => {
  //     return tc.appName.toLowerCase().includes(appName.toLowerCase());
  //   });
  //   setFilteredTC(filtered);
  // };

  const [feature, setFeature] = useState([])
  const [applications, setApplications] = useState([])
  const [editDetails, setEditDetails] = useState(null)
  const [exportFeatures, setExportFeatures] = useState([])
  let count = 0

  useEffect(() => {
    getApplications()
  }, [])

  useEffect(() => {
    console.log(exportFeatures)
  }, [exportFeatures])

  useEffect(() => {
    console.log(applications)
    getFeature()
  }, [applications])

  useEffect(() => {
    console.log(feature)
  }, [feature])

  const features = [
    {
      importType: 'New',
      name: 'feature1',
      featureId: 1,
      applicationName: 'app1',
      applicationId: 1,
      moduleName: 'module1',
      moduleId: 1,
      description: 'hello',
      devStatus: 'Inprogress',
      testCaseCreationStatus: 'Inprogress',
      testCaseReviewStatus: 'Inprogress'
    },
    {
      importType: 'old',
      name: 'feature2',
      featureId: 2,
      applicationName: 'app2',
      applicationId: 2,
      moduleName: 'module2',
      moduleId: 2,
      description: 'hi',
      devStatus: 'Complete',
      testCaseCreationStatus: 'Complete',
      testCaseReviewStatus: 'Complete'
    }
  ]
  const getApplications = () => {
    GET('getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        setApplications(res.data.message)
        setFeature([])
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, Please try again later'
        )
      })
  }

  const getFeature = () => {
    applications.map((obj) => {
      GET(`feature?AppID=${obj.id}`, { sessionID: sessionId })
        .then((res) => {
          // setFeature((p) => [...p, res.data.message]);
          // setFeature(res.data.message);
          if (res.data.message.length !== 0) {
            res.data.message.map((obj) => {
              if (obj.id) {
                setFeature((p) => {
                  return [...p, obj]
                })
              }
            })
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, Please try again later'
          )
        })
    })
  }

  const deleteFeature = () => {
    const confirmed = window.confirm('Are you sure you want to delete this feature?')

    if (confirmed) {
      DELETE(`/deleteFeature/${editDetails[0]}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Successfully deleted feature'
            )
            // You can also choose to reload the page after deletion if necessary
            // window.location.reload(true);
          } else {
            openNotification(
              notificationTypes.INFO,
              'Error deleting feature, Feature has dependency with Release'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error deleting feature, Please try again'
          )
        })
    }
  }

  const selectedEdit = (e) => {
    setEditDetails(e.target.value.split(','))
    // console.log(e.target.value);
  }

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Feature Management
            </h1>
          </div>
          <div className='grid grid-cols-10 items-center justify-between'>
            <div className='col-span-4'>
              <ul className='flex gap-5 my-2 py-2 items-center'>
                <li className='p-0'>
                  <select
                    className='text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg border-2 border-gray-400'
                    name='cars'
                    id='cars'
                  >
                    <option
                      className='mb-3 text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                      value='volvo'
                    >
                      Application
                    </option>
                    <option
                      className='mb-3 text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                      value='saab'
                    >
                      Module
                    </option>
                  </select>
                </li>
                <li className='relative py-2 flex w-full flex-wrap items-stretch'>
                  <input
                    type='search'
                    className='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                    placeholder='Search for Feature'
                    aria-label='Search'
                    aria-describedby='button-addon3'
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                  />
                  <button
                    className='relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0'
                    type='button'
                    id='button-addon3'
                    data-te-ripple-init
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      className='h-5 w-5'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z'
                        clip-rule='evenodd'
                      />
                    </svg>
                  </button>
                </li>
              </ul>
              <ul className='relative my-2 py-2 flex w-full flex-wrap items-stretch justify-between'>
                <li>
                  <button
                    onClick={() => navigate('/addfeature')}
                    className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg'
                  >
                    Add Feature
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      editDetails
                        ? navigate('/eachfeature', {
                          state: {
                            message: editDetails
                          }
                        })
                        : navigate('/feature')
                    }}
                    className={`flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg ${
                      editDetails ? '' : 'cursor-not-allowed'
                    }`}
                  >
                    Edit Feature
                  </button>
                </li>
                <li>
                  <button
                    onClick={deleteFeature}
                    className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg'
                  >
                    Delete Feature
                  </button>
                </li>{' '}
              </ul>
            </div>
            <div className='col-start-9 items-center col-end-11 flex flex-wrap justify-end'>
              <button
                onClick={() => setShowModal(true)}
                className='text-center items-center w-full flex py-2 my-2 hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6'
              >
                <img className='h-6' src={sheet} alt='' /> &nbsp; Import from
                excel
              </button>
              <button
                onClick={handleExportInsert}
                className='text-center items-center w-full flex py-2 my-2 hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-base border-2 border-black px-6'
              >
                <img className='h-5' src={exp} alt='export' /> &nbsp; Export to
                excel
              </button>
            </div>
            {/* <div className="absolute -mt-12 right-0 ml-32 mr-10 col-span-1 grid grid-cols-1">
                <div className="col-span-1">
                  <button
                    onClick={() => navigate("/addfeature")}
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
                  >
                    Add Feature
                  </button>
                  <button
                    onClick={() =>
                      navigate("/eachfeature", {
                        state: {
                          message: editDetails,
                        },
                      })
                    }
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
                  >
                    Edit Feature
                  </button>
                  <button
                    onClick={deleteFeature}
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div className="mt-10 ml-5">
                <div className="flex">
                  <button
                    onClick={() => setShowModal(true)}
                    className="flex hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-sm border-2 border-black px-6 py-3"
                  >
                    <img className="h-6" src={sheet} /> &nbsp; Import from excel
                  </button>
                  <button
                    onClick={handleExport}
                    className="ml-5 flex hover:bg-customBlueFour rounded-2xl text-black font-bold font-DMSANS text-sm border-2 border-black px-6 py-3"
                  >
                    <img className="h-5" src={exp} alt="export" /> &nbsp; Export
                    to excel
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl bg-customWhite'>
            <table className='w-full overflow-y-auto mx-1 border-1 rounded-3xl border-customGreyThree'>
              <thead className='text-center bg-customGrey rounded-2xl'>
                <tr>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 text-left'
                  />
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Feature Name{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Feature ID{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Application Name{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Module Name{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Feature Description{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Dev Status{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Test Case Creation Status{' '}
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    Test Case Review Status{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  // filteredTC == null
                  //   ? null
                  //   : filteredTC.length > 0
                  //   ? filteredTC.map((tc) => <TestCase tc={tc} />)
                  // : <tr className='border-b border-1 border-customGreyThree'>
                  //   <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>No Features Found</td>
                  //  <td className='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>-</td>
                  // <td className='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>-</td>

                  feature?.map((obj) => {
                    count++
                    if (count <= feature.length) {
                      const applicationName = applications.find((item) => {
                        return item.id === obj.applicationId
                      })
                      // console.log("obj", obj);
                      return (
                        <tr className='border-b border-1 border-customGreyThree'>
                          <td>
                            <label className='text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap'>
                              <input
                                type='radio'
                                name='row_select'
                                value={`${obj.id},${obj.name}, ${obj.featureId}, ${applicationName.applicationName}, ${obj.moduleName}, ${obj.devStatus}, ${obj.testCaseCreationStatus}, ${obj.testCaseReviewStatus}, ${obj.description}`}
                                onChange={selectedEdit}
                              />
                            </label>
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.name}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.featureId}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {applicationName.applicationName}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.moduleName}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.description}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.devStatus}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.testCaseCreationStatus}
                          </td>
                          <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                            {obj.testCaseReviewStatus}
                          </td>
                        </tr>
                      )
                    }
                  })

                  // </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ImportExcel
        showModal={showModal}
        setShowModal={setShowModal}
        ImportExcel={handleImport}
      />
    </>
  )
}

export default Feature
