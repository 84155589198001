import { useState, useEffect } from 'react'
import { GET } from '../../config/api'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Application from './Application'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { openNotification, notificationTypes } from '../../utils/notifications'
import TableItem from './TableItem'
import Label from '../../components/InputComponents/Label'
import MultiSelect from 'react-select'

const ViewManualTestCases = () => {
  const { manualrunID, testpackID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [testPack, setTestPack] = useState({})
  const [tpID, settpID] = useState('')
  const [application, setApplication] = useState({})
  const [allTC, setAllTC] = useState({})
  const [filteredTC, setFilteredTC] = useState({})

  const selectOptions = [
    { value: 'No Run', label: 'No Run' },
    { value: 'Fail', label: 'Fail' },
    { value: 'Pass', label: 'Pass' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'In-Progress', label: 'In-Progress' }
  ]

  const getApplication = () => {
    GET(`/getTestPackById?id=${testpackID}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setTestPack(res.data.message)
          settpID(res.data.message.id)

          GET(`/getSpecificApp?appID=${res.data.message.application_id}`, {
            sessionID: sessionId
          })
            .then((res) => {
              if (res.data.status === 200 || res.data.status === 304) {
                setApplication(res.data.message)
              } else {
                console.log('error')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTC = () => {
    GET(`/gettestcasedata?id=${manualrunID}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setAllTC(res.data.message)
          setFilteredTC(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const updateMultiSelect = (item) => {
    let allFilteredItems = []
    item.forEach((selectedElement) => {
      const filterItems = allTC.filter((ele) => {
        return selectedElement.value === ele.status
      })
      allFilteredItems = [...allFilteredItems, ...filterItems]
    })
    setFilteredTC(allFilteredItems)
  }

  useEffect(() => {
    if (typeof testpackID !== 'undefined') {
      getApplication()
    }
    getTC()
  }, [testpackID, manualrunID])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        {/* <div className='col-span-2' /> */}
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Test Pack: {allTC[0] && allTC.length > 0 ? allTC[0].name : 'NA'}
            </h1>
            <div className='flex items-center justify-center flex-wrap'>
              <h1 className='font-semibold text-lg w-full text-center'>
                How to
              </h1>
              <p className='flex text-customBlue hover:text-customBlueFive items-center justify-center text-lg w-full'>
                <AiOutlineInfoCircle className='mr-2' />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://scribehow.com/shared/How_to_Execute_Test_Cases_as_Tester__3x21ymf8QP27VkZ-lEQMdg'
                >
                  Execute test cases?
                </a>
              </p>
            </div>
          </div>
        </div>

        <h1 className='col-span-10 text-black font-DMSANS text-base xl:text-2xl'>
          Select Test Case ({filteredTC != null && filteredTC.length > 0 ? filteredTC.length : 'NA'}):
        </h1>
        <div className='overflow-scroll overflow-contain h-[500px] bg-customWhite rounded-xl p-4 shadow col-span-10'>
          <div className='w-full mb-4'>
            <Label label='Filter by Test Case Result' />
            <MultiSelect
              defaultValue={selectOptions}
              options={selectOptions}
              onChange={(e) => updateMultiSelect(e)}
              isMulti
              name='TCResult'
              placeholder='Select result values you want to see'
              isSearchable={false}
              closeMenuOnSelect={false}
            />
          </div>
          <table className='w-full table-auto mx-1 border-1 rounded-3xl border-customGreyThree'>
            <thead class='text-center bg-customGrey rounded-2xl'>
              <tr>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left'
                >
                  Application {'>'} Module {'>'} Scenario
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left'
                >
                  Test Case Name
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 px-2 py-2 text-left'
                >
                  Test Case Description
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 p-2 text-left'
                >
                  Status
                </th>
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 p-1 text-left'
                />
                <th
                  scope='col'
                  class='text-base font-bold text-gray-900 p-1 text-left'
                />
              </tr>
            </thead>
            <tbody>
              {filteredTC == null
                ? null
                : filteredTC.length > 0
                  ? (
                      filteredTC.map((tc) => <TableItem testRunID={manualrunID} tc={tc} />)
                    )
                  : (
                    <tr class='border-b border-1 border-customGreyThree'>
                      <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                        No Test Cases Found
                      </td>
                      <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>
                        -
                      </td>
                    </tr>
                    )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ViewManualTestCases
