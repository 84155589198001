import React, { useState, useEffect } from "react";
import { documentTitles } from "../../constants/appConstants";
import DefectInfo from "../Defects/Defectsinfo/Index";
import Comments from "./Comments/Index";
import { useLocation } from "react-router-dom";
import Attachments from "./Attachments/Index";

const EditDefects = () => {
  const [data, setData] = useState(0);
  const location = useLocation();
  const dataArray = location.state.message.split(",");

  console.log(dataArray, "kkkkkkkkkkkkKKKKKKKKKKKKKKKKKKKKKKKKK");
  // const [defect, setDefect] = useState(dataArray[1].trim());
  const [number, setNumber] = useState(dataArray[0].trim());
  const [title, setTitle] = useState(dataArray[1].trim());
  const [createdDate, setCreatedDate] = useState(dataArray[5].slice(0, 11));
  const [priority, setPriority] = useState(dataArray[2].trim());
  const [status, setStatus] = useState(dataArray[4].trim());
  const [description, setDescription] = useState(dataArray[3].trim());
  
  const [projectId, setProjectId] = useState(dataArray[6].trim());
  const [issueTypeId, setIssueTypeId] = useState(dataArray[7].trim());
  
  const [externalDefectId, setExternalDefectId] = useState(dataArray[8].trim()); 
  const [externalDefectKey, setExternalDefectKey] = useState(dataArray[9].trim());
  const [dueDate, setDueDate] = useState(dataArray[10].slice(0, 10));
  const [assignee, setAssignee] = useState(dataArray[12].trim());
  const [reporter, setReporter] = useState(dataArray[11].trim());
  
  let jsons = JSON.parse(localStorage.getItem("defectsFormValue"));
  console.log(jsons,"jsons 32")
  const [jsonB, setJSONB] = useState(jsons[0].name ? jsons : []);
  console.log(jsonB,"jsons 34")
  let imageArr = JSON.parse(localStorage.getItem("defectsImage"));

  const [imageName, setImageName] = useState(imageArr);

  const handleEst = () => {
    setData(0);
  };

  const handlePlan = () => {
    setData(1);
  };

  const handlePlan2 = () => {
    setData(2);
  };

  useEffect(() => {
    document.title = documentTitles.EDIT_DEFECTS;
  }, []);


  return (
    <>
      <div>
        <div className="pb-52 bg-gray-100">
          <div className="mr-22 grid grid-cols-10 gap-16">
            <div className="ml-3 pb-32 col-span-10">
              <div>
                <div className="grid grid-cols-10">
                  <div className="col-span-6">
                    <h1 className="mt-5 ml-5 pb-3 mb-4  text-black font-bold font-DMSANS text-base xl:text-4xl leading-none lg:leading-11">
                      Defects Management
                    </h1>
                  </div>
                  <div className="col-span-2" />
                  <div className="col-span-2">
                    <div className="mt-5 ml-5 pb-3 mb-4">
                     
                    </div>
                  </div>
                </div>
                {/* <div className="grid grid-cols-6 gap-4 mt-5">
                  <div className="col-end-7">
                    <button
                      className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
                      type="button"
                      onClick={updateRelease}
                    >
                      Save
                    </button>
                  </div>
                </div> */}
                <div className="bg-customWhite p-10 rounded-xl shadow-xl">
                  <div>
                    <div className="flex mb-5">
                      <h1
                        onClick={handleEst}
                        className={
                          data === 0
                            ? "underline underline-offset-4 text-lg text-customBlueFive mr-2"
                            : "mr-2 text-lg text-black"
                        }
                      >
                        Defect Info
                      </h1>
                      <h1
                        onClick={handlePlan2}
                        className={
                          data === 2
                            ? "ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive"
                            : "ml-3 mr-2 text-lg text-black"
                        }
                      >
                        Attachments
                      </h1>
                      <h1
                        onClick={handlePlan}
                        className={
                          data === 1
                            ? "ml-3 mr-2 underline underline-offset-4 text-lg text-customBlueFive"
                            : "ml-3 mr-2 text-lg text-black"
                        }
                      >
                        Comments
                      </h1>
                    </div>
                  </div>
                  {data === 0 ? (
                    <DefectInfo
                      // defect1={defect}
                      number1={number}
                      title1={title}
                      priority1={priority}
                      description1={description}
                      status1={status}
                      createdDate1={createdDate}
                      dueDate1={dueDate}
                      assignee1={assignee}
                      reporter1={reporter}
                      defectsId={location.state.message.split(",")[0]}
                      projectId1={projectId}
                      issueTypeId1={issueTypeId}
                      externalDefectId={externalDefectId}
                      externalDefectKey={externalDefectKey}
                      jsonBData={jsonB ? jsonB : []}
                      imageNames={imageName}
                    />
                  ) : data === 1 ? (
                    <Comments
                      projectId1={projectId}
                      issueTypeId1={issueTypeId}
                      externalDefectId={externalDefectId}
                      defectsId={location.state.message.split(",")[0]}
                    />
                  ) : data === 2 ? (
                    <Attachments
                      projectId1={projectId}
                      issueTypeId1={issueTypeId}
                      externalDefectId={externalDefectId}
                      externalDefectKey={externalDefectKey}
                      imageNames={imageName}
                      defectsId={location.state.message.split(",")[0]}
                      jsonBData={jsonB ? jsonB : []}
                      title1={title}
                      priority1={priority}
                      description1={description}
                      status1={status}
                      createdDate1={createdDate}
                      assignee1={assignee}
                      reporter1={reporter}
                      dueDate1={dueDate}
                    />
                  ): (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDefects;
