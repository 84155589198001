module.exports = {

    // QA
    // defectsImageUrl: "https://qa.greentick.me/api/",

    //Local
    //defectsImageUrl: "http://127.0.0.1:8000/",

    //Preprod
    // defectsImageUrl: "https://preprod.greentick.me/api/"

    //Production
    defectsImageUrl: "https://greentick.me/api/"

  };
  