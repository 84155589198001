import { useState, useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'

import { GET, POST } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import SingleSelectComponent from '../../components/InputComponents/SingleSelectComponent'
import { accountType } from '../../constants/accType'
import { documentTitles } from '../../constants/appConstants'
import { FaUser } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const UserPage = () => {
  const user = useSelector((state) => state.user)
  const sessionId = user.sessionId
  console.log(user)
  const navigate = useNavigate()
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [accType, setAccType] = useState(accountType[0].value)
  const [app, setApp] = useState({})
  // const [applicationList, setApplicationList] = useState([])

  const addUser = () => {
    if (userName.length > 0 && email.length > 0) {
      const data = {
        username: userName,
        email,
        accountType: accType
      }
      POST('/addUser', data, { sessionID: sessionId })
        .then((res) => {
          // console.log(res.data)
          if (
            res.data.status === 200 ||
            res.data.status === 304 ||
            res.data.status === 'ok'
          ) {
            openNotification(notificationTypes.SUCCESS, 'User added')
            openNotification(
              notificationTypes.SUCCESS,
              'Verification Email sent to User'
            )
            navigate('/usermanagement')
          } else if (
            res.data.status === 50020
          ) {
            openNotification(notificationTypes.ERROR, 'ERROR - 50020', 'User Already Exists')
          } else {
            openNotification(notificationTypes.ERROR, 'Error adding user')
          }
        })
        .catch((err) => {
          openNotification(notificationTypes.ERROR, 'User Already Exists')
        })
    } else {
      openNotification(notificationTypes.ERROR, 'Error - 50010', 'Please fill all the fields')
    }
    setUserName('')
    setEmail('')
    setAccType(accountType[0].value)
  }

  useEffect(() => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 304) {
          setApp(res.data.message)
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
    document.title = documentTitles.USERMANAGEMENT
  }, [])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between flex-wrap items-center'>
            <h1 className='w-full font-bold text-black font-DMSANS text-4xl py-1'>
              User Management
            </h1>
            <h1 className='w-full font-bold text-black font-DMSANS text-2xl py-1'>
              Add User
            </h1>
          </div>
          <div className='mt-4 pb-6 min:h-2/5 mr-8'>
            <button
              className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg'
              type='button'
              onClick={addUser}
            >
              Add New User
              <FaUser className='text-base' />
            </button>
          </div>
        </div>
        <div className='flex justify-between items-center col-span-10'>
          <div className='border-2 border-customGreyTwo p-5 rounded-2xl w-full gap-4 mb-4'>
            <div>
              <label
                className='ml-1 block text-gray-700 text-sm font-bold mb-2'
                for='username'
              >
                Username
              </label>
              <input
                className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                id='username'
                type='text'
                placeholder='enter username'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div>
              <label
                className='ml-1 block text-gray-700 text-sm font-bold mb-2'
                for='username'
              >
                Enter Email ID
              </label>
              <input
                className='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 mb-3 leading-tight font-medium focus:outline-none focus:shadow-outline'
                id='email'
                type='email'
                placeholder='enter email ID'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label className='ml-2 block text-gray-700 text-sm font-bold mb-2'>
                Type of the User
              </label>
              <SingleSelectComponent
                value={accType}
                onChangeHandler={(e) => setAccType(e.target.value)}
                options={accountType.map((item) => item)}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-10 col-span-10 gap-4'>
          <div className='blur-sm mb-6 border-2 col-span-5 border-customGreyTwo p-5 rounded-xl'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              for='password'
            >
              User Roles
            </label>
            <fieldset className='flex flex-col'>
              <div class='my-1 form-check text-lg'>
                <input
                  disabled
                  className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  id='a'
                />
                <label
                  className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                  for='a'
                >
                  Administration
                </label>
              </div>
              <div class='my-1 form-check text-lg'>
                <input
                  disabled
                  className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  id='a'
                />
                <label
                  className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                  for='a'
                >
                  Test Case Management
                </label>
              </div>
              <div class='my-1 form-check text-lg'>
                <input
                  disabled
                  className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  id='a'
                />
                <label
                  className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                  for='a'
                >
                  CXO View
                </label>
              </div>
              <div class='my-1 form-check text-lg'>
                <input
                  disabled
                  className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  id='a'
                />
                <label
                  className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                  for='a'
                >
                  Test Pack Management
                </label>
              </div>
              <div class='my-1 form-check text-lg'>
                <input
                  disabled
                  className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  id='a'
                />
                <label
                  className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                  for='a'
                >
                  Report
                </label>
              </div>
              <div class='my-1 form-check text-lg'>
                <input
                  disabled
                  className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  id='a'
                />
                <label
                  className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                  for='a'
                >
                  User Management
                </label>
              </div>
            </fieldset>
          </div>
          <div className='blur-sm mb-6 border-2 col-span-5 border-customGreyTwo p-5 rounded-xl'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              for='password'
            >
              Applications allowed
            </label>
            <fieldset className='flex flex-col'>
              {app === null ? (
                <h1>Loading...</h1>
              ) : app.length > 0 ? (
                app.map((item, index) => {
                  return (
                    <div class='my-1 form-check text-lg'>
                      <input
                        disabled
                        // onChange={(e) => handleApplication(e, item)}
                        className='form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                        type='checkbox'
                        id='a'
                      />
                      <label
                        className='mt-1 form-check-label inline-block text-black font-light font-DMSANS'
                        for='a'
                      >
                        {item.applicationName}
                      </label>
                    </div>
                  )
                })
              ) : (
                <h1>No applications found</h1>
              )}
            </fieldset>
          </div>
        </div>
        <div className='flex justify-end col-span-10'>
          <button
            className='flex gap-1 items-center text-center text-base font-DMSANS font-semibold bg-customBlueFive text-white px-4 py-2 rounded-lg'
            type='button'
            onClick={addUser}
          >
            Add New User
            <FaUser className='text-base' />
          </button>
        </div>
      </div>
    </>
  )
}

export default UserPage
