import inputStyles from '../Input.module.css'

const Label = ({ label, required }) => {
  return (
    <label className={inputStyles.label} type='text'>
      {required
        ? (
          <span
            style={{
              color: 'red'
            }}
          >
            *
          </span>
          )
        : (
            ''
          )}{' '}
      {label}
    </label>
  )
}

export default Label
