import { useState, useEffect, useRef } from 'react'
import styles from '../modal.module.css'
import AssignTesterModal from '../AssignTesterModal'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { openNotification, notificationTypes } from '../../../utils/notifications'

const AutoTestModal = ({
  showAutoModal,
  setAutoShowModal,
  testpackId,
  isCloud
}) => {
  const modalRef = useRef(null)
  const [assignModal, setAssignModal] = useState(false)

  const closeModal = () => {
    setAutoShowModal(!showAutoModal)
  }

  const handleCloud = () => {
    openNotification(notificationTypes.SUCCESS, 'Success', 'Please wait for the test to complete.')
    setAutoShowModal(!showAutoModal)
  }

  useEffect(() => {
    if (showAutoModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showAutoModal])

  return (
    <>
      <div className={styles.wrapper} ref={modalRef}>
        <div className={styles.cardContainer}>
          <div className={styles.header}>
            <div className={styles.header}>
              <h2 className={styles.heading}>Automatic Run Assignment</h2>
            </div>
            <div className={styles.close}>
              <a onClick={closeModal}>
                <AiOutlineCloseCircle size={20} />
              </a>
            </div>
          </div>
          <div className={styles.modalBody}>
            <div className='mt-5 flex items-center justify-center'>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <button
                    className='px-8 py-4 text-xl rounded-3xl border-2 border-black focus:bg-black focus:text-white text-center mr-4'
                    onClick={() => setAssignModal(true)}
                  >
                    Assign to Tester
                  </button>
                </div>
                {
                  isCloud === true
                    ? (
                      <div>
                        <button
                          onClick={() => handleCloud()}
                          className='px-8 py-4 text-xl rounded-3xl border-2 border-black focus:bg-black focus:text-white text-center mr-4'
                        >
                          Run on Cloud
                        </button>
                      </div>)
                    : (<div />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssignTesterModal
        showModal={assignModal}
        setShowModal={setAssignModal}
        testpackId={testpackId}
      />
    </>
  )
}

export default AutoTestModal
