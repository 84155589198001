const status = {
  Ongoing: 'In Progress',
  Completed: 'Pending',
  Open: 'Review',
  TOSTART: 'Complete',
  Rejected: 'Deprecated',
  Approved:'Approved'
}

// export const TCstatus = [
//   {
//     id: 1,
//     value: status.Ongoing,
//     label: 'In Progress'
//   },
//   {
//     id: 2,
//     value: status.Completed,
//     label: 'Pending'
//   },
//   {
//     id: 3,
//     value: status.Open,
//     label: 'Review'
//   },
//   {
//     id: 4,
//     value: status.TOSTART,
//     label: 'Complete'
//   }
// ]
export const TCstatus = [
  {
    id: 1,
    value: status.Ongoing,
    label: 'In Progress'
  },
  {
    id: 2,
    value: status.Completed,
    label: 'Pending'
  },
  {
    id: 3,
    value: status.Rejected,
    label: 'Deprecated'
  },
  {
    id: 4,
    value: status.Approved,
    label: 'Approved'
  },
  {
    id: 5,
    value: status.Open,
    label: 'Review'
  },
  {
    id: 6,
    value: status.TOSTART,
    label: 'Complete'
  }
]
export const TCpriorityVal = [
  {
    id: 1,
    value: 'Low',
    label: 'Low'
  },
  {
    id: 2,
    value: 'Normal',
    label: 'Normal'
  },
  {
    id: 3,
    value: 'High',
    label: 'High'
  },
]
const type = {
  T1: 'Functional',
  T2: 'UI',
  T3: 'End to End'
}

export const TCtype = [
  {
    id: 1,
    value: type.T1,
    label: 'Functional'
  },
  {
    id: 2,
    value: type.T2,
    label: 'UI'
  },
  {
    id: 3,
    value: type.T3,
    label: 'End to End'
  }
]
