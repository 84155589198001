import React, { useEffect, useState } from 'react'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { GET } from '../../config/api'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiRefresh } from 'react-icons/bi'

const SecurityScanTable = () => {
  const location = useLocation()
  const sessionId = useSelector((state) => state.user.sessionId)
  const searchParams = new URLSearchParams(location.search)
  const domain = searchParams.get('domain')
  const [allScans, setAllScans] = useState([])
  const [isTableVisible, setTableVisible] = useState(true)
  const navigate = useNavigate()

  const getAllScans = () => {
    GET(`security/eachreport?domain=${domain}`, { sessionID: sessionId })
      .then((res) => {
        setAllScans(res.data.data)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong. Please try again later.'
        )
      })
  }

  const navigateToEachDomainReport = (appId, scanId) => {
    navigate(`/eachdomainreport?domain=${domain}&appId=${appId}&scanId=${scanId}`)
  }

  const handleRefresh = () => {
    setTableVisible(false)
    getAllScans()
    setTimeout(() => {
      setTableVisible(true)
    }, 500)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh()
    }, 6000000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getAllScans()
  }, [])

  return (
    <div>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='grid grid-cols-10'>
            <div className='col-span-6'>
              <h1 className='mt-5 ml-5 pb-3 mb-4  text-black font-bold font-DMSANS text-base xl:text-4xl leading-none lg:leading-11'>
                Security Report
              </h1>
            </div>
            <div className='col-span-3' />
            <div className='col-span-1'>
              <div className='mt-5 ml-5 pb-3 mb-4'>
                <button
                  onClick={() => navigate('/securitytest')}
                  class='mr-2 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded'
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          {allScans.length > 0
            ? (
              <div className='mt-4'>
                <div className='flex gap-2'>
                  <h3 className='text-lg font-bold mb-2'>Scans Audit of domain : <span className='text-blue-500'>{domain}</span></h3>
                  <button onClick={() => handleRefresh()} className='text-blue-500 text-3xl pb-3'><BiRefresh /></button>
                </div>
                {isTableVisible
                  ? (
                    <table className='border-collapse'>
                      <thead>
                        <tr>
                          <th className='border border-gray-400 text-sm px-4 py-2'>Status</th>
                          <th className='border border-gray-400 text-sm px-4 py-2'>Created Time</th>
                          <th className='border border-gray-400 text-sm px-4 py-2'>Updated Time</th>
                          <th className='border border-gray-400 text-sm px-4 py-2'>Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allScans.map((data, index) => (
                          <tr key={index} onClick={() => navigateToEachDomainReport(data.appID, data.id)} className='cursor-pointer hover:bg-blue-200'>
                            <td className='border border-gray-400 px-4 py-2'>
                              {data.isComplete === '1'
                                ? (
                                  <span className='text-green-500'>Complete</span>
                                  )
                                : (
                                  <span className='text-yellow-500'>In Progress</span>
                                  )}
                            </td>
                            <td className='border border-gray-400 px-4 py-2'>
                              {new Date(data.createdTime).toLocaleString('en-US', {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                              })}
                            </td>
                            <td className='border border-gray-400 px-4 py-2'>
                              {new Date(data.updatedTime).toLocaleString('en-US', {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                              })}
                            </td>
                            <td className='border border-gray-400 px-4 py-2'>
                              {data.score}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <p>Loading...</p>
                    )}
              </div>
              )
            : (
              <p>No scan report available.</p>
              )}
        </div>
      </div>
    </div>
  )
}

export default SecurityScanTable
