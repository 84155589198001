import React from 'react'
import { FaHandsHelping } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const Howitworks = () => {
  const navigate = useNavigate()
  return (
    <li className='pb-48 px-6 mb-24 border-b-2 border-customGreyThree'>
      <div
        onClick={() => navigate('/howitworks')}
        class='hover:bg-customBlueFour flex items-center p-2 text-base font-bold text-black rounded-lg'
      >
        <FaHandsHelping className='w-6 h-10 text-gray-700' />
        <span class='mt-1 flex-1 ml-3 whitespace-nowrap'>
          How Greentick Works?
        </span>
      </div>
    </li>
  )
}

export default Howitworks
