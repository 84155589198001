import { routes } from "../constants/routes";
import React from "react";
import { useSelector } from "react-redux";
import {
  MdOutlineDashboardCustomize,
  MdManageSearch,
  MdOutlineFeaturedPlayList,
  MdSecurity,
  MdSettings,
} from "react-icons/md";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SiSpeedtest } from "react-icons/si";
import { TbReport } from "react-icons/tb";
import { BsRocketTakeoff } from "react-icons/bs";
import { FcWorkflow } from "react-icons/fc";

export const MenuContext = React.createContext();

export const MenuListProvider = (props) => {

  // const userId = useSelector((state) => state.user.userId);
  // const accountType = useSelector((state) => state.user.accountType);
  // console.log(userId, "userId 11111111");
  // console.log(accountType, "accountType 11111111");

  const MenuLists = [
    {
      id: 1,
      name: "Dashboard",
      path: routes.DASHBOARD,
      icon: <MdOutlineDashboardCustomize size={24} />,
      accountType: [2, 0, 1, 3],
      api: "",
      submenu: false,
      model: true,
      dynamicMenu: [],
      contextMenu: [],
    },
    {
      id: 2, // important
      name: "Test Case Management",
      path: routes.TESTCASEMANAGEMENT,
      icon: <MdManageSearch size={24} />,
      accountType: [2, 0, 1],
      api: "getApplicationFromCompany", // important
      apitype: "application", // important
      model: false, // important
      submenu: true, // important
      contextMenu: [
        {
          id: 1,
          name: "Add Application",
          path: "",
          catagory: "Application",
          model: true,
        },
      ],
      dynamicMenu: [],
    },
    {
      id: 3, // important
      name: "Test Pack ",
      path: routes.TESTPACKMANAGEMENT,
      icon: <MdManageSearch size={24} />,
      accountType: [2, 0, 3],
      contextMenu: [],
      submenu: true,
      model: false,
      dynamicMenu: [],
    },
    {
      id: 4, // important
      name: "Create CXO view",
      path: routes.TESTPACKMANAGEMENT,
      icon: <VscGitPullRequestCreate size={24} />,
      accountType: [1],
      api: "getApplicationFromCompany", // important
      apitype: "cxo", // important
      contextMenu: [],
      submenu: true,
      model: false,
      dynamicMenu: [],
    },
    {
      id: 5, // important
      name: "User management",
      path: routes.USERMANAGEMENT,
      icon: <AiOutlineUsergroupAdd size={24} />,
      accountType: [1],
      dynamicMenu: [],
      submenu: false,
      model: false,
      contextMenu: [
        {
          id: 1,
          name: "Add User",
          path: routes.ADDUSER,
          catagory: "",
          model: false,
        },
        {
          id: 2,
          name: "View User",
          path: routes.USERMANAGEMENT,
          catagory: "",
          model: false,
        },
      ],
    },
    {
      id: 6, // important
      name: 'Test Execution',
      path: routes.EXECUTION,
      icon: <SiSpeedtest size={22} />,
      accountType: [0, 1, 2],
      submenu: true,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 7, // important
      name: "Test Reports",
      path: routes.REPORT,
      icon: <TbReport size={24} />,
      accountType: [0, 1, 2],
      api: "gettestrundetails", // important
      apitype: "testreport", // important
      submenu: true,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 8, // important
      name: "Security Report",
      path: routes.SECURITYTEST,
      icon: <MdSecurity size={24} />,
      accountType: [0, 1, 2],
      submenu: false,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 9, // important
      name: "Release Planner",
      path: routes.RELEASEPLANNER,
      icon: <BsRocketTakeoff size={24} />,
      accountType: [0, 1, 2],
      submenu: false,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 10, // important
      name: "Feature Management",
      path: routes.FEATURE,
      icon: <MdOutlineFeaturedPlayList size={24} />,
      accountType: [0, 1, 2],
      submenu: false,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 12, // important
      name: 'Reports',
      path: routes.TESTRUNSUMMARY,
      icon: <TbReport size={24} />,
      accountType: [0, 1, 2],
      submenu: true,
      model: true,
      contextMenu: [],
      dynamicMenu: []
    },
    {
      id: 11, // important
      name: "Defects",
      path: routes.DEFECTS,
      icon: <MdOutlineFeaturedPlayList size={24} />,
      accountType: [0, 1, 2],
      submenu: false,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 12, // important
      name: "How Greentick Works?",
      path: routes.HOWITWORKS,
      icon: <FcWorkflow size={24} />,
      accountType: [0, 1, 2],
      submenu: false,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    },
    {
      id: 13,
      name: "Settings",
      path: routes.SETTINGS,
      icon: <MdSettings size={24} />,
      accountType: [1],
      submenu: false,
      model: false,
      contextMenu: [],
      dynamicMenu: [],
    }
   
  ];
  const [MenuList, setMenuList] = React.useState(MenuLists || []);
  // const [modelstate, setModelState] = React.useState({
  //   modelType: "",
  //   isModal: false,
  //   itemID: "",
  //   scenarioID: "",
  //   appID: "",
  // });

  return (
    <MenuContext.Provider value={[MenuList, setMenuList]}>
      {props.children}
    </MenuContext.Provider>
  );
};
