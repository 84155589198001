import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DELETE, GET, POST } from '../../config/api'
// import TestCase from "./TestCase";
import { documentTitles } from '../../constants/appConstants'
import { useNavigate } from 'react-router-dom'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import moment from 'moment'

const ReleasePlanner = () => {
  const navigate = useNavigate()
  const sessionId = useSelector((state) => state.user.sessionId)

  const [allTC, setAllTC] = useState({})
  const [appName, setAppName] = useState('')
  const [filteredTC, setFilteredTC] = useState({})
  const [editDetails, setEditDetails] = useState(null)
  const [release, setRelease] = useState([])

  useEffect(() => {
    const getRelease = async () => {
      GET('/release', { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            setRelease(res.data.message)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error in getting release, Please try again'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting Releases, Please try again'
          )
        })
    }
    getRelease()
  }, [])

  const selectedEdit = (obj) => {
    const editdet = `${obj.id}, ${obj.name}, ${obj.version}, ${obj.releaseDate}, ${obj.description}, ${obj.overallStatus}, ${obj.devStartDate}, ${obj.devEndDate}, ${obj.effort}`
    setEditDetails(editdet)
    navigate('/editreleaseplanner', {
      state: {
        message: editdet
      }
    })
  }

  const deleteRelease = (releaseID) => {
    const confirmed = window.confirm('Are you sure you want to delete this release?')
    // const releaseID = editDetails.split(',')
    if (confirmed) {
      DELETE(`/deleteRelease/${releaseID}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            window.location.reload(true)
            openNotification(
              notificationTypes.SUCCESS,
              'Successfully deleted release'
            )
          } else if (res.data.status === 2000) {
            res.data.errors.forEach((errorMessage) => {
              openNotification(
                notificationTypes.INFO,
                errorMessage
              )
            })
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error deleting release, Please try again'
          )
        })
    }
  }

  const getTC = () => {
    GET('/getTestpackDetails', { sessionID: sessionId })
      .then((res) => {
        // console.log(res.data.message)
        if (res.data.status === 200 || res.data.status === 304) {
          setAllTC(res.data.message)
          setFilteredTC(res.data.message)
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const filterTC = () => {
    const filtered = allTC.filter((tc) => {
      return tc.applicationName.toLowerCase().includes(appName.toLowerCase())
    })
    setFilteredTC(filtered)
  }

  useEffect(() => {
    getTC()
    document.title = documentTitles.TESTPACKMANAGEMENT
  }, [])

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Release Planner
            </h1>
          </div>
          <div className='col-span-10'>
            <div className='my-4 grid grid-cols-2 gap-4'>
              <div className='col-span-1'>
                <div>
                  <div class='mb-3 w-full'>
                    <div class='relative mb-4 flex w-full flex-wrap items-stretch'>
                      <input
                        type='search'
                        class='relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l border border-solid border-black bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none'
                        placeholder='Search for Releases'
                        aria-label='Search'
                        aria-describedby='button-addon3'
                        value={appName}
                        onChange={(e) => setAppName(e.target.value)}
                      />
                      <button
                        class='relative z-[2] rounded-r border-2 border-blue-400 px-6 py-2 text-xs font-medium uppercase text-blue-400 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0'
                        type='button'
                        id='button-addon3'
                        data-te-ripple-init
                        onClick={filterTC}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          class='h-5 w-5'
                        >
                          <path
                            fill-rule='evenodd'
                            d='M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z'
                            clip-rule='evenodd'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='absolute right-0 ml-20 mr-16 col-span-1 grid grid-cols-1 gap-4'>
                <div className='col-span-1'>
                  <button
                    onClick={() => navigate('/addreleaseplanner')}
                    class='mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded'
                  >
                    Add Release
                  </button>
                  {/* <button
                    onClick={() => {
                      editDetails
                        ? navigate("/editreleaseplanner", {
                          state: {
                            message: editDetails,
                          },
                        })
                        : navigate("/releaseplanner");
                    }}
                    class={`mr-1 bg-blue-500 text-lg text-white font-bold p-2 rounded ${editDetails ? `hover:bg-blue-700` : `cursor-not-allowed`
                      }`}
                  >
                    Edit Release
                  </button>
                  <button
                    onClick={deleteRelease}
                    class="mr-1 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
                  >
                    Delete
                  </button> */}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className='mt-2'>
                  <table className='table-auto w-full overflow-x-scroll border-2 bg-customWhite rounded-3xl border-customGreyThree'>
                    <thead class='text-center bg-customGrey rounded-2xl'>
                      <tr>
                        {/* <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 text-left'
                        /> */}
                        <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 px-8 py-4 text-left'
                        >
                          Release Name{' '}
                        </th>
                        <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 px-8 py-4 text-left'
                        >
                          Release Version{' '}
                        </th>
                        <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 px-8 py-4 text-left'
                        >
                          Release Date{' '}
                        </th>
                        <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 px-8 py-4 text-left'
                        >
                          Status{' '}
                        </th>
                        <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 px-8 py-4 text-left'
                        >
                          Description{' '}
                        </th>
                        <th
                          scope='col'
                          class='text-xl font-bold text-gray-900 text-left'
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // filteredTC == null
                        //   ? null
                        //   : filteredTC.length > 0
                        //   ? filteredTC.map((tc) => <TestCase tc={tc} />)
                        // : <tr class='border-b border-1 border-customGreyThree'>
                        //   <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>No Features Found</td>
                        //  <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>-</td>
                        // <td class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>-</td>
                        release.length === 0
                          ? (
                            <tr>
                              <td />
                              <td className='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                                Please add release to display
                              </td>
                            </tr>
                            )
                          : (
                              release?.map((obj) => {
                                const releaseDate = moment(obj.releaseDate)
                                  .utc()
                                  .format('DD-MM-YYYY')
                                const releaseID = (obj.id)
                                return (
                                  <tr className='border-b border-1 border-customGreyThree '>
                                    <td class='underline cursor-pointer text-base text-blue-600 font-medium px-8 py-4 whitespace-nowrap' onClick={() => selectedEdit(obj)}>
                                      {obj.name}
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                                      {obj.version}
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                                      {releaseDate}
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                                      {obj.overallStatus}
                                    </td>
                                    <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap'>
                                      <div class='w-60 overflow-x-scroll'>
                                        {obj.description}
                                      </div>
                                    </td>
                                    <td class='text-2xl text-customRed font-medium px-8 py-4 whitespace-nowrap -z-50 cursor-pointer'>
                                      <MdOutlineDeleteOutline onClick={() => deleteRelease(releaseID)} />
                                    </td>
                                  </tr>
                                )
                              })
                            )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReleasePlanner
