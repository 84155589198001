import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../../config/api'
import Test from './Scenario/TestCase'
import { openNotification, notificationTypes } from '../../../../utils/notifications'
import Scenario from './Scenario'

const Module = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [scene, setScenario] = useState({})
  const [isScenario, setIsScenario] = useState(false)

  const handleModuleTests = () => {
    GET(`/getScenario?moduleID=${props.modules.id}`, { sessionID: sessionId })
      .then((res) => {
        setScenario(res.data.message)
        setIsScenario(true)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  useEffect(() => {
    if (typeof props.modules.id !== 'undefined') {
      handleModuleTests()
    }
  }, [props.modules.id])

  return (
    <>
      <div className='ml-16 pt-2 font-DMSANS text-sm xl:text-2xl font-medium leading-none lg:leading-11'>
        <i class='fa-solid fa-angle-right' />&nbsp;&nbsp;
        <span
          onClick={() => handleModuleTests(props.modules.id)}
        >
          {props.modules.modelName}&nbsp;
        </span>
      </div>
      {isScenario && (
        scene.map((item) => (
          <Scenario
            scenario={item}
            appID={props.modules.id}
            testPackID={props.testPackID}
          />
        ))
      )}
    </>
  )
}

export default Module
