import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import Arrow1 from '../../../assets/Icons/arrowright.png'
import rightclick from '../../../assets/Icons/threedots.png'
import ContextMenu from '../../ContextMenu'
import User from '../../../assets/Icons/user.png'

function UserManagement () {
  const contUserRef = useRef(null)

  const navigate = useNavigate()
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const addUser = () => {
    navigate('/addUser')
  }

  const viewUsers = () => {
    navigate('/usermanagement')
  }

  const handleUser = (e) => {
    if (e.button === 0) {
      navigate('/usermanagement')
    }
  }

  const menuItemsUser = [
    {
      id: 1,
      text: 'Add User',
      handleFunction: addUser
    },
    {
      id: 2,
      text: 'View Users',
      handleFunction: viewUsers
    }
  ]

  return (
    <li>
      <div
        ref={contUserRef}
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        class='hover:bg-customBlueFour'
      >
        <div
          onClick={(e) => handleUser(e)}
          className='flex items-center p-2 text-base font-bold text-black rounded-lg'
        >
          <img className='mr-2' src={Arrow1} alt='Arrow' />
          <img src={User} alt='User' />
          <span class='flex-1 ml-3 whitespace-nowrap'>User management</span>
          <img
            src={rightclick}
            style={{ visibility: isHover ? 'visible' : 'hidden' }}
            className='ml-5'
            alt='Dashboard'
          />
        </div>
        <ContextMenu
          parentRef={contUserRef}
          items={menuItemsUser}
        />
      </div>
    </li>
  )
}

export default UserManagement
