import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import DoughnutChart from './DoughnutChart '

const Box = (props) => {
  return (
    <View
      style={{
        width: '30%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
      }}
    >
      <View
        style={{
          width: 17,
          height: 4,
          backgroundColor: props.color,
          borderRadius: 20,
          marginRight: 8,
          marginTop: 4
        }}
      />
      <View>
        <Text style={styles.lable}>{props.label}</Text>
        <Text style={styles.data}>{props.value}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    paddingHorizontal: 50,
    paddingVertical: 24,
    border: 1,
    borderColor: '#E5E8EB',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    fontSize: 14,
    fontWeight: 700
  },
  lable: {
    color: '#929191',
    fontSize: 12,
    fontWeight: 500
  },
  data: {
    color: '#11263C',
    fontSize: 12,
    fontWeight: 600
  },
  textContainer: {
    flex: 1
  },
  flexWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    rowGap: 10,
    columnGap: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  }
})

const ChartSection = (props) => {
  console.log(props)
  const data = [
    { label: 'Pass', value: props.pass, color: '#40A87B' },
    { label: 'In-Progress', value: props.progress, color: '#FFCC00' },
    { label: 'No Results', value: props.noRun, color: '#DDDDDD' },
    { label: 'Fail', value: props.fail, color: '#E39B8A' },
    { label: 'On Hold', value: props.onhold, color: '#FFE2C3' }
  ]

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{props.title}</Text>
        <View style={styles.flexWrap}>
          {data.map((item, index) => (
            <Box
              label={item.label}
              value={item.value}
              color={item.color}
              key={index}
            />
          ))}
        </View>
      </View>
      <View>
        <DoughnutChart data={data} />
      </View>
    </View>
  )
}

export default ChartSection
