import { Circle, Path, Svg } from '@react-pdf/renderer'
import React from 'react'

const DoughnutChart = ({ data }) => {
  const total = data.reduce((sum, item) => sum + item.value, 0)
  const radius = 50
  const strokeWidth = 14
  const gapAngle = 0 // Gap angle in degrees
  const center = radius + strokeWidth / 2
  let startAngle = 0

  const getPath = (value) => {
    const percentage = (value / total) * 100
    const angle = (percentage / 100) * (360 - gapAngle * data.length)
    const endAngle = startAngle + angle

    const x1 = center + radius * Math.cos((startAngle * Math.PI) / 180)
    const y1 = center + radius * Math.sin((startAngle * Math.PI) / 180)

    const x2 = center + radius * Math.cos((endAngle * Math.PI) / 180)
    const y2 = center + radius * Math.sin((endAngle * Math.PI) / 180)

    const largeArcFlag = angle <= 180 ? 0 : 1

    startAngle = endAngle + gapAngle

    return `M${x1},${y1} A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2}`
  }

  return (
    <Svg width='114' height='114'>
      {data.map((item, index) => (
        <Path
          key={index}
          d={getPath(item.value)}
          fill='none'
          stroke={item.color}
          strokeWidth={strokeWidth}
          strokeLineCap='round'
        />
      ))}
      <Circle cx={center} cy={center} r={radius - strokeWidth} fill='white' />
    </Svg>
  )
}

export default DoughnutChart
