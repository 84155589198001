import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../../config/api'
import { openNotification, notificationTypes } from '../../../../utils/notifications'
import Scenario from './Scenario'
import Arrow from '../../../../assets/Icons/arrow.png'
import User from '../../../../assets/Icons/module.png'

const Module = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [scene, setScenario] = useState({})
  const [isScenario, setIsScenario] = useState(false)

  const getScenario = (id) => {
    GET(`/getScenario?moduleID=${id}`, { sessionID: sessionId })
      .then((res) => {
        setScenario(res.data.message)
        setIsScenario(true)
      })
      .catch((err) => {
        openNotification(notificationTypes.ERROR, 'Error')
      })
  }

  useEffect(() => {
    getScenario(props.modules.id)
  }, [props.modules.id])

  return (
    <>
      <div
        className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-8 leading-none lg:leading-11'
      >
        <span class='flex items-center p-2 font-normal text-black rounded-lg'>
          <img className='mr-2' src={Arrow} alt='Arrow' />
          <img src={User} className='h-6 mr-2' alt='User' />
          {props.modules.modelName}&nbsp;
        </span>
      </div>
      {
        isScenario && (
          scene.map((item) => (
            <Scenario
              scenario={item}
              moduleID={props.modules.id}
              manualrunID={props.manualrunID}
              testpackID={props.testpackID}
            />
          ))
        )
      }
    </>
  )
}

export default Module
