import { routes } from './routes'

export const signedOutNavLinks = [
  {
    name: 'About',
    route: routes.ABOUT
  },
  {
    name: 'Dashboard',
    route: routes.DASHBOARD
  },
  {
    name: 'Partnership',
    route: routes.Partnership
  }
]
