import { useState, useEffect, useRef } from "react";
import { documentTitles } from "../../constants/appConstants";
import { useSelector } from "react-redux";
import pencil from "../../assets/Icons/pencil.png";
import { POST, GET } from "../../config/api";
import { notificationTypes, openNotification } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";

const AddDefects = () => {
  const inputRef = useRef(null);
  const sessionID = useSelector((state) => state.user.sessionId);
  const userId = useSelector((state) => state.user.userId);
  const sessionId = useSelector((state) => state.user.sessionId);
  const formSettingData = useSelector((state) => state.user.settings);

  const [defect, setDefect] = useState("");
  const [title, setTitle] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState([]);
  const [accountType, setAccountType] = useState("greentick");

  const [projectId, setProjectId] = useState("");
  const [reporter, setReporter] = useState("");
  const [assignee, setAssignee] = useState("");
  const [jiraUrl, setJiraUrl] = useState("");
  const [jiraStatusBoardId, setJiraStatusBoardId] = useState("");

  const [projectIdData, setProjectIdData] = useState([]);
  const [issueStatusData, setIssueStatusData] = useState([]);
  const [issueStatusBoardData, setIssueStatusBoardData] = useState([]);
  const [statusValueData, setStatusValueData] = useState([]);
  const [reporterData, setReporterData] = useState([]);
  const [settingsData, setSettingsData] = useState([]);

  const [issueTypeId, setIssueTypeId] = useState("");
  const [issueTypeIdData, setIssueTypeIdData] = useState([]);

  const [fileData, setFileData] = useState([]);

  const navigate = useNavigate();

  const [jsonB, setJSONB] = useState([]);

  useEffect(() => {
    document.title = documentTitles.DEFECTS;
    getSettings()
    getAllUsers()
    if (formSettingData && typeof formSettingData === 'object' && Object.keys(formSettingData).length > 0) {
      const form =
        formSettingData.defectsConfiguration &&
        formSettingData.defectsConfiguration;

      setFormData(form && form.length > 0 ? form : []);
      if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
        setAccountType(formSettingData.accountType);
        if (
          formSettingData.userName &&
          formSettingData.url &&
          formSettingData.token
        ) {
          getProjectIds(
            formSettingData.userName,
            formSettingData.url,
            formSettingData.token
          );

          if (formSettingData.url === "") {
            openNotification(
              notificationTypes.ERROR,
              "Error: 80220",
              "Please add jira url in settings"
            );
            return;
          }
        }
      }
    }

  }, []);

  useEffect(() => {
    const getUsers = () => {
      GET("/getUsers", { sessionID })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            const response = res.data.message;
            setUsers(response);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUsers();
    document.title = documentTitles.USER;
  }, [sessionID]);

  const getProjectIds = async (userName, url, token) => {
    GET(`/getallproject?userName=${userName}&url=${url}&token=${token}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setProjectIdData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getallIssueStatus = async (projectId) => {
    GET(`/getallissuestatus?userName=${formSettingData.userName}&url=${formSettingData.url}&token=${formSettingData.token}&projectId=${projectId}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIssueStatusData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting ststus, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getallIssueStatusBoard = async (projectId) => {
    GET(`/getstatusboardvalues?userName=${formSettingData.userName}&url=${formSettingData.url}&token=${formSettingData.token}&projectId=${projectId}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIssueStatusBoardData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting ststus board values, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getAllUsers = async () => {
    GET(`/getallusers`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setReporterData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };


  async function getSettings() {
    await GET(`/getSettings?userId=${userId}`, { sessionID })
      .then((res) => {
        if (res.data.status === "ok") {
          setSettingsData(res.data.message)
          setJiraUrl(res.data.message.url)
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getIssueTypeIds = async (id) => {
    GET(
      `/getissuetypeid?userName=${formSettingData.userName}&url=${formSettingData.url}&token=${formSettingData.token}&id=${id}`,
      {
        sessionID: sessionId,
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          setIssueTypeIdData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const postDefects = () => {
    const data = {
      title,
      description,
      priority,
      createdDate,
      dueDate,
      status,
      projectId,
      reporter,
      assignee,
      issueTypeId,
      other: jsonB,
    };


    let formsData = new FormData();
    for (let i = 0; i < fileData.length; i++) {
      formsData.append('image', fileData[i]);
    }
    formsData.append("title", title);
    formsData.append("description", description);
    formsData.append("priority", priority);
    formsData.append("createdDate", createdDate);
    formsData.append("dueDate", dueDate);
    formsData.append("status", status);
    formsData.append("jiraStatusBoardId", jiraStatusBoardId);
    formsData.append("projectId", projectId);
    formsData.append("reporter", reporter);
    formsData.append("issueTypeId", issueTypeId);
    formsData.append("assignee", assignee);
    formsData.append("other", JSON.stringify(jsonB));

    if (accountType === "jira") {
      if (new Date(createdDate) >= new Date(dueDate)) {
        openNotification(
          notificationTypes.ERROR,
          "Error: 80210",
          "Enter valid due date"
        );
        return;
      }

      if (issueTypeId.length > 0 && projectId.length > 0 && title.length > 0 &&
        createdDate.length > 0 && dueDate.length > 0 && status.length > 0 && priority.length > 0 &&
        description.length > 0 && assignee.length > 0 && reporter.length > 0) {
        POST("/defects", formsData, { sessionID: sessionId })
          .then((res) => {
            if (res.data.status === 200) {
              openNotification(
                notificationTypes.SUCCESS,
                "Defects created Successfully"
              );
              navigate("/defects");
            } else {
              openNotification(
                notificationTypes.ERROR,
                "Error while creating Defects, Please try again later"
              );
            }
          })
          .catch(() => {
            openNotification(
              notificationTypes.ERROR,
              "Error while creating Defects, Please try again later"
            );
          });
        }else {
          if (projectId === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30820',
              'Please enter Project'
            )
          } else if (issueTypeId === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please enter Issue type'
            )
          } else if (title === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please enter Title'
            )
          } else if (createdDate === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please select a Created date'
            )
          } else if (dueDate === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please select a Due date'
            )
          } else if (status === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please select a Status'
            )
          } else if (priority === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please select a Priority'
            )
          }  else if (reporter === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please select a Reporter'
            )
          }else if (assignee === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please select a Assignee'
            )
          } else if (description === '') {
            openNotification(
              notificationTypes.ERROR,
              'Error: 30830',
              'Please enter description'
            )
          }
        }
    } else {
      if (new Date(createdDate) >= new Date(dueDate)) {
        openNotification(
          notificationTypes.ERROR,
          "Error: 80210",
          "Enter valid due date"
        );
        return;
      }
      if (issueTypeId.length > 0 && projectId.length > 0 && title.length > 0 &&
        createdDate.length > 0 && dueDate.length > 0 && status.length > 0 && priority.length > 0 &&
        description.length > 0 && assignee.length > 0 && reporter.length > 0) {
        POST("/defects", formsData, { sessionID: sessionId })
          .then((res) => {
            if (res.data.status === 200) {
              openNotification(
                notificationTypes.SUCCESS,
                "Defects created Successfully"
              );
              navigate("/defects");
            } else {
              openNotification(
                notificationTypes.ERROR,
                "Error while creating Defects, Please try again later"
              );
            }
          })
          .catch(() => {
            openNotification(
              notificationTypes.ERROR,
              "Error while creating Defects, Please try again later"
            );
          });
      }else {
        if (projectId === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30820',
            'Please enter Project'
          )
        } else if (issueTypeId === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please enter Issue type'
          )
        } else if (title === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please enter Title'
          )
        } else if (createdDate === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Created date'
          )
        } else if (dueDate === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Due date'
          )
        } else if (status === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Status'
          )
        } else if (priority === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Priority'
          )
        }  else if (reporter === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Reporter'
          )
        }else if (assignee === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Assignee'
          )
        } else if (description === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please enter description'
          )
        }
      }
    }
  };

  const handleFormJSONData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let type = e.target.type;

    for (let i = 0; i < value.length - 3; i++) {
      if (value[i] === value[i + 1] && value[i] === value[i + 2] && value[i] === value[i + 3]) {
        alert("Four or more consecutive identical characters are not allowed!");
        e.target.value = '';
        return;
      }
    }

    console.log(name, value, type, "name value");

    let found = false;

    if (jsonB.length > 0) {
      const newState = jsonB.map((obj) => {
        if (obj.name === name) {
          found = true;
          return { ...obj, value: type === "number" ? parseFloat(value) : value };
        }
        return obj;
      });
      setJSONB(newState);
    }

    let arr = [...jsonB];
    if (!found) {
      arr.push({
        name: name,
        value: type === "number" ? parseFloat(value) : value,
      });
      console.log(arr, "arr 374");
      setJSONB(arr);
    }
    console.log(JSON.stringify(arr), "arr stringify")
  };

  const handleFileChange = async (e) => {
    const fileObj = e.target.files;
    if (!fileObj) {
      return;
    }
    setFileData(fileObj);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // const specialCharacterCount = (inputValue.match(/[^\w\s]/g) || []).length;
    // if (specialCharacterCount > 5) {
    //     alert("More than 5 special characters are not allowed!");
    //     e.target.value = '';
    //     return;
    // }
    for (let i = 0; i < inputValue.length - 3; i++) {
      if (inputValue[i] === inputValue[i + 1] && inputValue[i] === inputValue[i + 2] && inputValue[i] === inputValue[i + 3]) {
        openNotification(
          notificationTypes.ERROR,
          "Consecutive identical characters and special characters are not allowed!"
        );
        e.target.value = '';
        return;
      }
    }
  };

  const handleStatusFilter = (e) => {
    let filteredData = issueStatusData.filter(item => item.id === e);
    setStatusValueData(filteredData);
  };

  const handleProjectChange = (e) => {
    setIssueTypeId([]);
    setStatusValueData([]);
    setProjectId(e.target.value);
    getIssueTypeIds(e.target.value);
    getallIssueStatusBoard(e.target.value);
    getallIssueStatus(e.target.value);
  };

  const handleStatusId = (e) => {
    const filteredStatusId = issueStatusBoardData.filter((item) => item.name === e);
    if (filteredStatusId.length > 0) {
      setJiraStatusBoardId(filteredStatusId[0].id);
      console.log(filteredStatusId[0], "entered value");
    } else {
      console.log("No matching item found for", e);
    }
  };


  return (
    <div className="bg-customGrey p-3">
      <div className="grid grid-cols-10 gap-x-4 gap-y-2">
        <div className="col-span-6">
          <h1 className="text-5xl ml-2 font-bold font-DMSANS">Add Defects</h1>
        </div>
        <div className="col-span-3" />

        <div className="col-span-10 me-3 mt-5 flex justify-end">
          <button
            onClick={() => navigate("/defects")}
            className="bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded"
          >
            Back
          </button>
          <button
            className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
            type="button"
            onClick={postDefects}
          >
            Save
          </button>
        </div>

        <div className="col-span-10 mt-5 bg-gray-200 p-4 rounded-xl">
          <div className="bg-white p-3 rounded-xl">
            <div className="grid grid-col-10 gap-x-4 gap-y-2">
              {" "}
              <div className="col-span-5">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Project &nbsp;<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  {accountType === "jira" ? (
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="projectId"
                      id="projectId"
                      onChange={(e) => {
                        handleProjectChange(e)
                      }}
                    >
                      <option>select Project Id</option>
                      {projectIdData.map((items, index) => {
                        return (
                          <>
                            <option value={items.id}>{items.name}</option>
                          </>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      className="p-2 w-full border-2 rounded-lg"
                      name="projectId"
                      id="projectId"
                      type="text"
                      value={projectId}
                      required
                      onChange={(e) => { handleInputChange(e); setProjectId(e.target.value) }}
                    />
                  )}

                </h1>
              </div>
              <div className="col-span-5">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Issue Type &nbsp;<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  {accountType === "jira" ? (
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="issueId"
                      id="issueId"
                      onChange={(e) => {
                        setStatusValueData([])
                        setIssueTypeId(e.target.value);
                        handleStatusFilter(e.target.value)
                      }}
                    >
                      <option>select Issue Type Id</option>
                      {issueTypeIdData.map((items, index) => {
                        return (
                          <>
                            <option value={items.id}>{items.name}</option>
                          </>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      className="p-2 w-full border-2 rounded-lg"
                      name="issueId"
                      id="issueId"
                      type="text"
                      value={issueTypeId}
                      required
                      onChange={(e) => { handleInputChange(e); setIssueTypeId(e.target.value) }}
                    />
                  )}
                </h1>
              </div>

              {/* <div className="col-span-10 ">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Defect
                </div>
                <h1 className="text-black">
                  <input
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={defect}
                    required
                    onChange={(e) => setDefect(e.target.value)}
                  />
                </h1>
              </div> */}
              <div className="col-span-10">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Title<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  <input
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={title}
                    required
                    onChange={(e) => { handleInputChange(e); setTitle(e.target.value) }}
                  />
                </h1>
              </div>

              <div className="col-span-3">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Created Date &nbsp;<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  <input
                    type="date"
                    className="p-2 w-full border-2 rounded-lg"
                    value={createdDate}
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(e) => setCreatedDate(e.target.value)}
                  />
                </h1>
              </div>
              <div className="col-span-3">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Due Date &nbsp;<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  <input
                    type="date"
                    className="p-2 w-full border-2 rounded-lg"
                    value={dueDate}
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </h1>
              </div>
              {accountType === "jira" ? (
                <div className="col-span-4">
                  <div className="text-md mt-2" style={{ color: "grey" }}>
                    Status &nbsp;<span style={{ color: "red" }}>*</span>
                  </div>
                  <h1 className="text-black">
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="overallStatus"
                      id="overallStatus"
                      onChange={(e) => { setStatus(e.target.value); handleStatusId(e.target.value) }}
                    >
                      <option>select Status</option>
                      {statusValueData.map((items, index) => {
                        return (
                          <>
                            <option value={items.status}>{items.status}</option>
                          </>
                        );
                      })}
                    </select>
                  </h1>
                </div>) : (
                <div className="col-span-4">
                  <div className="text-md mt-2" style={{ color: "grey" }}>
                    Status &nbsp;<span style={{ color: "red" }}>*</span>
                  </div>
                  <h1 className="text-black">
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="overallStatus"
                      id="overallStatus"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option>select</option>
                      <option>Open</option>
                      <option>Reopen</option>
                      <option>In-Progress</option>
                      <option>Fixed</option>
                      <option>Duplicate</option>
                      <option>Rejected</option>
                      <option>Deferred</option>
                      <option>Closed</option>
                    </select>
                  </h1>
                </div>
              )}
              <div className="col-span-3">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Priority &nbsp;<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  <select
                    className="p-2 w-full border-2 rounded-lg"
                    name="overallStatus"
                    id="overallStatus"
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    <option>select</option>
                    <option>Highest</option>
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                    <option>Lowest</option>
                  </select>
                </h1>
              </div>
              {accountType === "jira" ? (
                <>
                  <div className="col-span-3">
                    <div className="text-md mt-2" style={{ color: "grey" }}>
                      Reporter &nbsp;<span style={{ color: "red" }}>*</span>
                    </div>
                    <h1 className="text-black">
                      <select
                        className="p-2 w-full border-2 rounded-lg"
                        name="reporter"
                        id="reporter"
                        onChange={(e) => {
                          setReporter(e.target.value);
                        }}
                      >
                        <option>select Reporter</option>
                        {reporterData.map((items, index) => {
                          return (
                            <>
                              <option value={items.accountId}>{items.displayName}</option>
                            </>
                          );
                        })}
                      </select>
                    </h1>
                  </div>
                  <div className="col-span-4">
                    <div className="text-md mt-2" style={{ color: "grey" }}>
                      Assignee &nbsp;<span style={{ color: "red" }}>*</span>
                    </div>
                    <h1 className="text-black">
                      <select
                        className="p-2 w-full border-2 rounded-lg"
                        name="assignee"
                        id="assignee"
                        onChange={(e) => {
                          setAssignee(e.target.value);
                        }}
                      >
                        <option>select Assignee</option>
                        {reporterData.map((items, index) => {
                          return (
                            <>
                              <option value={items.accountId}>{items.displayName}</option>
                            </>
                          );
                        })}
                      </select>
                    </h1>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-span-3">
                    <div className="text-md mt-2" style={{ color: "grey" }}>
                      Reporter &nbsp;<span style={{ color: "red" }}>*</span>
                    </div>
                    <h1 className="text-black">
                      <select
                        className="p-2 w-full border-2 rounded-lg"
                        name="reporter"
                        id="reporter"
                        onChange={(e) => {
                          setReporter(e.target.value);
                        }}
                      >
                        <option>select Reporter</option>
                        {users.map((items, index) => {
                          return (
                            <>
                              <option value={items.username}>{items.username}</option>
                            </>
                          );
                        })}
                      </select>
                    </h1>
                  </div>
                  <div className="col-span-4">
                    <div className="text-md mt-2" style={{ color: "grey" }}>
                      Assignee &nbsp;<span style={{ color: "red" }}>*</span>
                    </div>
                    <h1 className="text-black">
                      <select
                        className="p-2 w-full border-2 rounded-lg"
                        name="assignee"
                        id="assignee"
                        onChange={(e) => {
                          setAssignee(e.target.value);
                        }}
                      >
                        <option>select Assignee</option>
                        {users.map((items, index) => {
                          return (
                            <>
                              <option value={items.username}>{items.username}</option>
                            </>
                          );
                        })}
                      </select>
                    </h1>
                  </div>
                </>
              )}
              <div className="col-span-10">
                <div className="text-md mt-2" style={{ color: "grey" }}>
                  Description &nbsp;<span style={{ color: "red" }}>*</span>
                </div>
                <h1 className="text-black">
                  <textarea
                    value={description}
                    onChange={(e) => { handleInputChange(e); setDescription(e.target.value) }}
                    placeholder="Enter Description"
                    className="p-2 w-full border-2 rounded-lg"
                  />
                </h1>
              </div>

              <div className="col-span-10">
                <label
                  style={{ color: "grey" }}
                  className="text-md mt-2 block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  htmlFor="multiple_files"
                >
                  Attachements
                </label>
                <input
                  className="block w-full text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="multiple_files"
                  type="file"
                  multiple
                  ref={inputRef}
                  onChange={handleFileChange}
                />
              </div>
              {formData &&
                formData.map((item, index) => {
                  return (
                    <div className="col-span-10" key={index}>
                      {(() => {
                        if (item.type === "text") {
                          return (
                            <>
                              <div
                                className="text-md mt-2"
                                style={{ color: "grey" }}
                              >
                                {item.label}
                              </div>
                              <h1 className="text-black">
                                <input
                                  type={item.subtype}
                                  name={item.name}
                                  className="p-2 w-full border-2 rounded-lg"
                                  placeholder={item.placeholder}
                                  required
                                  onChange={(e) => {
                                    handleFormJSONData(e);
                                    handleInputChange(e);
                                  }}
                                />
                              </h1>
                            </>
                          );
                        } else if (item.type === "textarea") {
                          return (
                            <>
                              <div
                                className="text-md mt-2"
                                style={{ color: "grey" }}
                              >
                                {item.label}
                              </div>
                              <textarea
                                type={item.subtype}
                                name={item.name}
                                onChange={(e) => {
                                  handleFormJSONData(e);
                                  handleInputChange(e);
                                }}
                                placeholder={item.placeholder}
                                className="p-2 w-full border-2 rounded-lg"
                              />
                            </>
                          );
                        } else if (item.type === "select") {
                          return (
                            <>
                              <div
                                className="text-md mt-2"
                                style={{ color: "grey" }}
                              >
                                {item.label}
                              </div>
                              <select
                                className="p-2 w-full border-2 rounded-lg"
                                // name="overallStatus"
                                id={item.name + index}
                                name={item.name}
                                onChange={(e) => {
                                  handleFormJSONData(e);
                                }}
                              >
                                <option value="">Select</option>
                                {item.values.map((items, index1) => {
                                  return (
                                    <>
                                      <option value={items.value}>
                                        {items.label}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </>
                          );
                        } else if (item.type === "radio-group") {
                          return (
                            <>
                              {item.values.map((items, index1) => {
                                return (
                                  <label className="text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap">
                                    <input
                                      type="radio"
                                      name={item.name}
                                      value={items.value}
                                      onChange={(e) => {
                                        handleFormJSONData(e);
                                      }}
                                    />
                                    &nbsp;{items.label}
                                  </label>
                                );
                              })}
                            </>
                          );
                        } else if (item.type === "checkbox-group") {
                          return (
                            <>
                              {item.values.map((items, index1) => {
                                return (
                                  <label className="text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap">
                                    <input
                                      type="checkbox"
                                      name={item.name}
                                      value={items.value}
                                      onChange={(e) => {
                                        handleFormJSONData(e);
                                      }}
                                    />
                                    &nbsp;{items.label}
                                  </label>
                                );
                              })}
                            </>
                          );
                        } else if (item.type === "number") {
                          return (
                            <>
                              <div
                                className="text-md mt-2"
                                style={{ color: "grey" }}
                              >
                                {item.label}
                              </div>
                              <h1 className="text-black">
                                <input
                                  type={item.subtype}
                                  className="p-2 w-full border-2 rounded-lg"
                                  placeholder={item.placeholder}
                                  required
                                  name={item.name}
                                  onChange={(e) => {
                                    handleFormJSONData(e);
                                    handleFormJSONData(e);
                                  }}
                                />
                              </h1>
                            </>
                          );
                        } else if (item.type === "date") {
                          return (
                            <>
                              <div
                                className="text-md mt-2"
                                style={{ color: "grey" }}
                              >
                                {item.label}
                              </div>
                              <h1 className="text-black">
                                <input
                                  type={item.subtype}
                                  className="p-2 w-full border-2 rounded-lg"
                                  placeholder={item.placeholder}
                                  required
                                  name={item.name}
                                  onChange={(e) => {
                                    handleFormJSONData(e);
                                  }}
                                />
                              </h1>
                            </>
                          );
                        } else if (item.type === "file") {
                          return (
                            <>
                              <div
                                className="text-md mt-2"
                                style={{ color: "grey" }}
                              >
                                {item.label}
                              </div>
                              <h1 className="text-black">
                                <input
                                  type={item.subtype}
                                  className="p-2 w-full border-2 rounded-lg"
                                  placeholder={item.placeholder}
                                  required
                                  name={item.name}
                                  onChange={(e) => {
                                    handleFormJSONData(e);
                                  }}
                                />
                              </h1>
                            </>
                          );
                        }
                      })()}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDefects;
