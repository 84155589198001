import React, { useEffect, useState } from 'react'
import { POST } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import MultiSelect from 'react-select'
import Label from '../../components/InputComponents/Label'
import { useSelector } from 'react-redux'
import moment from 'moment'
import labelToDescriptionMap from './ActivityMap/activitiesMapping'
import DataTable from 'react-data-table-component'
import { BarLoader } from 'react-spinners'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
// import styles from '/modal.module.css'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'

let userActivitiesBackup = []

const UserProductivityReport = ({ isBar = false, showAll = false }) => {
  const [reportData, setReportData] = useState([])
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [selectedActivities, setSelectedActivities] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [selectedChip, setSelectedChip] = useState('lastWeek')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userColors, setUserColors] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const sessionId = useSelector((state) => state.user.sessionId)

  const [clickedBarData, setClickedBarData] = useState(null)

  useEffect(() => {
    handleLastWeekClick(null, true)
  }, [])

  const getReportData = (filters = {}) => {
    setIsLoading(true)
    POST('/userActivityLog', filters, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setReportData(res.data.message)
          userActivitiesBackup = res.data.message.userDetails
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error',
            'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleFilterToggle = () => {
    setIsFilterVisible((prev) => !prev)
  }

  const handleActivitySelection = (selected) => {
    setSelectedActivities(selected)
  }

  const handleUserSelection = (selected) => {
    setSelectedUsers(selected)
  }

  const handleApplyFilters = () => {
    if (fromDate && toDate && fromDate > toDate) {
      // Show error message for invalid date range
      openNotification(
        notificationTypes.ERROR,
        'Date Error',
        "Please select valid date range. 'From Date' should be less than 'To Date'."
      )
    }

    if ((fromDate || toDate) && (!fromDate || !toDate)) {
      // Show error message for incomplete date selection
      openNotification(
        notificationTypes.ERROR,
        'Date Error',
        "Please select both 'From Date' and 'To Date'."
      )
    }

    const filters = {
      username: selectedUsers?.map((item) => item.label),
      activities: selectedActivities?.map((item) => item.value),
      fromDate,
      toDate
    }
    getReportData(filters)
    setSelectedChip('')
  }

  const handleClearFilters = () => {
    setSelectedActivities([])
    setSelectedUsers([])
    setFromDate('')
    setToDate('')
    getReportData()
    setSelectedChip('')
  }

  const handleLastWeekClick = (event, isFirstLoad) => {
    if (selectedChip === 'lastWeek' && !isFirstLoad) {
      setFromDate('')
      setToDate('')
      setSelectedChip('')

      return getReportData()
    }
    const today = moment()
    const yesterday = moment(today).subtract(1, 'days')
    const yesterdayFormatted = yesterday.format('YYYY-MM-DD')
    const oneWeekAgo = moment().subtract(7, 'days')
    const todayFormatted = today.format('YYYY-MM-DD')
    const oneWeekAgoFormatted = oneWeekAgo.format('YYYY-MM-DD')
    setFromDate(oneWeekAgoFormatted)
    setToDate(yesterdayFormatted)
    setSelectedUsers([])
    setSelectedActivities([])
    setSelectedChip('lastWeek')
    getReportData({
      fromDate: oneWeekAgoFormatted,
      toDate: yesterdayFormatted
    })
  }

  const handleTodayClick = () => {
    if (selectedChip === 'today') {
      setFromDate('')
      setToDate('')
      setSelectedChip('')
      return getReportData()
    }
    const today = moment()
    const todayFormatted = today.format('YYYY-MM-DD')
    setFromDate(todayFormatted)
    setToDate(todayFormatted)
    setSelectedUsers([])
    setSelectedActivities([])
    setSelectedChip('today')

    getReportData({
      fromDate: todayFormatted,
      toDate: todayFormatted
    })
  }

  const handleYesterdayClick = () => {
    if (selectedChip === 'yesterday') {
      setFromDate('')
      setToDate('')
      setSelectedChip('')
      return getReportData()
    }
    const today = moment()
    const yesterday = moment(today).subtract(1, 'days')
    const yesterdayFormatted = yesterday.format('YYYY-MM-DD')
    setFromDate(yesterdayFormatted)
    setToDate(yesterdayFormatted)
    setSelectedUsers([])
    setSelectedActivities([])
    setSelectedChip('yesterday')

    getReportData({
      fromDate: yesterdayFormatted,
      toDate: yesterdayFormatted
    })
  }

  const handleSearchKeywordChange = (event) => {
    setSearchKeyword(event.target.value)
    setErrorMessage('')
    if (!event.target.value) {
      setErrorMessage('Please enter some text to search.')
      return setReportData((prevReportData) => ({
        ...prevReportData,
        userDetails: userActivitiesBackup
      }))
    }
    const filteredData = userActivitiesBackup.filter((item) => {
      const activities = labelToDescriptionMap[item.activities]
      const users = item.username
      const createdAt = moment(item.createdAt).format('DD-MMM-YY HH:mm:ss')
      const rowStr = '' + activities + users + createdAt
      // const rowStr = "" + activities + users + item.createdAt;
      return rowStr?.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setReportData((prevReportData) => ({
      ...prevReportData,
      userDetails: filteredData
    }))
  }
  const handleFocus = () => {
    // Show error message when the input field is focused without entering any text
    if (!searchKeyword) {
      setErrorMessage('Please enter some text to search.')
    }
  }
  const handleBlur = () => {
    setErrorMessage('')
  }
  const getActivityLabel = (activityLabel) => {
    // const activityLabel = row.activities;
    // Get the description based on the label from the imported mapping
    const activityDescription = labelToDescriptionMap[activityLabel]
    return activityDescription || activityLabel
  }

  const columns = [
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true
    },
    {
      name: 'Date & Time', // Combined column for date and time
      selector: (row) => {
        const dateTime = moment(row.createdAt)
        return dateTime.format('DD-MMM-YY  HH:mm:ss') // Format the date as desired
      },
      sortable: true
    },
    {
      name: 'Activities',
      selector: (row) => getActivityLabel(row.activities),
      sortable: true
    }
  ]
  const getChartOptions = () => {
    const fromDateFormatted = moment(fromDate).format('DD-MMM-YY')
    const toDateFormatted = moment(toDate).format('DD-MMM-YY')

    const titleText =
      fromDate && toDate
        ? `User Productivity Report - From ${fromDateFormatted} to ${toDateFormatted}`
        : 'User Productivity Report'

    return {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            padding: 5,
            maxRotation: 45,
            minRotation: 0,
            autoSkip: false
          }
        },
        y: {
          stacked: true,
          beginAtzero: true,
          ticks: {
            callback: (value) => (Number.isInteger(value) ? value : ''),
            stepSize: determineStepSize(reportData)
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom', // Set the position of the legend
          onClick: () => {},
          labels: {
            usePointStyle: true
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (value, context) => {
            const datasetArray = []

            context.chart.data.datasets.forEach((dataset) => {
              if (dataset.data[context.dataIndex] !== undefined) {
                datasetArray.push(dataset.data[context.dataIndex])
              }
            })

            function totalSum (total, currentValue) {
              return total + currentValue
            }

            const sum = datasetArray.reduce(totalSum, 0)
            if (context.datasetIndex === datasetArray.length - 1) return sum
            else return ''
          },
          color: 'black'
        },
        title: {
          display: true,
          text: titleText,
          padding: {
            bottom: 25 // Adjust the value as needed
          },
          position: 'top', // Position the title at the top of the chart
          align: 'center' // Center-align the title horizontally
        }
      },
      borderWidth: 0.5,
      barThickness: 15,
      onClick: handleBarClick
    }
  }
  const handleBarClick = (event, elements) => {
    if (elements && elements.length > 0) {
      const dataIndex = elements[0].index
      const activityLabel = reportData.userActivitiesData[dataIndex].activity
      // Map activity label to description
      const activityDescription = getActivityLabel(activityLabel)

      const details = reportData.distinctUsernames.map((user) => ({
        username: user.username,
        count:
          reportData.userActivitiesData[dataIndex].userCounts.find(
            (userData) => userData.username === user.username
          )?.count || 0
      }))
      // Filter userActivityDetails based on the activity
      const matchingDetails = reportData.userActivityDetails.filter(
        (userActivity) => userActivity.activities === activityLabel
      )

      // Calculate the count of each username by date
      const usernameCountByDate = {}
      matchingDetails.forEach((userActivity) => {
        const date = userActivity.createdAt
        const username = userActivity.username

        if (!usernameCountByDate[date]) {
          usernameCountByDate[date] = {}
        }

        if (!usernameCountByDate[date][username]) {
          usernameCountByDate[date][username] = 1
        } else {
          usernameCountByDate[date][username]++
        }
      })
      // Extract date information with count and remove duplicates
      const dateDetails = []
      matchingDetails.forEach((userActivity) => {
        const date = userActivity.createdAt
        const username = userActivity.username
        const count = usernameCountByDate[date]?.[username] || 0

        // Check if the record already exists in dateDetails
        const existingRecord = dateDetails.find(
          (record) => record.username === username && record.date === date && record.count === count
        )

        // If not, add it to dateDetails
        if (!existingRecord) {
          dateDetails.push({ username, date, count })
        }
      })

      // Set the clicked data to state
      setClickedBarData({ activity: activityDescription, details, dateDetails, fromDate, toDate })
    }
  }
  const handlePopupClose = () => {
    // Close the popup and reset the clickedBarData
    setClickedBarData(null)
  }

  const determineStepSize = (data) => {
    const maxBarValue = Math.max(
      ...data.userActivitiesData.map((activity) =>
        Math.max(
          ...activity.userCounts.map((userData) => userData.count)
        )
      )
    )
    // If the maximum bar value is more than 100, use stepSize 50, else use default stepSize
    return maxBarValue > 100 ? 50 : 10 // Adjust the default stepSize as needed
  }

  useEffect(() => {
    const distinctUsernames = reportData?.distinctUsernames || []
    const distinctUserColors = {}
    distinctUsernames.forEach((user, index) => {
      // Generate permanent color based on the index (you can use any method)
      const color = `rgba(${Math.floor(index * 40) % 256}, ${
        Math.floor(index * 80) % 256
      }, ${Math.floor(index * 120) % 256}, 0.5)`
      distinctUserColors[user.username] = color
    })
    setUserColors(distinctUserColors)
  }, [reportData])

  const getChartData = () => {
    return {
      labels: reportData?.userActivitiesData.map((data) =>
        getActivityLabel(data.activity)
      ),
      datasets: reportData?.distinctUsernames?.map((user) => {
        return {
          label: user.username,
          data: reportData.userActivitiesData.map((activity) => {
            return (
              activity.userCounts.find(
                (userData) => userData.username === user.username
              )?.count || 0
            )
          }),
          backgroundColor: userColors[user.username]
        }
      })
    }
  }

  const icon = isFilterVisible ? <FiChevronUp /> : <FiChevronDown />

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 'bold'
      }
    },
    headCells: {
      style: {
        fontSize: '16px'
      }
    }
  }

  const getClickedBarChartData = (clickedBarData) => {
    const { activity, details, dateDetails, fromDate, toDate } = clickedBarData
    // Function to generate a random color
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }
    // Generate an array of all dates within the range
    let allDatesInRange = []
    if (fromDate && toDate) {
      const currentDate = moment(fromDate)
      while (currentDate.isSameOrBefore(toDate)) {
        allDatesInRange.push(currentDate.format('DD-MMM-YY')) // Format date as DD-MMM-YY
        currentDate.add(1, 'day')
      }
    } else {
      // Fallback to extracting unique dates from dateDetails
      allDatesInRange = [...new Set(dateDetails.map((item) => item.date))]
        .sort()
        .map((date) => moment(date, 'YYYY-MM-DD').format('DD-MMM-YY')) // Format date as DD-MMM-YY
    }

    return {
      labels: allDatesInRange,
      datasets: details.map((user) => ({
        label: user.username,
        data: allDatesInRange.map((date) => {
          // Find the matching date in dateDetails and get the count
          const matchingDetail = dateDetails.find(
            (item) => moment(item.date, 'YYYY-MM-DD').format('DD-MMM-YY') === date && item.username === user.username
          )
          return matchingDetail ? matchingDetail.count : 0
        }),
        backgroundColor: userColors[user.username],
        borderColor: getRandomColor(), // Set the border color for each bar
        borderWidth: 1 // Set the border width
      }))
    }
  }
  const getClickedBarChartOptions = (activity) => {
    return {
      scales: {
        x: { stacked: true },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            callback: (value) => (Number.isInteger(value) ? value : '')
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom', // Set the position of the legend
          labels: {
            usePointStyle: true
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          color: 'black',
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0
          }
        },
        title: {
          display: true,
          text: `Activity: ${activity}`,
          padding: {
            bottom: 25 // Adjust the value as needed
          },
          position: 'top', // Position the title at the top of the chart
          align: 'center', // Center-align the title horizontally
          font: {
            size: 20,
            weight: 'bold'
          }
        }
      },
      borderWidth:1,
      barThickness: 18,
    }
  }
  const handleDateChange = (e) => {
    setToDate(e.target.value)
  }

  const handleDateKeyDown = (e) => {
    e.preventDefault() // Prevent manual editing
  }

  const handleDateBlur = (e) => {
    e.target.blur() // Remove focus to prevent manual editing
  }

  return (
    <div>
      {showAll && (
        <div className='flex items-center'>
          <button
            className='bg-customBlueFour font-bold text-black px-2 py-2 rounded-md mb-4 w-full text-left block flex items-center justify-between'
            onClick={handleFilterToggle}
          >
            <span>Filters</span>
            {icon}
          </button>
        </div>
      )}
      {isFilterVisible && showAll && (
        <div className='space-y-4'>
          <div className='flex flex-col'>
            <div className='flex flex-wrap'>
              <div className='w-1/3 pr-4'>
                <Label label='Select Activities:' />
                <MultiSelect
                  options={reportData.distinctActivitiesList
                    .map((activity) => ({
                      label: labelToDescriptionMap[activity] || activity,
                      value: activity
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))} // Sort alphabetically
                  isMulti
                  placeholder='Select activities'
                  value={selectedActivities}
                  onChange={handleActivitySelection}
                />
              </div>
              <div className='w-1/3 pr-4'>
                <Label label='Select Users:' />
                <MultiSelect
                  options={reportData.distinctUsernames.map((user) => ({
                    label: user.username,
                    value: user.userId
                  }))}
                  isMulti
                  placeholder='Select users'
                  value={selectedUsers}
                  onChange={handleUserSelection}
                />
              </div>
              <div className='w-1/3 flex'>
                <div className='w-full space-y-2'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-1/2 pr-4'>
                      <Label label='From Date:' />
                      <input
                        type='date'
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        onKeyDown={handleDateKeyDown}
                        onBlur={handleDateBlur}
                        className='border rounded p-1 w-full'
                      />
                    </div>
                    <div className='w-1/2'>
                      <Label label='To Date:' />
                      <input
                        type='date'
                        value={toDate}
                        onChange={handleDateChange}
                        onKeyDown={handleDateKeyDown}
                        onBlur={handleDateBlur}
                        className='border rounded p-1 w-full'
                      />
                    </div>
                  </div>
                  <div className=' flex justify-center space-x-4 p-2'>
                    <button
                      onClick={handleApplyFilters}
                      className='bg-blue-500 text-white px-4 py-2 rounded-md'
                    >
                      Apply
                    </button>
                    <button
                      onClick={handleClearFilters}
                      className='bg-red-500 text-white px-4 py-2 rounded-md'
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {showAll && (
          <div className='w-1/3 mb-2'>
            <div className='w-full space-x-2 p-2'>
              <button
                className={`py-1 my-2 rounded-2xl text-black font-bold font-DMSANS text-sm border-2 border-black px-2 h-fit ${
                  selectedChip === 'lastWeek' ? 'bg-customBlueFour' : ''
                }`}
                onClick={handleLastWeekClick}
              >
                Last week
              </button>
              <button
                className={`py-1 my-2 rounded-2xl text-black font-bold font-DMSANS text-sm border-2 border-black px-2 h-fit ${
                  selectedChip === 'today' ? 'bg-customBlueFour' : ''
                }`}
                onClick={handleTodayClick}
              >
                Today
              </button>
              <button
                className={`py-1 my-2 rounded-2xl text-black font-bold font-DMSANS text-sm border-2 border-black px-2 h-fit ${
                  selectedChip === 'yesterday' ? 'bg-customBlueFour' : ''
                }`}
                onClick={handleYesterdayClick}
              >
                Yesterday
              </button>
            </div>
          </div>
        )}
        {reportData && isBar && (
          <div className='flex items-center justify-center'>
            <div className='w-full max-w-screen-xl text-center'>
              {reportData?.userActivitiesData?.length > 0
                ? (
                  <Bar
                    data={getChartData()}
                    options={getChartOptions()}
                    plugins={[ChartDataLabels]}
                  />
                  )
                : (
                  <p className='text-xl'>Data not found for chart</p>
                  )}
            </div>
          </div>
        )}
        {clickedBarData && (
          <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50'>
            <div className='popup-container relative bg-white p-6 rounded-lg w-full h-full m-4'>
              <div className='absolute top-0 right-0 m-4'>
                <a onClick={handlePopupClose}>
                  <CloseCircleOutlined style={{ fontSize: '30px', color: 'black' }} />
                </a>
              </div>
              <Bar
                data={getClickedBarChartData(clickedBarData)}
                options={getClickedBarChartOptions(clickedBarData.activity)}
                plugins={[ChartDataLabels]}
              />
            </div>
          </div>
        )}
        {showAll && (
          <div className='w-1/3 mb-2'>
            <input
              type='text'
              placeholder='Enter text to search...'
              className='border rounded p-2 w-full'
              value={searchKeyword}
              onChange={handleSearchKeywordChange}
              onFocus={handleFocus} // Handle focus event
              onBlur={handleBlur}
            />
            {/* Display error message */}
            {errorMessage && (
              <div className='text-red-600 mt-2'>
                {errorMessage}
              </div>
            )}
          </div>
        )}
        {showAll ? (
          isLoading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px'
              }}
            >
              <BarLoader loading={isLoading} />
              <p>Loading...</p>
            </div>
          ) : (
            <DataTable
              title='User Activity Log'
              columns={columns}
              data={reportData.userDetails}
              pagination
              striped
              highlightOnHover
              responsive
              customStyles={customStyles}
              defaultSortFieldId={2} // Set the default sort field ID
              defaultSortAsc={false} // Set the default sort order to descending
              fixedHeader
              fixedHeaderScrollHeight='300px'
            />
          )
        ) : null}
      </div>
    </div>
  )
}

export default UserProductivityReport
