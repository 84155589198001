import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../../../config/api'
import { openNotification, notificationTypes } from '../../../../../utils/notifications'
import TestCase from './TestCase'
import Arrow from '../../../../../assets/Icons/arrow.png'
import User from '../../../../../assets/Icons/scenario.png'

const Scenario = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [testCases, setTestCases] = useState({})
  const [isTestCases, setIsTestCases] = useState(false)

  const getTestCases = (id) => {
    GET(`/getTestCases?scenarioID=${id}`, { sessionID: sessionId })
      .then((res) => {
        setTestCases(res.data.message)
        setIsTestCases(true)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getTestCases(props.scenario.id)
  }, [props.scenario.id])

  return (
    <>
      <div className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-12 leading-none lg:leading-11'>
        <span class='flex items-center p-2 font-normal text-black rounded-lg'>
          <img className='mr-2' src={Arrow} alt='Arrow' />
          <img src={User} className='h-6 mr-2' alt='User' />
          {props.scenario.scenarioName}&nbsp;
        </span>
        {
                    isTestCases && (
                      testCases.map((item) => (
                        <TestCase
                          testCase={item}
                          scenarioID={props.scenario.id}
                          manualrunID={props.manualrunID}
                          testpackID={props.testpackID}
                        />
                      ))
                    )
                }
      </div>
    </>
  )
}

export default Scenario
