import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { signIn } from '../../../redux/ducks/userDuck'
import arrow from '../../../assets/Icons/greenarrow.png'
import { routes } from '../../../constants/routes'

function SignInForm () {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [btnText, setBtnText] = useState('Sign-In')

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [email, password])

  const handleSignIn = (e) => {
    e.preventDefault()

    setIsBtnDisabled(true)
    setBtnText('Signing In...')
    dispatch(
      signIn({
        email,
        password
      })
    )
  }

  const handleForgetpassword = () => {
    navigate(routes.FORGETPASSWORD)
  }

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Enter') {
      document.getElementById('myButton').click()
    }
  })

  return (
    <div>
      <form class='rounded px-2 lg:px-8 pt-6 pb-8 mb-4'>
        <div class='mb-4'>
          <label class='ml-2 block text-gray-700 text-sm font-bold mb-2' for='username'>
            Enter Email ID
          </label>
          <input
            class='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            id='email'
            type='email'
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div class='mb-6'>
          <label class='ml-2 block text-gray-700 text-sm font-bold mb-2' for='password'>
            Password
          </label>
          <input
            class='bg-white appearance-none border-2 border-gray-200 rounded-lg w-full p-3 text-gray-700 leading-snug font-medium focus:outline-none focus:shadow-outline'
            id='password'
            type='password'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <div className='text-xl font-medium text-black cursor-pointer' onClick={handleForgetpassword}>
            Forget Password?
          </div>
          <div class='flex justify-end'>
            <button
              id='myButton'
              className={
                isBtnDisabled === true
                  ? 'bg-customGreyOne border-2 text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline cursor-not-allowed'
                  : 'bg-customGreenFour hover:bg-green-300 border-2 text-green-600 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
              }
              type='button'
              onClick={handleSignIn}
              disabled={isBtnDisabled}
            >
              <div className='flex'>
                {btnText}
                <img className='mr-1' src={arrow} />
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SignInForm
