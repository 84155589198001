import { routes } from "./routes";

import SignUpPage from "../pages/SignUpPage";
import SignInPage from "../pages/SignInPage";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
import AddUserCreatePassword from "../pages/AddUserCreatePassword";
import HomePage from "../pages/HomePage";
import DashboardPage from "../pages/DashboardPage";
import DashboardReleasePage from "../pages/DashboardRelease";
import CompanyOnboarding from "../pages/CompanyOnboardingPage";
import ApplicationPage from "../pages/applicationPage";
import ModulePage from "../pages/modulePage";
import ScenarioPage from "../pages/scenarioPage";
import TestCasePage from "../pages/TestCasePage";
import UserPage from "../pages/UserPage";
import CXOPage from "../pages/CXOPage";
import CXOAssignPage from "../pages/CXOAssignPages";
import ViewManualTestCases from "../pages/ViewManualTestCases";
import ViewManualRunPage from "../pages/ViewManualRunPage";
import ViewManualRunResultsPage from "../pages/ViewManualRunResultsPage";
import AddTestRunPage from "../pages/AddTestRunPage";
import ViewAutomaticTestCases from "../pages/ViewAutomaticTestCases";
import UserManagement from "../pages/User Management";
import ViewTestPack from "../pages/ViewTestPack";
import AutomaticRunExecutionPage from "../pages/AutomaticRunExecutionPage";
import AutomaticRunResultsPage from "../pages/AutomaticRunResultsPage";
import EnquiryPage from "../pages/EnquiryPage";
import ViewManualRunReportPage from "../pages/ViewManualRunReport";
import ViewCxoPackPage from "../pages/ViewCxoPackPage";
import AddTestCasePage from "../pages/AddTestCasePage";
import ReleasePlanner from "../pages/ReleasePlanner";
import TestCaseManagement from "../pages/TestCaseManagement";
import TestPackManagement from "../pages/TestPackManagement";
import ReportLandingPage from "../pages/ReportLandingPage";
import ExecutionLandingPage from "../pages/TestExecutionLandingPage";
import AddReleasePlanner from "../pages/AddReleasePlanner";
import EditReleasePlanner from "../pages/EditRelease";
import AddFeature from "../pages/AddFeature";
import Feature from "../pages/Feature";
import Howitworks from "../pages/Howitworks";
import EachFeature from "../pages/EachFeature";
import CreateUserButton from "../pages/Howitworks/CreateUserButton";
import RunRequestButton from "../pages/Howitworks/RunRequestButton";
import SecurityTest from "../pages/SecurityScan";
import EachDomainReport from "../pages/EachDomainReport";
import SecurityScanTable from "../pages/SecurityScanTable";
import Defects from "../pages/Defects/Index";
import AddDefects from "../pages/Add Defects/Index";
import EditDefects from "../pages/EditDefects/Index";
import TestSteps from "../pages/TestSteps/Index";
import AddTestSteps from "../pages/AddTestSteps/Index";
import EditTestSteps from "../pages/EditTestSteps/Index";

import Settings from "../pages/Settings";
import ViewTestSteps from "../pages/AddTestSteps/viewTestSteps";
import TestRunSummary from '../pages/TestRunSummary'
import ReportChart from '../pages/ReportChart'
// import {UserActivity} from '../pages/ReportChart/activity'
import UserProductivityReport from '../pages/UserProductivityReport'
import { WeekReport } from '../pages/ReportChart/goupChart'
import FeatureManagement from '../pages/FeatureManage'
import FeatureManage from '../pages/FeatureManage'
import TestCaseStatusReport from '../pages/TestCaseStatusReport'

export const signedOutRoutes = [
  {
    path: routes.SIGN_IN,
    element: <SignInPage />,
  },
  {
    path: routes.SIGN_UP,
    element: <SignUpPage />,
  },
  {
    path: routes.FORGETPASSWORD,
    element: <ForgetPassword />,
  },
  {
    path: routes.RESETPASSWORD,
    element: <ResetPassword />,
  },
  {
    path: routes.ADDUSERCREATEPASSWORD,
    element: <AddUserCreatePassword />,
  },
  {
    path: routes.HOWITWORKS,
    element: <Howitworks />,
  },
  {
    path: routes.CREATEUSERBUTTON,
    element: <CreateUserButton />,
  },
  {
    path: routes.RUNREQUESTBUTTON,
    element: <RunRequestButton />,
  },
];

export const signedInRoutes = [
  {
    path: routes.HOME,
    element: <DashboardPage />,
  },
  {
    path: routes.DASHBOARD,
    element: <DashboardPage />,
  },
  {
    path: routes.DASHBOARDRELEASE,
    element: <DashboardReleasePage />,
  },

  {
    path: routes.EMAILVARIFICATION,
    element: <DashboardPage />,
  },
  {
    path: routes.ADDUSER,
    element: <UserPage />,
  },
  {
    path: routes.COMPANY,
    element: <CompanyOnboarding />,
  },
  {
    path: routes.APP_DETAILS,
    element: <ApplicationPage />,
  },
  {
    path: routes.MODULE_DETAILS,
    element: <ModulePage />,
  },
  {
    path: routes.SCENARIO_DETAILS,
    element: <ScenarioPage />,
  },
  {
    path: routes.TC_DETAILS,
    element: <TestCasePage />,
  },
  {
    path: routes.CREATECXOVIEW,
    element: <CXOPage />,
  },
  {
    path: routes.ASSIGNCXO_DETAILS,
    element: <CXOAssignPage />,
  },
  {
    path: routes.VIEWMR_DETAILS,
    element: <ViewManualTestCases />,
  },
  {
    path: routes.VIEWMANUALRUN_DETAILS,
    element: <ViewManualRunPage />,
  },
  {
    path: routes.VIEWMANUALRUN_RESULTSDETAILS,
    element: <ViewManualRunResultsPage />,
  },
  {
    path: routes.VIEWAUTOMATICRUN_DETAILS,
    element: <ViewAutomaticTestCases />,
  },
  {
    path: routes.POSTAUTOMATICRUN_RESULT,
    element: <AutomaticRunExecutionPage />,
  },
  {
    path: routes.USERMANAGEMENT,
    element: <UserManagement />,
  },
  {
    path: routes.VIEWTESTPACK_DETAILS,
    element: <ViewTestPack />,
  },
  {
    path: routes.VIEWAUTOMATICRUN_RESULTSDETAILS,
    element: <AutomaticRunResultsPage />,
  },
  {
    path: routes.VIEWMANUALRUN_REPORT,
    element: <ViewManualRunReportPage />,
  }, 
  {
    path: routes.DEFECTS,
    element: <Defects />,
  },
  {
    path: routes.ADD_DEFECTS,
    element: <AddDefects />,
  },
  {
    path: routes.ADD_TEST_STEPS,
    element: <AddTestSteps />,
  },
  {
    path: routes.EDIT_TEST_STEPS,
    element: <EditTestSteps />,
  },
  {
    path: routes.EDIT_DEFECTS,
    element: <EditDefects />,
  },
  {
    path: routes.TEST_STEPS,
    element: <TestSteps />,
  },
  {
    path: routes.EDIT_TEST_STEPS,
    element: <TestSteps />,
  },
  {
    path: routes.VIEWCXOPACK_DETAILS,
    element: <ViewCxoPackPage />,
  },
  {
    path: routes.CREATETESTRUNDETAILS,
    element: <AddTestRunPage />,
  },
  {
    path: routes.VIEWEXECUTIONDATA_DETAILS,
    element: <ViewManualRunReportPage />,
  },
  {
    path: routes.addTestCase,
    element: <AddTestCasePage />,
  },
  {
    path: routes.ADDRELEASEPLANNER,
    element: <AddReleasePlanner />,
  },
  {
    path: routes.EDITRELEASEPLANNER,
    element: <EditReleasePlanner />,
  },
  {
    path: routes.RELEASEPLANNER,
    element: <ReleasePlanner />,
  },
  {
    path: routes.ADDFEATURE,
    element: <AddFeature />,
  },
  {
    path: routes.FEATURE,
    element: <Feature />,
  },
  {
    path: routes.EACHFEATURE,
    element: <EachFeature />,
  },
  {
    path: routes.HOWITWORKS,
    element: <Howitworks />,
  },
  {
    path: routes.CREATEUSERBUTTON,
    element: <CreateUserButton />,
  },
  {
    path: routes.RUNREQUESTBUTTON,
    element: <RunRequestButton />,
  },
  {
    path: routes.TESTCASEMANAGEMENT,
    element: <TestCaseManagement />,
  },
  {
    path: routes.TESTPACKMANAGEMENT,
    element: <TestPackManagement />,
  },
  {
    path: routes.REPORT,
    element: <ReportLandingPage />,
  },
  {
    path: routes.EXECUTION,
    element: <ExecutionLandingPage />,
  },
  {
    path: routes.SECURITYTEST,
    element: <SecurityTest />,
  },
  {
    path: routes.EACHDOMAINREPORT,
    element: <EachDomainReport />,
  },
  {
    path: routes.SECURITYSCANTABLE,
    element: <SecurityScanTable />,
  }, 
  {
    path: routes.SETTINGS,
    element: <Settings />,
  },
  {
    path: routes.VIEW_TEST_STEPS,
    element: <ViewTestSteps />,
  },
  {
    path: routes.TESTRUNSUMMARY,
    element: <TestRunSummary />
  },
  {
    path: routes.REPORTCHART,
    element: <ReportChart />
  },
  {
    path: '/weekreport',
    element: <WeekReport />
  },
  {
    path: routes.USERPRODUCTIVITYREPORT,
    element: <UserProductivityReport isBar showAll />
  },
  {
    path: routes.FEATUREMANAGE,
    element: <FeatureManage />
  },
  {
    path: routes.TESTCASESTATUSREPORT,
    element: <TestCaseStatusReport />
  }
];

export const renderAlwaysRoutes = [
  {
    path: routes.HOME,
    element: <HomePage />,
  },
  {
    path: routes.ENQUIRY,
    element: <EnquiryPage />,
  },
];

export const defaultInputMargin = "0 0 12px 0";

export const documentTitles = {
  HOME: "Green Tick",
  SIGN_IN: "Sign In | Green Tick",
  SIGN_UP: "Sign Up | Green Tick",
  DASHBOARD: "Dashboard",
  COMPANY_ONBOARDING: "Company Onboarding",
  APPLICATION: "Application",
  MODULE: "Module",
  SCENARIO: "Scenario",
  TESTCASE: "Test Case",
  TESTPACK: "Test Pack",
  TESTCASEMANAGEMENT: "Test Case Management",
  TESTPACKMANAGEMENT: "Test Pack Management",
  DEFECTS: "Defects Management",
  RELEASEPLANNER: "Release Planner",
  EDIT_DEFECTS: "Edit Defects",
  REPORT: "Report",
  EXECUTION: "Execution",
  USER: "User",
  USERMANAGEMENT: "User Management",
  CXO_TESTPACK: "Test Pack Management",
  COMING_SOON: "Coming Soon",
  TESTRUNSUMMARY: 'Test Run Summary',
  REPORTCHART: 'ReportChart',
  SETTINGS: 'Settings'
};
