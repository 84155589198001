import React, { useState, useEffect } from 'react'
export const HandleModelContext = React.createContext()

const useContextMenu = () => {
  const [clicked, setClicked] = useState(false)
  const [points, setPoints] = useState({
    x: 0,
    y: 0
  })
  const [contexName, setItems] = useState([])
  const [itemID, setitemID] = useState('')
  const [moduleID, setmoduleID] = useState('')
  const [scenarioID, setscenarioID] = useState('')
  const [modelType, setModelType] = useState('')
  const [appID, setappID] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)

  useEffect(() => {
    const handleClick = () => setClicked(false)
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])
  return {
    clicked,
    setClicked,
    points,
    setPoints,
    contexName,
    setItems,
    itemID,
    setitemID,
    moduleID,
    setmoduleID,
    appID,
    setappID,
    scenarioID,
    setscenarioID,
    isModal,
    setIsModal,
    isUpdateModal,
    setIsUpdateModal,
    modelType,
    setModelType
  }
}
export default useContextMenu
