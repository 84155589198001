const month = {
  None: 'None',
  STARTUP: 'Startup',
  MIDSIZE: 'SMB',
  ENTERPRISE: 'Enterprise'
}

export const companyType = [
  {
    id: 1,
    value: month.None,
    label: 'None'
  },
  {
    id: 2,
    value: month.STARTUP,
    label: 'Startup'
  },
  {
    id: 3,
    value: month.MIDSIZE,
    label: 'SMB'
  },
  {
    id: 4,
    value: month.ENTERPRISE,
    label: 'Enterprise'
  }
]
