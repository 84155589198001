import set from '../../../assets/Icons/setting.png'

const Setting = () => {
  return (
    <li>
      <div class='hover:bg-customBlueFour flex items-center p-2 text-base font-bold text-black rounded-lg'>
        <img src={set} alt='User' />
        <span class='flex-1 ml-3 whitespace-nowrap'>Settings
        </span>
      </div>
    </li>
  )
}

export default Setting
