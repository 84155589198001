import { Page, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import Header from './Header'
import Footer from './Footer'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 40,
    paddingHorizontal: 32
  },
  container: { marginTop: 40 }
})

const BasicPageLayout = (props) => {
  return (
    <Page size='A4' style={styles.page}>
      <Header title={props.headerTitle} />
      <View style={styles.container}>{props.children}</View>
      <Footer />
    </Page>
  )
}

export default BasicPageLayout
