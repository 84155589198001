import { useState, useEffect } from 'react'
import { GET } from '../../../config/api'
import { useSelector } from 'react-redux'
import { openNotification, notificationTypes } from '../../../utils/notifications'
import Module from './Module'
import Arrow from '../../../assets/Icons/arrow.png'
import User from '../../../assets/Icons/app.png'

const Application = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [modules, setModules] = useState({})
  const [isModule, setIsModule] = useState(false)

  const handleAppModules = (id) => {
    GET(`/getModules?appID=${id}`, { sessionID: sessionId })
      .then((res) => {
        setModules(res.data.message)
        setIsModule(true)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Error'
        )
      })
  }

  useEffect(() => {
    if (typeof props.app.id !== 'undefined') {
      handleAppModules(props.app.id)
    }
  }, [props.app.id])

  return (
    <>
      <div
        className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-5 leading-none lg:leading-11'
      >
        <span class='flex items-center p-2 font-normal text-black rounded-lg'>
          <img className='mr-2' src={Arrow} alt='Arrow' />
          <img src={User} className='h-6 mr-2' alt='User' />
          {props.app.applicationName}&nbsp;
        </span>
      </div>
      {isModule && (
        modules.map((item) => (
          <Module
            modules={item}
            appID={props.app.id}
            manualrunID={props.manualrunID}
            testpackID={props.testpackID}
          />
        ))
      )}
    </>
  )
}

export default Application
