import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../config/api'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import moment from 'moment'
import SingleSelectComponent from '../../../components/InputComponents/SingleSelectComponent'

const TestRun = ({ releaseId }) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [testRun, setTestRun] = useState([])
  const count = 0

  useEffect(() => {
    const getTestRun = async () => {
      GET(`/release/testrundetails/${releaseId}`, { sessionID: sessionId })
        .then((res) => {
          if (res.data.status === 200) {
            setTestRun(res.data.message)
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Error in getting TestRuns, Please try again'
            )
          }
        })
        .catch(() => {
          openNotification(
            notificationTypes.ERROR,
            'Error in getting Releases, Please try again'
          )
        })
    }
    getTestRun()
  }, [])

  return (
    <>
      <div className='grid grid-cols-6 gap-4 mt-5'>
        <div className='col-end-7'>
          <button
            className='bg-customGreenFour text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end hidden'
            type='button'
            // onClick={onSave(rdevStartDate, rdevEndDate, rEffort)}
          >
            Save
          </button>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div className='overflow-x-auto mx-5 bg-customWhite rounded-xl p-4 shadow-xl'>
              {/* <table className="bg-gray-200 border-2 border-gray-400 p-4 mr-16 rounded-xl w-full"> */}
              <table className='overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
                <thead class='text-center bg-customGrey rounded-2xl'>
                  <tr>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Run Name
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Build Version
                    </th>

                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Exe Env
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Pass
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Fail
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      No Run
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      In-Progress
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      On Hold
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Start Date
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      End Date
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Test Type
                    </th>
                    <th
                      scope='col'
                      class='text-base font-bold text-gray-900 px-8 py-4 text-left'
                    >
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {testRun.length > 0
                    ? (
                        testRun?.map((obj) => {
                          return (
                            <tr class='border-b border-1 border-customGreyThree'>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>
                                  <a href={`/viewmanualrunresults/${obj.id}`}>
                                    {' '}
                                    {obj.name}{' '}
                                  </a>
                                </p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.buildVersion}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>
                                  {obj.executionEnvironment}
                                </p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.passCount}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.failCount}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.norunCount}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.inprogressCount}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.onholdCount}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                {moment(obj.startDate).utc().format('DD-MM-YYYY')}
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                {moment(obj.endDate).utc().format('DD-MM-YYYY')}
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.testType}</p>
                              </td>
                              <td class='text-base text-gray-600 font-medium px-8 py-1'>
                                <p className='break-words'>{obj.description}</p>
                              </td>
                            </tr>
                          )
                        })
                      )
                    : (
                      <></>
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestRun
