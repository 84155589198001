import React, { useState, useEffect, useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '../constants/routes'
import { useSelector, useDispatch } from 'react-redux'

import { BsAppIndicator } from 'react-icons/bs'
import { CgListTree } from 'react-icons/cg'
import { RiUser6Line } from 'react-icons/ri'
import { TbReportAnalytics } from 'react-icons/tb'
import { BiLogOutCircle } from 'react-icons/bi'
import { MdBugReport } from 'react-icons/md'
import { FcMindMap } from 'react-icons/fc'
import { FaHandsHelping } from 'react-icons/fa'

import { ModelContext } from '../contexts/modelContext'
import { MenuContext } from '../contexts/menuContext'
import Sidebaritem from './Sidebaritem'
import { GET } from '../config/api'
import { notificationTypes, openNotification } from '../utils/notifications'
import { signOut } from '../redux/ducks/userDuck'

import FooterImage from '../assets/Images/logo.png'
import logoImage from '../assets/Images/favicon.ico'

function Sidebar ({
  sidebarOpen,
  setSidebarOpen,
  setRefreshState,
  refreshState
}) {
  const user = useSelector((state) => state.user)
  const [modelstate, setModelState] = useContext(ModelContext)
  const [MenuList, setMenuList] = useContext(MenuContext)
  const sessionId = useSelector((state) => state.user.sessionId)
  const dispatch = useDispatch()
  const trigger = useRef(null)
  const sidebar = useRef(null)

  const storedSidebarExpanded =
    localStorage.getItem('sidebar-expanded') ?? 'true'
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  )
  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded)
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded')
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded')
    }
  }, [sidebarExpanded])
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      ) { return }
      setSidebarOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  }, [])

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  }, [])

  const handleSignOut = () => {
    dispatch(signOut(sessionId))
  }

  //  Test case
  const getApplication = () => {
    setModelState({ ...modelstate, loading: true })
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        const resdata = res.data.message
        console.log(resdata, "resdataresdata resdata")
        const appData = []
  
        if (Array.isArray(resdata) && resdata.length > 0) {
          resdata.forEach((e) => {
            appData.push({
              id: e.id,
              app_id: e.id,
              appDesc: e.applicationDescription,
              companyId: e.companyId,
              companyName: e.company_name,
              name: e.applicationName || 'app',
              path: routes.TESTCASEMANAGEMENT,
              icon: <BsAppIndicator size={14} />,
              submenu: true,
              model: false,
              dynamicMenu: [],
              contextMenu: [
                {
                  id: 1,
                  name: 'Add Module',
                  path: '',
                  catagory: 'addModule',
                  model: true
                },
                {
                  id: 2,
                  name: 'View Application',
                  path: `/applications/${e.id}`,
                  catagory: 'viewApplication',
                  model: false
                },
                {
                  id: 3,
                  name: 'Modify Application',
                  path: '',
                  catagory: 'modifyApplication',
                  model: true
                },
                {
                  id: 4,
                  name: 'Delete Application',
                  path: '',
                  catagory: 'deleteApplication',
                  model: true
                }
              ],
              accountType: []
            })
          })
        } else {
          appData.push({
            id: 1,
            name: 'No Application Found',
            submenu: false,
            model: false,
            dynamicMenu: [],
            accountType: []
          })
        }
  
        MenuList.filter((targetID) => {
          if (targetID.id == 2) {
            targetID.dynamicMenu = appData
          }
        })
  
        setMenuList(MenuList)
        getModule()
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }
  

  // module

  const getModule = () => {
    MenuList.filter((targetID) => {
      if (targetID.id == 2) {
        targetID.dynamicMenu.map((module) => {
          if (module) {
            GET(`/getModules?appID=${module.id}`, { sessionID: sessionId })
              .then((res) => {
                const resdata = res.data.message
                const appData = []
                if (resdata && resdata.length > 0) {
                  resdata.map((e) => {
                    appData.push({
                      id: e.id,
                      modelDescription: e.modelDescription,
                      applicationId: e.application_id,
                      name: e.modelName || 'Module',
                      path: routes.TESTCASEMANAGEMENT,
                      icon: <CgListTree size={14} />,
                      submenu: true,
                      model: false,
                      dynamicMenu: [],
                      contextMenu: [
                        {
                          id: 1,
                          name: 'Add Scenario',
                          path: '',
                          catagory: 'addScenario',
                          model: true
                        },
                        {
                          id: 2,
                          name: 'View Module',
                          path: `/module/${e.id}`,
                          catagory: 'viewModule',
                          model: false
                        },
                        {
                          id: 3,
                          name: 'Modify Module',
                          path: '',
                          catagory: 'modifyModule',
                          model: true
                        },
                        {
                          id: 4,
                          name: 'Delete Module',
                          path: '',
                          catagory: 'deleteModule',
                          model: true
                        }
                      ],
                      accountType: []
                    })
                  })
                } else {
                  appData.push({
                    id: 1,
                    name: 'No Module Found',
                    submenu: false,
                    model: false,
                    dynamicMenu: [],
                    accountType: []
                  })
                }
                module.dynamicMenu = appData
              })
              .catch((err) => {
                console.log(err)
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              })
          }
        })
      }
    })
    setMenuList(MenuList)
    getScenarioData()
  }
  // scenario
  const getScenarioData = () => {
    MenuList.filter((targetID) => {
      if (targetID.id == 2) {
        targetID.dynamicMenu.map((modules) => {
          if (modules) {
            setTimeout(() => {
              modules.dynamicMenu.map((scenario) => {
                GET(`/getScenario?moduleID=${scenario.id}`, {
                  sessionID: sessionId
                })
                  .then((res) => {
                    const resResult = res.data.message
                    const appData = []
                    if (resResult && resResult.length > 0) {
                      resResult.map((e) => {
                        appData.push({
                          id: e.id,
                          module_id: e.module_id,
                          applicationId: e.application_id,
                          name: e.scenarioName || 'scenario',
                          path: routes.TESTCASEMANAGEMENT,
                          icon: <CgListTree size={14} />,
                          submenu: true,
                          model: false,
                          dynamicMenu: [],
                          contextMenu: [
                            {
                              id: 1,
                              name: 'Add Test Case',
                              path: `/addTestCase/${e.id}/${e.module_id}/${e.application_id}`,
                              catagory: 'addTestCase',
                              model: false
                            },
                            {
                              id: 2,
                              name: 'View Scenario',
                              path: `/scenario/${e.id}`,
                              catagory: 'viewScenario',
                              model: false
                            },
                            {
                              id: 3,
                              name: 'Modify Scenario',
                              path: '',
                              catagory: 'modifyScenario',
                              model: true
                            },
                            {
                              id: 4,
                              name: 'Delete Scenario',
                              path: '',
                              catagory: 'deleteScenario',
                              model: true
                            }
                          ],
                          accountType: []
                        })
                      })
                    } else {
                      appData.push({
                        id: 1,
                        name: 'No scenario Found',
                        submenu: false,
                        model: false,
                        dynamicMenu: [],
                        accountType: []
                      })
                    }
                    scenario.dynamicMenu = appData
                  })
                  .catch((err) => {
                    console.log(err)
                    openNotification(
                      notificationTypes.ERROR,
                      'Something went wrong, please try again later'
                    )
                  })
              })
            }, 2000)
          }
        })
      }
    })
    setMenuList(MenuList)
    getTestCasesData()
  }

  // testCase

  const getTestCasesData = () => {
    MenuList.filter((targetID) => {
      if (targetID.id == 2) {
        targetID.dynamicMenu.map((module) => {
          setTimeout(() => {
            module.dynamicMenu.map((scenario) => {
              setTimeout(() => {
                scenario.dynamicMenu.map((testRes) => {
                  if (testRes) {
                    GET(`/getTestCases?scenarioID=${testRes.id}`, {
                      sessionID: sessionId
                    })
                      .then((res) => {
                        const resdata = res.data.message
                        const appData = []
                        if ((Array.isArray(resdata) && resdata.length > 0)) {
                          resdata.map((e) => {
                            appData.push({
                              id: e.id,
                              module_id: e.module_id,
                              scenario_id: e.scenario_id,
                              testCase_id: e.testCase_id,
                              applicationId: e.application_id,
                              testcase_status: e.testcase_status,
                              name: e.testcase_name || 'Module',
                              path: `testcase/${e.id}/${e.scenario_id}`,
                              icon: <MdBugReport size={14} />,
                              submenu: false,
                              model: false,
                              dynamicMenu: [],
                              contextMenu: [
                                {
                                  id: 1,
                                  name: 'Update Test Case',
                                  path: '',
                                  catagory: 'modifyTC',
                                  model: true
                                },
                                {
                                  id: 2,
                                  name: 'Delete Test Case',
                                  path: '',
                                  catagory: 'deleteTC',
                                  model: true
                                }
                              ],
                              accountType: []
                            })
                          })
                        } else {
                          appData.push({
                            id: 1,
                            name: 'No TestCase Found',
                            submenu: false,
                            model: false,
                            dynamicMenu: [],
                            accountType: []
                          })
                        }
                        testRes.dynamicMenu = appData
                      })
                      .catch((err) => {
                        console.log(err)
                        openNotification(
                          notificationTypes.ERROR,
                          'Something went wrong, please try again later'
                        )
                      })
                  }
                })
              }, 2000)
            })
          }, 2000)
        })
      }
    })
    setMenuList(MenuList)
    setModelState({ ...modelstate, loading: false })
  }

  const getCXOTestpack = () => {
    MenuList.filter((targetID) => {
      if (targetID.id == 4) {
        targetID.dynamicMenu.map((module) => {
          if (module) {
            GET(`/getAllTestPacks?appid=${module.id}`, { sessionID: sessionId })
              .then((res) => {
                const resdata = res.data.message
                const appData = []
                if (resdata && resdata.length > 0) {
                  resdata.map((e) => {
                    appData.push({
                      id: e.id,
                      application_id: e.application_id,
                      automaticExecution: e.automaticExecution,
                      manualExecution: e.manualExecution,
                      onCloudExecution: e.onCloudExecution,
                      testpack_description: e.testpack_description,
                      name: e.testpack_name || 'Test Pack',
                      path: `/viewcxopack/${e.id}`,
                      icon: <FcMindMap size={14} />,
                      submenu: false,
                      model: false,
                      dynamicMenu: [],
                      accountType: []
                    })
                  })
                } else {
                  appData.push({
                    id: 1,
                    name: 'No TestPack Found',
                    submenu: false,
                    model: false,
                    dynamicMenu: [],
                    accountType: []
                  })
                }

                module.dynamicMenu = appData
              })
              .catch((err) => {
                console.log(err)
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              })
          }
        })
      }
    })
    setMenuList(MenuList)
    setModelState({ ...modelstate, loading: false })
  }

  const getApplicationCXO = () => {
    setModelState({ ...modelstate, loading: true })
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        const resdata = res.data.message
        const appData = []
        if (resdata) {
          resdata.map((e) => {
            appData.push({
              id: e.id,
              app_id: e.id,
              appDesc: e.applicationDescription,
              companyId: e.companyId,
              companyName: e.company_name,
              name: e.applicationName || 'CXO app',
              path: '/testpackmanagement',
              icon: <BsAppIndicator size={14} />,
              submenu: true,
              model: false,
              dynamicMenu: [],
              contextMenu: [
                {
                  id: 1,
                  name: 'Add Test Pack',
                  path: `/createcxoview/${e.id}`,
                  catagory: '',
                  model: false
                }
              ],
              accountType: []
            })
          })
        } else {
          appData.push({
            id: 1,
            name: 'No Application Found',
            submenu: false,
            model: false,
            dynamicMenu: [],
            accountType: []
          })
        }
        MenuList.filter((targetID) => {
          if (targetID.id == 4) {
            targetID.dynamicMenu = appData
          }
        })
        setMenuList(MenuList)
        getCXOTestpack()
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const getTestpacks = () => {
    GET('/getTestpacks', { sessionID: sessionId })
      .then((res) => {
        const resdata = res.data.message
        const testPacks = []
        if (resdata && resdata.length > 0) {
          const groupbyName = resdata.reduce((group, app) => {
            const { applicationName } = app
            group[applicationName] = group[applicationName] ?? []
            group[applicationName].push(app)
            return group
          }, {})

          resdata.map((packs) => {
            const submenu = []
            groupbyName[packs.applicationName].map((data) => {
              submenu.push({
                id: data.id,
                name: data.testpack_name || 'Test Pack',
                path: `/assigncxo/${data.id}`,
                icon: <MdBugReport size={14} />,
                submenu: false,
                model: false,
                dynamicMenu: [],
                accountType: []
              })
            })
            testPacks.push({
              id: packs.id,
              testpack_name: packs.testpack_name,
              name: packs.applicationName || 'application name',
              path: '/testpackmanagement',
              icon: <BsAppIndicator size={14} />,
              submenu: true,
              model: false,
              dynamicMenu: submenu,
              accountType: []
            })
          })
        } else {
          testPacks.push({
            id: 1,
            name: 'No TestPack Found',
            submenu: false,
            model: false,
            dynamicMenu: [],
            accountType: []
          })
        }
        MenuList.filter((targetID) => {
          if (targetID.id == 3) {
            targetID.dynamicMenu = testPacks
          }
        })

        setMenuList(MenuList)
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const testExecutions = () => {
    setModelState({ ...modelstate, loading: true })
    GET('/gettestrun', { sessionID: sessionId })
      .then((res) => {
        const resdata = res.data.message
        const testExecution = []
        if (resdata) {
          resdata.map((e) => {
            testExecution.push({
              id: e.id,
              testPackId: e.testPackId,
              name: e.name || 'Execution',
              path: `/viewMR/${e.id}/${e.testPackId}`,
              icon: <TbReportAnalytics size={16} />,
              submenu: false,
              model: false,
              dynamicMenu: [],
              accountType: [1, 2]
            })
          })
        } else {
          testExecution.push({
            id: 1,
            name: 'No Execution Found',
            submenu: false,
            model: false,
            dynamicMenu: [],
            accountType: [1, 2]
          })
        }
        MenuList.filter((targetID) => {
          if (targetID.id === 6) {
            targetID.dynamicMenu = testExecution
          }
        })
        setMenuList(MenuList)
        setModelState({ ...modelstate, loading: false })
      })
      .catch((err) => {
        console.log(err)
        setModelState({ ...modelstate, loading: false })
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const testReport = () => {
    GET('/gettestrundetails', { sessionID: sessionId })
      .then((res) => {
        const resdata = res.data.message
        const testReport = []
        if (resdata) {
          resdata.map((e) => {
            testReport.push({
              id: e.id,
              testPackId: e.testPackId,
              name: e.name || 'Report',
              path: `/viewmanualrunresults/${e.id}`,
              icon: <TbReportAnalytics size={16} />,
              submenu: false,
              model: false,
              dynamicMenu: [],
              accountType: []
            })
          })
        } else {
          testReport.push({
            id: 1,
            name: 'No Report Found',
            submenu: false,
            model: false,
            dynamicMenu: [],
            accountType: []
          })
        }
        MenuList.filter((targetID) => {
          if (targetID.id == 7) {
            targetID.dynamicMenu = testReport
          }
        })
        setMenuList(MenuList)
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }
  const reportChart = () => {
    const testReport = [
      {
        id: 1,
        name: 'TestRun Status',
        path: '/testrunsummary',
        icon: <TbReportAnalytics size={16} />,
        submenu: false,
        model: false,
        dynamicMenu: [],
        accountType: []

      },
      {
        id: 2,
        name: 'User Productivity',
        path: '/userproductivityreport',
        icon: <TbReportAnalytics size={16} />,
        submenu: false,
        model: false,
        dynamicMenu: [],
        accountType: []

      },
      {
        id: 3,
        name: 'TestCase Status',
        path: '/testcasestatusreport',
        icon: <TbReportAnalytics size={16} />,
        submenu: false,
        model: false,
        dynamicMenu: [],
        accountType: []
      },
      {
        id: 4,
        name: 'Feature Release Map',
        path: '/featuremanagement',
        icon: <TbReportAnalytics size={16} />,
        submenu: false,
        model: false,
        dynamicMenu: [],
        accountType: []

      }
    ]
    MenuList.filter((targetID) => {
      if (targetID.id == 12) {
        targetID.dynamicMenu = testReport
      }
    })
    setMenuList(MenuList)
  }
  React.useEffect(() => {
    setModelState({ ...modelstate, sidebarExpanded: true })
    getApplication()
    if (user.accountType === 1) {
      getApplicationCXO()
    }
    if (user.accountType !== 1) {
      getTestpacks()
    }
    if (user.accountType !== 0) {
      testExecutions()
    }
    testReport()
    reportChart()
    setRefreshState(false)
  }, [refreshState])

  React.useEffect(() => {}, [MenuList])

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-green-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden='true'
      />

      {/* Sidebar */}
      <div
        id='sidebar'
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-68 ${
          sidebarExpanded ? 'lg:w-22' : 'lg:w-20'
        } lg:sidebar-expanded:!w-68 2xl:!w-68 shrink-0 bg-white p-4 transition-all duration-200 ease-in-out scroll scroll-3 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-68'
        }`}
      >
        <div className='h600' />
        {/* Sidebar header */}
        <div className='flex justify-between mb-10 pr-3 sm:px-2'>
          {/* Close button */}
          <button
            ref={trigger}
            className='lg:hidden text-gray-500 hover:text-gray-400'
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
          >
            <span className='sr-only'>Close sidebar</span>
            <svg
              className='w-6 h-6 fill-current'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z' />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to='/' className='block'>
            <img
              src={sidebarExpanded ? FooterImage : logoImage}
              alt='greentick'
            />
          </NavLink>
        </div>

        {/* Links */}
        <div className='space-y-8'>
          {/* Pages group */}
          <div>
            <ul className='mt-3'>
              {MenuList.map(
                (item, index) =>
                  item.accountType.includes(user.accountType) && (
                    <Sidebaritem key={index} item={item} index={index} />
                  )
              )}
            </ul>
            <ul className='mt-20'>
              <li className='pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 '>
                <NavLink
                  end
                  className='block text-gray-900 cursor-pointer truncate transition duration-150 '
                >
                  <div className='flex items-center justify-between'>
                    <div className='grow flex items-center'>
                      <FaHandsHelping size={20} />
                      <span className='text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                        Help
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li className='pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 '>
                <NavLink
                  end
                  className='block text-gray-900 cursor-pointer truncate transition duration-150 '
                >
                  <div className='flex items-center justify-between'>
                    <div className='grow flex items-center'>
                      <RiUser6Line size={20} />
                      <span className='text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                        {user === null ? '' : user.username}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>

              <li className='pl-2 pr-0 py-2 rounded-sm mb-0.5 last:mb-0 '>
                <NavLink
                  end
                  onClick={() => handleSignOut()}
                  className='block text-gray-900 cursor-pointer truncate transition duration-150 '
                >
                  <div className='flex items-center justify-between'>
                    <div className='grow flex items-center'>
                      <BiLogOutCircle size={20} />
                      <span className='text-base font-DMSANS font-bold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                        Sign Out
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className='pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto'>
          <div className='px-3 py-2'>
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded)
                setModelState({
                  ...modelstate,
                  sidebarExpanded: !modelstate.sidebarExpanded
                })
              }}
            >
              <span className='sr-only'>Expand / collapse sidebar</span>
              <svg
                className='w-6 h-6 fill-current sidebar-expanded:rotate-180'
                viewBox='0 0 24 24'
              >
                <path
                  className='text-slate-400'
                  d='M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z'
                />
                <path className='text-slate-600' d='M3 23H1V1h2z' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
