import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../config/api'
import { useNavigate } from 'react-router-dom'
import { notificationTypes, openNotification } from '../../../utils/notifications'
import Report from '../../../assets/Icons/reports.png'
import arrow from '../../../assets/Icons/arrow.png'
import Arrow1 from '../../../assets/Icons/arrowright.png'
import module from '../../../assets/Icons/scenario.png'

const Reports = () => {
  const navigate = useNavigate()

  const [isReport, setIsReport] = useState(false)
  const [run, setRun] = useState([])
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const sessionId = useSelector(state => state.user.sessionId)

  const handleReport = () => {
    setIsReport(!isReport)
  }

  const getTestRuns = () => {
    GET('/gettestrun', { sessionID: sessionId })
      .then(res => {
        if (res.data.status === 200) {
          setRun(res.data.message)
        } else {
          openNotification(notificationTypes.ERROR, 'Error', 'Something went wrong')
        }
      })
      .catch(err => {
        console.log(err)
        openNotification(notificationTypes.ERROR, 'Error', 'Something went wrong')
      })
  }

  useEffect(() => {
    getTestRuns()
  }, [])

  return (
    <div
      style={{ backgroundColor: isReport ? '#E5F0F8' : 'white' }}
    >
      <div
        onClick={handleReport}
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        class='hover:bg-customBlueFour'
      >
        <div
          className='flex items-center p-2 text-base font-bold text-black rounded-lg'
          onClick={() => navigate('/report')}
        >
          {
            isReport
              ? (
                <img className='mr-2' src={arrow} alt='arrow' />
                )
              : (
                <img className='mr-2' src={Arrow1} alt='Arrow' />
                )
          }
          <img src={Report} alt='report' />
          <span class='ml-2'>
            &nbsp;Test Reports&nbsp;
          </span>
        </div>
      </div>
      {
        isReport
          ? (
              run === null
                ? null
                : run.length > 0
                  ? (
                      run.map(
                        (item, index) => (
                          <div
                            onClick={() => navigate(`/viewmanualrunresults/${item.id}`)}
                            class='hover:bg-customBlueFour ml-5 flex items-center p-2 text-base text-black rounded-lg'
                          >
                            <img src={arrow} alt='arrow' />
                            <img className='ml-2 h-7' src={module} alt='report' />
                            <span class='ml-3'>
                              {item.name}&nbsp;
                            </span>
                          </div>
                        )
                      ))
                  : (
                    <div>
                      No Test Runs
                    </div>
                    )
            )
          : null
      }
    </div>
  )
}

export default Reports
