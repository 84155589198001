import { useState, useEffect } from 'react'
import { GET, PUT } from '../../config/api'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ManualRunModal from '../../components/Modal/ManualRunModal'
import { openNotification, notificationTypes } from '../../utils/notifications'
import { routes } from '../../constants/routes'
import TableItems from './TableItem'

const ViewManualRunPage = () => {
  const { tcID } = useParams()
  const sessionId = useSelector((state) => state.user.sessionId)
  const navigate = useNavigate()

  const [steps, setSteps] = useState({})

  // const completeTestcase = (viewmanualrunID) => {
  //   PUT(`/completeTestCase?testCaseID=${viewmanualrunID}`, {}, { sessionID: sessionId })
  //     .then((res) => {
  //       openNotification(notificationTypes.SUCCESS, 'Success', 'Test case completed successfully')
  //       navigate(routes.VIEWMANUALRUN)
  //     }
  //     )
  //     .catch((err) => {
  //       console.log(err)
  //       openNotification(notificationTypes.ERROR, 'Error', 'Error in completing test case')
  //     }
  //     )
  // }

  const getTestcaseSteps = (tcID) => {
    GET(`getteststeps?id=${tcID}`, { sessionID: sessionId })
      .then((res) => {
        // sort response data by step number
        if (res.data.status === 200 || res.data.status === 304) {
          const data = res.data.message.sort((a, b) => a.stepNumber - b.stepNumber)
          setSteps(data)
        } else {
          console.log('error')
        }
      }
      )
      .catch((err) => {
        console.log(err)
      }
      )
  }

  useEffect(() => {
    getTestcaseSteps(tcID)
  }, [tcID])

  return (
    <>
      <div>
        <div className='pb-5'>
          <div className='grid grid-cols-1 gap-1'>
            {/* <div className='col-span-2' /> */}
            <div className='mb-40 col-span-8'>
              <div className='mt-5 py-10 min:h-2/5'>
                <h1
                  className='py-3 mb-4 text-black font-bold font-DMSANS text-base xl:text-3xl ml-3 leading-none lg:leading-11'
                >
                  Select Test Case
                </h1>
                <div className='w-full bg-customWhite rounded-xl p-4 shadow'>
                  <table className='w-full table-auto ml-1 mr-10 border-1 rounded-3xl border-customGreyThree'>
                    <thead class='bg-customGrey rounded-2xl pr-6'>
                      <tr>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left'>
                          Step ID
                        </th>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left'>
                          Step Description
                        </th>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left'>
                          Expected Output
                        </th>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left'>
                          Actual Output
                        </th>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left'>
                          Comments
                        </th>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left'>
                          Status
                        </th>
                        <th scope='col' class='text-center text-base font-medium text-gray-900 p-3 text-left'>
                          Completed
                        </th>
                        <th scope='col' class='text-base font-medium text-gray-900 p-3 text-left' />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        steps === null
                          ? null
                          : steps.length > 0
                            ? (
                                steps.map((step, index) => (
                                  <TableItems
                                    key={index}
                                    testcaseID={tcID}
                                    item={step}
                                  />
                                ))
                              )
                            : (
                              <div />
                              )
                      }
                    </tbody>
                  </table>
                </div>
                {/* <div className='ml-auto'>
                  <button
                    onClick={() => completeTestcase(viewmanualrunID)}
                    className='ml-2 bg-customBlue text-white font-bold py-2 px-4 rounded mt-5'
                  >
                    Submit
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewManualRunPage
