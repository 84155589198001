import { GET } from '../../../../../../config/api'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import User from '../../../../../../assets/Icons/module.png'
import { openNotification, notificationTypes } from '../../../../../../utils/notifications'
import Arrow from '../../../../../../assets/Icons/arrow.png'

const TestPackItem = (props) => {
  const navigate = useNavigate()

  const sessionId = useSelector((state) => state.user.sessionId)

  const [testpack, setTestPack] = useState([])

  const getTestpacks = () => {
    GET(`/getAllTestPacks?appid=${props.applicationID}`, { sessionID: sessionId })
      .then((res) => {
        console.log(res.data.message)
        setTestPack(res.data.message)
      }
      )
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getTestpacks()
  }, [props.applicationID])

  return (
    <>
      <div>
        {
                    testpack === null
                      ? null
                      : testpack.length > 0
                        ? (
                            testpack.map((item, index) => (
                              <div key={index} className='ml-5'>
                                <div onClick={() => navigate(`/viewcxopack/${item.id}`)} className='flex hover:bg-customBlueFour items-center p-1 font-normal text-black rounded-lg'>
                                  <img src={Arrow} className='ml-1 mr-2' alt='Arrow' />
                                  <img src={User} className='h-6 mr-2' alt='User' />
                                  <div className='text-base'>{item.testpack_name}</div>
                                </div>
                              </div>
                            ))
                          )
                        : (
                          <div className='hover:bg-customBlueFour flex flex-row items-center'>
                            <img src={Arrow} className='ml-1 mr-2' alt='Arrow' />
                            <div className='text-customBlueFour font-DMSANS text-sm xl:text-base font-medium ml-5 leading-none lg:leading-11'>No Testpacks</div>
                          </div>
                          )
                }
      </div>
    </>
  )
}

export default TestPackItem
