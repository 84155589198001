import React, { useState } from 'react'
import { RiUser6Line } from 'react-icons/ri'
import { BiLogOutCircle } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux'
import { signOut } from '../redux/ducks/userDuck'
import { FcWorkflow } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import { GET } from '../config/api'
import { notificationTypes, openNotification } from '../utils/notifications'
// import Help from './header/Help';
// import UserMenu from './header/UserMenu';
// import Breadcrumb from '../components/Breadcrumb';

function Header ({ sidebarOpen, setSidebarOpen }) {
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [ProfileMenuOpen, setProfileMenuOpen] = useState(false)

  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const sessionId = useSelector((state) => state.user.sessionId)
  const dispatch = useDispatch()
  const handleSignOut = () => {
    dispatch(signOut(sessionId))
    GET('/exportToFile?activity=logOut', { sessionID: sessionId })
      .then(() => {
        openNotification(
          notificationTypes.SUCCESS,
          'Sign Out'
        )
      })
  }

  return (
    <header className='sticky top-0 z-40 w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50  lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white/95 supports-backdrop-blur:bg-white/60 dark:bg-transparent'>
      {/* sticky top-0 bg-white  bg-opacity-110 border-b border-slate-200 text-gray-900 z-30 */}
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-16 -mb-px'>
          {/* Header: Left side */}
          <div className='flex'>
            {/* Hamburger button */}
            <button
              className='text-slate-500 hover:text-slate-600 lg:hidden'
              aria-controls='sidebar'
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation()
                setSidebarOpen(!sidebarOpen)
              }}
            >
              <span className='sr-only'>Open sidebar</span>
              <svg
                className='w-6 h-6 fill-current'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect x='4' y='5' width='16' height='2' />
                <rect x='4' y='11' width='16' height='2' />
                <rect x='4' y='17' width='16' height='2' />
              </svg>
            </button>
            {/* <Breadcrumb /> */}
          </div>

          {/* Header: Right side */}
          <div className='flex items-center'>
            {/* <button
              className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ml-3 ${searchModalOpen && 'bg-slate-200'}`}
              onClick={(e) => { e.stopPropagation(); setSearchModalOpen(true); }}
              aria-controls="search-modal"
            >
              <span className="sr-only">Search</span>
              <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path className="fill-current text-slate-500" d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                <path className="fill-current text-slate-400" d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
              </svg>
            </button>
            <SearchModal id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} />
            <Notifications />
            <Help /> */}
            {/*  Divider */}
            {/* <hr className="w-px h-6 bg-slate-200 mx-3" /> */}
            <button
              className='flex items-center text-base font-DMSANS font-bold ml-3 text-gray-900 rounded-full hover:text-gray-800 md:mr-0'
              type='button'
              onClick={() => navigate('/howitworks')}
            >
              <span className='sr-only'>How Greentick Works?</span>
              <FcWorkflow size={20} className='mx-2' />
              How Greentick Works?
            </button>
            <button
              className='flex items-center text-base font-DMSANS font-bold ml-3 text-gray-900 rounded-full hover:text-gray-800 md:mr-0'
              type='button'
              onClick={(e) => {
                setProfileMenuOpen(!ProfileMenuOpen)
              }}
            >
              <span className='sr-only'>User Name</span>
              <RiUser6Line size={20} />
              <span className='mx-2'>{user === null ? '' : user.username}</span>
            </button>
            <button
              className='flex items-center text-sm font-DMSANS font-medium ml-3 text-gray-600 rounded-full hover:text-gray-800 md:mr-0'
              type='button'
              onClick={(e) => {
                handleSignOut()
              }}
            >
              <span className='sr-only'>Sign Out</span>
              <BiLogOutCircle size={20} />
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
