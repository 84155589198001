import { GET, POST } from '../../../config/api'

export const signInUser = (userData) => {
  return POST('/login', userData, {})
}

export const getCompanystatus = (email) => {
  return GET(`/getuserdetailsbyemail?email=${email}`, {}, {})
}

export const signUpUser = (userData) => {
  return POST('/signup', userData, { sessionID: null })
}

export const signOut = (sessionId, userData = {}) => {
  return GET('/logout', userData, { sessionID: sessionId })
}
