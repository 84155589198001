import IMG from '../../assets/Images/loginImage.png'
import Resetpassword from '../../components/Forms/Resetpassword'
function ResetPassword () {
  return (
    <div className='bg-customGrey pt-12 px-24'>
      <div className='items-center justify-center h-screen grid grid-cols-1 lg:grid-cols-2 gap-8'>
        <div className='hidden lg:block border-r border-customGreenSix '>
          <img className='pr-16' src={IMG} alt='login' />
        </div>
        <div>
          <Resetpassword />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
