import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { documentTitles } from "../../constants/appConstants";
import TeststepsInfo from "../EditTestSteps/TestStepsInfo/Index";
import { useNavigate, useLocation } from "react-router-dom";

const EditTestSteps = () => {
  const [data, setData] = useState(0);
  const location = useLocation();
  const sessionId = useSelector((state) => state.user.sessionId);
  const dataArray = location.state.message.split(",");

  console.log(dataArray, "data array of teststep page");

  const [number, setNumber] = useState(dataArray[0].trim());
  const [title, setTitle] = useState(dataArray[1].trim());
  const [createdDate, setCreatedDate] = useState(dataArray[5].slice(0, 11));
  const [priority, setPriority] = useState(dataArray[2].trim());
  const [status, setStatus] = useState(dataArray[4].trim());
  const [description, setDescription] = useState(dataArray[3].trim());

  const [teststepDescription, setTeststepDescription] = useState(dataArray[6].trim());
  const [testData, setTestData] = useState(dataArray[7].trim());
  const [expectedResult, setExpectedResult] = useState(dataArray[8].trim());

  
  let jsons = JSON.parse(localStorage.getItem("teststepsFormValue"));
  const [jsonB, setJSONB] = useState(jsons ? jsons : []);

  let imageArr = JSON.parse(localStorage.getItem("teststepsImage"));

  const [imageName, setImageName] = useState(imageArr);
  const navigate = useNavigate();

  const handleEst = () => {
    setData(0);
  };

  const handlePlan = () => {
    setData(1);
  };

  const handlePlan2 = () => {
    setData(2);
  };

  const sectionThree = () => {
    setData(3);
  };

  const sectionFour = () => {
    setData(4);
  };

  const sectionFive = () => {
    setData(5);
  };

  const sectionSix = () => {
    setData(6);
  };

  const sectionSeven = () => {
    setData(7);
  };

  useEffect(() => {
    document.title = documentTitles.RELEASEPLANNER;
  }, []);

  return (
    <>
      <div>
        <div className="pb-52 bg-gray-100">
          <div className="mr-22 grid grid-cols-10 gap-16">
            <div className="ml-3 pb-32 col-span-10">
              <div>
                <div className="grid grid-cols-10">
                  <div className="col-span-6">
                    <h1 className="mt-5 ml-5 pb-3 mb-4  text-black font-bold font-DMSANS text-base xl:text-4xl leading-none lg:leading-11">
                      Teststeps Management
                    </h1>
                  </div>
                  <div className="col-span-2" />
                  <div className="col-span-2">
                    <div className="mt-5 ml-5 pb-3 mb-4">
                     
                    </div>
                  </div>
                </div>
                {/* <div className="grid grid-cols-6 gap-4 mt-5">
                  <div className="col-end-7">
                    <button
                      className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
                      type="button"
                      onClick={updateRelease}
                    >
                      Save
                    </button>
                  </div>
                </div> */}
                <div className="bg-customWhite p-10 rounded-xl shadow-xl">
                  <div>
                    <div className="flex mb-5">
                      <h1
                        onClick={handleEst}
                        className={
                          data === 0
                            ? "underline underline-offset-4 text-lg text-customBlueFive mr-2"
                            : "mr-2 text-lg text-black"
                        }
                      >
                        Test Steps Info
                      </h1>
                    </div>
                  </div>
                  {data === 0 ? (
                    <TeststepsInfo
                    teststepsId={number}
                      number1={number}
                      title1={title}
                      priority1={priority}
                      description1={description}
                      status1={status}
                      createdDate1={createdDate}
                      teststepDescription1={teststepDescription}
                      testData1={testData}
                      expectedResult1={expectedResult}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTestSteps;
