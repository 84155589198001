import LOGIN from '../../assets/Images/companyOnboarding.png'
import SignUpForm from '../../components/Forms/SignUpForm'
import { useEffect } from 'react'
import { documentTitles } from '../../constants/appConstants'

function SignUpPage () {
  useEffect(() => {
    document.title = documentTitles.SIGN_UP
  }, [])

  return (
    <div className='bg-customGrey pt-12 px-24'>
      <div className='flex items-center justify-center h-screen grid grid-cols-1 lg:grid-cols-2 gap-8'>
        <div className='hidden lg:block border-r border-customGreenSix '>
          <img src={LOGIN} className='pt-16' alt='login' />
          <h1 className='mt-8 text-3xl mb-4 font-DMSANS font-bold '>
            Test lead
          </h1>
          <p className='font-DMSANS font-bold text-sm mr-24 mb-4'>
            CXO Dashboard helps Test Lead plays a crucial role in increasing the planning and productivity of
            the testing team. Dashboard helps test lead do release based planning for developing test plans,
            managing test execution, and continuously improving the process, the Test Lead can ensure that the
            testing team is working efficiently and effectively. This, in turn, can help to ensure the quality
            and success of the project
          </p>
        </div>
        <div className='ml-12'>
          <SignUpForm />
        </div>
      </div>
    </div>
  )
}

export default SignUpPage
