const labelToDescriptionMap = {
  login_success: 'Logged in',
  login_failed: 'Login failed',
  upload_release: 'Uploaded release',
  upload_application: 'Uploaded application',
  upload_module: 'Uploaded module',
  upload_scenario: 'Uploaded scenario',
  upload_testpack: 'Uploaded test pack',
  upload_feature: 'Uploaded feature',
  upload_user: 'Uploaded user',
  delete_user: 'Deleted user',
  upload_featuremaprelease: 'Uploaded feature map release',
  upload_testpackmapping: 'Created test pack mapping',
  upload_company: 'Uploaded company',
  update_application: 'Updated application',
  delete_application: 'Deleted application',
  update_module: 'Updated module',
  delete_module: 'Deleted module',
  update_scenario: 'Updated scenario',
  delete_scenario: 'Deleted scenario',
  upload_automaticrun: 'Uploaded automaticrun',
  import_feature: 'Imported feature',
  update_feature: 'Updated feature',
  delete_feature: 'Deleted feature',
  upload_manualrunreport: 'Uploaded manualrunreport',
  upload_testcase: 'Uploaded testcase',
  update_testcase: 'Updated testcase',
  delete_testcase: 'Deleted testcase',
  upload_testcasesteps: 'Uploaded testcasesteps',
  upload_testcasestepsviaexcel: 'Uploaded testcasestepsviaexcel',
  update_testcasesteps: 'Updated testcasesteps',
  update_testcasestepsviaexcel: 'Updated testcasestepsviaexcel',
  delete_testcasesteps: 'Deleted testcasesteps',
  upload_testerdata: 'Uploaded testerdata',
  update_testpack: 'Updated testpack',
  delete_testpack: 'Deleted testpack',
  assign_testpack: 'Assign testpack',
  assign_testrun: 'Assign testrun',
  delete_testrun: 'Deleted testrun',
  update_testrun: 'Updated testrun'
}

export default labelToDescriptionMap
