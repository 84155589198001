import { useState, useEffect } from 'react'
import { GET } from '../../../config/api'
import Module from './Module'
import { useSelector } from 'react-redux'
import { openNotification, notificationTypes } from '../../../utils/notifications'

const Application = (props) => {
  const sessionId = useSelector((state) => state.user.sessionId)

  const [modules, setModules] = useState({})
  const [isModule, setIsModule] = useState(false)

  const handleAppModules = () => {
    GET(`/getModules?appID=${props.app.id}`, { sessionID: sessionId })
      .then((res) => {
        setModules(res.data.message)
        setIsModule(true)
      })
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    if (typeof props.app.id !== 'undefined') {
      handleAppModules()
    }
  }, [props.app.id])

  return (
    <>
      <div className='ml-5 pt-5 font-DMSANS text-sm xl:text-2xl font-medium leading-none lg:leading-11'>
        <i class='fa-solid fa-angle-right' />&nbsp;&nbsp;
        <span
          onClick={() => handleAppModules(props.app.id)}
        >
          {props.app === null ? null : props.app.applicationName}&nbsp;
        </span>
      </div>
      {isModule && (
        modules.map((item) => (
          <Module
            modules={item}
            appID={props.app.id}
            testPackID={props.testPackID}
          />
        ))
      )}
    </>
  )
}

export default Application
