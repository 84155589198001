import { useState, useEffect } from "react";
import React, { useRef } from "react";
import moment from "moment";
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";
import { GET } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'

const ViewTestSteps = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const sessionId = useSelector((state) => state.user.sessionId);
  const jirKey = location.state.message;
  // const [jirKey, setJirKey] = useState(dataArray);
  const [testStepsJira, setTestStepsJira] = useState(null)
  const start = useRef();
  const endData = useRef();
  const [totalpages, setTotalpages] = useState();
  const [currentPagee, setCurrentPagee] = useState(1);

  useEffect(() => {
    start.current = 1;
    endData.current = 5;
    getTestStepsJiraa();
  }, [])

  const getTestStepsJiraa = async () => {
    GET(`/getteststepsjira?jirKey=${jirKey}&start=${start.current}&endData=${endData.current}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res) {
          console.log(res.data.message.values, "get response of get teststeps jira");
          let totalpage = Math.ceil(res.data.message.total / 5);
          setTotalpages(totalpage);
          setTestStepsJira(res.data.message.values);
        } else {
          openNotification(
            console.log("Error in getting jira key, Please try again"),
            notificationTypes.ERROR,
            "Error in getting jira key, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const handlePageClick = (e) => {
    if (currentPagee < totalpages) {
      start.current = start.current + 5;
      console.log(start.current, " start.current  start.current front")
      setCurrentPagee(currentPagee + 1)
      getTestStepsJiraa(start.current);
    }
  }

  const handlePrevPageClick = (e) => {
    if (currentPagee >= 2) {
      start.current = start.current - 5;
      console.log(start.current, " start.current  start.current back")
      setCurrentPagee(currentPagee - 1)
      getTestStepsJiraa(start.current);
    }
  }

  return (
    <>
      <div className='grid grid-cols-10 gap-1 m-2 p-2'>
        <div className='col-span-10'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-black font-DMSANS text-4xl py-1'>
              Test Steps
            </h1>
          </div>
          <div>
            <button
              onClick={() => navigate("/testcasemanagement")}
              className="bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded "
            >
              Back
            </button>
          </div>
          <div className='overflow-x-auto bg-customWhite rounded-xl p-4 shadow-xl bg-customWhite '>
            <table className='w-full overflow-hidden mx-1 border-1 rounded-3xl border-customGreyThree'>
              <thead className='text-center bg-customGrey rounded-2xl'>
                <tr>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    description
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    expectedResult
                  </th>
                  <th
                    scope='col'
                    className='text-base font-bold text-gray-900 px-8 py-4 text-left'
                  >
                    testData
                  </th>

                </tr>
              </thead>
              <tbody>
                {!testStepsJira || testStepsJira.length <= 0 ? (
                  <tr>
                    <td />
                    <td className="text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap">
                      {testStepsJira ? "No TestSteps to display" : "Loading..."}
                    </td>
                  </tr>
                ) : (
                  testStepsJira.map((obj) => {
                    return (
                      <tr class='border-b border-1 border-customGreyThree'>
                        <td class='text-base text-gray-600 font-medium px-8 py-1'
                        >
                          <p className='break-words'
                            style={{ width: "300px" }}
                          >{obj.inline.description}</p>
                        </td>
                        <td
                          class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-1'

                        >
                          <p className='break-words'
                            style={{ width: "300px" }}
                          >
                            {obj.inline.expectedResult}
                          </p>
                        </td>
                        <td class='text-base text-gray-600 font-medium px-8 py-1'>
                          <p className='break-words' style={{ width: "300px" }}>
                            {obj.inline.testData}
                          </p>
                        </td>

                      </tr>
                    );
                  })
                )
                }
              </tbody>
            </table>
            <div className="flex justify-center mt-4">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mx-1 rounded"
                onClick={() => handlePrevPageClick()}
              >
                Previous
              </button>
              <span className="mx-2">
                Page {currentPagee} of {totalpages}
              </span>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mx-1 rounded"
                onClick={() => handlePageClick()}
              >
                Next
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTestSteps;
