import inputStyles from '../Input.module.css'

// Constants
import { defaultInputMargin } from '../../../constants/appConstants'

// components
import Label from '../Label'

const TextField = ({
  label,
  name,
  placeholder,
  value,
  onChangeHandler,
  maxLength = 500,
  margin = defaultInputMargin,
  type = 'text',
  required = false,
  disabled = false
}) => {
  return (
    <div
      style={{
        margin
      }}
      className={inputStyles.wrapper}
    >
      {label && <Label label={label} required={required} />}
      <input
        type={type}
        name={name}
        required={required}
        disabled={disabled}
        className={inputStyles.container}
        placeholder={placeholder}
        onChange={onChangeHandler}
        value={value}
        maxLength={maxLength}
      />
    </div>
  )
}

export default TextField
