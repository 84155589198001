import React, { useEffect, useState } from 'react'
import { POST } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import MultiSelect from 'react-select'
import Label from '../../components/InputComponents/Label'
import { useSelector } from 'react-redux'
import moment from 'moment'
import labelToDescriptionMap from './ActivityMap/activitiesMapping'
import DataTable from 'react-data-table-component'
import { BarLoader } from 'react-spinners'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

let userActivitiesBackup = []

export const WeekReport = (props) => {
  const [reportData, setReportData] = useState([])
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [selectedActivities, setSelectedActivities] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [selectedChip, setSelectedChip] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userColors, setUserColors] = useState({})
  const [lastToLastWeekdata, setLastToLastWeekdata] = useState([])

  const sessionId = useSelector((state) => state.user.sessionId)

  useEffect(() => {
    console.log('i am called second')
    const oneWeekAgo = moment().subtract(8, 'days')
    console.log('oneweekagao', oneWeekAgo)
    const oneWeekAgoFormatted = oneWeekAgo.format('YYYY-MM-DD')
    // Fetch data for the week before last week
    const twoWeeksAgo = moment().subtract(14, 'days')
    console.log('twoweekagao', twoWeeksAgo)
    const twoWeeksAgoFormatted = twoWeeksAgo.format('YYYY-MM-DD')
    console.log('oneWeekAgoFormatted', oneWeekAgoFormatted)
    console.log('twoWeeksAgoFormatted', twoWeeksAgoFormatted)
    setFromDate(twoWeeksAgoFormatted)
    setToDate(oneWeekAgoFormatted)
    setSelectedUsers([])
    setSelectedActivities([])
    setSelectedChip('lastToLastWeek')
    getReportData({
      fromDate: twoWeeksAgoFormatted,
      toDate: oneWeekAgoFormatted
    }, setLastToLastWeekdata
    )
  }, [])

  useEffect(() => {
    console.log('i am called first')
    const today = moment()
    const yesterday = moment(today).subtract(1, 'days')
    console.log('yesterday', yesterday)
    const yesterdayFormatted = yesterday.format('YYYY-MM-DD')
    const oneWeekAgo = moment().subtract(7, 'days')
    console.log('oneweek', oneWeekAgo)
    const oneWeekAgoFormatted = oneWeekAgo.format('YYYY-MM-DD')
    console.log('yesterdayFormatted', yesterdayFormatted)
    console.log('oneWeekAgoFormatted', oneWeekAgoFormatted)
    setFromDate(oneWeekAgoFormatted)
    setToDate(yesterdayFormatted)
    setSelectedUsers([])
    setSelectedActivities([])
    setSelectedChip('lastWeek')
    getReportData({
      fromDate: oneWeekAgoFormatted,
      toDate: yesterdayFormatted
    }, setReportData)
  }, [])

  const getReportData = (filters = {}, callback) => {
    setIsLoading(true)
    POST('/userActivityLog', filters, { sessionID: sessionId })
      .then((res) => {
        callback(res.data.message)
        userActivitiesBackup = res.data.message.userActivityDetails
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleFilterToggle = () => {
    setIsFilterVisible((prev) => !prev)
  }

  const handleActivitySelection = (selected) => {
    setSelectedActivities(selected)
  }

  const handleUserSelection = (selected) => {
    setSelectedUsers(selected)
  }

  const handleApplyFilters = () => {
    const filters = {
      username: selectedUsers?.map((item) => item.label),
      activities: selectedActivities?.map((item) => item.value),
      fromDate,
      toDate
    }
    getReportData(filters)
    setSelectedChip('')
  }

  const handleClearFilters = () => {
    setSelectedActivities([])
    setSelectedUsers([])
    setFromDate('')
    setToDate('')
    getReportData()
    setSelectedChip('')
  }
  const getActivityLabel = (row) => {
    const activityLabel = row.activities
    // Get the description based on the label from the imported mapping
    const activityDescription = labelToDescriptionMap[activityLabel]
    return activityDescription || activityLabel
  }
  const getChartOptions = () => {
    return {
      responsive: true,
      scales: {
        x: {
          stacked: true
        //   ticks: {
        //   callback: () => 'LTLW|LW'
        // }
        },
        // x2: {
        //   border: {
        //     display: false
        //   },
        //   grid: {
        //     display: false
        //   }
        // },
        y: { stacked: true, beginAtzero: true }
      },
      plugins: {
        legend: {
          position: 'right', // Set the position of the legend,
          display: true
          // labels: {
          //   filter: function (legendItem, chartData) {
          //     // Assuming you want to hide the label not containing "LW"
          //     return !legendItem.text.includes("LW");
          //   },
          // },
        },
        datalabels: {
          anchor: 'end',
          align: 'start',
          offset: -20,
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0
          },
          // formatter: (value, context) => {
          //   const datasetArray = [];
          //   context.chart.data.datasets.forEach((dataset) => {
          //     if (dataset.data[context.dataIndex] != undefined) {
          //       datasetArray.push(dataset.data[context.dataIndex]);
          //     }
          //   });

          //   function totalSum(total, datapoint) {
          //     return total + datapoint;
          //   }

          //   let sum = datasetArray.reduce(totalSum, 0);
          //   if (context.datasetIndex == datasetArray.length - 1) return sum;
          //   else return "";
          // },
          color: 'black'
        },
        title: {
          display: true,
          text: `Productivity Report Chart (comparing last two weeks data) - From ${moment(fromDate).format('YYYY-MM-DD')} to ${moment(toDate).format('YYYY-MM-DD')}`,
          position: 'top', // Position the title at the top of the chart
          align: 'center' // Center-align the title horizontally
        }
      }
    }
  }
  useEffect(() => {
    const distinctUsernames = reportData?.distinctUsernames || []
    const distinctUserColors = {}
    distinctUsernames.forEach((user, index) => {
      // Generate permanent color based on the index (you can use any method)
      const color = `rgba(${Math.floor(index * 40) % 256}, ${
        Math.floor(index * 80) % 256
      }, ${Math.floor(index * 120) % 256}, 0.5)`
      distinctUserColors[user.username] = color
    })
    setUserColors(distinctUserColors)
  }, [reportData])

  const getChartData = () => {
    console.log('@@@@@@@@@@@@@@@@@@@', reportData)
    console.log('###################', lastToLastWeekdata)

    // Extract all unique activities from both datasets
    const allActivities = Array.from(
      new Set([
        ...(reportData?.userActivitiesData || []).map((data) => data.activity),
        ...(lastToLastWeekdata?.userActivitiesData || []).map((data) => data.activity)
      ])
    )

    const reportDataDataset = reportData?.distinctUsernames?.map((user) => {
      return {
        label: `${user.username} (LW)`,
        data: allActivities.map((activity) => {
          return (
            reportData.userActivitiesData.find(
              (data) => data.activity === activity
            )?.userCounts.find(
              (userData) => userData.username === user.username
            )?.count || 0
          )
        }),
        backgroundColor: userColors[user.username],
        stack: 0
      }
    })

    const lastToLastWeekdataDataset = lastToLastWeekdata?.distinctUsernames?.map((user) => {
      return {
        label: `${user.username}`,
        data: allActivities.map((activity) => {
          return (
            lastToLastWeekdata.userActivitiesData.find(
              (data) => data.activity === activity
            )?.userCounts.find(
              (userData) => userData.username === user.username
            )?.count || 0
          )
        }),
        backgroundColor: userColors[user.username],
        stack: 1
      }
    })

    return {
      labels: allActivities,
      datasets: [...(lastToLastWeekdataDataset || []), ...(reportDataDataset || [])]
    }
  }

  const icon = isFilterVisible ? <FiChevronUp /> : <FiChevronDown />
  console.log('reportData', reportData)
  console.log('lasttolastweekdata', lastToLastWeekdata)
  return (
    <div>
      <div className='flex items-center'>
        <button
          className='hover:bg-customBlueFour font-bold text-black px-2 py-2 rounded-md mb-4 w-full text-left block flex items-center justify-between'
          onClick={handleFilterToggle}
        >
          <span>Filters</span>
          {icon}
        </button>
      </div>
      {isFilterVisible && (
        <div className='space-y-4'>
          <div className='flex flex-col'>
            <div className='flex flex-wrap'>
              <div className='w-1/3 pr-4'>
                <Label label='Select Activities:' />
                <MultiSelect
                  options={reportData.distinctActivitiesList.map(
                    (activity) => ({
                      label: labelToDescriptionMap[activity] || activity,
                      value: activity
                    })
                  )}
                  isMulti
                  placeholder='Select activities'
                  value={selectedActivities}
                  onChange={handleActivitySelection}
                />
              </div>
              <div className='w-1/3 pr-4'>
                <Label label='Select Users:' />
                <MultiSelect
                  options={reportData.distinctUsernames.map((user) => ({
                    label: user.username,
                    value: user.userId
                  }))}
                  isMulti
                  placeholder='Select users'
                  value={selectedUsers}
                  onChange={handleUserSelection}
                />
              </div>
              <div className='w-1/3 flex items-center'>
                <div className='w-full space-y-2'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-1/2 pr-4'>
                      <Label label='From Date:' />
                      <input
                        type='date'
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className='border rounded p-1 w-full'
                      />
                    </div>
                    <div className='w-1/2'>
                      <Label label='To Date:' />
                      <input
                        type='date'
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className='border rounded p-1 w-full'
                      />
                    </div>
                  </div>
                  <div className='w-full justify-center space-x-4 p-2'>
                    <button
                      onClick={handleApplyFilters}
                      className='bg-blue-500 text-white px-4 py-2 rounded-md'
                    >
                      Apply
                    </button>
                    <button
                      onClick={handleClearFilters}
                      className='bg-red-500 text-white px-4 py-2 rounded-md'
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {/* <div className="w-1/3 mb-2">
          <div className="w-full space-x-2 p-2">
            <button
              className={`py-1 my-2 rounded-2xl text-black font-bold font-DMSANS text-sm border-2 border-black px-2 h-fit ${
                selectedChip === "lastWeek" ? "bg-customBlueFour" : ""
              }`}
              onClick={handleLastWeekClick}
            >
              Last week
            </button>
          </div>
        </div> */}

        <div className='flex items-center justify-center'>
          <div className='w-80 md:w-4/5 max-w-screen-md text-center'>
            {reportData?.userActivitiesData?.length > 0 && lastToLastWeekdata?.userActivitiesData?.length > 0
              ? (
                <div>
                  <Bar
                    data={getChartData()}
                    options={getChartOptions()}
                    plugins={[ChartDataLabels]}
                  />
                  <h1 className='text-left'>The left side bar represents last to last week's data</h1>
                  <h1 className='text-left'>The right side bar represents last week's data {fromDate} to {toDate}</h1>
                </div>
                )
              : (
                <p className='text-xl'>Data not found for chart</p>
                )}
          </div>
        </div>

      </div>
    </div>
  )
}
