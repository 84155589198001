import TextField from '../../InputComponents/InputField'
import { useState, useEffect, useRef } from 'react'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { useSelector } from 'react-redux'
import { POST, GET } from '../../../config/api'
import styles from '../modal.module.css'
import { CloseCircleOutlined } from '@ant-design/icons'

const ScenarioModalbutton = ({
  showModal,
  setShowModal,
  setSchedule
}) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)

  const [scenarioName, setScenarioName] = useState('')
  const [note, setNote] = useState('')
  const [applications, setApplications] = useState([])
  const [modules, setModules] = useState([])
  const [selectedModuleId, setSelectedModuleId] = useState('')
  const [selectedAppId, setSelectedAppId] = useState('')
  const applicationID = selectedAppId

  const closeModal = () => {
    setShowModal(false)
  }

  const addScenario = (e) => {
    e.preventDefault()
    if (scenarioName.length > 0) {
      const Data = {
        scenarioName,
        scenarioDescription: note,
        applicationID
      }
      POST(`/postScenario?moduleID=${selectedModuleId}`, Data, {
        sessionID: sessionId
      })
        .then((res) => {
          if (res.data.status === 200) {
            openNotification(
              notificationTypes.SUCCESS,
              'Scenario added successfully'
            )
            GET(`/getScenario?moduleID=${selectedModuleId}`, {
              sessionID: sessionId
            })
              .then((res) => {
                if (res.data.status === 200 || res.data.status === 304) {
                  setSchedule(res.data.message)
                  setShowModal(false)
                  setScenarioName('')
                } else {
                  openNotification(
                    notificationTypes.ERROR,
                    'Something went wrong, please try again later'
                  )
                }
              })
              .catch((err) => {
                console.log(err)
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              })
          } else if (res.data.status === 30620) {
            openNotification(
              notificationTypes.ERROR,
              'ERROR: 30620',
              'Something went wrong, please try again later'
            )
          } else if (res.data.status === 30630) {
            openNotification(
              notificationTypes.ERROR,
              'ERROR: 30630',
              res.data.message
            )
          } else {
            openNotification(
              notificationTypes.ERROR,
              'Something went wrong, please try again later'
            )
          }
        })
        .catch((err) => {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        })
    } else {
      openNotification(
        notificationTypes.ERROR,
        'Error: 30610',
        'Please enter all the details'
      )
    }
  }

  const getApplications = () => {
    GET('/getApplicationFromCompany', { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setApplications(res.data.message || [])
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  const handleAppModules = (appId) => {
    GET(`/getModules?appID=${appId}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          setModules(res.data.message || [])
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      })
  }

  useEffect(() => {
    getApplications()
  }, [])

  useEffect(() => {
    if (selectedAppId) {
      handleAppModules(selectedAppId)
    }
  }, [selectedAppId])

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
  }, [showModal])

  return (
    <div ref={modalRef} className={styles.wrapper}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Add Scenario</h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <CloseCircleOutlined
                style={{ fontSize: '30px', color: 'black' }}
              />
            </a>
          </div>
        </div>
        <ul className='flex gap-5'>
          <li>
            <select
              className='mb-7 text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => {
                const selectedAppId = e.target.value
                const selectedApp = applications.find(
                  (app) => app.id === selectedAppId
                )
                setSelectedAppId(selectedAppId, selectedApp)
                setSelectedModuleId('')
              }}
              value={selectedAppId || ''}
            >
              <option value=''>Select Application</option>
              {applications.map((value) => (
                <option
                  className='mb-3 text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.applicationName}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className='mb-7 text-base font-DMSANS font-bold bg-gray-200 px-4 py-2 rounded-lg'
              onChange={(e) => setSelectedModuleId(e.target.value)}
              value={selectedModuleId || ''}
            >
              <option value=''>Select Module</option>
              {modules.map((value) => (
                <option
                  className='mb-3 text-base font-DMSANS font-bold bg-gray-200 border-2 border-gray-400 px-6 py-3 rounded-lg'
                  value={value.id}
                  key={value.id}
                >
                  {value.modelName}
                </option>
              ))}
            </select>
          </li>
        </ul>
        <TextField
          label='Name of the Scenario'
          placeholder={scenarioName}
          value={scenarioName}
          onChangeHandler={(e) => setScenarioName(e.target.value)}
          maxLength={5000}
          type='text'
          required
        />
        <div>
          <label
            htmlFor='Note'
            className='form-label inline-block mb-2 text-gray-700'
          >
            Notes
          </label>
          <textarea
            className='form-control block w-full px-3 py-3 mb-5 text-base font-normal text-gray-700 bg-gray-300 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            value={note}
            onChange={(e) => setNote(e.target.value)}
            id='Note'
            rows='3'
          />
          <div className='flex items-center justify-between'>
            <button
              className='w-full bg-black hover:bg-white hover:text-black border-2 hover:border-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='button'
              onClick={addScenario}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScenarioModalbutton
