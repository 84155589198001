import { useNavigate } from 'react-router-dom'

const TestCaseExecution = (props) => {
  const navigate = useNavigate()

  return (
    <>
      <tr class='border-b border-1 border-customGreyThree'>
        <td class='text-base text-gray-600 font-medium px-8 py-4 whitespace-nowrap '>{props.tc.applicationName}</td>
        <td onClick={() => navigate(`/viewMR/${props.tc.id}/${props.tc.testPackId}`)}><a class='text-base text-green-600 underline underline-offset-4 font-medium px-8 py-4 whitespace-nowrap'>{props.tc.name}</a></td>
      </tr>
    </>
  )
}

export default TestCaseExecution
