import { useState, useEffect, useRef } from 'react'
import {
  notificationTypes,
  openNotification
} from '../../../utils/notifications'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../../config/api'
import styles from '../modal.module.css'
import close from '../../../assets/Icons/close.png'
import CxoSelect from '../../InputComponents/SingleSelectComponent/CxoSelect'
import TextField from '../../InputComponents/InputField'

const AssignRunModal = ({
  showModal,
  setShowModal,
  testpackId
}) => {
  const modalRef = useRef(null)
  const sessionId = useSelector((state) => state.user.sessionId)

  const [getTesterName, setTesterName] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [options, setOptions] = useState({})

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const assignTester = () => {
    const data = {
      name,
      testpack_id: testpackId,
      description
    }
    POST('/assignTester', data, { sessionID: sessionId })
      .then((res) => {
        openNotification(
          notificationTypes.SUCCESS,
          'Test Pack Assigned'
        )
        window.location.reload()
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  useEffect(() => {
    if (showModal) {
      modalRef.current.style.top = '0'
      modalRef.current.style.opacity = '1'
    } else {
      modalRef.current.style.top = '120vh'
      modalRef.current.style.opacity = '0'
    }
    GET('/getTester', { sessionID: sessionId })
      .then((res) => {
        setOptions(res.data.message)
        setTesterName(res.data.message[0].User.id)
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }, [showModal])

  return (
    <div className={styles.wrapper2} ref={modalRef}>
      <div className={styles.cardContainer3}>
        <div className={styles.header}>
          <div className={styles.header}>
            <h2 className={styles.heading1}>Assign Tester</h2>
          </div>
          <div className={styles.close}>
            <a onClick={closeModal}>
              <img className='h-12' src={close} alt='close' />
            </a>
          </div>
        </div>
        <div className='mt-5'>
          <TextField
            label='Name'
            placeholder='Enter Name'
            value={name}
            onChangeHandler={(e) => setName(e.target.value)}
          />
          <div>
            <label for='exampleFormControlTextarea1' class='form-label inline-block mb-2 text-gray-700'>
              description
            </label>
            <textarea
              class='form-control block w-full px-3 py-2 mb-5 text-base font-medium text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              value={description}
              placeholder='Enter Description'
              onChange={(e) => setDescription(e.target.value)}
              id='Note'
              rows='3'
            />
          </div>
          <div className='flex flex-col items-center'>
            <button
              className='bg-customGreenFour text-green-800 border-2 hover:border-green-800 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline'
              onClick={assignTester}
              type='button'
            >
              Add Test Pack
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignRunModal
