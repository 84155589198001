import { Image, Page, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  headerPage: {
    backgroundColor: '#E5F2EC',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const HeaderPage = (props) => {
  return (
    <Page size='A4' style={styles.headerPage}>
      <Image src='./GreentickLogo.png' style={{ height: 80 }} />
      <Text style={{ marginTop: 20, ...props.title }}>
        Test completion Report for-
      </Text>
      <Text style={props.title}>{props.name}</Text>
    </Page>
  )
}

export default HeaderPage
