import { useNavigate } from 'react-router-dom'
import tp from '../../../assets/Icons/testpack.png'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Arrow from '../../../assets/Icons/arrow.png'
import Arrow1 from '../../../assets/Icons/arrowright.png'
import rightclick from '../../../assets/Icons/threedots.png'
import TestPack from './TestPack'

function CxoView () {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)

  const [isCXO, setIsCXO] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const handleCXO = () => {
    setIsCXO(!isCXO)
  }

  return (
    <>
      <li>
        <div
          onMouseOver={MouseOver}
          onMouseOut={MouseOut}
          class='hover:bg-customBlueFour'
        >
          <div
            className='flex items-center p-2 text-base font-bold text-black rounded-lg'
            onClick={() => navigate('/testpackmanagement')}
          >
            {
            isCXO
              ? (
                <img className='mr-2' src={Arrow} alt='Arrow' />
                )
              : (
                <img className='mr-2' src={Arrow1} alt='Arrow' />
                )
          }
            <img src={tp} alt='Sign Up' />
            <span onClick={handleCXO} class='flex-1 ml-3 whitespace-nowrap'>Create CXO view</span>
            <img
              style={{ visibility: isHover ? 'visible' : 'hidden' }}
              src={rightclick}
              className='ml-5'
              alt='Dashboard'
            />
          </div>
        </div>
        <div>
          {
            user == null
              ? (null)
              : (
                  user.accountType === 1
                    ? (
                      <div>
                        {isCXO &&
                        (
                          <TestPack />
                        )}
                      </div>
                      )
                    : (
                      <div />
                      )
                )
          }
        </div>
      </li>
    </>
  )
}

export default CxoView
