import { useState, useEffect } from 'react'
import { documentTitles } from '../../constants/appConstants'
import { useSelector } from 'react-redux'
import pencil from '../../assets/Icons/pencil.png'
import { POST } from '../../config/api'
import { notificationTypes, openNotification } from '../../utils/notifications'
import { useNavigate } from 'react-router-dom'

const AddReleasePlanner = () => {
  const sessionId = useSelector((state) => state.user.sessionId)
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [version, setVersion] = useState('')
  const [desc, setDesc] = useState('')
  const [overallStatus, setOverallStatus] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    document.title = documentTitles.RELEASEPLANNER
  }, [])

  const postRelease = () => {
    const data = {
      name,
      version,
      releaseDate: date,
      description: desc,
      overallStatus
    }

    if (name === '' || date === '' || version === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error: 80210',
        'Please fill all the fields'
      )
      return
    }
    if (overallStatus === '') {
      openNotification(
        notificationTypes.ERROR,
        'Error: 80220',
        'Please select a status'
      )
      return
    }

    POST('/release', data, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Release created Successfully'
          )
          navigate('/releaseplanner')
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Error while creating Release, Please try again later'
          )
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          'Error while creating Release, Please try again later'
        )
      })
  }

  return (
    <div className='bg-customGrey p-3'>
      <div className='grid grid-cols-10 gap-x-4 gap-y-2'>
        <div className='col-span-6'>
          <h1 className='text-5xl ml-2 font-bold font-DMSANS'>
            Add Release
          </h1>
        </div>
        <div className='col-span-3' />

        <div className='col-span-1 flex justify-end'>
          <button
            onClick={() => navigate('/releaseplanner')}
            class='bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 rounded'
          >
            Back
          </button>
        </div>

        <div className='col-span-10 mt-5 flex justify-end'>
          <button
            className='bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end'
            type='button'
            onClick={postRelease}
          >
            Save
          </button>
        </div>

        <div className='col-span-10 mt-5 bg-gray-200 p-4 rounded-xl'>
          <div className='bg-white p-3 rounded-xl'>
            <div className='grid grid-col-10 gap-x-4 gap-y-2'>
              <div className='col-span-10 '>
                <div className='text-sm mt-2' style={{ color: 'grey' }}>
                  Release Name
                </div>
                <h1 className='text-black'>
                  <input
                    type='text'
                    className='p-2 w-full border-2 rounded-lg'
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </h1>
              </div>
              <div className='col-span-3'>
                <div className='text-sm mt-2' style={{ color: 'grey' }}>
                  Release Version
                </div>
                <h1 className='text-black'>
                  <input
                    type='text'
                    className='p-2 w-full border-2 rounded-lg'
                    value={version}
                    required
                    onChange={(e) => setVersion(e.target.value)}
                  />
                </h1>
              </div>

              <div className='col-span-3'>
                <div className='text-sm mt-2' style={{ color: 'grey' }}>
                  Release Date &nbsp;
                </div>
                <h1 className='text-black'>
                  <input
                    type='date'
                    className='p-2 w-full border-2 rounded-lg'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </h1>
              </div>

              <div className='col-span-4'>
                <div className='text-sm mt-2' style={{ color: 'grey' }}>
                  Status &nbsp;
                </div>
                <h1 className='text-black'>
                  <select
                    className='p-2 w-full border-2 rounded-lg'
                    name='overallStatus'
                    id='overallStatus'
                    onChange={(e) => setOverallStatus(e.target.value)}
                  >
                    <option>select</option>
                    <option>New</option>
                    <option>In-Progress</option>
                    <option>Completed</option>
                  </select>
                </h1>
              </div>

              <div className='col-span-10'>
                <div className='text-sm mt-2' style={{ color: 'grey' }}>
                  Description &nbsp;
                </div>
                <h1 className='text-black'>
                  <textarea
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    placeholder='Enter Description'
                    className='p-2 w-full border-2 rounded-lg'
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddReleasePlanner
