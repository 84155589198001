import { GET, DELETE } from '../../../../../../../config/api'
import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  notificationTypes,
  openNotification
} from '../../../../../../../utils/notifications'
import ContextMenu from '../../../../../../ContextMenu'
import UpdateTcModal from '../../../../../../Modal/UpdateTestCase'
import Arrow from '../../../../../../../assets/Icons/arrowright.png'
import User from '../../../../../../../assets/Icons/testcaseicon.png'
import rightclick from '../../../../../../../assets/Icons/threedots.png'

const TestCase = (props) => {
  const navigate = useNavigate()

  const sessionId = useSelector((state) => state.user.sessionId)
  const [isModal, setIsModal] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const MouseOver = () => {
    setIsHover(true)
  }

  const MouseOut = () => {
    setIsHover(false)
  }

  const coRef = useRef(null)

  const viewTC = () => {
    navigate(`/testcase/${props.item.id}/${props.scenarioID}`)
  }

  const modifyTC = () => {
    setIsModal(true)
  }

  const deleteTC = () => {
    DELETE(`/deleteTestcase?testcaseID=${props.item.id}`, { sessionID: sessionId })
      .then((res) => {
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            'Testcase deleted successfully'
          )
          GET(`/getTestCases?scenarioID=${props.scenarioID}`, { sessionID: sessionId })
            .then((res) => {
              if (res.data.status === 200 || res.data.status === 304) {
                props.setSchedule(res.data.message)
              } else {
                openNotification(
                  notificationTypes.ERROR,
                  'Something went wrong, please try again later'
                )
              }
            }
            )
            .catch((err) => {
              openNotification(
                notificationTypes.ERROR,
                'Something went wrong, please try again later'
              )
            })
          // window.location.reload()
        } else {
          openNotification(
            notificationTypes.ERROR,
            'Something went wrong, please try again later'
          )
        }
      }
      )
      .catch((err) => {
        openNotification(
          notificationTypes.ERROR,
          'Something went wrong, please try again later'
        )
      }
      )
  }

  const menuItems = [
    {
      id: 1,
      text: 'Update Test Case',
      handleFunction: modifyTC
    },
    {
      id: 2,
      text: 'Delete Test Case',
      handleFunction: deleteTC
    }
  ]

  return (
    <>
      <div
        className='py-1 font-DMSANS text-sm xl:text-base font-medium ml-8 leading-none lg:leading-11'
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
      >
        <span class='flex items-center hover:bg-customBlueFour p-1 font-normal text-black rounded-lg' ref={coRef} onClick={() => viewTC(props.item.id)}>
          <img className='ml-1 mr-2' src={Arrow} alt='Arrow' />
          <img src={User} className='h-8 mr-2' alt='User' />
          {props.item.testcase_name}
          <img
            style={{ visibility: isHover ? 'visible' : 'hidden' }}
            src={rightclick}
            className='ml-auto'
            alt='Dashboard'
          />
        </span>
        <ContextMenu
          parentRef={coRef}
          items={menuItems}
        />
      </div>
      <UpdateTcModal
        showModal={isModal}
        setShowModal={setIsModal}
        id={props.item.id}
        scenarioID={props.scenarioID}
      />
    </>
  )
}

export default TestCase
